import React, { Component } from 'react';
import moment from 'moment';
import {
  StyledProgessBar,
  StyledInnerProgessBar,
} from './progressBar.styles.js';
import { variables } from 'theme/variables';
const { custom_blue } = variables;

class ProgressBar extends Component {
  constructor(...args) {
    super(...args);
    this.state = {
      progress: 0,
    };
  }

  UNSAFE_componentWillMount() {
    this.startTime = moment();
    this.intervalId = setInterval(() => {
      if (this.state.progress < 95) {
        this.setState({ progress: this.state.progress + 1 });
      } else {
        clearInterval(this.intervalId);
      }
    }, Math.round(this.props.estimatedDuration / 100));
  }

  componentWillUnmount() {
    clearInterval(this.intervalId);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (!this.props.complete && nextProps && nextProps.complete) {
      clearInterval(this.intervalId);
      this.setState({ progress: 100 });
    }
  }

  render() {
    const wrapperStyles = this.props.wrapperStyle || {};
    const styles = this.props.style || {};
    return (
      <StyledProgessBar style={wrapperStyles}>
        <StyledInnerProgessBar
          style={{
            backgroundColor: this.props.color,
            width: this.state.progress + '%',
            ...styles,
          }}
        />
      </StyledProgessBar>
    );
  }
}

ProgressBar.defaultProps = {
  estimatedDuration: 15000,
  color: custom_blue,
};

export default ProgressBar;
