import React, { useEffect } from 'react';
import { useBranch } from 'baobab-react/hooks';
import { keyBy, startCase, map, get } from 'lodash';
import Accordion from 'theme/Accordion';
import { getAttributeCategory } from 'state/attributes/actions';
import { CheckboxList } from 'components/Checkbox/Checkbox';
import { SM } from '@zendeskgarden/react-typography';

const GlobalAttributeFilter = (props) => {
  const { categories = {} } = useBranch({
    categories: ['attributes', 'categories'],
  });

  useEffect(() => {
    getAttributeCategory();
  }, []);

  return (
    <AttributeFilters
      attributeValueFacet={props.attributeValueFacet}
      selectedValues={props.selectedValues}
      categories={categories}
      onChangeCheckboxList={props.onChangeCheckboxList}
    />
  );
};

const AttributeFilters = ({
  onChangeCheckboxList,
  categories,
  selectedValues,
  attributeValueFacet,
}) => {
  const facetBucketCounts = keyBy(get(attributeValueFacet, 'buckets'), 'value');
  function onChange(item, checked) {
    onChangeCheckboxList(
      { ...item, key: item.value, type: 'attribute_values' },
      checked
    );
  }

  return (
    <div>
      {map(get(categories, 'result'), (category, i) => {
        const selectedValuesList =
          selectedValues && selectedValues['attribute_values'];
        return (
          <Accordion key={i} title={`${startCase(category.name)}`}>
            {category.value ? (
              <CheckboxList
                selected={selectedValuesList}
                onChange={onChange}
                items={map(category.value, (v) => ({
                  ...v,
                  value: v.id,
                  label: v.value,
                  count: get(facetBucketCounts[v.id], 'count', '0'),
                }))}
              />
            ) : null}

            {map(get(category, 'sub_category'), (sc, i) => {
              return (
                <div style={{ paddingLeft: '4px' }} key={i}>
                  <SM paddingBottomSm bold>
                    {sc.name}
                  </SM>
                  {sc.value ? (
                    <div style={{ paddingLeft: '10px' }}>
                      <CheckboxList
                        selected={selectedValuesList}
                        onChange={onChange}
                        items={map(sc.value, (v) => ({
                          ...v,
                          value: v.id,
                          label: v.value,
                          count: get(facetBucketCounts[v.id], 'count', '0'),
                        }))}
                      />
                    </div>
                  ) : null}
                </div>
              );
            })}
          </Accordion>
        );
      })}
    </div>
  );
};

export default GlobalAttributeFilter;
