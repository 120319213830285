import React from 'react';
import { variables } from 'theme/variables';
const { custom_button_blue, color_white, custom_pale_blue } = variables;

const SVGLogo = (props) => {
  const blueStyle = {
    fill: custom_button_blue,
    transition: 'fill 0.2s linear',
  };
  const blueStyleB = { fill: custom_pale_blue, transition: 'fill 0.2s linear' };
  const whiteStyle = { fill: color_white, transition: 'fill 0.2s linear' };
  return (
    <svg viewBox="0 0 196 44">
      <g fill="none" fillRule="evenodd">
        <path
          style={props.white ? whiteStyle : blueStyle}
          className="a"
          d="M23.958 34.848a3.489 3.489 0 0 0-3.485 3.485 3.489 3.489 0 0 0 3.485 3.485 3.489 3.489 0 0 0 3.485-3.485 3.489 3.489 0 0 0-3.485-3.485m0 8.277a4.797 4.797 0 0 1-4.791-4.792 4.797 4.797 0 0 1 4.791-4.791 4.797 4.797 0 0 1 4.792 4.791 4.797 4.797 0 0 1-4.792 4.792"
        />
        <path
          style={props.white ? whiteStyle : blueStyle}
          className="a"
          d="M14.375 34.848a3.489 3.489 0 0 0-3.485 3.485 3.489 3.489 0 0 0 3.485 3.485 3.489 3.489 0 0 0 3.485-3.485 3.489 3.489 0 0 0-3.485-3.485m0 8.277a4.797 4.797 0 0 1-4.792-4.792 4.797 4.797 0 0 1 4.792-4.791 4.797 4.797 0 0 1 4.792 4.791 4.797 4.797 0 0 1-4.792 4.792"
        />
        <path
          style={props.white ? whiteStyle : blueStyle}
          className="a"
          d="M33.542 34.848a3.489 3.489 0 0 0-3.485 3.485 3.489 3.489 0 0 0 3.485 3.485 3.489 3.489 0 0 0 3.485-3.485 3.489 3.489 0 0 0-3.485-3.485m0 8.277a4.797 4.797 0 0 1-4.792-4.792 4.797 4.797 0 0 1 4.792-4.791 4.797 4.797 0 0 1 4.791 4.791 4.797 4.797 0 0 1-4.791 4.792"
        />
        <path
          style={props.white ? whiteStyle : blueStyle}
          className="a"
          d="M23.958 1.307a3.489 3.489 0 0 0-3.485 3.485 3.489 3.489 0 0 0 3.485 3.485 3.489 3.489 0 0 0 3.485-3.485 3.489 3.489 0 0 0-3.485-3.485m0 8.276a4.797 4.797 0 0 1-4.791-4.791A4.797 4.797 0 0 1 23.958 0a4.797 4.797 0 0 1 4.792 4.792 4.797 4.797 0 0 1-4.792 4.791"
        />
        <path
          style={props.white ? whiteStyle : blueStyle}
          className="a"
          d="M14.375 1.307a3.489 3.489 0 0 0-3.485 3.485 3.489 3.489 0 0 0 3.485 3.485 3.489 3.489 0 0 0 3.485-3.485 3.489 3.489 0 0 0-3.485-3.485m0 8.276a4.797 4.797 0 0 1-4.792-4.791A4.797 4.797 0 0 1 14.375 0a4.797 4.797 0 0 1 4.792 4.792 4.797 4.797 0 0 1-4.792 4.791"
        />
        <path
          style={props.white ? whiteStyle : blueStyle}
          className="a"
          d="M33.542 1.307a3.489 3.489 0 0 0-3.485 3.485 3.489 3.489 0 0 0 3.485 3.485 3.489 3.489 0 0 0 3.485-3.485 3.489 3.489 0 0 0-3.485-3.485m0 8.276a4.797 4.797 0 0 1-4.792-4.791A4.797 4.797 0 0 1 33.542 0a4.797 4.797 0 0 1 4.791 4.792 4.797 4.797 0 0 1-4.791 4.791"
        />
        <path
          style={props.white ? whiteStyle : blueStyle}
          className="a"
          d="M28.75 26.463a3.489 3.489 0 0 0-3.485 3.485 3.489 3.489 0 0 0 3.485 3.485 3.489 3.489 0 0 0 3.485-3.485 3.489 3.489 0 0 0-3.485-3.485m0 8.277a4.797 4.797 0 0 1-4.792-4.792 4.797 4.797 0 0 1 4.792-4.792 4.797 4.797 0 0 1 4.792 4.792 4.797 4.797 0 0 1-4.792 4.792"
        />
        <path
          style={props.white ? whiteStyle : blueStyle}
          className="a"
          d="M19.167 26.463a3.489 3.489 0 0 0-3.485 3.485 3.489 3.489 0 0 0 3.485 3.485 3.489 3.489 0 0 0 3.485-3.485 3.489 3.489 0 0 0-3.485-3.485m0 8.277a4.797 4.797 0 0 1-4.792-4.792 4.797 4.797 0 0 1 4.792-4.792 4.797 4.797 0 0 1 4.791 4.792 4.797 4.797 0 0 1-4.791 4.792"
        />
        <path
          style={props.white ? whiteStyle : blueStyle}
          className="a"
          d="M9.583 26.463a3.489 3.489 0 0 0-3.485 3.485 3.489 3.489 0 0 0 3.485 3.485 3.489 3.489 0 0 0 3.485-3.485 3.489 3.489 0 0 0-3.485-3.485m0 8.277a4.797 4.797 0 0 1-4.791-4.792 4.797 4.797 0 0 1 4.791-4.792 4.797 4.797 0 0 1 4.792 4.792 4.797 4.797 0 0 1-4.792 4.792"
        />
        <path
          style={props.white ? whiteStyle : blueStyle}
          className="a"
          d="M38.531 26.463a3.489 3.489 0 0 0-3.485 3.485 3.489 3.489 0 0 0 3.485 3.485 3.489 3.489 0 0 0 3.485-3.485 3.489 3.489 0 0 0-3.485-3.485m0 8.277a4.797 4.797 0 0 1-4.791-4.792 4.797 4.797 0 0 1 4.791-4.792 4.797 4.797 0 0 1 4.792 4.792 4.797 4.797 0 0 1-4.792 4.792"
        />
        <path
          style={props.white ? whiteStyle : blueStyle}
          className="b"
          d="M28.75 9.692a3.489 3.489 0 0 0-3.485 3.485 3.489 3.489 0 0 0 3.485 3.485 3.489 3.489 0 0 0 3.485-3.485 3.489 3.489 0 0 0-3.485-3.485m0 8.277a4.797 4.797 0 0 1-4.792-4.792 4.797 4.797 0 0 1 4.792-4.792 4.797 4.797 0 0 1 4.792 4.792 4.797 4.797 0 0 1-4.792 4.792"
        />
        <path
          style={props.white ? whiteStyle : blueStyle}
          className="a"
          d="M19.167 9.692a3.489 3.489 0 0 0-3.485 3.485 3.489 3.489 0 0 0 3.485 3.485 3.489 3.489 0 0 0 3.485-3.485 3.489 3.489 0 0 0-3.485-3.485m0 8.277a4.797 4.797 0 0 1-4.792-4.792 4.797 4.797 0 0 1 4.792-4.792 4.797 4.797 0 0 1 4.791 4.792 4.797 4.797 0 0 1-4.791 4.792"
        />
        <path
          style={props.white ? whiteStyle : blueStyle}
          className="a"
          d="M9.583 9.692a3.489 3.489 0 0 0-3.485 3.485 3.489 3.489 0 0 0 3.485 3.485 3.489 3.489 0 0 0 3.485-3.485 3.489 3.489 0 0 0-3.485-3.485m0 8.277a4.797 4.797 0 0 1-4.791-4.792 4.797 4.797 0 0 1 4.791-4.792 4.797 4.797 0 0 1 4.792 4.792 4.797 4.797 0 0 1-4.792 4.792"
        />
        <path
          style={props.white ? whiteStyle : blueStyle}
          className="a"
          d="M38.531 9.692a3.489 3.489 0 0 0-3.485 3.485 3.489 3.489 0 0 0 3.485 3.485 3.489 3.489 0 0 0 3.485-3.485 3.489 3.489 0 0 0-3.485-3.485m0 8.277a4.797 4.797 0 0 1-4.791-4.792 4.797 4.797 0 0 1 4.791-4.792 4.797 4.797 0 0 1 4.792 4.792 4.797 4.797 0 0 1-4.792 4.792"
        />
        <path
          style={props.white ? whiteStyle : blueStyle}
          className="a"
          d="M33.542 18.078a3.489 3.489 0 0 0-3.485 3.485 3.489 3.489 0 0 0 3.485 3.484 3.489 3.489 0 0 0 3.485-3.485 3.489 3.489 0 0 0-3.485-3.484m0 8.276a4.797 4.797 0 0 1-4.792-4.791 4.797 4.797 0 0 1 4.792-4.792 4.797 4.797 0 0 1 4.791 4.791 4.797 4.797 0 0 1-4.791 4.792"
        />
        <path
          style={props.white ? whiteStyle : blueStyle}
          className="a"
          d="M23.958 18.078a3.489 3.489 0 0 0-3.485 3.485 3.489 3.489 0 0 0 3.485 3.484 3.489 3.489 0 0 0 3.485-3.485 3.489 3.489 0 0 0-3.485-3.484m0 8.276a4.797 4.797 0 0 1-4.791-4.791 4.797 4.797 0 0 1 4.791-4.792 4.797 4.797 0 0 1 4.792 4.791 4.797 4.797 0 0 1-4.792 4.792"
        />
        <path
          style={props.white ? whiteStyle : blueStyle}
          className="a"
          d="M14.375 18.078a3.489 3.489 0 0 0-3.485 3.485 3.489 3.489 0 0 0 3.485 3.484 3.489 3.489 0 0 0 3.485-3.485 3.489 3.489 0 0 0-3.485-3.484m0 8.276a4.797 4.797 0 0 1-4.792-4.791 4.797 4.797 0 0 1 4.792-4.792 4.797 4.797 0 0 1 4.792 4.791 4.797 4.797 0 0 1-4.792 4.792"
        />
        <path
          style={props.white ? whiteStyle : blueStyle}
          className="a"
          d="M4.792 18.078a3.489 3.489 0 0 0-3.485 3.485 3.489 3.489 0 0 0 3.485 3.484 3.489 3.489 0 0 0 3.485-3.485 3.489 3.489 0 0 0-3.485-3.484m0 8.276A4.797 4.797 0 0 1 0 21.563a4.797 4.797 0 0 1 4.792-4.792 4.797 4.797 0 0 1 4.791 4.791 4.797 4.797 0 0 1-4.791 4.792"
        />
        <path
          style={props.white ? whiteStyle : blueStyle}
          className="a"
          d="M43.125 18.078a3.489 3.489 0 0 0-3.485 3.485 3.489 3.489 0 0 0 3.485 3.484 3.489 3.489 0 0 0 3.485-3.485 3.489 3.489 0 0 0-3.485-3.484m0 8.276a4.797 4.797 0 0 1-4.792-4.791 4.797 4.797 0 0 1 4.792-4.792 4.797 4.797 0 0 1 4.792 4.791 4.797 4.797 0 0 1-4.792 4.792"
        />
        <path
          style={props.white ? whiteStyle : blueStyle}
          className="a"
          fillRule="nonzero"
          d="M63.469 18.188c0 2.427-.612 4.289-1.836 5.585-1.224 1.297-2.992 1.946-5.305 1.946h-1.062V34h-4.25V11.156h5.797c4.437 0 6.656 2.344 6.656 7.031zm-8.203 3.843h.812c1.042 0 1.818-.286 2.328-.86.51-.572.766-1.51.766-2.812 0-1.198-.234-2.086-.703-2.664-.469-.578-1.193-.867-2.172-.867h-1.031v7.203zM67.109 34V11.156h4V34h-4zm21.282-15.813c0 2.428-.612 4.29-1.836 5.586-1.224 1.297-2.992 1.946-5.305 1.946h-1.063V34h-4.25V11.156h5.797c4.438 0 6.657 2.344 6.657 7.031zm-8.204 3.844H81c1.042 0 1.818-.286 2.328-.86.51-.572.766-1.51.766-2.812 0-1.198-.235-2.086-.703-2.664-.47-.578-1.193-.867-2.172-.867h-1.031v7.203zM101.564 34H91.266V11.156h10.296v3.672h-6.046v5.438h5.609v3.687h-5.61v6.328h6.047V34z"
        />
        <path
          style={props.white ? whiteStyle : blueStyleB}
          className="b"
          fillRule="nonzero"
          d="M114.266 28.016c0 1.875-.521 3.393-1.563 4.554-1.042 1.162-2.364 1.742-3.969 1.742-1.823 0-3.286-.265-4.39-.796v-1.657c.541.302 1.213.542 2.015.72.802.176 1.594.265 2.375.265 1.167 0 2.125-.443 2.875-1.328.75-.886 1.125-2.01 1.125-3.375 0-1.26-.265-2.26-.796-3-.532-.74-1.547-1.48-3.047-2.22-1.177-.593-2.058-1.168-2.641-1.726a5.235 5.235 0 0 1-1.297-1.945c-.281-.74-.422-1.625-.422-2.656 0-1.125.23-2.125.688-3a5.008 5.008 0 0 1 1.922-2.04 5.233 5.233 0 0 1 2.703-.726c.885 0 1.69.1 2.414.297.724.198 1.294.411 1.71.64l-.593 1.47c-1.156-.594-2.333-.891-3.531-.891-1.125 0-2.04.383-2.742 1.148-.704.766-1.055 1.779-1.055 3.04 0 1.28.255 2.275.766 2.984.51.708 1.51 1.442 3 2.203 1.541.75 2.669 1.627 3.382 2.633.714 1.005 1.07 2.226 1.07 3.664zM126.422 34h-8.281V11.156h8.28v1.469h-6.75v8.672h6.376v1.469h-6.375v9.765h6.75V34zm12.672 0l-2.016-8.281h-5.547L129.547 34h-1.594l5.5-22.844h1.578L140.656 34h-1.562zm-2.375-9.828l-2.016-8.516a34.318 34.318 0 0 1-.484-2.719c-.094.907-.24 1.813-.438 2.72l-1.953 8.515h4.89zm7.969-.156V34h-1.532V11.156h3.125c2.23 0 3.86.526 4.89 1.578 1.032 1.052 1.548 2.625 1.548 4.72 0 1.582-.281 2.885-.844 3.905-.563 1.021-1.505 1.787-2.828 2.297L153.578 34h-1.719l-4.265-9.984h-2.906zm0-1.407h2.14c1.386 0 2.453-.427 3.203-1.28.75-.855 1.125-2.126 1.125-3.813 0-1.72-.383-2.959-1.148-3.72-.766-.76-1.972-1.14-3.617-1.14h-1.703v9.953zm18.515-10.343c-1.719 0-3.086.908-4.101 2.726-1.016 1.818-1.524 4.336-1.524 7.555 0 2.094.234 3.917.703 5.469.469 1.552 1.14 2.744 2.016 3.578.875.833 1.89 1.25 3.047 1.25 1.187 0 2.208-.214 3.062-.64v1.405c-.812.47-1.88.703-3.203.703-1.448 0-2.716-.468-3.805-1.406-1.088-.937-1.93-2.294-2.523-4.07-.594-1.776-.89-3.883-.89-6.32 0-3.646.648-6.51 1.945-8.594 1.297-2.083 3.065-3.125 5.304-3.125 1.396 0 2.615.323 3.657.969l-.703 1.343c-.876-.562-1.87-.843-2.985-.843zM181.22 34h-1.531V22.766h-7.97V34h-1.53V11.156h1.53v10.14h7.97v-10.14h1.53V34z"
        />
      </g>
      <g>
        <text
          id="TM"
          fontFamily="Helvetica"
          fontSize="7"
          fontWeight="normal"
          style={props.white ? whiteStyle : blueStyleB}
        >
          <tspan x="184" y="16">
            TM
          </tspan>
        </text>
      </g>
    </svg>
  );
};

export default SVGLogo;
