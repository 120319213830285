import React, { Component } from 'react';
import { canUseDOM } from 'exenv';
import menuAim from 'higherOrder/menuAim';
import { NavLink as Link } from 'react-router-dom';
import NavigationDropdownMenu from './NavigationDropdownMenu';
import { get, map, find } from 'lodash';
import { StyledMenu } from './ProductsDropdownMenu.styles';
const linkArray = [
  { link: '/technical-content', title: 'Technical Content' },
  { title: 'Industry News', link: '/industry-news' },
  { title: 'Company News', link: '/company-news' },
  { title: 'Articles', link: '/blog' },
]; //{title: "Our Team", link: "/our-team"}

class CompanyDropdownMenu extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    this.props.initMenuAim({
      menuRef: this.menuRef,
      delay: 300,
      tolerance: 75,
    });
    this.setState({
      render: true,
      selectedCategory: find(linkArray, { link: this.props.location.pathname }),
    });
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const inLinkArray = find(linkArray, { link: nextProps.location.pathname });
    if (this.state.selectedCategory && !inLinkArray) {
      this.setState({
        selectedCategory: false,
        showCategories: false,
        subcategory: null,
      });
    }
  }

  render() {
    const rootSelected =
      this.state.render &&
      canUseDOM &&
      this.props.location.pathname === '/blog';
    return (
      <StyledMenu>
        <NavigationDropdownMenu
          isActive={this.state.selectedCategory || rootSelected}
          link={`/blog`}
          customTitle="Resources"
          className={`dropdown-menu`}
          onClickMenu={this.onClickMenu.bind(this)}
          onClose={this.onClose.bind(this)}
          {...this.props}
        >
          <div
            className="categories"
            onMouseLeave={this.props.handleMouseLeaveMenu}
            ref={(r) => (this.menuRef = r)}
          >
            {map(linkArray, ({ title, link }, i) => {
              return (
                <div className="link-wrapper" key={i}>
                  <Link
                    onClick={this.onClickCategory.bind(this, { title, link })}
                    key={i}
                    to={link}
                    className={`dropdown-link ${
                      link === get(this.state, 'selectedCategory.link')
                        ? 'active'
                        : ''
                    }`}
                  >
                    <h5>{title}</h5>
                  </Link>
                </div>
              );
            })}
          </div>
        </NavigationDropdownMenu>
      </StyledMenu>
    );
  }

  onClickMenu() {
    return !this.state.selectedCategory;
  }

  onClose() {
    this.setState({});
  }

  onCloseCategories() {
    this.setState({
      selectedCategory: false,
      showCategories: false,
      subcategory: null,
    });
  }

  onClickCategory(category) {
    this.setState({
      selectedCategory: category,
    });
  }
}

export default menuAim(CompanyDropdownMenu);
