import styled from 'styled-components/macro';
import { variables } from 'theme/variables';
import { Row, Col } from '@zendeskgarden/react-grid';
import { Anchor } from '@zendeskgarden/react-buttons';

const {
  custom_blue: customBlue,
  color_white: colorWhite,
  custom_grey: customGrey,
  color_grey_100: colorGrey100,
  color_grey_300: colorGrey300,
} = variables;

export const ActionRow = styled(Row)`
  margin-left: 5px;
  padding: 5px 6px;
  background-color: ${colorGrey100};
  position: sticky;
  top: 0px;
  height: 40px;
  max-height: 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 2;
`;

export const HeaderActionIconCol = styled(Col)`
  display: flex;
  align-items: center;
  height: 100%;
  .tooltipStyling {
    max-width: 500px;
    border-radius: 20px;
    color: white;
    box-shadow: 0 4px 16px #c0cff7;
    background-color: ${customBlue};
    border: none;
  }
  @media (max-width: 576px) {
    align-items: baseline;
    height: inherit;
  }
`;

export const CredentialButton = styled(Anchor)`
  border: none !important;
  background-color: transparent !important;
  color: ${customBlue};
  font-size: 1.2rem;
  font-weight: 700;
  padding: 5px 10px;
  min-width: 100px;
  &:hover {
    background-color: ${colorGrey300} !important;
    font-weight: 800;
  }
`;

export const WishlistButton = styled.button`
  ${(props) => {
    if (props.mainPage) {
      return props.smallHeader
        ? `
        margin-bottom: 10px;
        @media (max-width: 1038px) {
          margin-top: 5px;
          margin-bottom: 0px;
        }
      `
        : `
        margin-top: 10px;
      `;
    }
    return `
      margin-bottom: 10px;
      @media (max-width: 1038px) {
        margin-top: 5px;
        margin-bottom: 0px;
      }  
    `;
  }};
  height: 30px;
  width: 30px;

  margin-left: 10px;
  border: 1px solid ${customBlue};
  border-radius: 6px;
  background: white;
  z-index: 5;
  transition: background 0.33s;
  :hover {
    background: ${customBlue};
    span {
      color: white !important;
    }
  }
`;

export const WishlistCountBubble = styled('div')`
  height: 20px;
  width: 20px;
  border-radius: 50%;
  background-color: ${customBlue};
  color: ${colorWhite};
  font-size: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  top: -35px;
  left: 22px;
`;

export const RefactoredDashboardHeader = styled.header`
  background-color: ${colorWhite};
  width: 100%;
  height: 85px;
  padding: 10px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 0;
  .navLinksRow {
    flex-wrap: noWrap;
    margin-bottom: 1em;
  }
  .animate-logo {
    position: relative;
    z-index: 5;
    margin: 10px;
    margin-left: 10px;
  }
  .dashboardNavLinks {
    min-width: 60%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    height: 100%;
  }
  .mobileNavLinks {
    display: none;
  }
  .dashboardActionButtons {
    display: flex;
    flex-wrap: noWrap;
    justify-content: center;
  }
  .signInButton {
    max-height: 30px;
    max-width: 125px;
    margin-top: 10px;
  }
  .contactUsButton {
    max-height: 30px;
    max-width: 125px;
    margin-top: 10px;
    margin-left: 10px;
  }
  .dropdown-menu {
    margin-top: 15px !important;
    margin-right: 2em !important;
    z-index: 10;
  }
  #mobileMenuDropdown {
    box-shadow: none;
    min-height: 460px;
    min-width: 300px;
    right: 70px;
    border-radius: 10px;
    z-index: 7 !important;
  }
  #mobileMenuDropdown > div {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    margin: 10px 0;
    z-index: 7 !important;
  }
  .menuLink {
    padding: 6px 0px 8px 20px;
    color: ${customBlue};
    font-weight: 500;
    text-align: start;
    font-size: 18px;
    &:hover {
      background-color: ${customBlue} !important;
      color: ${colorWhite} !important;
    }
  }
  @media (max-width: 2000px) {
    background-size: 50% 100%;
  }
  @media (max-width: 1490px) {
    .dashboardActionButtons {
      padding-left: 0;
      margin-left: -20px;
    }
  }
  @media (max-width: 1440px) {
    background-size: 50% 80%;
    .dashboardNavLinks {
      flex-wrap: wrap;
      min-width: 60%;
    }
  }
  @media (max-width: 1400px) {
    .dashboardActionButtons {
      margin-left: -30px;
    }
  }
  @media (max-width: 1375px) {
    .dashboardNavLinks {
      font-size: 0.8rem;
      min-width: 40%;
    }
    .dashboardActionButtons {
      margin-left: -25px;
    }
  }
  @media (max-width: 1038px) {
    background-image: none;
    .dashboardActionButtons {
      display: flex;
      flex-wrap: wrap;
      margin-top: -15px;
      justify-content: center;
    }
    .signInButton,
    .contactUsButton {
      margin-bottom: 5px;
    }
    .dashboardNavLinks {
      max-height: 25px;
    }
  }
  @media (max-width: 885px) {
    .navLinksRow {
      margin-bottom: 0;
    }
  }
  @media (max-width: 768px) {
    max-height: 290px;
    z-index: 1;
    div.Col-sc-1anpd9s-0.bfYPGL.mobileNavLinks.index__col___2kq1L > div {
      z-index: 7;
    }
    .dashboardNavLinks,
    .dashboardActionButtons {
      display: none;
    }
    .mobileNavLinks {
      max-width: 80px;
      height: 60px;
      display: flex;
      justify-content: center;
      align-items: center;
      z-index: 7 !important;
    }
    .pipesearchLogo {
      min-width: 25%;
    }
    .dashboardActionButtons {
      min-width: 25%;
    }
    flex-wrap: column wrap;
    background-image: none;
  }
  @media (max-width: 666px) {
    max-height: 340px;
  }
  @media (min-width: 550px) {
    width: 100%;
    height: 6.28rem;
    margin-left: 0%;
    margin-right: 0%;
    margin-top: 0%;
    border-radius: unset;
    box-shadow: none;
    padding: 10px;
  }
  @media (max-width: 470px) {
    max-height: 100px;
    height: 100px;
  }

  @keyframes typingLarge {
    from {
      width: 0;
    }
    to {
      width: 425px;
    }
  }
  @keyframes typingMedium {
    from {
      width: 0;
    }
    to {
      width: 608px;
    }
  }
  @keyframes typingSmall {
    from {
      width: 0;
    }
    to {
      width: 436px;
    }
  }
  @keyframes blink-caret {
    from,
    to {
      border-color: transparent;
    }
    50% {
      border-color: ${customGrey};
    }
  }
`;

export const PipesearchLogo = styled('div')`
  background-image: url('/images/pipesearchLogoV2.svg');
  background-repeat: no-repeat;
  background-size: 85%;
  height: 70px;
  width: 300px;
  position: relative;
  z-index: 5;
  @media (max-width: 1660px) {
    background-size: 66%;
  }
`;

export const MobileMenuButton = styled.button`
  height: 30px;
  width: 30px;
  margin-top: 10px;
  margin-left: 10px;
  border: 1px solid ${customBlue};
  border-radius: 6px;
  background: white;
  z-index: 5;
  transition: background 0.33s;
  #menuButton {
    color: ${customBlue};
  }
  :hover {
    background: ${customBlue};
    #menuButton {
      color: ${colorWhite};
    }
  }
`;
