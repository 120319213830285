import React, { useState, useEffect, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import {
  groupBy,
  cloneDeep,
  findIndex,
  filter,
  map,
  get,
  find,
  forEach,
  reject,
} from 'lodash';
import Flex from 'styled-flex-component';
import { LG, SM } from '@zendeskgarden/react-typography';
import { Col, Row } from '@zendeskgarden/react-grid';
import { Tag } from '@zendeskgarden/react-tags';

import Checkbox from 'components/Checkbox/Checkbox';
import { variables } from 'theme/variables';
import {
  EndFinishFilterItemSelectionWrapper,
  SelectionCol,
  Item,
  EmptySelectionCol,
  SetPrimaryAlloyButton,
  PrimaryAlloyNonInteractiveCheckbox,
} from './AlloyFilterItemSelectionTwo.styles';
import AlloyFilterItemSelectionTwoResults from './components/AlloyFilterItemSelectionTwoResults/AlloyFilterItemSelectionTwoResults';

const { custom_blue: customBlue } = variables;

function AlloyFilterItemSelectionTwoItemSelection({
  updateTotalSelected,
  setSelectedValue,
  filteredAlloyValue,
  clearAlloyFilterValue,
  optionValues,
  selectionFilters,
  selectedValue,
  selectedFilters,
  inputValue,
  onClose,
  allowMultiple,
  disablePrimaryAlloy,
}) {
  const [initiated, setInitiated] = useState();
  const [activeState, setActiveState] = useState({
    alloys: [],
    activeGroups: [],
  });

  // We don't want to show Primary Alloy information if the user can only select one alloy
  // For backward compatibility, multiple alloy selection has been allowed unless multiple is explicity set to false in the transformer.
  const primaryAlloyEnabled = !disablePrimaryAlloy && allowMultiple !== false;

  const { activeGroups, alloyGroups, alloys } = activeState;

  const selectedAlloys = useMemo(() => filter(alloys, 'selected'), [alloys]);
  const primaryAlloy = useMemo(
    () => find(selectedAlloys, 'primary_alloy'),
    [selectedAlloys]
  );
  const totalSelected = useMemo(
    () => filter(alloys, ({ selected }) => selected).length,
    [alloys]
  );

  const chooseAlloy = useCallback(
    (_alloys, _activeGroups) => {
      const selected = filter(_alloys, 'selected');
      updateTotalSelected(selected.length + get(_activeGroups, 'length', 0));
      setSelectedValue(selected, {
        selectedFilters: { groups: _activeGroups },
      });
    },
    [setSelectedValue, updateTotalSelected]
  );

  useEffect(() => {
    updateTotalSelected(totalSelected);
  }, [totalSelected, updateTotalSelected]);

  const onSelectGroup = useCallback(
    (selectedGroup, selected) => {
      let cf;
      if (!selected) {
        cf = [...activeGroups, selectedGroup];
      } else {
        const index = findIndex(activeGroups, selectedGroup);
        cf = [...activeGroups];
        cf.splice(index, 1);
      }

      let alloyItems = alloys;
      if (!selected) {
        alloyItems = map(alloys, (a) => {
          const obj = { ...a };
          if (selectedGroup.value === obj.group) {
            obj.selected = false;
            // Remove primary alloy here because we are deslecting alloys that belong to this selected group
            obj.primary_alloy = false;
          }
          return obj;
        });
      }

      setActiveState({
        ...activeState,
        alloys: alloyItems,
        activeGroups: cf,
      });
      chooseAlloy(alloyItems, cf);
    },
    [activeGroups, activeState, alloys, chooseAlloy]
  );

  const onSelectItem = useCallback(
    (item, custom) => {
      const items = cloneDeep(alloys);
      if (custom === true) {
        items.push(item);
      }
      // Allow for cases where filters are generated from a different transformer which may not have set 'multiple' at all.
      // We are treating multiple === true as default
      if (allowMultiple === false) {
        forEach(items, (_element, index) => {
          items[index].selected = false;
        });
      }
      const itemInList = find(items, item);
      const primaryAlloyExists = !!find(items, 'primary_alloy');
      if (itemInList) {
        // Deselecting
        if (itemInList.selected) {
          // Replace the Primary Alloy if the existing one is deselected
          const firstOtherSelectedAlloy = find(
            items,
            (alloy) => alloy.value !== itemInList.value && alloy.selected
          );
          // We may be deselecting the last selected alloy.
          if (
            firstOtherSelectedAlloy &&
            itemInList.primary_alloy &&
            primaryAlloyEnabled
          ) {
            firstOtherSelectedAlloy.primary_alloy = true;
          }
          itemInList.primary_alloy = false;
        } else if (
          !itemInList.selected &&
          !primaryAlloyExists &&
          primaryAlloyEnabled
        ) {
          // Selecting
          itemInList.primary_alloy = true;
        }
        // We set or unset selected after our above mutations, because they depend on previous state
        itemInList.selected = !itemInList.selected;
      }

      const removeItemsGroup = reject(activeGroups, ['value', item.group]);

      setActiveState({
        ...activeState,
        alloys: items,
        activeGroups: removeItemsGroup,
      });
      chooseAlloy(items, removeItemsGroup);
    },
    [activeGroups, activeState, allowMultiple, alloys, chooseAlloy, primaryAlloyEnabled]
  );

  useEffect(() => {
    // when searching values, the event bubbles to root dropdown. we then pass that down to this componnet
    // where we set it. not the best performance but works
    // this is not ideal but it works.
    if (filteredAlloyValue?.alloy?.value) {
      const v = filteredAlloyValue;
      if (v.alloy.type) {
        onSelectGroup(v.alloy, v.selected);
      } else {
        onSelectItem(v.alloy, v.customValue);
      }
      clearAlloyFilterValue();
    }
  }, [
    clearAlloyFilterValue,
    filteredAlloyValue,
    filteredAlloyValue?.alloy?.value,
    onSelectGroup,
    onSelectItem,
  ]);

  function initInitialSelectedAlloys(_alloys, _selectedAlloys) {
    const items = map(_alloys, (g) => {
      const currentVg = find(_selectedAlloys, { value: g.value });
      return {
        ...g,
        selected: !!currentVg,
        primary_alloy: currentVg?.primary_alloy,
      };
    });

    const customValues = filter(_selectedAlloys, 'customValue');

    return [...items, ...customValues];
  }

  function handleSetPrimaryAlloy(alloyId) {
    const newAlloyList = map(alloys, (alloy) => ({
      ...alloy,
      primary_alloy: alloy.value === alloyId,
    }));
    setActiveState({
      ...activeState,
      alloys: newAlloyList,
    });
    const newSelected = map(filter(alloys, 'selected'), (alloy) => ({
      ...alloy,
      primary_alloy: alloy.value === alloyId,
    }));
    setSelectedValue(newSelected, {
      selectedFilters: { groups: activeGroups },
    });
  }

  useEffect(() => {
    if (!initiated) {
      const alloyItems = optionValues || [];
      const availableGroups = selectionFilters?.groups || [];

      const _selectedAlloys = selectedValue || [];
      const selectedGroups = selectedFilters?.groups || [];
      const selectedValueItems = initInitialSelectedAlloys(
        alloyItems,
        _selectedAlloys
      );

      const defaultState = {
        ...activeState,
        alloys: selectedValueItems,
        alloyGroups: availableGroups,
        activeGroups: selectedGroups,
      };

      setActiveState(defaultState);
      setInitiated(true);
    }
  }, [
    activeState,
    initiated,
    optionValues,
    selectedFilters?.groups,
    selectedValue,
    selectionFilters?.groups,
  ]);

  if (!alloys.length) {
    return <div />;
  }
  if (inputValue) {
    return (
      <AlloyFilterItemSelectionTwoResults
        inputValue={inputValue}
        activeState={activeState}
      />
    );
  }
  return (
    <EndFinishFilterItemSelectionWrapper>
      <Flex justifyBetween alignCenter>
        <LG bold>Choose Alloy</LG>
        <SM bold justifyEnd onClick={onClose} link>
          Done
        </SM>
      </Flex>

      <Row style={{ marginTop: 0 }}>
        <Col sm={6} size={10}>
          <SelectionCol>
            {alloys.length ? (
              map(alloyGroups, (group) => {
                const { label, value } = group;
                const selected = find(activeGroups, { value });
                return (
                  <div key={`${value}-${label}`}>
                    {allowMultiple !== false ? (
                      <Item
                        style={{ background: 'rgb(243, 243, 243)' }}
                        onClick={() => onSelectGroup(group, selected)}
                        key={`${value}-${label}`}
                      >
                        <Flex alignCenter>
                          <Checkbox checked={selected} />
                          <SM bold>{label}</SM>
                        </Flex>
                      </Item>
                    ) : (
                      <Item
                        style={{
                          background: 'rgb(243, 243, 243)',
                          cursor: 'auto',
                        }}
                        key={`${value}-${label}`}
                      >
                        <Flex alignCenter>
                          <SM bold>{label}</SM>
                        </Flex>
                      </Item>
                    )}
                    <div>
                      {map(
                        filter(alloys, ['group', group.value]),
                        (alloyItem) => {
                          const {
                            label: _label,
                            value: _value,
                            selected: _selected,
                          } = alloyItem;
                          return (
                            <Item
                              onClick={() => onSelectItem(alloyItem)}
                              key={`${_value}-${_label}`}
                            >
                              <Flex alignCenter>
                                <Checkbox checked={_selected} />
                                {_label}
                              </Flex>
                            </Item>
                          );
                        }
                      )}
                    </div>
                  </div>
                );
              })
            ) : (
              <EmptySelectionCol>No Items Match Criteria</EmptySelectionCol>
            )}
          </SelectionCol>
        </Col>

        <Col sm={6} size={6}>
          <SelectionCol>
            {!activeGroups.length && !selectedAlloys.length ? (
              <EmptySelectionCol>No Items Have Been Selected</EmptySelectionCol>
            ) : null}
            {map(activeGroups, (_filter, i) => (
              <React.Fragment key={i}>
                {i === 0 ? (
                  <Item key={-1} headerItem small>
                    <Flex alignCenter>Selected Alloy Groups</Flex>
                  </Item>
                ) : null}
                <Item onClick={() => onSelectGroup(_filter, true)} key={`${i}`}>
                  <Flex alignCenter>
                    <Checkbox checked />
                    {_filter.label}
                  </Flex>
                </Item>
              </React.Fragment>
            ))}
            {primaryAlloy && (
              <div key="primary-alloy">
                <Item headerItem small>
                  <Flex alignCenter>Primary Alloy</Flex>
                </Item>
                <Item
                  style={{
                    // The primary alloy can't be deselected from its heading group, so don't indicate it as clickable
                    cursor: 'default',
                  }}
                >
                  <Flex alignCenter>
                    <PrimaryAlloyNonInteractiveCheckbox checked />
                    {primaryAlloy?.label}
                  </Flex>
                </Item>
              </div>
            )}
            {map(groupBy(selectedAlloys, 'group'), (gr, groupId) => {
              const type =
                get(find(alloyGroups, { value: Number(groupId) }), 'label') ||
                'Custom Alloys';
              return (
                <div key={`${gr.value}-${type}`}>
                  <Item key={-12} headerItem small>
                    <Flex alignCenter>{type}</Flex>
                  </Item>
                  {map(gr, (alloy) => (
                    <Item key={`${alloy.value}-${alloy.label}`}>
                      <Flex alignCenter justifyBetween>
                        {/* This must be a Flex too so we can vertically center the text and still capture onClick across the full height of this row */}
                        <Flex
                          alignCenter
                          /* onClick is set here instead of the parent element because we don't want to capture the onClick for onSelectItem in the "Set primary" button area (to avoid frustrating misclicks above or below the button) */
                          onClick={() => onSelectItem(alloy)}
                          style={{
                            // Capture clicks all the way to the left edge of the "Set primary" button area
                            flexGrow: 1,
                            height: '100%',
                          }}
                        >
                          <Checkbox checked />
                          {alloy.label}
                        </Flex>
                        {primaryAlloyEnabled && (
                          <div>
                            {!alloy.primary_alloy ? (
                              <SetPrimaryAlloyButton
                                type="button"
                                className="set-primary-alloy-button"
                                onClick={() =>
                                  handleSetPrimaryAlloy(alloy.value)
                                }
                                style={{
                                  // Leave a sensible gap to prevent left-side misclicks
                                  marginLeft: '5px',
                                }}
                              >
                                Set primary
                              </SetPrimaryAlloyButton>
                            ) : (
                              <Tag
                                size="small"
                                color={customBlue}
                                uppercase
                                center
                              >
                                Primary
                              </Tag>
                            )}
                          </div>
                        )}
                      </Flex>
                    </Item>
                  ))}
                </div>
              );
            })}
          </SelectionCol>
        </Col>
      </Row>
    </EndFinishFilterItemSelectionWrapper>
  );
}

AlloyFilterItemSelectionTwoItemSelection.propTypes = {
  updateTotalSelected: PropTypes.func,
  setSelectedValue: PropTypes.func,
  filteredAlloyValue: PropTypes.shape({
    alloy: PropTypes.shape({
      value: PropTypes.number,
    }),
  }),
  clearAlloyFilterValue: PropTypes.func,
  optionValues: PropTypes.arrayOf(PropTypes.shape({})),
  selectionFilters: PropTypes.shape({
    groups: PropTypes.arrayOf(PropTypes.shape({})),
  }),
  selectedValue: PropTypes.arrayOf(PropTypes.shape({})),
  selectedFilters: PropTypes.shape({
    groups: PropTypes.arrayOf(PropTypes.shape({})),
  }),
  inputValue: PropTypes.string,
  onClose: PropTypes.func,
  allowMultiple: PropTypes.bool,
  disablePrimaryAlloy: PropTypes.bool,
};

export default AlloyFilterItemSelectionTwoItemSelection;
