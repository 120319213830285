import React, { useState, useEffect, useRef, useCallback } from 'react';
import { openModal } from 'state/modals/actions';
import { setGlobalSearchStatus } from 'state/application/actions';
import {
  isEndUser,
  isPartner,
  isFacilitator,
  isAdmin,
  hasSession,
} from 'utility/hasAccount';
import history from 'historyContainer';
import { trackClick } from 'utility/analytics';
import { useBranch } from 'baobab-react/hooks';
import { Grid, Row, Col } from '@zendeskgarden/react-grid';
import { Button } from 'theme/Button';
import Icon from 'components/Icon/Icon';
import { NavLink, withRouter } from 'react-router-dom';
import ProductsDropdownMenu from 'components/NavigationDropdownMenus/ProductsDropdownMenu';
import ContentDropdownMenu from 'components/NavigationDropdownMenus/ContentDropdownMenu';
import WhyPipesearchDropdownMenu from 'components/NavigationDropdownMenus/WhyPipesearchDropdownMenu';
import ServicesDropdownMenu from 'components/NavigationDropdownMenus/ServicesDropdownMenu';
import { map, get, filter } from 'lodash';
import { logout } from 'state/authentication/actions';
import { locationShape, matchShape } from 'propTypesObjects';
import {
  PublicHeaderComponent,
  Menu,
  MenuItem,
  Logo,
  HeaderRight,
  MobileMenuTrigger,
} from './PublicHeader.styles';

import CriteriaSearchOverlay from './components/SearchOverlay/CriteriaSearchOverlay';
import SVGLogo from './components/SVGLogo';
import MobileMenu from './components/MobileMenu/MobileMenu';

function PublicHeader({ match, location }) {
  const alwaysWhiteMenu = match.path;
  const isAuth = hasSession();
  const hasRole = isEndUser() || isPartner() || isFacilitator() || isAdmin();
  const [whiteMenu, setWhiteMenu] = useState(false);
  const [mmActive, setmmActive] = useState(false);
  const scrollY = useRef();
  const [headerHidden, setHeaderHidden] = useState();
  const [mmScroll, setmmScroll] = useState(0);
  const { alloyLists, searchOpen } = useBranch({
    alloyLists: ['global', 'alloyLists'],
    searchOpen: ['application', 'searchStatus'],
    email: ['authentication', 'session', 'email'],
  });

  function evalScroll() {
    const winScroll =
      document.body.scrollTop || document.documentElement.scrollTop;
    const scrollIsGreater = winScroll > scrollY.current;
    if (winScroll < 100) {
      setHeaderHidden(false);
    } else if (scrollIsGreater || winScroll <= 1200) {
      setHeaderHidden(true);
    } else if (!scrollIsGreater && winScroll > 1200) {
      setHeaderHidden(false);
    }
    scrollY.current = winScroll;
    if (winScroll > 10 && winScroll > 1200) {
      setWhiteMenu(true);
    } else {
      setWhiteMenu(false);
    }
  }

  useEffect(() => {
    window.addEventListener('scroll', evalScroll);
    return () => {
      window.removeEventListener('scroll', evalScroll);
    };
  }, []);

  useEffect(() => {
    let timer = null;

    if (mmActive) {
      setmmScroll(window.scrollY);
      timer = setTimeout(() => {
        document.body.style.overflowY = 'hidden';
        document.documentElement.style.overflowY = 'hidden';
      }, 700);
    } else if (!mmActive) {
      document.body.style.overflowY = '';
      document.documentElement.style.overflowY = '';
      window.scrollTo(0, mmScroll);
    }

    return () => {
      clearTimeout(timer);
    };
  }, [mmActive, mmScroll]);

  useEffect(() => {
    if (mmActive) {
      setmmActive(false);
    }
  }, [location, mmActive]);

  function toggleMM() {
    trackClick('toggleMobileMenu', 'hamburger', 'PublicHeader');
    setmmActive(!mmActive);
  }

  const toggleSearch = useCallback(() => {
    trackClick('toggleSearch', 'Search', 'PublicHeader');
    setGlobalSearchStatus(!searchOpen);
  }, [searchOpen]);

  const categoriesFormatted = map(get(alloyLists, 'groups.results'), (r) => {
    const { name, id, slug } = r;
    return {
      name,
      displayName: name,
      id,
      route: `octg/${slug}/`,
      subcategories: map(
        filter(get(alloyLists, 'alloys.results'), ['metallurgy_group', id]),
        (alloy) => ({
          ...alloy,
          name: `${alloy.name}`,
          parentId: id,
          parentName: name,
          route: `octg/${alloy.path}`,
        })
      ),
    };
  });

  const appLogout = useCallback(async () => {
    trackClick('logout', 'logout', 'AppHeader');
    const logoutRequest = await logout();
    if (!logoutRequest.error) {
      history.push('/login');
    } else {
      alert('There was an error signing out.');
    }
  }, []);

  return (
    <PublicHeaderComponent
      className="app-header-comp"
      alwaysWhiteMenu={alwaysWhiteMenu}
      whiteMenu={whiteMenu && !mmActive}
      mmActive={mmActive}
      headerHidden={headerHidden}
    >
      <div className="header-inner">
        <Grid className="header-grid">
          <Row className="header-row">
            <Col size="auto" className="logo-col">
              <NavLink
                exact
                className="nav-link-a"
                activeClassName="active-menu-item"
                to="/"
              >
                <Logo className="animate-logo">
                  <SVGLogo
                    white={
                      (!alwaysWhiteMenu && (!whiteMenu || mmActive)) || mmActive
                    }
                  />
                </Logo>
              </NavLink>
            </Col>
            <Col size="auto" className="primary-menu-col">
              <Menu className="animate-menu menu-1 menu">
                <MenuItem>
                  <div className="inner">
                    <ServicesDropdownMenu location={location} />
                  </div>
                </MenuItem>
                <MenuItem>
                  <div className="inner">
                    <ProductsDropdownMenu
                      fullCategories={categoriesFormatted}
                    />
                  </div>
                </MenuItem>

                <MenuItem>
                  <div className="inner">
                    <WhyPipesearchDropdownMenu location={location} />
                  </div>
                </MenuItem>
                <MenuItem>
                  <div className="inner">
                    <ContentDropdownMenu location={location} />
                  </div>
                </MenuItem>

                <MenuItem>
                  <div className="inner">
                    <NavLink
                      className="item"
                      onClick={(e) => {
                        e.preventDefault();
                        openModal({
                          type: 'contactUsModal',
                        });
                      }}
                      to="/contact"
                    >
                      Contact Us
                    </NavLink>
                  </div>
                </MenuItem>
              </Menu>
            </Col>

            <Col
              size="auto"
              style={{ maxWidth: 'none', flex: 1 }}
              className="secondary-menu-col"
            >
              <HeaderRight>
                {(isAuth && hasRole) || !isAuth ? (
                  <Menu className="menu-2 menu">
                    <MenuItem>
                      <div className="inner">
                        {false && !isFacilitator() ? null : (
                          <span
                            className="icon-nav-link"
                            onClick={toggleSearch}
                            onKeyDown={toggleSearch}
                            role="button"
                            tabIndex={0}
                            style={{ display: `block` }}
                            aria-label="Search"
                          >
                            <Icon icon="icon-search-left" />
                          </span>
                        )}
                      </div>
                    </MenuItem>

                    <MenuItem>
                      <div className="inner">
                        {isAuth ? (
                          <NavLink to="/dashboard">My Dashboard</NavLink>
                        ) : (
                          <NavLink to="/login">Sign In</NavLink>
                        )}
                      </div>
                    </MenuItem>
                  </Menu>
                ) : (
                  <Button link bold hoverColorLight onClick={appLogout}>
                    Log Out
                  </Button>
                )}
              </HeaderRight>
            </Col>
          </Row>
        </Grid>
        {searchOpen ? (
          <CriteriaSearchOverlay
            match={match}
            location={location}
            toggleSearch={toggleSearch}
            active={searchOpen}
          />
        ) : null}
      </div>

      <MobileMenuTrigger
        onClick={() => toggleMM()}
        active={mmActive}
        whiteMenu={whiteMenu}
        className="mm-trigger"
      >
        <span />
        <span />
        <span />
      </MobileMenuTrigger>
      <MobileMenu active={mmActive} />
    </PublicHeaderComponent>
  );
}

PublicHeader.propTypes = {
  match: matchShape,
  location: locationShape,
};

export default withRouter(PublicHeader);
