import React, { useState } from 'react';
import { TabList, Tab } from '@zendeskgarden/react-tabs';
import Icon from 'components/Icon/Icon';
import {
  SidebarFilterHeader,
  SidebarFilterBody,
  SidebarContentWrapper,
  SidebarFilterTabs,
} from '../../GlobalSideBar.styles.js';
import Flex from 'styled-flex-component';
import { IconButton } from '@zendeskgarden/react-buttons';
import { MD } from '@zendeskgarden/react-typography';
import { closeSidebar } from 'state/sidebars/actions';
import { variables } from 'theme/variables';
import { useBranch } from 'baobab-react/hooks';
import { get } from 'lodash';

import InteractionForm from 'components/BasicForms/InteractionForm';
import { PersonnelContainer } from 'components/BasicForms/PersonnelForm.js';

const { spacing } = variables;

const InteractionSidebar = (props) => {
  const [tab, setActiveTab] = useState('details');
  const [objectId] = useState(props.options.objectId);
  const [interaction, setInteraction] = useState(props.options.interaction);

  const { rfqMeta } = useBranch({
    rfqMeta: ['requestForQuote', 'meta', 'result'],
  });

  const interactionId = interaction?.id;
  const contentObject = {
    objectId: objectId,
    contentType: props.options.contentType,
  };

  const activeTab = {
    details: (
      <InteractionForm
        onSuccess={(interactionResult) => {
          setInteraction(interactionResult);
          setActiveTab('personnel');
          if (props.options.onSuccess) {
            props.options.onSuccess();
          }
        }}
        interactionId={interaction?.id}
        data={interaction ? interaction : contentObject}
      ></InteractionForm>
    ),
    personnel: (
      <PersonnelContainer
        label="Attendee"
        type="interaction"
        contentType={get(rfqMeta, 'crm_content_types.interaction')}
        objectId={interaction?.id}
        data={interaction}
      ></PersonnelContainer>
    ),
  }[tab];

  return (
    <SidebarContentWrapper>
      <SidebarFilterHeader padding={`${spacing}`}>
        <Flex full justifyBetween alignCenter>
          <Flex alignCenter>
            <MD scaled paddingHorizontal bold color="#fff">
              Manage Interaction
            </MD>
          </Flex>
          <IconButton
            onClick={closeSidebar}
            disableBackground
            alignCenter
            justifyEnd
            size="large"
            aria-label="closeSidebar"
          >
            <Icon button icon="icon-close" fontSize="13px" color={'#fff'} />
          </IconButton>
        </Flex>
      </SidebarFilterHeader>
      <SidebarFilterTabs>
        <TabList className="tabs no-border">
          <Tab
            className="tab"
            slate
            onClick={() => setActiveTab('details')}
            selected={tab === 'details'}
          >
            Interaction Details
          </Tab>
          <Tab
            disabled={!interactionId}
            className="tab"
            slate
            onClick={() => (!interactionId ? null : setActiveTab('personnel'))}
            selected={tab === 'personnel'}
          >
            Personnel
          </Tab>
        </TabList>
      </SidebarFilterTabs>
      <SidebarFilterBody>{activeTab}</SidebarFilterBody>
    </SidebarContentWrapper>
  );
};

export default InteractionSidebar;
