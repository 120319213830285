import { lowerCase, capitalize, replace, split, size, map, join } from 'lodash';
import { plurals } from './staticMap.ts';

export function titleCase(string) {
  /* 
    Use regex because startCase removes punctuation
    https://github.com/lodash/lodash/issues/3383
  */
  return replace(replace(string, /\w+/g, lowerCase), /\w+/g, capitalize);
}

// This helper method is specific to converting a string that is seperated by '-'s to be title cased
// ex: exclusive-marketing ---> Exclusive Marketing
export const convertToTitleCase = (string) => {
  const splitString = split(string, '-');
  let titleCaseString;
  if (size(splitString) > 1) {
    const newArr = map(splitString, (itm) => capitalize(itm));
    titleCaseString = join(newArr, ' ');
  } else {
    titleCaseString = capitalize(splitString);
  }
  return titleCaseString;
};

export function removeDollarSignsAndCommas(value) {
  const valueWithoutCommas = replace(value || '', /,/g, '');
  const valueWithoutCommasOrDollarSigns = replace(valueWithoutCommas, '$', '');
  return valueWithoutCommasOrDollarSigns;
}

export function pluralize(string, { capitalize: _capitalize, quantity }) {
  if (quantity === 1) {
    return _capitalize ? titleCase(string) : string;
  }
  let plural;
  if (plurals[string]) {
    plural = plurals[string];
  } else {
    plural = `${string}s`;
  }
  return _capitalize ? titleCase(plural) : plural;
}
