import asyncTreeRequester from 'utility/asyncTreeRequester';
import tree from 'state';
import * as effects from './effects';

import { customersCursor } from '../actions';

export async function getProspectingHistory({
  pagination,
  filters,
  sorting,
} = {}) {
  return asyncTreeRequester({
    func: effects.getProspectingHistory.bind(this),
    pagination,
    filters,
    sorting,
    cursor: customersCursor,
    path: ['prospecting', 'history'],
  });
}

export async function saveSearchType(type) {
  customersCursor.set(['prospecting', 'currentSearch', 'type'], type);
  tree.commit();
}

export function clearSearchData() {
  customersCursor.set(['prospecting', 'currentSearch', 'type'], null);
  customersCursor.set(['prospecting', 'currentSearch', 'prospects'], null);
  customersCursor.set(
    ['prospecting', 'currentSearch', 'selectedProspects'],
    null
  );
  customersCursor.set(['prospecting', 'currentSearch', 'success'], null);
  tree.commit();
}

export function clearPastSearch() {
  customersCursor.set(['prospecting', 'currentSearch', 'prospects'], null);
  customersCursor.set(
    ['prospecting', 'currentSearch', 'selectedProspects'],
    null
  );
  customersCursor.set(['prospecting', 'currentSearch', 'success'], null);
  tree.commit();
}

export function saveSuccess() {
  customersCursor.set(['prospecting', 'currentSearch', 'success'], true);
  tree.commit();
}

export function resetSuccess() {
  customersCursor.set(
    ['prospecting', 'currentSearch', 'selectedProspects'],
    null
  );
  customersCursor.set(['prospecting', 'currentSearch', 'success'], false);
  tree.commit();
}

export function saveIngestedUsers(ingestedUsers) {
  customersCursor.set(
    ['prospecting', 'currentSearch', 'ingestedUsers'],
    ingestedUsers
  );
  tree.commit();
}

export async function getProspectingSuggestion(key, value) {
  let result;
  try {
    result = await effects.fetchProspectingSuggestion(key, value);
  } catch (err) {
    result = {
      error: err,
    };
  }
  return result;
}

export async function getProspectPreview(terms, searchType) {
  await asyncTreeRequester({
    func: effects.getProspectPreview.bind(this, terms, searchType),
    cursor: customersCursor,
    path: ['prospecting', 'currentSearch', 'prospects'],
  });
}

export async function saveSelectedProspects(selectedProspects) {
  customersCursor.set(
    ['prospecting', 'currentSearch', 'selectedProspects'],
    selectedProspects
  );
  tree.commit();
}

export async function ingestProspects(psLogId, enrichmentIds, attributeValues) {
  let result;
  try {
    result = await effects.ingestProspects(
      psLogId,
      enrichmentIds,
      attributeValues
    );
  } catch (err) {
    result = {
      error: err,
    };
  }
  return result;
}

export async function ingestCompanies(
  psLogId,
  companyLinkedinUrls,
  attributeValues
) {
  let result;
  try {
    result = await effects.ingestCompanies(
      psLogId,
      companyLinkedinUrls,
      attributeValues
    );
  } catch (err) {
    result = {
      error: err,
    };
  }
  return result;
}
