export function defaultActiveCustomer() {
  return {
    customerBusinessInfo: {},
    customerCategoryInterests: {},
    customerDetails: {},
    customerSettings: {},
    customerFormData: {},
    customerFlexFormData: {},
    customerWishList: {},
    wishlist: {
      itemSelectionArray: [],
      itemSelection: {},
    },
  };
}

export function state() {
  return {
    customers: {},
    activeCustomer: defaultActiveCustomer(),
  };
}
