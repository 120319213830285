import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faThLarge as farFaThLarge,
  faChevronUp as farFaChevronUp,
  faChevronDown as farFaChevronDown,
  faHeart as farFaHeart,
  faEnvelope as farFaEnvelope,
  faPhone as farFaPhoneAlt,
  faCopy as farFaCopy,
  faExternalLink as farFaExternalLink,
  faHandshake as farFaHandshake,
  faOilWell as farFaOilWell,
  faPencilRuler as farFaPencilRuler,
  faPipeSection as farFaPipeSection,
  faFlaskVial as farFaFlaskVial,
  faStopwatch as farFaStopwatch,
  faIndustryAlt as farFaIndustryAlt,
  faFileChartPie as farFaFileChartPie,
  faBars as farFaBars,
  faDistributeSpacingVertical as farFaDistributeSpacingVertical,
  faRotateRight as farFaRotateRight,
  faExclamationCircle as farFaExclamationCircle,
  faPlusCircle as farFaPlusCircle,
  faMinusCircle as farFaMinusCircle,
  faFileArrowUp as farFaFileArrowUp,
  faFileArrowDown as farFileArrowDown,
} from '@fortawesome/pro-regular-svg-icons';
import {
  faThLarge as fasFaThLarge,
  faHeart as fasFaHeart,
  faCaretLeft as fasCaretLeft,
  faEnvelope as fasFaEnvelope,
  faCircle as fasFaCircle,
  faPlusCircle as fasFaPlusCircle,
  faAngleUp as fasFaAngleUp,
  faAngleDown as fasFaAngleDown,
} from '@fortawesome/pro-solid-svg-icons';
import {
  faClipboardQuestion as falFaClipboardQuestion,
  faBars as falFaBars,
  faDistributeSpacingVertical as falFaDistributeSpacingVertical,
  faFileSearch as falFaFileSearch,
  faClipboardList as falFaClipbardList,
  faSquareList as falFaSquareList,
  faXmark as falFaXMark,
  faHandshake as falFaHandshake,
  faOilWell as falFaOilWell,
  faPencilRuler as falFaPencilRuler,
  faPipeSection as falFaPipeSection,
  faFlaskVial as falFaFlaskVial,
  faStopwatch as falFaStopwatch,
  faIndustryAlt as falFaIndustryAlt,
  faFileChartPie as falFaFileChartPie,
  faMagnifyingGlass as falFaMagnifyingGlass,
  faPencil as falFaPencil,
  faClone as falFaClone,
} from '@fortawesome/pro-light-svg-icons';
import {
  faInventory as fadFaInventory,
  faImage as fadFaImage,
  faClone as fadFaClone,
  faFileImage as fadFaFileImage,
  faCloudUpload as fadFaCloudUpload,
  faChartNetwork as fadFaChartNetwork,
  faChartLine as fadFaChartLine,
  faThLarge as fadFaThLarge,
  faTh as fadFaTh,
  faWarehouseAlt as fadFaWarehouseAlt,
  faBuilding as fadFaBuilding,
  faPaperPlane as fadFaPaperPlane,
  faStar as fadFaStar,
  faChartPieAlt as fadFaChartPieAlt,
  faBoxCheck as fadFaBoxCheck,
  faMapMarkedAlt as fadFaMapMarkedAlt,
  faIndustry as fadFaIndustry,
  faIndustryAlt as fadFaIndustryAlt,
  faUsers as fadFaUsers,
  faDatabase as fadFaDatabase,
  faHexagon as fadFaHexagon,
  faBoxesAlt as fadFaBoxesAlt,
  faBrowser as fadFaBrowser,
  faEnvelope as fadFaEnvelope,
  faPhoneAlt as fadFaPhoneAlt,
  faMapMarkerAlt as fadFaMapMarkerAlt,
  faCompressArrowsAlt as fadFaCompressArrowsAlt,
  faExpandArrowsAlt as fadFaExpandArrowsAlt,
  faTimes as fadFaTimes,
  faCodeMerge as fadFaCodeMerge,
  faComments as fadFaComments,
  faTag as fadFaTag,
  faTags as fadFaTags,
  faFolderUpload as fadFaFolderUpload,
  faEye as fadFaEye,
  faTrash as fadFaTrash,
  faBells as fadFaBells,
  faAdjust as fadFaAdjust,
  faClipboard as fadFaClipboard,
  faCommentAlt as fadFaCommentAlt,
  faHouse as fadFaHouse,
  faCube as fadFaCube,
  faShoppingCart as fadFaShoppingCart,
  faMegaphone as fadFaMegaphone,
  faList as fadFaList,
  faHeart as fadFaHeart,
  faBook as fadFaBook,
  faLifeRing as fadLifeRing,
  faBarcodeScan as fadBarcodeScan,
  faLink as fadLink,
  faUser as fadFaUser,
  faPlusCircle as fadFaPlusCircle,
  faInfoCircle as fadInfoCircle,
  faClipboardListCheck as fadFaClipboardListCheck,
  faCheckCircle as fadFaCheckCircle,
  faExclamationCircle as fadFaExclamationCircle,
  faUserLock as fadFaUserLock,
  faClock as fadFaClock,
  faToolbox as fadFaToolbox,
  faGlobe as fadFaGlobe,
} from '@fortawesome/pro-duotone-svg-icons';
import {
  faLinkedin as fabFaLinkedin,
  faFacebookSquare as fabFaFacebookSquare,
  faTwitterSquare as fabFaTwitterSquare,
} from '@fortawesome/free-brands-svg-icons';

library.add(
  fadFaTrash,
  fadFaBells,
  fadFaClone,
  fadFaFileImage,
  fadFaCloudUpload,
  fadFaAdjust,
  fadFaEye,
  fadFaImage,
  fadFaComments,
  fadFaFolderUpload,
  fadFaCodeMerge,
  fadFaBrowser,
  fadFaCompressArrowsAlt,
  fadFaMapMarkerAlt,
  fadFaExpandArrowsAlt,
  fadFaEnvelope,
  farFaEnvelope,
  fasFaEnvelope,
  fadFaPhoneAlt,
  farFaPhoneAlt,
  fadFaMapMarkedAlt,
  fadFaBoxesAlt,
  fadFaInventory,
  fadFaChartNetwork,
  fadFaChartLine,
  fadFaThLarge,
  fadFaTh,
  fadFaWarehouseAlt,
  fadFaBuilding,
  fadFaPaperPlane,
  fadFaStar,
  fadFaChartPieAlt,
  fadFaBoxCheck,
  fadFaIndustry,
  fadFaUsers,
  fadFaDatabase,
  fadFaHexagon,
  fadFaTimes,
  fadFaTag,
  fadFaTags,
  fadFaClipboard,
  farFaThLarge,
  fasFaThLarge,
  fadFaCommentAlt,
  fadFaHouse,
  fadFaCube,
  fadFaShoppingCart,
  fadFaMegaphone,
  fadFaList,
  fadFaHeart,
  farFaHeart,
  fasFaHeart,
  fadFaBook,
  farFaChevronUp,
  farFaChevronDown,
  fadLifeRing,
  fadBarcodeScan,
  fadLink,
  fadFaUser,
  fadFaPlusCircle,
  fasFaPlusCircle,
  fadInfoCircle,
  fabFaLinkedin,
  fabFaFacebookSquare,
  fabFaTwitterSquare,
  fadFaClipboardListCheck,
  fadFaCheckCircle,
  fadFaExclamationCircle,
  falFaFileSearch,
  fadFaUserLock,
  fadFaClock,
  fasFaHeart,
  fasFaCircle,
  falFaClipbardList,
  falFaSquareList,
  falFaClipboardQuestion,
  falFaBars,
  falFaDistributeSpacingVertical,
  farFaCopy,
  fasCaretLeft,
  fadFaIndustryAlt,
  farFaExternalLink,
  falFaXMark,
  farFaHandshake,
  farFaOilWell,
  farFaPencilRuler,
  farFaPipeSection,
  farFaFlaskVial,
  farFaStopwatch,
  farFaIndustryAlt,
  farFaFileChartPie,
  falFaHandshake,
  falFaOilWell,
  falFaPencilRuler,
  falFaPipeSection,
  falFaFlaskVial,
  falFaStopwatch,
  falFaIndustryAlt,
  falFaFileChartPie,
  falFaMagnifyingGlass,
  farFaBars,
  farFaDistributeSpacingVertical,
  farFaRotateRight,
  fadFaToolbox,
  fadFaGlobe,
  falFaPencil,
  falFaClone,
  farFaExclamationCircle,
  farFaPlusCircle,
  farFaMinusCircle,
  farFaFileArrowUp,
  farFileArrowDown,
  fasFaAngleUp,
  fasFaAngleDown
);
