import isIe11 from 'utility/isIe11';
export default function loadGlobalScrips() {
  if (!isIe11()) {
    loadTermly();
  }
}

export function loadTermly() {
  setTimeout(() => {
    var s = document.createElement('script');
    s.type = 'text/javascript';
    s.async = true;
    s.src = 'https://app.termly.io/embed.min.js';
    s.id = 'c77fa8a4-5edb-4ea2-ac84-4b65b17c51d1';
    s.setAttribute('data-name', 'termly-embed-banner');
    var x = document.getElementsByTagName('script')[0];
    x.parentNode.insertBefore(s, x);
  }, 10);
}

export function loadDashboardScrips() {
  // <script data-jsd-embedded data-key="45bb3a55-9fb2-43fa-9a33-d4f03e405750" data-base-url="https://jsd-widget.atlassian.com" src="https://jsd-widget.atlassian.com/assets/embed.js"></script>
}
