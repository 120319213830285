import React from 'react';
import PropTypes from 'prop-types';
import { MD } from '@zendeskgarden/react-typography';
import Icon from 'components/Icon/Icon';
import { IconButton } from '@zendeskgarden/react-buttons';
import Flex from 'styled-flex-component';
import { variables } from 'theme/variables';
import styled from 'styled-components/macro';
import { locationShape } from '../../../../propTypesObjects';

const {
  routed_modal_header_height: routedModalHeaderHeight,
  spacing,
  spacing_lg: spacingLg,
  app_page_padding_small: appPagePaddingSmall,
  app_page_padding_medium: appPagePaddingMedium,
  media_sm: mediaSm,
  logo_url_white: logoUrlWhite,
  logo_url: logoUrl,
} = variables;

const StyledTopicHeader = styled.div`
  background-color: rgba(14, 54, 107, 0.35);
  min-height: ${routedModalHeaderHeight};
  max-height: ${routedModalHeaderHeight};
  display: flex;
  align-items: center;
  position: absolute;
  left: 0;
  right: 0;
  z-index: 3;
  padding: ${() => `${spacing} ${appPagePaddingSmall}`};
  @media ${mediaSm} {
    padding: ${() => `${spacing} ${appPagePaddingMedium}`};
  }

  ${(props) => `
    ${
      props.white
        ? `
      background-color: #fff;
      box-shadow: 0px 8px 22.08px 1.92px rgba(0, 0, 0, 0.04);
      .icon-close {
        color: #7c7c7c !important;
      }
    `
        : ''
    }
  `};
`;

export const Logo = styled('div')`
  background-image: url('${(props) => props.src}');
  background-size: contain;
  width: 150px;
  height: 30px;
  opacity: 1;
  background-repeat: no-repeat;
`;

function TopicHeader({ white, logo, title, icon, closeModal, done, location }) {
  return (
    <StyledTopicHeader white={white} padding={`${spacing} ${spacingLg}`}>
      <Flex full justifyBetween alignCenter>
        {logo ? (
          <Flex alignCenter>
            <Logo src={white ? logoUrl : logoUrlWhite} />
            <MD paddingHorizontalSm bold navy>
              {title ? title(location) : ''}
            </MD>
          </Flex>
        ) : (
          <Flex alignCenter>
            <Icon icon={icon} fontSize="16px" color="#fff" />
            <MD paddingHorizontalSm bold navy>
              {title ? title(location) : ''}
            </MD>
          </Flex>
        )}
        <IconButton
          onClick={closeModal}
          disableBackground
          alignCenter
          justifyCenter
          size="large"
          aria-label="closeModal"
        >
          {done ? (
            <MD blue bold link>
              Done
            </MD>
          ) : (
            <Icon button icon="icon-close" fontSize="13px" color="#fff" />
          )}
        </IconButton>
      </Flex>
    </StyledTopicHeader>
  );
}

TopicHeader.propTypes = {
  white: PropTypes.bool,
  logo: PropTypes.bool,
  title: PropTypes.func,
  icon: PropTypes.string,
  closeModal: PropTypes.func,
  done: PropTypes.bool,
  location: locationShape,
};

export default TopicHeader;
