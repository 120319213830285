import tree from 'state';
import { isBoolean } from 'lodash';

export function toggleExpand(value) {
  tree.merge(['sidebarPage'], {
    sidebarExpanded: isBoolean(value)
      ? value
      : !tree.get(['sidebarPage', 'sidebarExpanded']),
  });
}
