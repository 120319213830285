import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
import { variables } from 'theme/variables';

const { custom_blue: customBlue } = variables;

const CustomizedNavLink = styled(NavLink)`
  p {
    .activeLink {
      color: ${customBlue};
    }
  }
`;

export default CustomizedNavLink;
