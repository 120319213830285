import {
  forEach,
  pick,
  values,
  every,
  isString,
  isNumber,
  mapValues,
  map,
  get,
  find,
} from 'lodash';
import tree from 'state';
import { baseValidation } from './validation_items';

const operators = [
  { label: 'Equals', value: 'equals' },
  { label: 'Range', value: 'range' },
];

function getMetadata() {
  return (
    tree.select(['requestForQuote']).get(['meta', 'result']) ||
    tree.select(['onboarding']).get(['meta', 'result']) ||
    tree.select(['metadata']).get(['rfqMetadata', 'result']) ||
    tree.select(['metadata']).get(['onboardingMetadata', 'result'])
  );
}

export function generateTechnicalDetails(metadata, serverValues = {}) {
  const { lengthValue } = serverValues;
  // INITIALIZER
  const { range } = metadata || getMetadata();

  return {
    technical_details: {
      result: {
        length_range: {
          /// SET FOR DEFAULTS
          operators,
          operator:
            lengthValue && lengthValue.min && lengthValue.max
              ? operators[1]
              : operators[0],
          items: map(range, ({ name, id, category }) => ({
            value: id,
            label: name,
            category,
          })),
          validation: (args) =>
            baseValidation({
              ...args,
              validationOptions: {
                isString: true,
                forceValid:
                  true || args.itemSelectionItem.technicalDetailsConfirmed,
              },
            }), // I ahve no idea why this has a force valid when technical details are confirmed
          value: lengthValue
            ? (() => {
                const valMin = find(range, { id: lengthValue.min });
                const valMax = find(range, { id: lengthValue.max });
                const val = valMin || valMax;
                if (val && (!valMin || !valMax)) {
                  return {
                    value: val.id,
                    label: val.name,
                    category: val.category,
                  };
                }
                if (valMin && valMax) {
                  return {
                    min: {
                      value: valMin.id,
                      label: valMin.name,
                      category: valMin.category,
                    },
                    max: {
                      value: valMax.id,
                      label: valMax.name,
                      category: valMax.category,
                    },
                  };
                }
                return null;
              })()
            : null,
        },
      },
    },
  };
}

export function transformTechnicalDetails(data, pointer, metadata) {
  const _pointer = pointer || {};
  // Set Technical Details on new data load.
  // if not pointer it means it is a loaded item from the server and we want to use the server result.
  // Load current RFQ's items
  const currentTechDetails =
    _pointer.result ||
    generateTechnicalDetails(metadata, {
      lengthValue: { max: data.lengths_max, min: data.lengths_min },
    }).technical_details.result;
  const detailsFormatted = mapValues(data, (value, key) => {
    const setValueNeeded = isString(value) || isNumber(value) || !value;
    if (!setValueNeeded) {
      // ALLOY INFO
      return value;
    }
    const obj = {
      value: {
        value: value || '',
        label: value || '',
      },
      validation: (args) =>
        key === 'pren_min'
          ? true
          : baseValidation({
              ...args,
              validationOptions: {
                forceValid: args.itemSelectionItem.technicalDetailsConfirmed,
              },
            }),
    };
    return obj;
  });

  if (
    get(metadata, 'technicalDetailsConfirmed') &&
    !get(currentTechDetails, 'metallurgy')
  ) {
    return {
      ...currentTechDetails,
      ...{ metallurgy: detailsFormatted.metallurgy },
    };
  }
  if (!get(metadata, 'technicalDetailsConfirmed')) {
    return { ...currentTechDetails, ...detailsFormatted };
  }
  return currentTechDetails;
}

export function minimumDataForTechnicalDetails(rfqItem) {
  const category = get(rfqItem, 'category.value.template');
  const obj = {
    all: [
      'category',
      'od',
      'min_yield',
      'weight_per_foot',
      'alloy',
      'quantity',
      'wall',
      'inside_diameter',
      'range',
    ],
    min: [
      ['category', 'od', 'min_yield', 'weight_per_foot', 'alloy', 'quantity'],
    ],
  };

  if (category === 'Mechanical') {
    obj.min = [
      ['category', 'od', 'inside_diameter', 'min_yield'],
      ['category', 'od', 'wall', 'min_yield'],
    ];
  }

  if (category === 'OCTG') {
    obj.min = [['category', 'od', 'weight_per_foot', 'min_yield']];
  }

  const items = pick(rfqItem, obj.all);
  let hasFields;
  forEach(obj.min, (min) => {
    const requiredItems = pick(rfqItem, min);
    if (every(values(requiredItems), 'value')) {
      hasFields = true;
    }
  });

  return {
    hasFields,
    items,
  };
}
