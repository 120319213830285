/* eslint-disable react/prop-types */
import React, { createContext, useContext, useEffect, useState } from 'react';

/**
 * Top Level Provider for Utility Providers
 ** Nest any Children Providers here
 */
function UtilityProvider({ children }) {
  return (
    // <ExampleProvider>
    <EasterEggProvider>{children}</EasterEggProvider>
    // </ExampleProvider>
  );
}

const EasterEggContext = createContext();

function EasterEggProvider({ children }) {
  const [easterEgg, setEasterEgg] = useState(false);

  useEffect(() => {
    function handleBunny(e) {
      if (e.key === '|') {
        setEasterEgg(!easterEgg);
      }
    }
    window.addEventListener('keydown', handleBunny);

    return () => {
      window.removeEventListener('keydown', handleBunny);
    };
  }, [easterEgg]);

  return (
    <EasterEggContext.Provider value={easterEgg}>
      {children}
    </EasterEggContext.Provider>
  );
}

/**
 * Used for hiding easter eggs
 * @return  {boolean} based on if the user types the "|" symbol
 ** const easterEgg = useEasterEgg()
 */
export const useEasterEgg = () => useContext(EasterEggContext);

export default UtilityProvider;
