import React, { useState, useEffect, useRef, useCallback } from 'react';
import { hasSession } from 'utility/hasAccount';
import { useBranch } from 'baobab-react/hooks';
import { trackClick } from 'utility/analytics';
import history from 'historyContainer';
import { map, get, filter } from 'lodash';
import { Link } from 'react-router-dom';
import anime from 'animejs';
import { PropTypes } from 'prop-types';
import {
  MobileMenuComponent,
  MobileMenuButton,
  Menu,
  MenuItem,
  Dropdown,
} from './MobileMenu.styles';

const companyRoutes = [
  { route: '/about-us', name: 'About Us' },
  { route: '/our-model', name: 'Our Model' },
];
const servicesRoutes = [
  { route: '/pipe-recover', name: 'PipeRecover™' },
  { route: '/pipe-stats', name: 'PipeStats™' },
  { route: '/pipe-facts', name: 'PipeFacts™' },
];

function MobileMenu({ active }) {
  const animation1 = useRef();
  const animation2 = useRef();
  const isAuth = hasSession();

  const [submenuActive, setSubmenuActive] = useState(false);
  const { alloyLists } = useBranch({
    alloyLists: ['global', 'alloyLists'],
  });

  const toggleSubmenu = useCallback(
    (type) => {
      trackClick('toggleSubmenu', 'Technical Resources', 'MobileMenu');
      setSubmenuActive(type === submenuActive ? null : type);
    },
    [submenuActive]
  );

  useEffect(() => {
    animation1.current = anime
      .timeline({
        autoplay: false,
      })
      .add(
        {
          targets: '.primary-mobile-menu li',
          opacity: {
            value: [0, 1],
            duration: 200,
            easing: 'linear',
          },
          delay: anime.stagger(50),
        },
        200
      )
      .add(
        {
          targets: '.buttons button',
          opacity: {
            value: [0, 1],
            duration: 200,
            easing: 'linear',
          },
          translateY: {
            value: ['40px', '0px'],
            duration: 400,
            easing: 'cubicBezier(0.220, 0.265, 0.085, 0.980)',
          },
          delay: anime.stagger(150),
        },
        200
      );

    animation2.current = anime
      .timeline({
        autoplay: false,
      })
      .add({
        targets: '.primary-mobile-menu li',
        opacity: {
          value: [1, 0],
          duration: 125,
          easing: 'linear',
        },
        direction: 'reverse',
        delay: anime.stagger(50),
      })
      .add({
        targets: '.buttons button',
        opacity: {
          value: [1, 0],
          duration: 100,
          easing: 'linear',
        },
        translateY: {
          value: ['0px', '40px'],
          duration: 400,
          easing: 'cubicBezier(0.220, 0.265, 0.085, 0.980)',
        },
        delay: anime.stagger(50),
      });

    return () => {
      animation1.current.pause();
      animation1.current = null;
      animation2.current.pause();
      animation2.current = null;

      document.body.style.overflowY = '';
      document.documentElement.style.overflowY = '';
    };
  }, []);

  function activeAnimation() {
    animation1.current.play();
  }

  function inactiveAnimation() {
    animation2.current.play();
  }

  useEffect(() => {
    if (active) {
      activeAnimation();
    } else if (!active) {
      inactiveAnimation();
    }
  }, [active]);

  const categoriesFormatted = map(get(alloyLists, 'groups.results'), (r) => {
    const { name, id, slug } = r;
    return {
      name,
      displayName: name,
      id,
      route: `octg/${slug}/`,
      subcategories: map(
        filter(get(alloyLists, 'alloys.results'), { metallurgy_group: id }),
        (alloy) => ({
          ...alloy,
          name: `${alloy.name}`,
          parentId: id,
          parentName: name,
          route: `octg/${alloy.path}`,
        })
      ),
    };
  });

  return (
    <MobileMenuComponent active={active}>
      <Menu active={active} className="primary-mobile-menu">
        <ul>
          <MenuItem>
            <Link to="/">Home</Link>
          </MenuItem>
          {/* <MenuItem>
            <Link to="/find-inventory">Find Inventory</Link>
          </MenuItem> */}
          {/* <MenuItem>
            <Link to="/services">Services</Link>
          </MenuItem> */}
          <MenuItem>
            <Link to="/why-pipesearch">Why PipeSearch</Link>
          </MenuItem>
          <MenuItem>
            <Link to="/buyers">Pipe Buyers</Link>
          </MenuItem>
          <MenuItem>
            <Link to="/sellers">Pipe Sellers</Link>
          </MenuItem>

          <MenuItem hasDropdown>
            <span
              onClick={toggleSubmenu(this, 'services')}
              onKeyUp={toggleSubmenu(this, 'services')}
              role="link"
              tabIndex={0}
            >
              Services
            </span>
            <Dropdown active={submenuActive === 'services'}>
              <ul>
                {map(servicesRoutes, (itm, i) => (
                  <MenuItem style={{ opacity: 1 }} key={`${itm.id}-1-${i}`}>
                    <Link to={`/c/${itm.route}`}>{itm.name}</Link>
                  </MenuItem>
                ))}
              </ul>
            </Dropdown>
          </MenuItem>

          <MenuItem hasDropdown>
            <span
              onClick={toggleSubmenu.bind(this, 'products')}
              onKeyUp={toggleSubmenu.bind(this, 'products')}
              role="link"
              tabIndex={0}
            >
              OCTG Products
            </span>
            <Dropdown active={submenuActive === 'products'}>
              <ul>
                {map(categoriesFormatted, (itm, i) => (
                  <MenuItem style={{ opacity: 1 }} key={`${itm.id}-1-${i}`}>
                    <Link to={`/c/${itm.route}`}>{itm.name}</Link>
                  </MenuItem>
                ))}
              </ul>
            </Dropdown>
          </MenuItem>

          <MenuItem hasDropdown>
            <span
              onClick={toggleSubmenu.bind(this, 'company')}
              onKeyUp={toggleSubmenu.bind(this, 'company')}
              role="link"
              tabIndex={0}
            >
              Company
            </span>
            <Dropdown active={submenuActive === 'company'}>
              <ul>
                {map(companyRoutes, (itm, i) => (
                  <MenuItem style={{ opacity: 1 }} key={`${itm.id}-2-${i}`}>
                    <Link to={`${itm.route}`}>{itm.name}</Link>
                  </MenuItem>
                ))}
              </ul>
            </Dropdown>
          </MenuItem>
          <MenuItem>
            <Link to="/contact">Contact</Link>
          </MenuItem>
          {/* <MenuItem className="search">
            <Link to="/">Search Our Site <Icon icon="icon-search-left" /></Link>
          </MenuItem> */}
        </ul>
      </Menu>

      <div className="buttons">
        {isAuth ? (
          <MobileMenuButton
            onClick={() => {
              trackClick('dashboard', 'Dashboard', 'MobileMenu');
              history.push('/dashboard');
            }}
            white
            publicButton
          >
            My Dashboard
          </MobileMenuButton>
        ) : (
          <MobileMenuButton
            onClick={() => {
              trackClick('login', 'Login', 'MobileMenu');
              history.push('/login');
            }}
            white
            publicButton
          >
            Sign In
          </MobileMenuButton>
        )}
      </div>

      <svg className="menu-background">
        <circle
          className="circle-1"
          r="288"
          fill="none"
          strokeWidth="100px"
          stroke="rgba(255, 255, 255, 0.02)"
          cx="-110"
          cy="270"
        />
        <circle
          className="circle-2"
          r="288"
          fill="none"
          strokeWidth="100px"
          stroke="rgba(255, 255, 255, 0.02)"
          cx="500"
          cy="690"
        />
      </svg>
    </MobileMenuComponent>
  );
}

MobileMenu.propTypes = {
  active: PropTypes.bool,
};

export default MobileMenu;
