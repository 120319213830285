import React, { useState, useEffect, useCallback } from 'react';
import { addClass, removeClass } from 'utility/domUtils';
import { useBranch } from 'baobab-react/hooks';
import Modal from 'forks/customModal';
import { get } from 'lodash';
import { closeSidebar } from 'state/sidebars/actions';
import GlobalInterestsFilter from './sidebars/GlobalInterestsFilter/GlobalInterestsFilter';
import CustomerApprovalSidebar from './sidebars/CustomerApprovalSidebar/CustomerApprovalSidebar';
import AddCompanySidebar from './sidebars/AddCompanySidebar/AddCompanySidebar';
import AddEngagementSidebar from './sidebars/AddEngagementSidebar/AddEngagementSidebar';
import InteractionSidebar from './sidebars/InteractionSidebar/InteractionSidebar';
import ActionItemSidebar from './sidebars/ActionItemSidebar/ActionItemSidebar';
import InternalRelationshipSidebar from './sidebars/InternalRelationshipSidebar/InternalRelationshipSidebar';
import PartnerCustomerSidebar from './sidebars/PartnerCustomerSidebar/PartnerCustomerSidebar';
import {
  GLOBAL_INTERESTS_FILTERS,
  CUSTOMER_APPROVAL,
  ADD_PARTNER_CUSTOMER,
  ADD_EMPLOYEE,
  ADD_COMPANY,
  ADD_ENGAGEMENT,
  ADD_INTERACTION,
  ADD_ACTIONITEM,
  ADD_INTERNALRELATIONSHIP,
  ADD_MARKETCOMP,
} from './GlobalSideBarTypes';
import AddEmployeeSidebar from './sidebars/AddEmployeeSidebar/AddEmployeeSidebar';
import AddMarketCompSidebar from './sidebars/MarketCompsSidebar/AddMarketCompSidebar';

const StyledSidebar = Modal.styled`
  width: ${(props) => props.width || '100%'};
  height: 100vh;
  position: fixed;  
  right: 0;
  top: 0px;
  bottom: 0;
  align-items: flex-start;
  justify-content: center;
  background-color: #fff;
  opacity: 1;
  max-width: ${(props) => props.maxWidth || '550px'};
  transform: ${(props) =>
    props.opacity ? 'translate3d(0, 0, 0)' : 'translate3d(100%, 0px, 0)'};
  transition: all cubic-bezier(0.2, 0, 0, 1) 240ms 10ms;
  overflow: scroll;
  box-shadow: ${(props) =>
    props.boxShadow && props.opacity ? props.boxShadow : '0,0,0,0'};
`;

function GlobalSideBar() {
  const [opacity, setOpacity] = useState(0);
  const [shouldWarn, setShouldWarn] = useState(0);
  const { sidebars } = useBranch({
    sidebars: ['sidebars'],
  });

  const onCloseSidebar = useCallback(() => {
    if (
      shouldWarn &&
      !window.confirm(
        'Are you sure you want to close the window? All data will be lost. '
      )
    ) {
      return;
    }
    closeSidebar();
  }, [shouldWarn]);

  const afterOpen = useCallback(() => {
    setTimeout(() => {
      setOpacity(1);
    }, 100);
  }, []);

  const beforeClose = useCallback(
    () =>
      new Promise((resolve) => {
        setOpacity(0);
        setTimeout(resolve, 300);
        setShouldWarn(false);
      }),
    []
  );

  const { sidebarOpen } = sidebars;
  const { sidebarType } = sidebars;
  const sidebarFilterStyle = get(sidebars, 'options.style') === 'filter';
  const sidebarWidth = get(sidebars, 'options.width', null);
  const sidebarMaxWidth = get(sidebars, 'options.maxWidth', null);
  const sidebarBackgroundStyles = sidebarFilterStyle
    ? {
        background: 'background-color: rgba(9, 30, 66, 0);',
      }
    : {};

  useEffect(() => {
    if (sidebarType) {
      addClass(document.getElementsByTagName('html')[0], 'overflow-hidden');
    } else {
      removeClass(document.getElementsByTagName('html')[0], 'overflow-hidden');
    }
    return () => {
      removeClass(document.getElementsByTagName('html')[0], 'overflow-hidden');
    };
  }, [sidebarType]);

  const disableEscape = sidebarType === CUSTOMER_APPROVAL;

  const renderSidebars = () => {
    switch (sidebarType) {
      case GLOBAL_INTERESTS_FILTERS:
        return <GlobalInterestsFilter options={sidebars.options} />;
      case ADD_EMPLOYEE:
        return (
          <AddEmployeeSidebar
            onClickClose={onCloseSidebar}
            options={sidebars.options}
            onSetCloseWarn={(warn) => setShouldWarn(warn)}
          />
        );
      case CUSTOMER_APPROVAL:
        return (
          <CustomerApprovalSidebar
            onClickClose={onCloseSidebar}
            onSetCloseWarn={(warn) => setShouldWarn(warn)}
            options={sidebars.options}
          />
        );
      case ADD_PARTNER_CUSTOMER:
        return (
          <PartnerCustomerSidebar
            onClickClose={onCloseSidebar}
            onSetCloseWarn={(warn) => setShouldWarn(warn)}
            options={sidebars.options}
          />
        );
      case ADD_COMPANY:
        return (
          <AddCompanySidebar
            onClickClose={onCloseSidebar}
            onSetCloseWarn={(warn) => setShouldWarn(warn)}
            options={sidebars.options}
          />
        );
      case ADD_ENGAGEMENT:
        return <AddEngagementSidebar options={sidebars.options} />;
      case ADD_INTERACTION:
        return <InteractionSidebar options={sidebars.options} />;
      case ADD_ACTIONITEM:
        return <ActionItemSidebar options={sidebars.options} />;
      case ADD_INTERNALRELATIONSHIP:
        return <InternalRelationshipSidebar options={sidebars.options} />;
      case ADD_MARKETCOMP:
        return <AddMarketCompSidebar options={sidebars.options} />;
      default:
        return null;
    }
  };

  return (
    <StyledSidebar
      boxShadow={
        sidebarFilterStyle ? '-4px 0px 50px rgba(0, 0, 0, 0.25)' : null
      }
      width={sidebarWidth}
      maxWidth={sidebarMaxWidth}
      isOpen={sidebarOpen}
      onBackgroundClick={(...args) => onCloseSidebar(...args)}
      afterOpen={afterOpen}
      beforeClose={beforeClose}
      onEscapeKeydown={
        get(sidebars.options, 'disableEscape') || disableEscape
          ? null
          : onCloseSidebar
      }
      opacity={opacity}
      allowScroll
      backgroundProps={{ opacity, ...sidebarBackgroundStyles }}
    >
      {renderSidebars()}
    </StyledSidebar>
  );
}

export default GlobalSideBar;
