import styled from 'styled-components/macro';
import Modal from 'forks/customModal';
import { variables } from 'theme/variables';

const { custom_border_grey: customBorderGrey } = variables;

export const MenuItem = styled.li`
  width: 100%;
  a {
    display: block;
    width: 100%;
    padding: 20px;
    border-bottom: 1px solid ${customBorderGrey};
  }
`;

export const Logout = styled.div`
  border-top: 1px solid ${customBorderGrey};
`;

export const Close = styled.div`
  position: absolute;
  top: 20px;
  right: 20px;
`;

export const StyledDrawer = Modal.styled`
  width: ${(props) => props.width || '20rem'};
  height: 100vh;
  position: fixed;
  padding: 1rem;
  right: 0;
  top: 0px;
  bottom: 0;
  align-items: flex-start;
  justify-content: center;
  background-color: #fff;
  opacity: 1;
  min-width: 200px;
  box-shadow: 0 13px 76px rgba(45, 54, 79, 0.16);
  transform: ${(props) =>
    props.opacity ? 'translate3d(0, 0, 0)' : 'translate3d(100%, 0px, 0)'};
  transition: transform cubic-bezier(0.2, 0, 0, 1) 240ms 10ms;
  overflow: hidden;
  .d-sidebar-menu {
    white-space: normal;
    display: flex;
    flex-direction: column;
    padding-top: 15px;
  }
`;

export const DrawerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: calc(100% - 30px);
  overflow: scroll;
`;
