import styled, { css, keyframes } from 'styled-components/macro';

const slideDown = keyframes`
  from {
    opacity: 0;
    transform: translate3d(0,-75px,0)
  }
  to {
    opacity: 1;
    transform: translate3d(0,0px,0);
  }
`;

const animation = (props) =>
  css`
    ${slideDown} 200ms 0ms ease forwards;
  `;

export const PublicItemSelectionFiltersWrapper = styled('div')`
  position: fixed;
  background: #ffffff;
  top: 0px;
  left: 0;
  right: 0;
  transform: translate3d(0, -75px, 0);
  box-shadow: 0 18px 29px 43px rgb(245, 245, 245);
  padding-top: 120px;
  padding-bottom: 20px;
  border-bottom: 1px solid #e2e2e2;
  animation: ${animation};
  span {
    padding: 0;
  }
  button {
    font-size: 12px;
    padding: 0;
    height: 31px;
    line-height: 28px;
    min-width: 77px;
    opacity: 0;
    transition: all 0.3s ease;
    position: absolute;
    bottom: -21px;
    left: 0;
    right: 0;
    margin: 0 auto;
    font-size: 16px;
    height: 38px;
    line-height: 37px;
    border-radius: 18px;
    min-width: 118px;
    font-weight: bold;
    &.show {
      opacity: 1;
    }
  }
`;

export const CrtieriaSearchWrapper = styled('div')`
  max-width: 1330px;
  margin: 0 auto;
  .item-selection-filters {
    padding: 0;
    .filter-wrapper.not_small {
      max-width: 300px !important;
    }
  }
  @media (max-width: 1180px) {
    padding-left: 20px;
    padding-right: 20px;
  }
`;

export const StyledInlineSearchOverlay = styled('div')`
  margin-right: 10px;
  form {
    display: flex;
    flex: 1;
  }
  input {
    transition: width 0.4s ease, opacity 0.3s 200ms ease;
  }
  &.focused {
    input {
      transition: width 0.8s ease, opacity 0.3s ease;
    }
  }
`;

export const SearchWrapper = styled('div')`
  position: relative;
  width: 40px;
  height: 40px;
`;

export const IconWrapper = styled('div')`
  position: absolute;
  right: 0;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  width: 44px;
  height: 100%;
  pointer-events: none;
`;

export const StyledInput = styled('input')`
  height: 44px;
  padding-left: 20px;
  padding-right: 40px;
  font-size: 14px;
  line-height: 16px;
  text-align: left;
  border-radius: 3px;
  box-shadow: 0 2px 1px rgba(0, 0, 0, 0.02);
  border: 1px solid #ededef;
  border-top: 0;
  background: #f1f2f5;
  max-width: 400px;
  outline: none;
  position: absolute;
  right: 0;
  opacity: 0;
  cursor: pointer;
  &.focused {
    cursor: text;
    opacity: 1;
    & ~ .icon-wrapper {
      pointer-events: all;
      cursor: pointer;
      * {
        cursor: pointer;
      }
    }
  }
  &::placeholder {
    color: #979797;
  }
  @media (max-width: 1155px) {
    max-width: 300px;
  }
  @media (max-width: 1040px) {
    max-width: 240px;
  }
  @media (max-width: 992px) {
    max-width: 360px;
  }
`;
