import fetchJson from 'utility/fetchJson';
import { BASE } from '../../constants';
import { map, find } from 'lodash';
import Promise from 'bluebird';

export async function fetchGlobalMetallargy() {
  const items = [
    '/api/meta/metallurgy-group/?limit=100',
    '/api/meta/metallurgy/?limit=100',
  ];
  const [groupsReq, alloysReq] = await Promise.map(
    items,
    async (path) => {
      const result = await fetchJson(`${BASE}${path}`, {
        method: 'GET',
      });
      return result;
    },
    { concurrency: 2 }
  );

  const groups = groupsReq;
  let alloys = alloysReq;

  alloys.results = map(alloys.results, (a) => {
    const group =
      find(groups.results, (g) => a.metallurgy_group === g.id) || {};
    a.metallurgy_group_slug = group.slug;
    a.metallurgy_group_name = group.name;
    a.path = a.metallurgy_group_slug
      ? `${a.metallurgy_group_slug}/${a.slug}`
      : `${'group'}/${a.slug}`;
    return a;
  });
  return { groups, alloys };
}

export async function fetchGlobalMetallargyGroupDetails(slug) {
  const groupsReq = await fetchJson(
    `${BASE}/api/meta/metallurgy-group/slug/${slug}/`,
    {
      method: 'GET',
    }
  );
  return groupsReq;
}

export async function fetchGlobalMetallargyDetails(slug) {
  const alloyReq = await fetchJson(
    `${BASE}/api/meta/metallurgy/slug/${slug}/`,
    {
      method: 'GET',
    }
  );
  return alloyReq;
}

export async function postUnAuthCriteria({ criteria }) {
  const unAuthCriteria = await fetchJson(`${BASE}/api/p/unauth-criteria/`, {
    method: 'POST',
    body: JSON.stringify({ criteria }),
  });
  return unAuthCriteria;
}

export async function getUnAuthCriteria(id) {
  const unAuthCriteria = await fetchJson(
    `${BASE}/api/p/unauth-criteria/${id}/`,
    {
      method: 'GET',
    }
  );
  return unAuthCriteria;
}
