import tree from 'state';
import asyncTreeRequester from 'utility/asyncTreeRequester';
import { pick } from 'lodash';

import { onboardingValueExtractor } from 'utility/dynamicFormHelpers';
import { defaultActiveCustomer } from '../state';
import * as effects from './effects';
import generatePartnerCustomer from './transformer_partner_customers';

export const customersCursor = tree.select(['customers']);

export function resetCustomerCursors() {
  customersCursor.set(['activeCustomer'], defaultActiveCustomer());
}

export async function getPartnerCustomers({
  pagination,
  filters,
  sorting,
} = {}) {
  return asyncTreeRequester({
    func: effects.getPartnerCustomers.bind(this),
    pagination,
    filters,
    sorting,
    cursor: customersCursor,
    path: ['currentCustomers'],
  });
}

export async function fetchActivePartnerCustomerData(userId) {
  await asyncTreeRequester({
    func: effects.getPartnerCustomerDetails.bind(this, userId),
    cursor: customersCursor,
    path: ['activeCustomer', 'customerDetails'],
  });
}

export async function setActivePartnerCustomer(customer, { reset } = {}) {
  if (reset !== false) {
    resetCustomerCursors();
  }
  const userId = customer ? customer.user || customer.pk || customer.id : null;
  let customerBusinessInfo = {};
  let currentCustomer = customer;
  if (customer) {
    customerBusinessInfo = await fetchActivePartnerCustomerData(userId);
    currentCustomer = { ...customer, ...customerBusinessInfo };
  }
  const customerFormData = generatePartnerCustomer({
    seedData: currentCustomer,
  });

  customerFormData.user = userId;
  customersCursor.set(['activeCustomer', 'customerFormData'], customerFormData);
  tree.commit();
}

export async function submitPartnerCustomer() {
  const customer = customersCursor.get(['activeCustomer', 'customerFormData']);
  const currentUserId = customer.user;
  const values = onboardingValueExtractor(pick(customer, 'customer'));
  const customerDetails = await asyncTreeRequester({
    func: currentUserId
      ? effects.updatePartnerCustomer.bind(this, currentUserId, values)
      : effects.submitPartnerCustomer.bind(this, values),
    cursor: customersCursor,
    isSaving: true,
    path: ['activeCustomer', 'customerDetails'],
    transformer: ({ result, cursor = {} }) => {
      const original = cursor.result || {};
      return { ...original, ...result };
    },
  });

  return customerDetails;
}
