import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import FilterItemValues from 'components/FilterItemGroup/components/FilterItemValues/FilterItemValues';
import styled from 'styled-components/macro';
import { addressSuggestion } from 'state/application/actions';

const GoogleLocationLookupWrapper = styled.div`
  width: 100%;
`;

const defaultArray = [];

function GoogleLocationLookup({
  colorOverride,
  validation,
  itemSelection,
  itemSelectionMini,
  size,
  medium,
  semibold,
  itemsSmall,
  value,
  selectedValue,
  popperModifiers,
  defaultValue,
  setSelectedValue,
  fullBorder,
  clearable,
}) {
  const [addresses, setAddresses] = useState([]);

  const searchAddresses = useCallback(async (addressValue) => {
    const aa = await addressSuggestion(addressValue);
    if (aa && !aa.error) {
      setAddresses((previousAddresses) => {
        if (
          aa.length > 0 ||
          (aa.length === 0 && previousAddresses.length > 0)
        ) {
          return aa;
        }
        return previousAddresses;
      });
    } else {
      setAddresses((previousAddresses) => {
        if (previousAddresses.length > 0) {
          return [];
        }
        return previousAddresses;
      });
    }
  }, []);

  return (
    <GoogleLocationLookupWrapper>
      <FilterItemValues
        persistCasing
        colorOverride={colorOverride}
        minDropdownWidth="320px"
        error={validation === 'error'}
        itemSelection={itemSelection === false ? false : !itemSelectionMini}
        fullBorder={fullBorder}
        size={size}
        medium={medium}
        semibold={semibold}
        itemsSmall={itemsSmall}
        search={searchAddresses}
        optionEllipsis
        optionValues={addresses || defaultArray}
        selectedValue={value || selectedValue}
        ignoreFilter
        popperModifiers={popperModifiers}
        defaultValue={defaultValue}
        setSelectedValue={setSelectedValue}
        title="Address"
        clearable={clearable !== false}
      />
    </GoogleLocationLookupWrapper>
  );
}

GoogleLocationLookup.propTypes = {
  colorOverride: PropTypes.string,
  validation: PropTypes.string,
  itemSelection: PropTypes.bool,
  itemSelectionMini: PropTypes.bool,
  size: PropTypes.number,
  medium: PropTypes.bool,
  semibold: PropTypes.bool,
  itemsSmall: PropTypes.bool,
  value: PropTypes.string,
  selectedValue: PropTypes.string,
  // LINT OVERRIDE #6
  // Object has undetermined keys
  // eslint-disable-next-line react/forbid-prop-types
  popperModifiers: PropTypes.object,
  defaultValue: PropTypes.string,
  setSelectedValue: PropTypes.func,
  fullBorder: PropTypes.bool,
  clearable: PropTypes.bool,
};

export default GoogleLocationLookup;
