import UAParser from 'ua-parser-js';
import queryString from 'query-string';
import { canUseDOM } from 'exenv';

import { state as globalState } from './global/state';
import { state as application } from './application/state';
import { state as appSettings } from './appSettings/state';
import { state as preferences } from './preferences/state';
import { state as authentication } from './authentication/state';
import { state as dashboard } from './dashboard/state';
import modals from './modals/state';
import { state as sidebars } from './sidebars/state';
import { state as sidebarPage } from './sidebarPage/state';
import { state as rfqInterests } from './rfqInterests/state';
import requestForQuoteState from './requestForQuote/state';
import { state as onboarding } from './onboarding/state';
import { state as customers } from './customers/state';
import { state as metadata } from './metadata/state';
import { state as publicPages } from './public/state';
import { state as listingsPage } from './listings/state';
import { state as productsPage } from './products/state';
import { state as listingsManagementPage } from './listingsManagement/state';
import { state as productsManagementPage } from './productsManagement/state';
import { state as contentPage } from './content/state';
import { state as categoriesPage } from './categories/state';
import searchPageState from './search/state';
import { state as sellersApplicationPage } from './sellersApplication/state';
import { state as supplyInterests } from './supplyInterests/state';
import { state as wishlistInterests } from './wishlistInterests/state';
import { state as messaging } from './messaging/state';
import { state as activity } from './activity/state';
import { state as tags } from './tags/state';
import { state as conversations } from './conversations/state';
import { state as campaigns } from './campaigns/state';
import { state as attributes } from './attributes/state';
import { state as crm } from './crm/state';
import companies from './companies/state';
import marketComparables from './marketComparables/state';

export default function getInitialState(initialState = {}) {
  const uaParser = new UAParser();
  if (initialState.userAgent) {
    uaParser.setUA(initialState.userAgent);
  }

  let initialQueryParams = {};
  if (canUseDOM) {
    initialQueryParams = queryString.parse(window.location.search) || {};
  }

  return {
    cursors: {
      // app based state
      initialQueryParams,
      browser: uaParser.getResult(),
      application: application(initialState),
      global: globalState(initialState),
      appSettings: appSettings(initialState),
      metadata: metadata(initialState),
      preferences: preferences(initialState),
      messaging: messaging(initialState),
      activity: activity(initialState),
      tags: tags(initialState),
      // component based state:
      authentication: authentication(initialState),
      dashboard: dashboard(initialState),
      modals: modals(initialState),
      sidebars: sidebars(initialState),
      sidebarPage: sidebarPage(initialState),
      rfqInterests: rfqInterests(initialState),
      supplyInterests: supplyInterests(initialState),
      requestForQuote: requestForQuoteState(initialState),
      onboarding: onboarding(initialState),
      customers: customers(initialState),
      public: publicPages(initialState),
      listings: listingsPage(initialState),
      products: productsPage(initialState),
      listingsManagement: listingsManagementPage(initialState),
      productsManagement: productsManagementPage(initialState),
      content: contentPage(initialState),
      categories: categoriesPage(initialState),
      search: searchPageState(initialState),
      sellersApplication: sellersApplicationPage(initialState),
      wishlistInterests: wishlistInterests(initialState),
      conversations: conversations(initialState),
      campaigns: campaigns(initialState),
      attributes: attributes(initialState),
      crm: crm(initialState),
      companies: companies(initialState),
      marketComparables: marketComparables(initialState),
    },
  };
}
