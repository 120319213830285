import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { SM, MD } from '@zendeskgarden/react-typography';
import { Formik } from 'formik';
import { Form } from 'theme/Form';
import { Row, Col } from '@zendeskgarden/react-grid';
import { FullFormFieldWrapper } from 'theme/AuthForms.styles';
import { useBranch } from 'baobab-react/hooks';
import { get, find, capitalize, toLower, includes, filter } from 'lodash';
import { Button } from 'theme/Button';
import {
  createInternalRelationship,
  updateInternalRelationship,
  deleteInternalRelationship,
} from 'state/customers/actions';
import AutoCompleteFormItem from 'components/MultiTypeForm/components/MultiSelectFormItem/AutoCompleteFormItem';
import { relationshipStrengthMap } from 'utility/staticMap.ts';
import { Field, Textarea } from '@zendeskgarden/react-forms';
import { getSuggestion } from 'state/application/actions';
import { internalRelationshipShape } from 'propTypesObjects';
import Flex from 'styled-flex-component';
import { variables } from 'theme/variables';
import { getRole } from 'utility/hasAccount';
import { GreyButton } from './InternalRelationshipForm.styles';

const { color_red_400: colorRed400 } = variables;

function InternalRelationshipForm({ customerId, relationship, onSuccess }) {
  const { value: role } = getRole() || {};
  const internalRelationshipId = relationship?.id;

  const [deletingStatus, setDeletingStatus] = useState({});

  const { clientId } = useBranch({
    clientId: [
      'authentication',
      'session',
      'roles',
      role,
      'data',
      'company',
      'id',
    ],
  });

  const submitInternalRelationship = useCallback(
    async (values, { setSubmitting, setStatus }) => {
      setSubmitting(true);
      const outData = {
        user: customerId,
        relatedUser: get(values, 'holder.pk'),
        type: get(values, 'strength.label'),
        description: get(values, 'description'),
      };

      const saveInternalRelationshipResult = internalRelationshipId
        ? await updateInternalRelationship(internalRelationshipId, outData)
        : await createInternalRelationship(outData);

      const { error } = saveInternalRelationshipResult;
      if (error) {
        setStatus({ error });
      } else {
        setStatus({ error });
        if (onSuccess) {
          onSuccess(saveInternalRelationshipResult);
        }
      }
      setSubmitting(false);
    },
    [customerId, internalRelationshipId, onSuccess]
  );

  const onDeleteInternalRelationship = useCallback(async () => {
    setDeletingStatus({ loading: true });
    const result = await deleteInternalRelationship(internalRelationshipId);
    if (result.error) {
      setDeletingStatus({ error: result.error });
    } else {
      setDeletingStatus({ loading: false });
      onSuccess();
    }
  }, [internalRelationshipId, onSuccess]);

  return (
    <Formik
      initialValues={{
        holder: relationship
          ? {
              pk: relationship.related_user,
              label: relationship.related_user_name,
            }
          : null,
        strength: relationship
          ? find(relationshipStrengthMap, {
              label: capitalize(toLower(relationship.type)),
            })
          : null,
        description: relationship?.description || '',
      }}
      onSubmit={submitInternalRelationship}
    >
      {({ values, isSubmitting, status, setFieldValue, handleSubmit }) => (
        <FormRendered
          deletingStatus={deletingStatus}
          internalRelationshipId={internalRelationshipId}
          onDelete={onDeleteInternalRelationship}
          values={values}
          handleSubmit={handleSubmit}
          isSubmitting={isSubmitting}
          status={status}
          setFieldValue={setFieldValue}
          relationshipStrengths={relationshipStrengthMap}
          clientId={clientId}
          disabled={
            !values.holder || !values.strength || values.description === ''
          }
        />
      )}
    </Formik>
  );
}

InternalRelationshipForm.propTypes = {
  relationship: internalRelationshipShape,
  onSuccess: PropTypes.func,
  customerId: PropTypes.string,
};

function FormRendered({
  deletingStatus,
  internalRelationshipId,
  onDelete,
  values,
  handleSubmit,
  isSubmitting,
  status,
  setFieldValue,
  relationshipStrengths,
  clientId,
  disabled,
}) {
  const error =
    (status && status.error) || (deletingStatus && deletingStatus.error);

  const [transitionShowConfirmDelete, setTransitionShowConfirmDelete] =
    useState(false);

  const searchStrengths = (input) =>
    filter(relationshipStrengthMap, (strength) =>
      includes(toLower(strength.label), toLower(input))
    );

  const handleCancelDelete = () => {
    setTransitionShowConfirmDelete(false);
  };

  const handleConfirmDelete = () => {
    setTransitionShowConfirmDelete(false);
    onDelete();
  };

  return (
    <>
      <Form size="large" onSubmit={handleSubmit}>
        <Row>
          <Col>
            <MD navy bold>
              {internalRelationshipId
                ? 'Edit Relationship'
                : 'Add a Relationship'}
            </MD>
          </Col>
          <Col size="12">
            <FullFormFieldWrapper spacer spacing="0px">
              <SM bold navy scaled tag="span">
                Relationship Holder
              </SM>
              <AutoCompleteFormItem
                active={!!values.holder}
                style={{ marginTop: '10px' }}
                defaultValue="Select Employee"
                selectedItem={values.holder}
                setSelectedValue={async (value) => {
                  setFieldValue('holder', value);
                }}
                search={(value) =>
                  getSuggestion('user', value, {
                    clientId,
                    labelKey: 'full_name',
                    valueKey: 'pk',
                  })
                }
              />
            </FullFormFieldWrapper>
          </Col>

          <Col size="12">
            <FullFormFieldWrapper spacer spacing="0px">
              <SM bold navy scaled tag="span">
                Relationship Strength
              </SM>
              <AutoCompleteFormItem
                active={!!values.strength}
                style={{ marginTop: '10px' }}
                defaultValue="Select Strength"
                optionValues={relationshipStrengths}
                selectedItem={values.strength}
                setSelectedValue={(value) => {
                  setFieldValue('strength', value);
                }}
                search={searchStrengths}
              />
            </FullFormFieldWrapper>
          </Col>
          <Col size="12">
            <FullFormFieldWrapper spacer spacing="0px">
              <Field>
                <SM bold navy scaled tag="span">
                  Relationship Description
                </SM>
                <Textarea
                  medium
                  textarea
                  minRows={5}
                  isResizable
                  placeholder="Describe the relationship"
                  value={values.description}
                  onChange={(e) => {
                    setFieldValue('description', e.target.value);
                  }}
                  style={{ marginTop: '10px' }}
                />
              </Field>
            </FullFormFieldWrapper>
          </Col>
        </Row>
        <Row style={{ marginTop: '20px' }}>
          <Col
            alignCenter
            justifyBetween={internalRelationshipId}
            justifyCenter={!internalRelationshipId}
          >
            {error ? (
              <div>
                <SM error validation="error">
                  {error}
                </SM>
              </div>
            ) : null}
            {!transitionShowConfirmDelete ? (
              <Button
                loading={isSubmitting || undefined}
                type="submit"
                disabled={disabled}
                buttonStatus
                error={status && status.error}
                primary
              >
                {internalRelationshipId ? 'Save Changes' : 'Save & Add'}
              </Button>
            ) : null}
          </Col>
          {internalRelationshipId && !transitionShowConfirmDelete ? (
            <Col alignCenter justifyEnd>
              <Button
                color={colorRed400}
                link
                buttonStatus
                onClick={() => setTransitionShowConfirmDelete(true)}
              >
                Delete Relationship
              </Button>
            </Col>
          ) : null}
        </Row>
      </Form>
      {transitionShowConfirmDelete ? (
        <>
          <Flex justifyCenter>
            <MD navy>Are you sure you want to delete this Relationship?</MD>
          </Flex>
          <Row style={{ marginTop: '15px' }}>
            <Col
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Button primary type="button" onClick={handleConfirmDelete}>
                Delete
              </Button>
            </Col>
            <Col
              style={{
                display: 'flex',
                justifyContent: 'between',
                alignItems: 'center',
              }}
            >
              <GreyButton
                type="button"
                style={{ marginLeft: '10px' }}
                onClick={handleCancelDelete}
              >
                Cancel
              </GreyButton>
            </Col>
          </Row>
        </>
      ) : null}
    </>
  );
}

FormRendered.propTypes = {
  deletingStatus: PropTypes.shape({
    error: PropTypes.string,
  }),
  internalRelationshipId: PropTypes.number,
  onDelete: PropTypes.func,
  disabled: PropTypes.bool,
  values: PropTypes.shape({
    holder: PropTypes.shape({
      pk: PropTypes.number,
    }),
    strength: PropTypes.shape({
      value: PropTypes.number,
      label: PropTypes.string,
    }),
    description: PropTypes.string,
  }),
  handleSubmit: PropTypes.func,
  isSubmitting: PropTypes.bool,
  status: PropTypes.shape({
    error: PropTypes.string,
  }),
  setFieldValue: PropTypes.func,
  relationshipStrengths: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.number,
      label: PropTypes.string,
    })
  ),
  clientId: PropTypes.number,
};

export default InternalRelationshipForm;
