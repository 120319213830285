import React from 'react';
import styled from 'styled-components';
import { variables } from 'theme/variables';
import { PropTypes } from 'prop-types';

const { custom_blue: customBlue } = variables;

const MenuIconStyle = styled.div`
  display: flex;
  justify-content: center;
  color: ${customBlue};
  padding-left: ${(props) => (props.isExpanded ? `` : `12px`)};
  width: 25px;
`;

function MenuIcon({ active, icon, isExpanded }) {
  return icon ? (
    <MenuIconStyle active={active} isExpanded={isExpanded}>
      {icon}
    </MenuIconStyle>
  ) : null;
}

MenuIcon.propTypes = {
  active: PropTypes.bool,
  icon: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  isExpanded: PropTypes.bool,
};

export default MenuIcon;
