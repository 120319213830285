import React, { useEffect, useState } from 'react';
import { fetchFile } from 'state/application/actions';
import Icon from 'components/Icon/Icon';
import PropTypes from 'prop-types';
import { ThumbnailWrapper } from './DocumentDownload.styles';

function ThumbnailDownload({
  position,
  modal,
  style = {},
  height,
  width,
  disableDelete,
  onRemoveFile,
  documentItem = {},
}) {
  const { fileId } = documentItem;
  const [image, setImage] = useState();

  // TODO: Possibly set up loading and error renders for these two states since they
  // are being used in our useEffect. Possibly change to console log for the error since
  // it is just a thumbnail?
  const [, setLoading] = useState();
  const [, setError] = useState();

  useEffect(() => {
    async function downloadFile() {
      setLoading(true);
      const file = await fetchFile(fileId, { isImage: true });
      if (file && !file.error) {
        setImage(file);
        setError(false);
      } else {
        setError(file.error);
      }
      setLoading(false);
    }
    downloadFile();
  }, [fileId]);

  return (
    <ThumbnailWrapper
      style={{ position: 'relative', ...style }}
      className="thumbnail-download-wrapper"
    >
      {!disableDelete && (
        <Icon
          style={{
            position: 'absolute',
            top: '-10px',
            right: '-10px',
            background: '#b95959',
            borderRadius: '100px',
            width: '30px',
            height: '30px',
          }}
          icon="icon-delete"
          color="#fff"
          fontSize="13px"
          buttonStyle
          onClick={(e) => {
            e.stopPropagation();
            if (window.confirm(`Are you sure you want to delete this image?`)) {
              onRemoveFile(e);
            }
          }}
        />
      )}

      <div
        onClick={
          modal && image && image.image
            ? () => window.open(image.image, '', 'width=900,height=700')
            : null
        }
        onKeyUp={
          modal && image && image.image
            ? () => window.open(image.image, '', 'width=900,height=700')
            : null
        }
        role="button"
        tabIndex={0}
        size="auto"
        aria-label="thumbnail"
      >
        <div
          style={{
            height: height || '80px',
            width: width || '120px',
            backgroundSize: 'contain',
            backgroundPosition: position || 'center',
            backgroundRepeat: 'no-repeat',
            backgroundImage: `url(${image?.image})`,
          }}
        />
      </div>
    </ThumbnailWrapper>
  );
}

ThumbnailDownload.propTypes = {
  position: PropTypes.string,
  modal: PropTypes.bool,
  style: PropTypes.shape({}),
  height: PropTypes.string,
  width: PropTypes.string,
  disableDelete: PropTypes.bool,
  onRemoveFile: PropTypes.func,
  documentItem: PropTypes.shape({}),
};

export default ThumbnailDownload;
