import styled from 'styled-components/macro';
import { variables } from 'theme/variables';

const {
  custom_silver: customSilver,
  custom_grey: customGrey,
  custom_blue: customBlue,
  color_white: colorWhite,
  custom_light_grey: customLightGrey,
  custom_black: customBlack,
  custom_teal: customTeal,
} = variables;

export const CheckBoxWrapper = styled('div')`
  font-size: ${(props) => props.size || '12px'};
  width: 1em;
  height: 1em;
  position: absolute;
  color: ${customBlue};
  left: 1em;
  cursor: pointer;
  * {
    cursor: pointer;
    font-size: 1em;
    color: ${customBlue};
  }
  ${(props) =>
    props.small
      ? `
    left: 0.8em;
  `
      : ''}
  ${(props) =>
    !props.checked
      ? `
    border: 1px solid ${customSilver};
    background-color: ${colorWhite};
  `
      : ''}
  input {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    opacity: 0;
    width: 100%;
    height: 100%;
  }
`;

export const CheckboxFoaxInput = styled('div')`
  position: relative;
  ${(props) =>
    !props.border
      ? ''
      : `
    border: 1px solid #e0e0e0 !important;
    padding: 7px 13px !important;
    display: table !important;
  `}
  &:first-child {
    margin-top: 0;
  }
  label {
    display: flex;
    align-items: center;
  }
  input {
    position: absolute;
    opacity: 0;

    ${/* styles the text label */ ''}
    & + label {
      position: relative;
      cursor: pointer;
      padding: 0;
      transition: background 0.2s;
      font-size: 12px;
      font-weight: ${(props) => (props.type === 'radio' ? '400px' : '500px')};
      color: ${(props) => props.labelColor || customBlack};
      user-select: none;
    }

    ${/* label:before styles the checkbox square */ ''}
    & + label:before {
      content: '';
      margin-right: 10px;
      display: inline-block;
      vertical-align: text-top;
      width: ${(props) => (props.type === 'radio' ? '14px' : '18px')};
      height: ${(props) => (props.type === 'radio' ? '14px' : '18px')};
      background: ${(props) =>
        props.loadingData
          ? customTeal
          : props.uncheckedBackground || 'transparent'};
      border: 1px solid ${customSilver};
      transition: background 0.2s;
      border-radius: ${(props) => (props.type === 'radio' ? '100%' : '3px')};
    }

    & + label:after {
    }

    &:hover + label:before {
      background: ${customLightGrey};
    }

    &:focus + label:before {
      box-shadow: 0 0 0 3px ${customLightGrey};
    }

    &:checked + label:before {
      box-shadow: 0 0 0 3px transparent;
    }

    &:checked + label:before {
      background: ${(props) => {
        if (props.loadingData) {
          return customTeal;
        }
        if (props.checkedBackground) {
          return `${props.checkedBackground} !important`;
        }
        return customBlue;
      }};
      border: 1px solid ${customBlue};
    }

    &:disabled + label {
      color: #b8b8b8;
      cursor: auto;
    }

    &:disabled + label:before {
      box-shadow: none;
      background: #ddd;
    }

    ${/* border and box-shadow form the custom stylized checkmark */ ''}
    &:checked + label:after {
      content: '';
      position: absolute;
      left: 4px;
      transform: translateX(50%);
      background: #fff;
      border: 1px solid ${(props) => props.checkmarkColor || colorWhite};
      width: ${(props) => (props.type === 'radio' ? '6px' : '2px')};
      height: ${(props) => (props.type === 'radio' ? '6px' : '2px')};
      border-radius: ${(props) => (props.type === 'radio' ? '100%' : '0px')};
      box-shadow: ${(props) =>
        props.type === 'radio'
          ? 'none'
          : `
        2px 0 0 ${props.checkmarkColor || colorWhite},
        4px 0 0 ${props.checkmarkColor || colorWhite},
        4px -2px 0 ${props.checkmarkColor || colorWhite},
        4px -4px 0 ${props.checkmarkColor || colorWhite},
        4px -6px 0 ${props.checkmarkColor || colorWhite},
        4px -8px 0 ${props.checkmarkColor || colorWhite};
      `};
      transform: rotate(45deg) scale(0.8);
    }
  }
`;

export const CheckboxList = styled('div')`
  height: ${(props) => props.height || 'auto'};
  overflow-y: auto;
  overflow-scrolling: touch;
  padding-bottom: 10px;
`;

export const CheckboxListWrapper = styled('div')`
  border: ${(props) =>
    props.height || props.footer
      ? `1px solid ${customLightGrey}`
      : '1px solid transparent'};
  ${CheckboxFoaxInput} {
    padding: ${(props) => (props.height || props.footer ? `0 15px` : '0')};
  }
`;

export const CheckboxListFooter = styled('div')`
  height: 30px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${customLightGrey};
  cursor: pointer;
  color: ${customGrey};
`;
