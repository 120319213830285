import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { closeModal } from 'state/modals/actions';
import Icon from 'components/Icon/Icon';
import Flex from 'styled-flex-component';
import styled from 'styled-components/macro';
import PageViewWrapper from 'higherOrder/PageViewWrapper';
import { Row } from '@zendeskgarden/react-grid';
import { SM, LG } from '@zendeskgarden/react-typography';
import { Formik } from 'formik';
import { Form } from 'theme/Form';
import { isEmpty, map, noop } from 'lodash';
import { useBranch } from 'baobab-react/hooks';
import { Button } from 'theme/Button';
import { rfqMetaShape } from 'propTypesObjects';
import { variables } from 'theme/variables';
import DropZoneFileManager from '../../../DropZone/DropZoneFileManager';
import MultiTypeForm from '../../../MultiTypeForm/MultiTypeForm';
import { generateBulkUserUpload } from '../../../../state/customers/partner/transformer_partner_customers';

const { custom_blue: customBlue } = variables;

export const Wrapper = styled.section`
  width: 500px;
  max-width: 90%;
  .icon-close {
    position: absolute;
    top: 5px;
    right: 5px;
  }
`;

export const SubmittedMessage = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 40px;
  margin: 0 auto;
  text-align: center;
`;

function UserFileUploadModal({ styles }) {
  const { rfqMetaData } = useBranch({
    rfqMetaData: ['requestForQuote', 'meta', 'result'],
  });
  const [submitting, setSubmitting] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [bulkUploadFileDropzone, setBulkUploadFileDropzone] = useState({});

  async function handleBulkUserUpload() {
    setSubmitting(true);
  }

  async function onSaved() {
    setSubmitted(true);
  }

  return (
    <Wrapper
      style={{
        margin: '0 auto',
        maxWidth: '800px',
        ...(styles || { margin: '0 auto' }),
      }}
    >
      <Icon
        onClick={closeModal}
        buttonStyle
        icon="icon-close"
        fontSize="10px"
      />
      {submitted ? (
        <SubmittedMessage>
          <div>Thank you for submitting your bulk user upload form.</div>
          <div>
            Please check for your uploaded users within the next 10 minutes.
          </div>
          <div style={{ marginBottom: '10px' }}>
            Until then you can continue to browse the site.
          </div>
          <Button
            buttonStatus
            primary
            style={{ marginTop: '10px', marginBottom: 0 }}
            onClick={closeModal}
          >
            Close
          </Button>
        </SubmittedMessage>
      ) : (
        <Formik validate={noop} onSubmit={() => handleBulkUserUpload()}>
          {(props) => (
            <RenderedForm
              // LINT OVERRIDE #3
              // Component wraps another component
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...props}
              values={props?.values}
              rfqMeta={rfqMetaData}
              submitting={submitting}
              onSaved={(...args) => onSaved(...args)}
              bulkUploadFileDropzone={bulkUploadFileDropzone}
              setBulkUploadFileDropzone={setBulkUploadFileDropzone}
            />
          )}
        </Formik>
      )}
    </Wrapper>
  );
}

UserFileUploadModal.propTypes = {
  styles: PropTypes.shape({
    background: PropTypes.string,
    bottom: PropTypes.string,
    boxShadow: PropTypes.string,
    left: PropTypes.string,
    position: PropTypes.string,
    right: PropTypes.string,
    top: PropTypes.string,
  }),
  values: PropTypes.shape({}),
};

function RenderedForm({
  values,
  rfqMeta,
  setFieldValue,
  handleSubmit,
  submitting,
  onSaved,
  bulkUploadFileDropzone,
  setBulkUploadFileDropzone,
}) {
  const getTagData = useCallback(async () => {
    const initialTagData = await generateBulkUserUpload();
    const originalTag = initialTagData.bulkUsers || {};
    if (originalTag?.fields?.tag) {
      originalTag.fields.tag.value =
        values?.tag || initialTagData?.tag?.value || [];
    }
    return { tag: originalTag };
  }, [values?.tag]);

  const [tagData, setTagData] = useState({});
  const [errorResubmitKey, setErrorResubmitKey] = useState(0);

  const formattedTags = map(values.tag, 'value');

  // This is specifically for the tag data already available for users for the autocomplete
  useEffect(() => {
    async function waitForTagData() {
      const response = await getTagData();
      setTagData(response);
    }
    waitForTagData();
  }, [getTagData]);

  // This is just a hack to not have the dropzone immediately try to upload the file
  useEffect(() => {
    if (submitting && !isEmpty(bulkUploadFileDropzone?.files)) {
      setErrorResubmitKey(
        (errorResubmitKeyPrevious) => errorResubmitKeyPrevious + 1
      );
    }
  }, [submitting, bulkUploadFileDropzone?.files]);

  return (
    <Form size="small">
      <Flex style={{ margin: '0 auto', flexWrap: 'wrap' }}>
        <LG bold style={{ margin: '20px' }}>
          Bulk User Upload
        </LG>
        <Row style={{ width: '100%', justifyContent: 'space-evenly' }}>
          <DropZoneFileManager
            type="upload"
            setExternalState={setBulkUploadFileDropzone}
            onSaved={onSaved}
            externalSubmit
            additionalSubmitData={
              !isEmpty(formattedTags) ? { tags: formattedTags } : {}
            }
            errorResubmitKey={errorResubmitKey}
            multiple={false}
            outerWrapperStyle={{ width: '70%', margin: '10px 0 0' }}
            disableDelete
            customPath="api/user/bulk/excel-template/"
          />
        </Row>
        <Row
          style={{
            width: '100%',
            justifyContent: 'space-evenly',
            maxWidth: '400px',
            margin: '0 auto',
          }}
        >
          <MultiTypeForm
            ignoreHeader
            data={tagData}
            setData={(cat, _type, val) => {
              setFieldValue('tag', val.value);
            }}
            contentType={rfqMeta.tag_content_types.AuthUser}
          />
          {values?.tag?.length > 0 && (
            <SM style={{ color: customBlue, textAlign: 'center' }}>
              These tags will be added to each user uploaded in the bulk upload
              form.
            </SM>
          )}
        </Row>
        <Row
          style={{
            width: '100%',
            justifyContent: 'space-evenly',
            marginBottom: '30px',
          }}
        >
          <Button
            buttonStatus
            primary
            style={{ marginTop: '10px', marginBottom: 0 }}
            disabled={isEmpty(bulkUploadFileDropzone?.files) || submitting}
            onClick={() => handleSubmit()}
          >
            Save & Close
          </Button>
        </Row>
      </Flex>
    </Form>
  );
}

RenderedForm.propTypes = {
  values: PropTypes.shape({
    tag: PropTypes.arrayOf(PropTypes.shape({})),
  }),
  rfqMeta: rfqMetaShape,
  setFieldValue: PropTypes.func,
  handleSubmit: PropTypes.func,
  submitting: PropTypes.bool,
  onSaved: PropTypes.func,
  bulkUploadFileDropzone: PropTypes.shape({
    files: PropTypes.shape({}),
  }),
  setBulkUploadFileDropzone: PropTypes.func,
};

export default PageViewWrapper(UserFileUploadModal, {
  pageType: 'modal',
  pageName: 'UserFileUploadModal',
});
