import React, { useState, useEffect, useCallback } from 'react';
import Spinner from 'components/Spinner/Spinner';
import { Link } from 'react-router-dom';
import { trackClick } from 'utility/analytics';
import { IconButton } from '@zendeskgarden/react-buttons';
import { Tag } from '@zendeskgarden/react-tags';
import { Dropdown, Trigger, Menu } from '@zendeskgarden/react-dropdowns';
import { variables } from 'theme/variables';
import Flex from 'styled-flex-component';
import { SM, MD } from '@zendeskgarden/react-typography';
import Icon from 'components/Icon/Icon';

import { get, filter, map, groupBy } from 'lodash';
import moment from 'moment';
import useStreamNotificationFeed from 'hooks/useStreamNotificationFeed';
import { PropTypes } from 'prop-types';
import NotificationDropdownList from './components/NotificationDropdownList/NotificationDropdownList';
import {
  MenuHeader,
  MenuBody,
  MenuFooter,
  MenuWrapper,
} from './NotificationDropdown.styles';

const {
  custom_blue: customBlue,
  spacing_xs: spacingXs,
  custom_light_grey: customLightGrey,
  line_height_xxl: lineHeightXxl,
} = variables;

function NotificationDropdown({ session }) {
  const [isOpenGlobal, setOpen] = useState(undefined);
  const { feedList, feed, resetNotifications, loading } =
    useStreamNotificationFeed({ isOpenGlobal });

  const toggleOpen = useCallback(() => {
    trackClick('toggleNotificationDropdown', 'button', 'NotificationDropdown');
    setOpen(!isOpenGlobal);
    resetNotifications();
  }, [isOpenGlobal, resetNotifications]);

  function close() {
    if (isOpenGlobal) {
      setOpen(false);
    }
  }

  useEffect(() => {
    window.addEventListener('click', close);
    return () => {
      window.removeEventListener('click', close);
    };
  });

  const unSeen = filter(feed, ({ is_seen: isSeen, activities }) => {
    const isSelf = activities && activities[0].user_id === session.user_id;
    return !isSeen && !isSelf;
  });

  let activity = map(
    filter(feedList, (fli) => {
      const isSelf =
        get(fli.activities, 'length') === 1 &&
        fli.activities[0].user_id === session.user_id;
      return !isSelf;
    }),
    (a) => ({ day: moment(a.updated_at).format('YYYY-MM-DD'), ...a })
  );
  activity = groupBy(activity, 'day');

  const displayMenu = useCallback(() => {
    if (loading) {
      return <Spinner style={{ margin: '30px auto' }} />;
    }
    if (feedList && feedList.length === 0) {
      return (
        <div>
          <MD center paddingTopLg>
            There Are No Notifications At This Time.
          </MD>
        </div>
      );
    }
    return map(activity, (ac, d) => (
      <div key={d}>
        <MenuHeader
          onClick={(e) => e.stopPropagation()}
          style={{
            backgroundColor: customLightGrey,
            height: lineHeightXxl,
          }}
        >
          <SM bold>
            {moment.utc(d).isSame(moment(), 'day')
              ? 'Today'
              : moment.utc(d).local().format('DD-MMM-YYYY')}
          </SM>
        </MenuHeader>
        <MenuBody onClick={(e) => e.stopPropagation()}>
          <NotificationDropdownList
            onClickItem={(e) => {
              e.stopPropagation();
              toggleOpen();
            }}
            notifications={ac}
          />
        </MenuBody>
      </div>
    ));
  }, [activity, feedList, loading, toggleOpen]);

  return (
    <Dropdown style={{ width: '200px' }} arrow={false} isOpen={!!isOpenGlobal}>
      <Trigger>
        <span style={{ outline: 'none' }}>
          <IconButton
            onClick={(e) => {
              e.stopPropagation();
              toggleOpen();
            }}
            style={{ maxWidth: '35px', maxHeight: '35px', minWidth: '35px' }}
            square
            whiteButtonLink
            black
            bold
            alignCenter
            justifyCenter
          >
            {unSeen.length ? (
              <Tag
                style={{ minWidth: '18px', minHeight: '18px' }}
                size="small"
                absolute
                shadow
                circle
                blue
                button
                pill
              >
                {unSeen.length}
              </Tag>
            ) : null}
            <Icon
              pointer
              color={isOpenGlobal ? customBlue : undefined}
              icon="icon-notification"
              fontSize="15px"
            />
          </IconButton>
        </span>
      </Trigger>
      {isOpenGlobal ? (
        <Menu
          style={{ padding: '0px', width: '400px', marginTop: '-30px' }}
          menuHeader
          arrow={false}
          placement="bottom-end"
        >
          <span>
            <MenuHeader onClick={(e) => e.stopPropagation()}>
              <Flex style={{ width: '100%' }} justifyBetween alignCenter>
                <Flex alignCenter>
                  <Icon icon="icon-notification" fontSize="16px" color="#fff" />
                  <MD style={{ paddingLeft: spacingXs }} color="#fff">
                    Notifications
                  </MD>
                </Flex>
                <IconButton
                  onClick={(e) => {
                    trackClick(
                      'closeNotificationDropdown',
                      'icon-close',
                      'NotificationDropdown'
                    );
                    e.stopPropagation();
                    toggleOpen();
                  }}
                  disableBackground
                  alignCenter
                  justifyEnd
                  aria-label="closeNotificationDropdown"
                >
                  <Icon button icon="icon-close" fontSize="12px" color="#fff" />
                </IconButton>
              </Flex>
            </MenuHeader>
            <MenuWrapper>{displayMenu()}</MenuWrapper>
            <MenuFooter
              onClick={(e) => {
                e.stopPropagation();
                trackClick(
                  'viewAllNotifications',
                  'View All Activity',
                  'NotificationDropdown'
                );
                toggleOpen();
              }}
            >
              <Link style={{ fontSize: '12px' }} to="/dashboard/activity">
                View All Activity
              </Link>
            </MenuFooter>
          </span>
        </Menu>
      ) : null}
    </Dropdown>
  );
}

NotificationDropdown.propTypes = {
  session: PropTypes.oneOfType([
    PropTypes.shape({
      user_id: PropTypes.number,
    }),
    PropTypes.string,
  ]),
};

export default NotificationDropdown;
