import fetchJson from 'utility/fetchJson';
import { getValuesForRequest } from 'utility/reverseUrlFilters';
import { BASE } from '../../constants';

export async function getCategories() {
  const response = await fetchJson(`${BASE}/api/p/category/`, {
    method: 'GET',
  });
  return response;
}

export async function getContent(id) {
  const response = await fetchJson(`${BASE}/api/p/content/${id}/`, {
    method: 'GET',
  });
  return response;
}

export async function getContentList({ filters, pagination, sorting = {} }) {
  const filtersCopy = !filters ? getValuesForRequest('blog') : filters;
  const body = { ...filtersCopy, ...pagination, ...sorting };
  const response = await fetchJson(`${BASE}/api/p/content/list/`, {
    method: 'POST',
    body: JSON.stringify(body),
  });
  return response;
}

export async function addContent(data) {
  const response = await fetchJson(`${BASE}/api/p/content/`, {
    method: 'POST',
    body: JSON.stringify(data),
  });
  return response;
}

export async function patchContent(id, data) {
  const response = await fetchJson(`${BASE}/api/p/content/${id}/`, {
    method: 'PATCH',
    body: JSON.stringify(data),
  });
  return response;
}

export async function updateStatus(id, status) {
  const response = await fetchJson(`${BASE}/api/p/content/${id}/${status}/`, {
    method: 'POST',
  });
  return response;
}

export async function getSimilarContentList(
  id,
  { filters, pagination, sorting = {} }
) {
  const filtersCopy = !filters ? getValuesForRequest('blog') : filters;
  const body = { ...filtersCopy, ...pagination, ...sorting, id };
  const response = await fetchJson(`${BASE}/api/p/content/similar/`, {
    method: 'POST',
    body: JSON.stringify(body),
  });
  return response;
}
