import React, { useEffect, useState, useCallback, useRef } from 'react';
import PropTypes from 'prop-types';
import { SM } from '@zendeskgarden/react-typography';
import Promise, { map as promiseMap } from 'bluebird';
import {
  some,
  mapValues,
  omit,
  values,
  omitBy,
  toPairs,
  keyBy,
  isEmpty,
  map,
  cloneDeep,
  includes,
  keys,
  forEach,
  assign,
  size,
  isArray,
} from 'lodash';
import {
  createFile,
  fetchFilesForObjectId,
  deleteFile,
} from 'state/application/actions';

import DropZoneModal from 'components/DropZone/DropZoneModal';
import DropZoneFileRenderer from './DropZoneFileRenderer';
import DropZone from './DropZone.tsx';

/* 
  PROPS

  fileType (unknown type): Optional. Set file type for GET request. This isn't currently used anywhere, and it may be because the endpoint doesn't responed to type or sub_type querystrings yet. See the fetchFilesForObjectId effect
  fileFilters (object): Optional. List of keys and values by which to filter displayed files. See the 'files' key of setExternalData for object keys
  hidePreviousUploads (boolean): Optional. Specifically exempts component from getting existing file data (previously relied upon hasExternalSubmit value, but sometimes a component with externalSubmit wants to see existing files)
  portal (boolean): Optional. Determines whether to add or remove html tags in the DropZoneModal.
  forceShowHeader (boolean): Optional. Forces the passed in header to be shown on the DropZoneModal.
  customPath (string): Optional. Allows for a custom path to be passed in for the file upload. This is used in the UserFileUploadModal to upload to a specific path. send in 'api/etc...' and it will be appended to the base url.
*/

const additionalSubmitDataDefault = {};

function DropZoneFileManager({
  fileType,
  fileFilters,
  hidePreviousUploads,
  portal,
  forceShowHeader,
  hideFileRender,
  renderContinueContainer,
  descriptionContainer,
  uploadOnDrop,
  multiple,
  onClickThumbnail,
  showUploadAndRender,
  disableDelete,
  style,
  showModal,
  header,
  type,
  onSaved,
  contentType,
  imageContentType,
  fileContentType,
  objectId,
  children,
  onClose,
  height,
  isImage,
  externalSubmit,
  setExternalState,
  errorResubmitKey,
  additionalSubmitData = additionalSubmitDataDefault,
  modalHeader,
  setFileCount,
  outerWrapperStyle,
  customPath,
}) {
  const [uploaded, setUploaded] = useState({});
  const [getFileStatus, setGetFileStatus] = useState({});

  const hasExternalSubmit = !!externalSubmit;

  function uploadedItemsHaveError(uploadedItems) {
    return some(
      values(uploadedItems),
      (item) => item.error || (item.saved && item.saved.error)
    );
  }

  const [submitting, setSubmitting] = useState(false);

  const submittingRef = useRef(false);

  useEffect(() => {
    submittingRef.current = submitting;
  }, [submitting]);

  useEffect(() => {
    if (setExternalState) {
      setExternalState({
        files: uploaded,
        error: uploadedItemsHaveError(uploaded),
      });
    }
  }, [setExternalState, uploaded]);

  const onSubmitFiles = useCallback(
    async (e) => {
      if (e) {
        e.stopPropagation();
      }
      // Files that are queued and not saved
      const nonSaved = omitBy(
        uploaded,
        (f) => (f.saved && !f.queued) || f.server || f.error
      );

      let saved = [];
      if (!isEmpty(nonSaved)) {
        setSubmitting(true);
        // Queue files that haven't been saved
        setUploaded((uploadedPrevious) =>
          mapValues(uploadedPrevious, (file) => {
            const fileCopy = cloneDeep(file);
            if (!fileCopy.saved) {
              fileCopy.queued = true;
            }
            return fileCopy;
          })
        );
        saved = await promiseMap(
          toPairs(nonSaved),
          async ([id, file]) => {
            let savedFile;
            try {
              setUploaded((uploadedPrevious) => ({
                ...uploadedPrevious,
                [id]: { ...file, saving: true },
              }));
              await Promise.delay(500); // help the UI give loading state
              const data = new FormData();

              const uploadImage =
                isImage || includes(file.type || '', 'image/');
              const activeContentType =
                contentType ||
                (uploadImage ? imageContentType : fileContentType); // There are times when we dont want to pull back the images but want to dynamically set the type
              data.append(uploadImage ? 'image' : 'file', file.file, file.name);
              // Only appends the content_type if it is provided. Might not be needed for a custom path.
              if (activeContentType) {
                data.append('content_type', activeContentType);
              }
              // Only appends the object_id if it is provided. Might not be needed for a custom path.
              if (objectId) {
                data.append('object_id', objectId);
              }
              const mtrNumber =
                file?.mtrNumber?.length && file?.mtrNumber?.length > 0
                  ? file?.mtrNumber
                  : null;
              if (mtrNumber) {
                data.append('mtr_number', mtrNumber);
              }
              if (fileType) {
                data.append('type', fileType);
              }
              if (additionalSubmitData) {
                forEach(keys(additionalSubmitData), (key) => {
                  if (isArray(additionalSubmitData[key])) {
                    data.append(
                      `${key}`,
                      JSON.stringify(additionalSubmitData[key])
                    );
                  } else {
                    data.append(key, additionalSubmitData[key]);
                  }
                });
              }
              savedFile = await createFile(data, {
                isImage: uploadImage,
                customPath,
              });

              if (savedFile && savedFile.error) {
                setUploaded((uploadedPrevious) => ({
                  ...uploadedPrevious,
                  [id]: {
                    ...file,
                    saving: false,
                    error: savedFile.error,
                    queued: false,
                  },
                }));
              } else {
                setUploaded((uploadedPrevious) => ({
                  ...uploadedPrevious,
                  [id]: {
                    ...file,
                    saved: savedFile,
                    error: null,
                    saving: false,
                    queued: false,
                  },
                }));
              }
            } catch (err) {
              setUploaded((uploadedPrevious) => ({
                ...uploadedPrevious,
                [id]: {
                  ...file,
                  saving: false,
                  error: err.message,
                  queued: false,
                },
              }));
            }

            return { ...file, saved: savedFile };
          },
          { concurrency: 1 }
        );

        setSubmitting(false);
      }
      if (onSaved && !uploadedItemsHaveError(saved)) {
        onSaved(saved);
      }
    },
    [
      additionalSubmitData,
      contentType,
      fileContentType,
      fileType,
      imageContentType,
      isImage,
      objectId,
      onSaved,
      uploaded,
      customPath,
    ]
  );

  // There are some cases where we want the ability to wait to upload until an action has been performed outside of the component.
  // errorResubmitKey = a random id that triggers the use effect. - kinda hacky.
  // setExternalState = this allows parent components to know the state of this component - kinda hacky
  useEffect(() => {
    if (
      hasExternalSubmit &&
      (objectId || customPath) &&
      !submittingRef.current &&
      errorResubmitKey
    ) {
      // && !submitting
      // This will be called immediately as well, but with no effect besides triggering onSaved. Perhaps wrap the body of this function with an if statement for clarity
      onSubmitFiles();
    }
  }, [
    hasExternalSubmit,
    objectId,
    errorResubmitKey,
    onSubmitFiles,
    customPath,
  ]);

  useEffect(() => {
    async function getFiles() {
      const data = { objectId, contentType };
      if (fileType) {
        data.type = fileType;
      }
      const fileRequest = await fetchFilesForObjectId(data, {
        isImage,
      });
      if (fileRequest && fileRequest.length) {
        const results = map(fileRequest, (r) => ({
          ...r,
          saved: r,
          server: true,
        }));
        const allFiles = { ...keyBy(results, 'id') };
        if (fileFilters) {
          const filteredFiles = {};
          // Iterate through fileFilters keys and build a new object with file keys from uploaded that match the filter values
          forEach(keys(allFiles), (allFilesKey) => {
            let match = true;
            forEach(keys(fileFilters), (fileFiltersKey) => {
              if (
                allFiles[allFilesKey][fileFiltersKey] !==
                fileFilters[fileFiltersKey]
              ) {
                match = false;
              }
            });
            if (match) {
              assign(filteredFiles, {
                [allFilesKey]: allFiles[allFilesKey],
              });
            }
          });
          setUploaded(filteredFiles);
        } else {
          setUploaded(allFiles);
        }
      } else if (fileRequest?.error) {
        setGetFileStatus({ error: fileRequest?.error });
      }
    } // end func

    if (objectId && contentType && !hidePreviousUploads) {
      setUploaded({});
      getFiles();
    } else {
      setUploaded({});
    }
  }, [
    objectId,
    contentType,
    hasExternalSubmit,
    hidePreviousUploads,
    fileType,
    isImage,
    fileFilters,
  ]); // we may not always have or care to get the current files. IE conversation

  const onRemoveFile = useCallback(
    async ({ data, key }) => {
      if (data.saved) {
        const deleteF = await deleteFile(data.saved.id, {
          isImage,
          legacy: data.legacy,
        });
        if (deleteF.error) {
          setUploaded({
            ...uploaded,
            [key]: { ...data, error: deleteF.error },
          });
        } else {
          setUploaded(omit(uploaded, key));
        }
      } else {
        setUploaded(omit(uploaded, key));
      }
    },
    [isImage, uploaded]
  );

  const onAddFile = useCallback(
    (files) => {
      const newFiles = { ...uploaded, ...files };
      setUploaded(newFiles);
    },
    [uploaded]
  );

  const onCloseModal = useCallback(() => {
    const allAsServer = mapValues(uploaded, (file) => ({
      ...file,
      server: !!file.saved,
    }));
    setUploaded(allAsServer);
    onClose();
  }, [onClose, uploaded]);

  const savedUploadedFiles = omitBy(uploaded, (d) => !d.saved);

  useEffect(() => {
    if (setFileCount) {
      setFileCount(size(savedUploadedFiles));
    }
  }, [savedUploadedFiles, setFileCount]);

  return (
    <div style={outerWrapperStyle || null}>
      {(header &&
        (forceShowHeader ||
          !isEmpty(savedUploadedFiles) ||
          type === 'upload' ||
          showUploadAndRender) &&
        header(savedUploadedFiles)) ||
        ''}
      {showModal ? (
        <DropZoneModal
          portal={portal}
          isImage={isImage}
          uploadOnDrop={uploadOnDrop}
          multiple={multiple}
          onRemoveFile={onRemoveFile}
          onSubmitFiles={onSubmitFiles}
          uploaded={omitBy(uploaded, 'server')}
          setUploaded={setUploaded}
          descriptionContainer={descriptionContainer}
          renderContinueContainer={renderContinueContainer}
          onClose={onCloseModal}
          height={height}
          onAddFile={onAddFile}
          modalHeader={modalHeader}
          submitting={submitting}
          fileType={fileType}
        >
          {children}
        </DropZoneModal>
      ) : null}
      <div style={!isEmpty(savedUploadedFiles) && style ? style : {}}>
        {type === 'upload' ? (
          <DropZone
            externalSubmit={externalSubmit}
            isImage={isImage}
            uploadOnDrop={uploadOnDrop}
            multiple={multiple}
            onRemoveFile={onRemoveFile}
            onSubmitFiles={onSubmitFiles}
            uploaded={omitBy(uploaded, 'server')}
            setUploaded={setUploaded}
            height={height}
            onAddFile={onAddFile}
            descriptionContainer={descriptionContainer}
            renderContinueContainer={renderContinueContainer}
            submitting={submitting}
            fileType={fileType}
          >
            {children}
          </DropZone>
        ) : null}
        {(type !== 'upload' || showUploadAndRender) && !hideFileRender ? (
          <div style={type === 'upload' ? { marginTop: '20px' } : {}}>
            <DropZoneFileRenderer
              isImage={isImage}
              uploadOnDrop={uploadOnDrop}
              multiple={multiple}
              onClickThumbnail={onClickThumbnail}
              disableDelete={disableDelete}
              files={savedUploadedFiles}
              onRemoveFile={onRemoveFile}
            />
            {getFileStatus?.error ? (
              <SM error paddingTopSm>
                {getFileStatus?.error}
              </SM>
            ) : null}
          </div>
        ) : null}
      </div>
    </div>
  );
}

DropZoneFileManager.propTypes = {
  fileType: PropTypes.string,
  portal: PropTypes.bool,
  forceShowHeader: PropTypes.bool,
  hideFileRender: PropTypes.bool,
  renderContinueContainer: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.func,
  ]),
  descriptionContainer: PropTypes.node,
  uploadOnDrop: PropTypes.bool,
  multiple: PropTypes.bool,
  onClickThumbnail: PropTypes.func,
  showUploadAndRender: PropTypes.bool,
  disableDelete: PropTypes.bool,
  // LINT OVERRIDE #6
  // Object has undetermined keys
  // eslint-disable-next-line react/forbid-prop-types
  style: PropTypes.object,
  showModal: PropTypes.bool,
  header: PropTypes.func,
  type: PropTypes.string,
  onSaved: PropTypes.func,
  contentType: PropTypes.number,
  imageContentType: PropTypes.number,
  fileContentType: PropTypes.number,
  objectId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  children: PropTypes.node,
  onClose: PropTypes.func,
  height: PropTypes.string,
  isImage: PropTypes.bool,
  externalSubmit: PropTypes.bool,
  setExternalState: PropTypes.func,
  errorResubmitKey: PropTypes.number,
  // LINT OVERRIDE #6
  // Object has undetermined keys
  // eslint-disable-next-line react/forbid-prop-types
  additionalSubmitData: PropTypes.object,
  hidePreviousUploads: PropTypes.bool,
  // LINT OVERRIDE #6
  // Object has undetermined keys
  // eslint-disable-next-line react/forbid-prop-types
  fileFilters: PropTypes.object,
  modalHeader: PropTypes.node,
  setFileCount: PropTypes.func,
  outerWrapperStyle: PropTypes.shape({}),
  customPath: PropTypes.string,
};

export default DropZoneFileManager;
