import styled from 'styled-components/macro';
import { BaseModalBackground } from 'forks/customModal';

export const FadingBackground = styled(BaseModalBackground)`
  opacity: ${(props) => props.opacity};
  transition: opacity ease 200ms;
  align-items: flex-start;
  padding: 0 10px;
  background-color: rgba(0, 0, 0, 0.75);
`;

export const FadingBackgroundSidebar = styled(BaseModalBackground)`
  opacity: ${(props) => props.opacity};
  transition: ${(props) =>
    props.opacity
      ? 'opacity 220ms cubic-bezier(0.2, 0, 0, 1) 0s'
      : 'opacity 220ms cubic-bezier(0.2, 0, 0, 1) 140ms'};
  align-items: flex-end;
  justify-content: flex-end;
  padding: 0 !important;
  ${(props) =>
    props.background ? props.background : 'background-color: rgba(0,0,0,0);'};
`;
