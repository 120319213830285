import fetchJson from 'utility/fetchJson';
import { BASE } from '../../constants';

export async function fetchActivityLog(
  activity,
  { limit = 10, page = 1 } = {}
) {
  const messages = await fetchJson(
    `${BASE}/api/activity/${activity}/?limit=${limit}&page=${page}&sort=create_timestamp&direction=desc`,
    {
      method: 'GET',
    }
  );
  return messages;
}

export async function fetchActivityFeed(id, { limit = 10, page = 1 } = {}) {
  const messages = await fetchJson(
    `${BASE}/api/util/activity/?instance=${id}`,
    {
      method: 'GET',
    }
  );
  return messages;
}
