import React from 'react';
import { ThemeProvider } from '@zendeskgarden/react-theming';
import PublicTheme from 'theme/ThemeProviderOverrides/PublicThemeOverrides';

export default function PublicThemeOverrides(Component) {
  function PublicThemeOverridesWrapper(props) {
    return (
      <ThemeProvider theme={PublicTheme}>
        <Component
          // LINT OVERRIDE #3
          // Component wraps another component
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...props}
        />
      </ThemeProvider>
    );
  }

  return PublicThemeOverridesWrapper;
}
