function typographyBaseStyles(props) {
  return `${
    props.fieldLabel || props.fieldLabelPrefix || props.fieldLabelSuffix
      ? `
    position: absolute;
    right: ${props.fieldLabelPrefix ? 'auto' : '10px'};
    left: ${props.fieldLabelPrefix ? '0px' : 'auto'};
    display: inline;
    top: 50%;
    color: #ccc;
    margin-top: -8px;
  `
      : ''
  }`;
}

const TypographyTheme = {
  'typography.sm': (props) => typographyBaseStyles(props, 'SM'),
};

export default TypographyTheme;
