import React, { useState } from 'react';
import { TabList, Tab } from '@zendeskgarden/react-tabs';
import Icon from 'components/Icon/Icon';
import { useBranch } from 'baobab-react/hooks';
import Flex from 'styled-flex-component';
import { IconButton } from '@zendeskgarden/react-buttons';
import { MD } from '@zendeskgarden/react-typography';
import { closeSidebar } from 'state/sidebars/actions';
import { variables } from 'theme/variables';
import MarketCompForm from 'components/BasicForms/MarketCompForm';
import { projectProps } from 'propTypesObjects.js';
import {
  getMarketComparableItem,
  getMarketComparables,
  clearCurrentMarketComparable,
} from 'state/marketComparables/actions';
import { isEmpty, noop } from 'lodash';
import {
  SidebarFilterTabs,
  SidebarFilterHeader,
  SidebarFilterBody,
  SidebarContentWrapper,
} from '../../GlobalSideBar.styles';

const { spacing } = variables;

function AddMarketCompSidebar({ options }) {
  const formType = options?.type || 'Add';
  const [tab, setActiveTab] = useState(options.tab || 'overview');
  const { marketComparable } = useBranch({
    marketComparable: [
      'marketComparables',
      'currentMarketComparable',
      'result',
    ],
  });
  const [disabledTab, setDisabledTab] = useState(isEmpty(marketComparable));

  async function onSuccess({ type }, tabType) {
    if (type === 'edit' && marketComparable?.id) {
      await getMarketComparableItem(marketComparable?.id);
      closeSidebar();
      return;
    }
    await getMarketComparables();
    if (tabType === 'personnel') {
      setDisabledTab(false);
      setActiveTab('documents');
    } else {
      closeSidebar();
      clearCurrentMarketComparable();
    }
  }

  return (
    <SidebarContentWrapper>
      <SidebarFilterHeader padding={`${spacing}`}>
        <Flex full justifyBetween alignCenter>
          <Flex alignCenter>
            <MD scaled bold color="#fff">
              {formType === 'Add'
                ? 'Add Market Comparable'
                : `Edit ${
                    marketComparable?.name ||
                    `Market Comp ${marketComparable?.id}`
                  }`}
            </MD>
          </Flex>
          <IconButton
            onClick={closeSidebar}
            disableBackground
            alignCenter
            justifyEnd
            size="large"
            aria-label="closeSidebar"
          >
            <Icon button icon="icon-close" fontSize="13px" color="#fff" />
          </IconButton>
        </Flex>
      </SidebarFilterHeader>
      <SidebarFilterTabs>
        <TabList className="tabs no-border">
          <Tab
            className="tab"
            slate
            onClick={() => {
              setActiveTab('overview');
            }}
            selected={tab === 'overview'}
          >
            Overview
          </Tab>
          <Tab
            className="tab"
            slate
            onClick={() => {
              setActiveTab('description');
            }}
            selected={tab === 'description'}
          >
            Description
          </Tab>
          <Tab
            className="tab"
            slate
            onClick={() => {
              setActiveTab('quantity');
            }}
            selected={tab === 'quantity'}
          >
            Quantity
          </Tab>
          <Tab
            className="tab"
            slate
            onClick={() => {
              setActiveTab('pricing');
            }}
            selected={tab === 'pricing'}
          >
            Pricing
          </Tab>
          <Tab
            className="tab"
            slate
            onClick={() => {
              setActiveTab('personnel');
            }}
            selected={tab === 'personnel'}
          >
            Personnel
          </Tab>
          <Tab
            className="tab"
            slate
            onClick={() => (disabledTab ? noop : setActiveTab('documents'))}
            selected={tab === 'documents'}
            disabled={disabledTab}
          >
            Documents
          </Tab>
        </TabList>
      </SidebarFilterTabs>
      <SidebarFilterBody>
        {
          {
            overview: (
              <MarketCompForm
                tab={tab}
                onSuccess={(vals) => onSuccess(vals)}
                onDelete={() => closeSidebar()}
                marketCompId={marketComparable?.id}
                data={marketComparable}
                type={formType}
                changeTab={setActiveTab}
              />
            ),
            description: (
              <MarketCompForm
                tab={tab}
                onSuccess={(vals) => onSuccess(vals)}
                onDelete={() => closeSidebar()}
                marketCompId={marketComparable?.id}
                data={marketComparable}
                type={formType}
                changeTab={setActiveTab}
              />
            ),
            quantity: (
              <MarketCompForm
                tab={tab}
                onSuccess={(vals) => onSuccess(vals)}
                onDelete={() => closeSidebar()}
                marketCompId={marketComparable?.id}
                data={marketComparable}
                type={formType}
                changeTab={setActiveTab}
              />
            ),
            pricing: (
              <MarketCompForm
                tab={tab}
                onSuccess={(vals) => onSuccess(vals)}
                onDelete={() => closeSidebar()}
                marketCompId={marketComparable?.id}
                data={marketComparable}
                type={formType}
                changeTab={setActiveTab}
              />
            ),
            personnel: (
              <MarketCompForm
                tab={tab}
                onSuccess={(vals) => onSuccess(vals, 'personnel')}
                onDelete={() => closeSidebar()}
                marketCompId={marketComparable?.id}
                data={marketComparable}
                type={formType}
                changeTab={setActiveTab}
                disabled={disabledTab}
              />
            ),
            documents: (
              <MarketCompForm
                tab={tab}
                onSuccess={(vals) => onSuccess(vals)}
                onDelete={() => closeSidebar()}
                marketCompId={marketComparable?.id}
                data={marketComparable}
                type={formType}
                changeTab={setActiveTab}
                disabled={disabledTab}
              />
            ),
          }[tab]
        }
      </SidebarFilterBody>
    </SidebarContentWrapper>
  );
}

AddMarketCompSidebar.propTypes = projectProps.sidebar;

export default AddMarketCompSidebar;
