import { Item } from '@zendeskgarden/react-dropdowns';
import styled from 'styled-components';

export const SubMenuItems = styled('div')`
  display: none;
  @media (max-width: 767px) {
    display: inline-block;
    margin-left: 20px;
  }
`;

export const SubItem = styled(Item)`
  padding-top: 0px;
`;
