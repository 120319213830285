import { keyframes } from 'styled-components/macro';

export const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

export const slideUp = keyframes`
  from {
    opacity: 0;
    transform: translate3d(0,100%,0)
  }
  to {
    opacity: 1;
    transform: translate3d(0,0,0)
  }
`;
