import styled from 'styled-components/macro';
import { Button as ZendeskButton } from '@zendeskgarden/react-buttons';
import Flex from 'styled-flex-component';
import { variables } from './variables';

const {
  spacing_sm: spacingSm,
  spacing_xs: spacingXs,
  spacing_md: spacingMd,
  spacing_xxxl: spacingXxxl,
  spacing_xl: spacingXl,
  custom_blue: customBlue,
  custom_blue_soft: customBlueSoft,
  custom_grey: customGrey,
  custom_silver: customSilver,
  app_page_padding: appPagePadding,
  media_sm: mediaSm,
  media_md: mediaMd,
  app_page_padding_medium: appPagePaddingMedium,
  app_page_padding_small: appPagePaddingSmall,
  border_radius: borderRadius,
  color_white: colorWhite,
  custom_border_grey: customBorderGrey,
  font_weight_semibold: fontWeightSemibold,
  custom_light_grey: customLightGrey,
  custom_sky_blue: customSkyBlue,
} = variables;

export const Divider = styled('div')`
  ${(props) => (props.vertical ? `` : 'height: 100%;')}
  display: flex;
  min-height: ${(props) => props.minHeight || `15px`};
  width: 1px;
  margin: ${(props) => props.margin || `0 ${spacingSm}`};
  padding: ${(props) => props.padding || `0`};
  position: relative;
  font-size: 10px;
  &:after {
    content: '';
    position: absolute;
    top: ${(props) => props.top || 0};
    bottom: ${(props) => props.bottom || 0};
    right: 0;
    left: 0;
    margin: 0;
    width: 1px;
    background: ${(props) => props.background || customGrey};
  }
  ${(props) =>
    props.horizontal
      ? `
    height: 1px;
    min-height: 1px;
    width: 100%;
    margin: 0;
    &:after {
      content: "";
      position: absolute;
      top: auto;
      bottom: auto;
      right: 0;
      left: 0;
      height: 1px;
      width: 100%;
      background: ${props.background || customLightGrey};
    }
    ${
      props.section
        ? `
      padding: ${spacingXxxl}
    `
        : ''
    }
  `
      : ''}
`;

export const DividerContainer = styled(Flex)`
  position: relative;
  width: 100%;
  padding: ${(props) => {
    if (props.last) {
      return `${spacingMd} 0 ${0}`;
    }
    if (props.first) {
      return `${0} 0 ${spacingMd}`;
    }
    return `${spacingMd} 0 ${spacingMd}`;
  }};
  padding-left: ${(props) => props.paddingHorizontal || 0};
  padding-right: ${(props) => props.paddingHorizontal || 0};
  &:after {
    content: '';
    position: absolute;
    top: 0;
    bottom: auto;
    right: 0;
    left: 0;
    height: 1px;
    width: 100%;
    background: ${(props) => props.background || customLightGrey};
    display: ${(props) => (props.first ? 'none' : `block`)};
  }
  &:before {
    content: '';
    position: absolute;
    top: auto;
    bottom: 0;
    right: 0;
    left: 0;
    height: 1px;
    width: 100%;
    background: ${(props) => props.background || customLightGrey};
    display: ${(props) => (props.nested || props.last ? 'none' : 'block')};
  }
`;

export const Dot = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  &:after {
    content: '';
    width: 100%;
    height: 100%;
    max-width: ${(props) => props.size || '6px'};
    max-height: ${(props) => props.size || '6px'};
    min-width: ${(props) => props.size || '6px'};
    min-height: ${(props) => props.size || '6px'};
    margin: ${(props) => props.margin || `0 ${spacingXs} 0 0`};
    border-radius: 100%;
    background: ${(props) =>
      props.transparent ? 'transparent' : props.background || customBlue};
  }
`;

export const Card = styled('div')`
  padding: ${spacingXl};
  background: ${colorWhite};
  box-shadow: 0px 2px 13px rgba(68, 89, 109, 0.08);
  ${(props) =>
    props.active
      ? `
    background: ${customBlue}
    box-shadow: ${
      props.boxShadow ? props.boxShadow : '0px 9px 15px rgba(68,89,109,0.3)'
    };
  `
      : ''}
`;

export const BulletGroup = styled(Flex)`
  margin-top: ${spacingMd};
`;

export const Bullet = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  min-width: 30px;
  height: 30px;
  color: ${colorWhite};
  margin-right: ${spacingSm};
  border-radius: ${borderRadius};
  background: ${(props) =>
    props.transparent ? 'transparent' : props.background || customBlue};
  font-weight: ${fontWeightSemibold};
  ${(props) =>
    props.inverted
      ? `
    color: ${
      props.transparent ? 'transparent' : props.background || customBlue
    };
    background: ${colorWhite};
  `
      : ''}
`;

export const ScrollWrapper = styled('div')`
  position: relative;
`;

export const ScrollContainer = styled('div')`
  -webkit-overflow-scrolling: touch;
  overflow-scrolling: touch;
  ${(props) =>
    props.horizontal
      ? `
    overflow-x: auto;
  `
      : `
    position: absolute;
    top: ${props.top || '0px'}; 
    bottom: ${props.bottom || '0px'}; 
    right: ${props.right || '0px'}; ;
    left: ${props.left || '0px'};
    overflow: auto;
  `};
`;

export const PageCard = styled('div')`
  box-shadow: ${(props) =>
    props.boxShadow ? props.boxShadow : '0px 10px 50px rgba(68, 89, 109, 0.1)'};
  padding: ${(props) => props.mobilePadding || appPagePaddingSmall};
  @media ${mediaSm} {
    padding: ${(props) => props.tabletPadding || appPagePaddingMedium};
    padding-bottom: ${spacingXxxl};
    margin-bottom: ${spacingXxxl};
  }
  @media ${mediaMd} {
    padding: ${(props) => props.padding || appPagePadding};
    padding-bottom: ${spacingXxxl};
    margin-bottom: ${spacingXxxl};
  }
`;

export const LBracket = styled('div')`
  position: absolute;
  width: ${(props) => props.width || '12px'};
  height: ${(props) => props.height || '15px'};
  border-left: 2px solid ${customSilver};
  border-bottom: 2px solid ${customSilver};
  ${(props) =>
    props.table
      ? `
    margin-left: 4px;
    margin-top: -6px;
  `
      : ''}
`;

export const Ellipsis = styled('div')`
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;

export const Avatar = styled('div')`
  background-image: ${(props) =>
    `url(${props.src}` ||
    'url(https://therminic2018.eu/wp-content/uploads/2018/07/dummy-avatar.jpg)'};
  background-size: ${(props) => props.size || 'cover'};
  border-radius: 100%;
  width: ${(props) => {
    if (props.scale) {
      return props.scale;
    }
    if (props.large) {
      return '80px';
    }
    return '40px';
  }};
  height: ${(props) => {
    if (props.scale) {
      return props.scale;
    }
    if (props.large) {
      return '80px';
    }
    return '40px';
  }};
  background-position: ${(props) => props.position || 'center left'};
  background-repeat: no-repeat;
  cursor: pointer;
  ${(props) =>
    props.center
      ? `
    margin: 0 auto;
  `
      : ''}
`;

export const TableOverflowWrapper = styled('div')`
  max-width: 100%;
  min-width: ${(props) => props.minWidth};
`;

export const TableOverflowScroll = styled('div')`
  overflow-x: auto;
  overflow-scrolling: touch;
`;

export const DivButton = styled('button')`
  border: 1px solid ${customLightGrey};
  transition: all 0.3s ease;
  cursor: pointer;
  * {
    transition: all 0.3s ease;
    cursor: pointer;
  }
  &:hover {
    background: ${customSkyBlue};
  }
  &:active,
  &:focus {
    outline: none;
    border: 1px solid #cfcfd0;
  }
  ${(props) =>
    props.active
      ? `
   border: 1px solid ${customBlue} !important;
   background: ${customBlueSoft};
  `
      : ''}
`;

export const IconOutlineButton = styled(Flex)`
  max-width: 23px;
  margin-right: 10px;
  background: ${customBlue};
  padding: 7px;
  border-radius: 100px;
  cursor: pointer;
`;

export const ContactButton = styled(ZendeskButton)`
  background: #fff;
  margin-right: 10px;
  padding: 0 7px;
  border: 1px solid #ccc;
  margin-bottom: 10px;
  &&:hover {
    background-color: #fff;
  }
  @media ${mediaSm} {
    padding: 0 15px;
  }
`;

export const AccordianWrapper = styled.div`
  .accordion {
    border-bottom: 1px solid ${customBorderGrey};
  }

  .accordion__item + .accordion__item {
    border-top: 1px solid ${customBorderGrey};
  }

  .accordion__button {
    color: #444;
    position: relative;
    cursor: pointer;
    padding: 18px;
    padding-left: 35px;
    width: 100%;
    text-align: left;
    border: none;
    user-select: none;
    transition: all 0.2s ease;
    display: flex;
    align-items: center;
    justify-content: space-between;
    ${({ flexStart }) =>
      flexStart
        ? `
          align-items: flex-start;
          &:before {
            margin-top: 8px;
          }
        `
        : ''}
    .edit {
      transition: opacity 0.3s ease;
      font-size: 11px;
      opacity: 0;
      background-color: #fff;
      padding: 3px 10px;
      margin-left: 15px;
      pointer-events: none;
    }
    .save {
      transition: opacity 0.3s ease;
      font-size: 11px;
      opacity: 0;
      background-color: ${customBlue};
      padding: 3px 10px;
      margin-left: 15px;
      pointer-events: none;
    }
    &:focus {
      outline: none;
    }
    &:hover,
    &[aria-expanded='true'],
    &[aria-selected='true'] {
      .edit {
        opacity: 1;
        pointer-events: all;
        &:hover {
          opacity: 0.5;
        }
      }
    }
  }

  .accordion__panel {
    padding-top: 10px;
    padding-top: 10px;
    padding-bottom: 10px;
    .delete {
      opacity: 0;
      pointer-events: none;
      transition: all 0.2s ease;
    }
    &:hover {
      .delete {
        opacity: 1;
        pointer-events: all;
      }
    }
  }

  .accordion__button:hover,
  .accordion__button[aria-expanded='true'],
  .accordion__button[aria-selected='true'] {
    background-color: #f7f9fb;
  }

  .accordion__button:before {
    display: inline-block;
    left: 18px;
    content: '';
    height: 0px;
    width: 0px;
    margin-right: 12px;
    position: absolute;
    border-top: 4px solid transparent;
    border-left: 6px solid ${customBlue};
    border-bottom: 4px solid transparent;
    transform: translateY(0px) rotate(0deg);
    transform-origin: 55% 55%;
    transition: all 0.2s ease;
  }

  .accordion__button[aria-expanded='true']::before,
  .accordion__button[aria-selected='true']::before {
    transform: translateY(0px) rotate(90deg);
  }

  [hidden] {
    display: none;
  }

  .accordion__panel {
    padding: 20px;
    padding-top: 10px;
  }
`;

export const PillButtons = styled('div')`
  width: ${(props) => (props.triple ? '180px' : '120px')};
  border-radius: 60px;
  height: 24px;
  background: ${customBlueSoft};
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 3px;
  ${(props) => `
    background: ${props.activeColor || customBlueSoft};
  `}
`;
export const PillButton = styled('div')`
  height: 100%;
  min-width: ${(props) => (props.triple ? '33%' : '50%')};
  border-radius: 60px;
  cursor: pointer;
  text-align: center;
  font-size: 11px;
  justify-content: center;
  align-items: center;
  display: flex;
  ${(props) =>
    !props.active
      ? ''
      : `
    color: #fff;
    background: ${props.activeColor || customBlue};
  `}
  * {
    cursor: pointer;
  }
`;
