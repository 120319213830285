import styled from 'styled-components/macro';
import { Grid, Row } from '@zendeskgarden/react-grid';
import { variables } from 'theme/variables';

const {
  custom_blue: customBlue,
  color_white: colorWhite,
  media_md: mediaMd,
  public_font_size_md: publicFontSizeMd,
  public_font_weight_bold: publicFontWeightBold,
  public_font_size_sm: publicFontSizeSm,
  public_line_height_lg: publicLineHeightLg,
  copyright_color: copyrightColor,
  public_font_size_xs: publicFontSizeXs,
  custom_button_blue: customButtonBlue,
} = variables;

export const PublicFooterComponent = styled.footer`
  background-color: ${customBlue};
  background-image: url('/images/footerPipes.svg');
  background-repeat: no-repeat;
  background-position: left center;
  padding: 48px 0px 28px;
  @media (max-width: 767px) {
    background-size: 100% 100%;
  }
`;

export const FooterGrid = styled(Grid)`
  max-width: 80%;
  .footerMenuCol {
    width: auto;
    padding: 0 40px;
  }
  @media (max-width: 1400px) {
    .footerMenuCol {
      padding: 0 15px;
    }
  }
  @media (max-width: 767px) {
    max-width: 95%;
    #footerRow {
      width: 100%;
      justify-content: space-evenly;
    }
  }
`;

export const FooterMenu = styled.div`
  margin: 0px 12px 50px;
  min-width: 200px;
  max-width: 230px;
  @media ${mediaMd} {
    margin: 0px 0px 50px;
  }
  .title {
    font-size: ${publicFontSizeMd};
    font-weight: ${publicFontWeightBold};
    letter-spacing: 1px;
    margin-bottom: 16px;
    color: ${colorWhite};
    text-align: left;
  }
  ul {
    list-style: none;
    padding: 0;
    margin: 0;
    text-align: left;

    li {
      margin: 5px 0px;

      a {
        color: rgba(255, 255, 255, 0.5) !important;
        font-size: ${publicFontSizeSm};
        letter-spacing: 1.13px;
        line-height: ${publicLineHeightLg};
        display: block;
        transition: all 0.2s ease-out;
        &:hover,
        &:focus {
          text-decoration: none;
        }

        &:hover {
          transform: translateX(10px);
          color: ${customButtonBlue} !important;
        }
      }
    }
  }
  @media (max-width: 767px) {
    .title,
    ul,
    li {
      text-align: center;
    }
  }
`;

export const Copyright = styled.div`
  font-size: ${publicFontSizeXs};
  text-align: center;
  color: ${copyrightColor};
  letter-spacing: 0.96px;
  margin: 26px 0px 20px;

  .mobile-br {
    display: block;

    @media ${mediaMd} {
      display: none;
    }
  }

  .crafted {
    display: block;
    a {
      color: rgba(255, 255, 255, 0.5) !important;
      text-decoration: none;
      transition: color 0.2s linear;
      &:hover,
      &:focus,
      &:active {
        color: ${customButtonBlue} !important;
        text-decoration: none;
      }
    }

    @media ${mediaMd} {
      display: inline;
      &:before {
        content: '| ';
      }
    }
  }
`;

export const Logo = styled.div`
  max-width: 165px;
  width: 100%;
  margin: 0 auto 40px;

  @media ${mediaMd} {
    margin: 0 auto 24px;
  }

  img {
    width: 100%;
    height: auto;
  }
`;

export const SocialMediaRow = styled(Row)`
  max-width: 300px;
  display: flex;
  flex-wrap: noWrap;
  justify-content: space-evenly;
  .socialIcon {
    width: 50px;
    height: 50px;
  }
  @media (max-width: 991px) {
    margin-bottom: 30px;
  }
`;
