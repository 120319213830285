import styled, { css } from 'styled-components/macro';
import { variables } from 'theme/variables';
import { SidebarSecondaryHeader } from 'theme/Page';
import { NavLink } from 'react-router-dom';

const {
  media_md: mediaMd,
  sidebar_padding: sidebarPadding,
  color_grey_200: colorGrey200,
  color_grey_500: colorGrey500,
  custom_blue: customBlue,
  ag_grid_hover: agGridHover,
} = variables;

export const DashboardSidebarMenu = styled.div`
  white-space: nowrap;
  @media ${mediaMd} {
    display: block;
    white-space: normal;
  }
`;
export const SidebarHeader = styled(SidebarSecondaryHeader)`
  display: none;
  @media ${mediaMd} {
    display: flex;
    justify-content: ${(props) => (props.sidebarExpanded ? 'start' : 'center')};
    white-space: normal;
    width: 100%;
    margin-bottom: 0;
    padding: 0 20px;
    .width-border {
      padding: 0 11px;
      overflow: hidden;
    }
  }
`;

export const NavWrapper = styled.div`
  @media ${mediaMd} {
    padding: ${sidebarPadding};
  }
`;

export const CompressButton = styled.div`
  cursor: pointer;
  * {
    cursor: pointer;
  }
`;

export const NavIcon = styled.div`
  display: flex;
  justify-content: right;
  align-items: center;
  margin-right: 5px;
`;

const dropDownHover = css`
  text-decoration: none;
  border-radius: 5px;
  cursor: pointer;
`;

export const DropDownLink = styled(NavLink)`
  width: ${({ styledprops }) => (styledprops?.isExpanded ? '210px' : 'auto')};
  height: 45px;
  display: flex;
  align-items: center;
  padding-left: 25px;
  margin-top: 3px;
  maring-bottom: 3px;

  ${/* when link is active apply these stylings */ ''}

  &.active {
    background-color: ${colorGrey200};
    text-decoration: none;
    border-radius: 5px;

    &:hover {
      background-color: ${colorGrey200};
      ${dropDownHover}
    }
  }

  &:hover {
    background: ${agGridHover};
    ${dropDownHover}
  }
`;

export const SubMenuText = styled('p')`
  color: ${colorGrey500};
  font-size: 12px;
`;

export const SubMenuIcon = styled.div`
  display: flex;
  justify-content: center;
  width: 25px;
  p {
    font-size: 12px;
    color: ${(props) => (props.isExpanded ? `` : customBlue)};
  }
`;

export const SidebarLabel = styled.span`
  margin-left: 10px;
`;

export const MobileSidebarMenuLinkAdditions = styled.div`
  display: none;
  @media (max-width: 575px) {
    display: inline-block;
  }
`;
