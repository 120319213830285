import {
  isArray,
  isObject,
  reduce,
  pick,
  keys,
  isBoolean,
  get,
  map,
  replace,
  includes,
  isNumber,
} from 'lodash';
import { getValues } from 'utility/urlUtils';
import { CRITERIA_QUERY_VALUES } from 'state/requestForQuote/transformer_criteria';

export const REQUEST_GLOBALS = ['limit', 'page', 'sort', 'direction'];
export const OMMITED = ['count'];
export const MAPPING = {
  customers: {
    attribute_values: 'checkbox',
    keyword: 'search',
    sort: 'sort',
    page: 'pagination',
    limit: 'pagination',
    direction: 'sort',
    tag: 'checkbox',
    role: 'checkbox',
    status: 'checkbox',
    type: 'checkbox',
    job_function: 'checkbox',
    company_type: 'checkbox',
    company: 'checkbox',
    account_type: 'checkbox',
  },
  recipients: {
    attribute_values: 'checkbox',
    keyword: 'search',
    sort: 'sort',
    page: 'pagination',
    limit: 'pagination',
    direction: 'sort',
    status: 'checkbox',
    include_children: 'checkbox',
  },
  companies: {
    attribute_values: 'checkbox',
    keyword: 'search',
    sort: 'sort',
    page: 'pagination',
    limit: 'pagination',
    direction: 'sort',
    status: 'checkbox',
    priority: 'checkbox',
    tag: 'checkbox',
    company_type: 'checkbox',
    parent: 'checkbox',
  },
  opportunities: {
    attribute_values: 'checkbox',
    keyword: 'search',
    sort: 'sort',
    page: 'pagination',
    limit: 'pagination',
    direction: 'sort',
    priority: 'checkbox',
    company: 'checkbox',
    company_or_end_user: 'checkbox',
    include_children: 'checkbox',
    status: 'checkbox',
    owner: 'checkbox',
    quote_lead: 'checkbox',
    user: 'checkbox',
    engagement: 'checkbox',
  },
  itemOpportunities: {
    attribute_values: 'checkbox',
    keyword: 'search',
    sort: 'sort',
    page: 'pagination',
    limit: 'pagination',
    direction: 'sort',
    priority: 'checkbox',
    company: 'checkbox',
    status: 'checkbox',
    owner: 'checkbox',
    quote_lead: 'checkbox',
    application: 'checkbox',
    category: 'checkbox',
    end_finish: 'checkbox',
    end_finish_category: 'checkbox',
    end_finish_licensee: 'checkbox',
    end_finish_thread_type: 'checkbox',
    metallurgy: 'checkbox',
    metallurgy_group: 'checkbox',
    need_location_country: 'checkbox',
    well_type: 'checkbox',
  },
  listing: {
    attribute_values: 'checkbox',
    keyword: 'search',
    sort: 'sort',
    page: 'pagination',
    limit: 'pagination',
    direction: 'sort',
    brand_status: 'checkbox',
    category: 'checkbox',
    count: 'checkbox',
    end_finish: 'checkbox',
    end_finish_category: 'checkbox',
    end_finish_licensee: 'checkbox',
    end_finish_thread_type: 'checkbox',
    metallurgy: 'checkbox',
    metallurgy_group: 'checkbox',
    status: 'checkbox',
  },
  supplyOpportunities: {
    attribute_values: 'checkbox',
    keyword: 'search',
    sort: 'sort',
    page: 'pagination',
    limit: 'pagination',
    direction: 'sort',
    priority: 'checkbox',
    company: 'checkbox',
    status: 'checkbox',
    owner: 'checkbox',
    finding_company: 'checkbox',
    quote_lead: 'checkbox',
    supply_location_country: 'checkbox',
    user: 'checkbox',
  },
  wishlistItems: {
    attribute_values: 'checkbox',
    keyword: 'search',
    sort: 'sort',
    page: 'pagination',
    limit: 'pagination',
    direction: 'sort',
    category: 'checkbox',
    count: 'checkbox',
    end_finish: 'checkbox',
    end_finish_category: 'checkbox',
    end_finish_licensee: 'checkbox',
    end_finish_thread_type: 'checkbox',
    metallurgy: 'checkbox',
    metallurgy_group: 'checkbox',
    user: 'checkbox',
    company: 'checkbox',
    include_children: 'checkbox',
  },
  inventory: {
    attribute_values: 'checkbox',
    keyword: 'search',
    sort: 'sort',
    page: 'pagination',
    limit: 'pagination',
    direction: 'sort',
    category: 'checkbox',
    end_finish: 'checkbox',
    metallurgy_group: 'checkbox',
    type: 'checkbox',
    type_status: 'checkbox',
  },
  blog: {
    attribute_values: 'checkbox',
    keyword: 'search',
    sort: 'sort',
    page: 'pagination',
    limit: 'pagination',
    direction: 'sort',
  },
  categories: {
    attribute_values: 'checkbox',
    keyword: 'search',
    sort: 'sort',
    page: 'pagination',
    limit: 'pagination',
    direction: 'sort',
    category: 'checkbox',
    metallurgy: 'checkbox',
    end_finish: 'checkbox',
  },
  search: {
    attribute_values: 'checkbox',
    keyword: 'search',
    sort: 'sort',
    page: 'pagination',
    limit: 'pagination',
    direction: 'sort',
    category: 'checkbox',
    metallurgy: 'checkbox',
    metallurgy_group: 'checkbox',
    end_finish: 'checkbox',
    end_finish_licensee: 'checkbox',
    end_finish_thread_type: 'checkbox',
    end_finish_category: 'checkbox',
    weight_type: 'checkbox',
    category_type: 'checkbox',
    alloy: 'checkbox',
  },
  engagements: {
    attribute_values: 'checkbox',
    priority: 'checkbox',
    keyword: 'search',
    sort: 'sort',
    page: 'pagination',
    limit: 'pagination',
    direction: 'sort',
    active_status: 'checkbox',
  },
  interactions: {
    attribute_values: 'checkbox',
    keyword: 'search',
    sort: 'sort',
    page: 'pagination',
    limit: 'pagination',
    direction: 'sort',
    active_status: 'checkbox',
  },
  actionItems: {
    attribute_values: 'checkbox',
    priority: 'checkbox',
    actionitem_status: 'checkbox',
    keyword: 'search',
    sort: 'sort',
    page: 'pagination',
    limit: 'pagination',
    direction: 'sort',
    active_status: 'checkbox',
  },
  campaigns: {
    attribute_values: 'checkbox',
    // TODO: backend should return filtered results for status
    // status: 'checkbox',
    campaign: 'checkbox',
  },
  supplyGroup: {
    attribute_values: 'checkbox',
    keyword: 'search',
    sort: 'sort',
    page: 'pagination',
    limit: 'pagination',
    direction: 'sort',
    category: 'checkbox',
    count: 'checkbox',
    end_finish: 'checkbox',
    end_finish_category: 'checkbox',
    end_finish_licensee: 'checkbox',
    end_finish_thread_type: 'checkbox',
    owner: 'checkbox',
    finding_company: 'checkbox',
    metallurgy: 'checkbox',
    metallurgy_group: 'checkbox',
    priority: 'checkbox',
    stage: 'checkbox',
    status: 'checkbox',
    supply_location_country: 'checkbox',
    user: 'checkbox',
    engagement: 'checkbox',
    quantity_uom: 'range',
  },
  supplyItem: {
    attribute_values: 'checkbox',
    keyword: 'search',
    sort: 'sort',
    page: 'pagination',
    limit: 'pagination',
    direction: 'sort',
    active_status: 'checkbox',
    category: 'checkbox',
    end_finish: 'checkbox',
    end_finish_category: 'checkbox',
    end_finish_licensee: 'checkbox',
    end_finish_thread_type: 'checkbox',
    owner: 'checkbox',
    finding_company: 'checkbox',
    metallurgy: 'checkbox',
    metallurgy_group: 'checkbox',
    priority: 'checkbox',
    status: 'checkbox',
    supply_location_country: 'checkbox',
    user: 'checkbox',
    quantity_uom: 'range',
  },
  attributes: {
    keyword: 'search',
    sort: 'sort',
    page: 'pagination',
    limit: 'pagination',
    direction: 'sort',
    active_status: 'checkbox',
    category: 'checkbox',
  },
  prospecting: {
    // TODO: backend returns "not a valid string" for the below two filters
    // state: 'checkbox',
    // type: 'checkbox',
    // TODO: backend should provide user's name under the "label" key
    // user: 'checkbox',
  },
  marketComparables: {
    attribute_values: 'checkbox',
    keyword: 'search',
    sort: 'sort',
    page: 'pagination',
    limit: 'pagination',
    direction: 'sort',
    type: 'checkbox',
    brand: 'checkbox',
    employee: 'checkbox',
    manufacturer: 'checkbox',
    buyer: 'checkbox',
    supplier: 'checkbox',
    end_user: 'checkbox',
    mtr: 'checkbox',
    condition: 'checkbox',
    manufacturing_process: 'checkbox',
    contact: 'checkbox',
    intel_status: 'checkbox',
    category: 'checkbox',
    metallurgy_group: 'checkbox',
    location: 'checkbox',
  },
  purchaseHistory: {
    keyword: 'search',
    sort: 'sort',
    page: 'pagination',
    limit: 'pagination',
    direction: 'sort',
    vendor: 'checkbox',
  },
  purchaseItemHistory: {
    keyword: 'search',
    sort: 'sort',
    page: 'pagination',
    limit: 'pagination',
    direction: 'sort',
    category: 'checkbox',
    end_finish: 'checkbox',
    metallurgy: 'checkbox',
    metallurgy_group: 'checkbox',
    vendor: 'checkbox',
  },
  orderHistory: {
    keyword: 'search',
    sort: 'sort',
    page: 'pagination',
    limit: 'pagination',
    direction: 'sort',
    customer: 'checkbox',
  },
  orderItemHistory: {
    keyword: 'search',
    sort: 'sort',
    page: 'pagination',
    limit: 'pagination',
    direction: 'sort',
    category: 'checkbox',
    end_finish: 'checkbox',
    metallurgy: 'checkbox',
    metallurgy_group: 'checkbox',
    customer: 'checkbox',
  },
  requestHistory: {
    keyword: 'search',
    sort: 'sort',
    page: 'pagination',
    limit: 'pagination',
    direction: 'sort',
    customer: 'checkbox',
  },
  requestItemHistory: {
    keyword: 'search',
    sort: 'sort',
    page: 'pagination',
    limit: 'pagination',
    direction: 'sort',
    category: 'checkbox',
    end_finish: 'checkbox',
    metallurgy: 'checkbox',
    metallurgy_group: 'checkbox',
    customer: 'checkbox',
  },
};

export function getValuesForRequest(type) {
  return pick(getValues(), keys(MAPPING[type]));
}

export function getSortForRequest() {
  return pick(getValues(), ['sort', 'direction']);
}

export function reverseCategorizeFacets(facets, type) {
  return reduce(
    facets,
    (accum, value, key) => {
      const currentAccum = accum;
      if (
        MAPPING[type] &&
        MAPPING[type][replace(key, '_missing', '')] === 'checkbox'
      ) {
        if (includes(key, '_missing')) {
          currentAccum[key] = { missing: value };
          return currentAccum;
        }

        const isBool =
          isBoolean(value) || value === 'true' || value === 'false';
        let boolValue;
        if (isBoolean(value)) {
          boolValue = value;
        } else if (value === 'true') {
          boolValue = true;
        } else if (value === 'false') {
          boolValue = false;
        } else {
          boolValue = value;
        }

        currentAccum[key] = isBool
          ? { [boolValue]: true }
          : reduce(
              value,
              (acc, v) => {
                acc[`__${v}`] = true;
                return acc;
              },
              {}
            );
      } else if (key === 'keyword') {
        currentAccum[key] = value;
      }

      return currentAccum;
    },
    {}
  );
}

export function generateFiltersObject(clonedValues) {
  const urlReadyObject = reduce(
    clonedValues,
    (accum, type, key) => {
      if (type === undefined) {
        return accum;
      }
      const currentAccum = accum;
      const filterValues = !isArray(type) && isObject(type) ? keys(type) : type; // could now be array
      const isBool =
        get(filterValues, '[0]') === 'false' ||
        get(filterValues, '[0]') === 'true';
      if (filterValues.length || (key === 'quantity_uom' && isNumber(type))) {
        // for when passing values like ID, OD and not coming from global interest filters
        if (
          includes(CRITERIA_QUERY_VALUES, key) &&
          !isArray(type) &&
          !isObject(type)
        ) {
          currentAccum[key] = type;
        } else if (get(filterValues, '[0]') === 'missing') {
          currentAccum[key] = true;
        } else if (isBool) {
          currentAccum[key] = [filterValues];
        } else if (key === 'keyword') {
          currentAccum[key] = type;
        } else if (
          key === 'quantity_uom' ||
          key === 'quantity_min' ||
          key === 'quantity_max'
        ) {
          currentAccum[key] =
            key === 'quantity_uom' ? `${filterValues}` : filterValues;
        } else {
          currentAccum[key] = map(
            filterValues,
            (d) => (d?.replace && replace(d, '__', '')) || d
          );
        }
      }

      return currentAccum;
    },
    {}
  );
  return urlReadyObject;
}
