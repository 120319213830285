import styled from 'styled-components/macro';
import { Card } from 'theme/Global.styles.js';
import { variables } from 'theme/variables';
import { Page } from 'theme/Page';
const { public_font_size_sm } = variables;

export const ConfirmEmailPage = styled(Page)``;

export const BannerWrapper = styled(Page)`
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  height: 100px;
`;

export const ConfirmCard = styled(Card)`
  z-index: 2;
  width: 400px;
  border-radius: 4px;
`;

export const Section = styled.section`
  padding: 40px 0;
  position: relative;
  min-height: 100vh;
  p {
    color: #a2a2a2;
    font-size: ${public_font_size_sm};
  }
`;

export const Logo = styled.img`
  height: 55px;
  width: auto;
  margin-bottom: 30px;
`;

export const BackgroundImgWrapper = styled.div`
  overflow: hidden;
  position: absolute;
  height: 600px;
  width: 500px;
  left: 0;
  right: -74%;
  bottom: 0;
  margin: 0 auto;
`;

export const BackgroundImg = styled.img`
  height: auto;
  width: 500px;
  opacity: 0.03;
`;

export const FormTextBottom = styled.div`
  p {
    color: #a2a2a2;
    font-size: ${public_font_size_sm};
  }
`;
