import { map } from 'lodash';
import { onboardingReverser, baseValidation } from 'utility/dynamicFormHelpers';
import { prospectingCompanySizeItems } from 'utility/staticMap.ts';

/*
  CURRENT OPTIONS
    minDropdownWidth - sets dropdown min width
    disableSelectionOnRange - dont allow selection when range
    items - renders list options else renders form input. Must be an array to enable autocomplete field
    operators - adds top right dropdown
    operator - selected default operator
    operatorIsValue - should operator value be sent to api
    templates - associated template
    disabled - hide input on specific template - dont include data or component in saving updating fetching
    filter - filter item options when input changes
    memo - memoize component for performance
    onUpdate - call everytime tree changes
    warning - warning UI
    error - error UI
    customValues - can input use custom values
    inputType - custom UI for type ie 'FilterItemSelection'
    FilterItemSelection can take selectionFilters - CUSTOM UI
    Dirty - has the field been touched
    Value - comes from server
    hidden - value is usually defaulted but the UI is hidden
    addressPath - set the path to address data in the response (default google_address)
    ui
      title - required
      condensed - sets paddingBottomSm on title typography component. Title must also be present for this to take effect
*/

export function createModifyItemRow(value, _, metadata, options = {}) {
  let config = {
    result: value,
    temp: !value,
    id: value && value.id,
    defaultOption: {
      ignoreInForm: true,
      fields: {
        default: {
          value: { value: options.initial },
        },
      },
    },
    searchCriteria: {
      ignoreHeader: true,
      fields: {
        keyword: {
          autoFocus: true,
          required: false,
          value: '',
          validation: baseValidation,
          ui: {
            title: 'Keyword',
            placeholder: 'Enter a Keyword',
            condensed: true,
            md: 4,
            itemSelection: true,
            fullBorder: true,
            subtext:
              'Searching by Keyword may increase search time by about 30 seconds.',
          },
        },
        prospectingIndustry: {
          required: false,
          customValues: false,
          clearable: true,
          items: [],
          value: { value: 'Oil & Energy', label: 'Oil & Energy' },
          key: 'industry',
          validation: baseValidation,
          ui: {
            condensed: true,
            title: 'Industry',
            placeholder: 'Search a specific Industry',
            md: 3,
            itemSelection: false,
            fullBorder: true,
          },
        },
        prospectingCompanySize: {
          required: false,
          customValues: false,
          clearable: true,
          items: map(prospectingCompanySizeItems, (element) => ({
            value: element.id,
            label: element.name,
          })),
          value: { value: '', label: '' },
          key: 'companySize',
          validation: baseValidation,
          ui: {
            condensed: true,
            title: 'Company Size',
            placeholder: 'Select a Company Size',
            md: 4,
            itemSelection: false,
            fullBorder: true,
          },
        },
        prospectingLocation: {
          required: false,
          customValues: false,
          clearable: true,
          items: [],
          value: { value: '', label: '' },
          key: 'location',
          validation: baseValidation,
          ui: {
            condensed: true,
            title: 'Location',
            placeholder: 'Enter a Location to search',
            md: 4,
            itemSelection: false,
            fullBorder: true,
          },
        },
      },
    },
  };
  if (value) {
    config = onboardingReverser(config, value);
    config.valid = true;
  } else {
    config.temp = true;
  }
  return config;
}

export function generateInitialSearchCriteria({ seedData } = {}) {
  if (!seedData) {
    return createModifyItemRow();
  }
  return createModifyItemRow(seedData);
}
