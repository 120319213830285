import React, { useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Field, Input, Textarea } from '@zendeskgarden/react-forms';
import { Col, Row as GridRow } from '@zendeskgarden/react-grid';
import { XL, MD, SM } from '@zendeskgarden/react-typography';
import {
  some,
  isArray,
  get,
  map,
  filter,
  findIndex,
  startCase,
  forEach,
  set,
  replace,
  isBoolean,
  join,
  size,
  noop,
  includes,
  toLower,
} from 'lodash';
import Flex from 'styled-flex-component';
import { Tooltip } from '@zendeskgarden/react-tooltips';

import Icon from 'components/Icon/Icon';
import { FormCheckbox } from 'components/Checkbox/Checkbox';
import { track } from 'utility/analytics';
import { variables } from 'theme/variables';
import { titleCase } from 'utility/formatStrings';

// Actions
import { getSuggestion } from 'state/application/actions';
import { getProspectingSuggestion } from 'state/customers/prospecting/actions';

// Components
import FilterItemValues from 'components/FilterItemGroup/components/FilterItemValues/FilterItemValues';
import GoogleLocationLookup from 'components/GoogleLocationLookup/GoogleLocationLookup';
import { Button } from 'theme/Button';
import Alert from 'theme/Alert';
import DatePicker from 'components/DatePicker/DatePicker';
import FilterItemGroupTwoFilters from 'components/FilterItemGroup/FilterItemGroupTwoFilters';
import PhoneInput from 'react-phone-input-2';
import { transformerFieldShape } from 'propTypesObjects';
import { getAttributeCategory } from 'state/attributes/actions';
import { flattenCat } from 'components/BasicForms/AttributesForm';
import MultiSelectFormItem from './components/MultiSelectFormItem/MultiSelectFormItem';
import FilterItemSelection from '../FilterItemGroup/components/FilterItemSelection/FilterItemSelection';

const {
  custom_navy: customNavy,
  custom_dark_grey: customDarkGrey,
  custom_red: customRed,
  custom_blue: customBlue,
  media_md: mediaMd,
  spacing_lg: spacingLg,
  spacing_xl: spacingXl,
  spacing_xxl: spacingXxl,
  spacing_md: spacingMd,
} = variables;

function MultiTypeForm({
  data,
  setData,
  formName,
  clientId,
  contentType,
  error,
  wrapperStyle,
  ignoreHeader,
  popperModifiers,
  invalidFields,
  unselect,
  isEditing,
}) {
  const formData = data;

  function onChangeCheckbox(cat, type, value) {
    setData(cat, type, {
      ...formData[type],
      value: { label: value.label, value: value.value },
    });
  }

  function onChangeDate(cat, type, value) {
    setData(cat, type, {
      ...formData[type],
      value: { label: value, value },
    });
  }

  function onChangePhone(cat, type, value) {
    setData(cat, type, {
      ...formData[type],
      value,
    });
  }

  const onChangeInput = useCallback(
    (cat, type, field, e) => {
      const val =
        field.isNumber && !Number.isNaN(e.currentTarget.value)
          ? Number(e.currentTarget.value)
          : e.currentTarget.value;
      setData(cat, type, {
        ...formData[type],
        value: { label: val, value: val },
      });
    },
    [formData, setData]
  );

  function onChangeSelect(cat, type, value) {
    setData(cat, type, {
      ...formData[type],
      value,
    });
  }

  function onChangeSelectOther(cat, type, buttons, currentValue, value) {
    const setValue = value[1] || value[0];
    setData(cat, type, {
      ...formData[type],
      value: isArray(currentValue)
        ? filter(
            [...currentValue, setValue],
            ({ value: objectValue }) => objectValue !== -1
          )
        : value, // remove other
      buttons: [...buttons, setValue], // make it look like we replace other with value
    });
  }

  function onChangeFilterItemGroupTwoFilters(cat, type, value) {
    setData(cat, type, {
      ...formData[type],
      ...value,
    });
  }

  function onChangeAlloy(cat, type, value) {
    setData(cat, type, {
      filter: {
        ...formData[cat].fields[type].filter,
        value,
      },
    });
  }

  const [countries, setCountries] = useState([]);
  const [reservoirs, setReservoirs] = useState([]);
  const [states, setStates] = useState([]);
  const [county, setCounty] = useState([]);
  const [tags, setTags] = useState([]);
  const [attributes, setAttributes] = useState([]);
  const [companies, setCompanies] = useState([]);
  const [users, setUsers] = useState([]);
  const [prospectingIndustries, setProspectingIndustries] = useState([]);
  const [prospectingCompanies, setProspectingCompanies] = useState([]);
  const [prospectingLocations, setProspectingLocations] = useState([]);
  const [prospectingJobTitles, setProspectingJobTitles] = useState([]);
  const [prospectingSchools, setProspectingSchools] = useState([]);
  const [attributeList, setAttributeList] = useState([]);

  useEffect(() => {
    async function fetchAttributeList() {
      const categories = await getAttributeCategory();
      const _attributeList = flattenCat(categories);
      const preparedAttributeList = map(_attributeList, (attribute) => ({
        value: attribute.id,
        name: `${attribute.value} (${
          attribute.sub_category_name || attribute.category_name
        })`,
      }));
      setAttributeList(preparedAttributeList);
    }
    let hasAttributesField = false;
    if (contentType) {
      forEach(formData, (subform) => {
        if (subform.fields?.attribute) {
          hasAttributesField = true;
        }
      });
    }
    if (hasAttributesField && !attributeList.length) {
      fetchAttributeList();
    }
  }, [attributeList.length, contentType, formData]);

  async function searchSuggestion({ setter, key, searchObj }, value) {
    track('searchSuggestion', {
      form: formName,
      value,
    });
    let aa;
    if (searchObj.prospecting) {
      const result = await getProspectingSuggestion(searchObj.key, value);
      const currentData = get(result, 'data');
      let mappedResult = [];
      if (currentData) {
        mappedResult = map(currentData, (element) => {
          const extraFields = {};
          if (searchObj.extraFields) {
            forEach(searchObj.extraFields, (fieldValue) => {
              set(extraFields, fieldValue, element[fieldValue]);
            });
          }
          return {
            value: element.name,
            label: searchObj.startCase ? titleCase(element.name) : element.name,
            ...extraFields,
          };
        });
      }
      aa = get(result, 'error') ? result : mappedResult;
    } else if (searchObj.key === 'attribute') {
      const searchResult = filter(attributeList, (attribute) =>
        includes(toLower(attribute.name), toLower(value))
      );
      aa = searchResult;
    } else {
      aa = await getSuggestion(key, value, {
        valueKey: searchObj.valueKey,
        labelKey: searchObj.labelKey,
        clientId: searchObj.clientId,
        content_type_id: searchObj.content_type_id,
        includeContacts: searchObj.includeContacts,
      });
    }
    if (aa && !aa.error) {
      setter(aa);
    } else {
      setter((previousValue) => {
        if (previousValue.length > 0) {
          return [];
        }
        return previousValue;
      });
    }
  }

  const search = {
    end_user: {
      items: companies,
      key: 'company',
      setter: setCompanies,
    },
    inquiring_company: {
      items: companies,
      key: 'company',
      setter: setCompanies,
    },
    company: {
      items: companies,
      key: 'company',
      setter: setCompanies,
    },
    parent: {
      items: companies,
      key: 'company',
      setter: setCompanies,
    },
    user: {
      items: users,
      key: 'user',
      setter: setUsers,
      clientId,
      includeContacts: false,
      labelKey: 'full_name',
    },
    contact: {
      items: users,
      key: 'user',
      setter: setUsers,
      clientId,
      includeContacts: true,
      labelKey: 'full_name',
    },
    company_representative: {
      items: users,
      key: 'user',
      setter: setUsers,
      clientId,
      includeContacts: true,
      labelKey: 'full_name',
    },
    tag: {
      content_type_id: contentType,
      items: tags,
      key: 'tag',
      setter: setTags,
      valueKey: 'name',
    },
    attribute: {
      content_type_id: contentType,
      items: attributes,
      key: 'attribute',
      setter: setAttributes,
      valueKey: 'name',
    },
    country: {
      items: countries,
      key: 'country',
      setter: setCountries,
    },
    states: {
      items: states,
      key: 'state',
      setter: setStates,
      getter: 'name',
    },
    county: {
      items: county,
      key: 'county',
      setter: setCounty,
    },
    reservoirs: {
      items: reservoirs,
      key: 'basin',
      setter: setReservoirs,
    },
    prospectingIndustry: {
      prospecting: true,
      items: prospectingIndustries,
      key: 'industry',
      setter: setProspectingIndustries,
      startCase: true,
    },
    prospectingCompany: {
      prospecting: true,
      items: prospectingCompanies,
      key: 'company',
      setter: setProspectingCompanies,
      startCase: true,
    },
    prospectingLocation: {
      prospecting: true,
      items: prospectingLocations,
      key: 'location',
      setter: setProspectingLocations,
      startCase: true,
      extraFields: ['meta'],
    },
    prospectingJobTitle: {
      prospecting: true,
      items: prospectingJobTitles,
      key: 'title',
      setter: setProspectingJobTitles,
      startCase: true,
    },
    prospectingSchool: {
      prospecting: true,
      items: prospectingSchools,
      key: 'school',
      setter: setProspectingSchools,
      startCase: true,
    },
  };

  function renderInfoTooltip(text) {
    return (
      text !== undefined && (
        <Tooltip
          type="light"
          className="tooltipStyling"
          placement="end"
          refKey="ref"
          size="medium"
          zIndex={5}
          trigger={
            <Icon
              color={customBlue}
              fontAwesome
              icon="info-circle"
              fontSize="12px"
              style={{
                cursor: 'pointer',
                color: customBlue,
                position: 'relative',
                left: '5px',
                top: '-10px',
              }}
            />
          }
        >
          <div id="tooltipText">{text}</div>
        </Tooltip>
      )
    );
  }

  const renderCheckboxOption = ({ field, name, key }) => {
    const whiteSpace = get(field, 'ui.nowrapTitle') ? 'nowrap' : undefined;
    const renderTitle = () => {
      if (get(field, 'ui.noTitle')) {
        return null;
      }
      if (get(field, 'ui.title') && !get(field, 'ui.condensed')) {
        return (
          <MD
            semibold={!get(field, 'ui.bold')}
            scaled
            paddingBottomMd
            bold={!!get(field, 'ui.bold')}
          >
            <span style={{ color: customRed }}>
              {get(field, 'required') ? '* ' : ''}
            </span>
            {get(field, 'ui.title')}{' '}
          </MD>
        );
      }
      if (get(field, 'ui.title') && get(field, 'ui.condensed')) {
        return (
          <>
            <SM
              bold
              scaled
              paddingBottomSm={!get(field, 'ui.noBottomTitlePadding')}
              as="span"
              style={{ color: customRed }}
            >
              {get(field, 'required') ? '* ' : ''}
            </SM>
            <SM
              bold={get(field, 'ui.bold') !== false}
              slate={get(field, 'ui.slate')}
              scaled
              paddingBottomSm={!get(field, 'ui.noBottomTitlePadding')}
              as="span"
            >
              {get(field, 'ui.title')}{' '}
            </SM>
          </>
        );
      }
      return (
        <SM bold scaled paddingBottomMd>
          <span style={{ color: customRed }}>
            {get(field, 'required') ? '* ' : ''}
          </span>
          {startCase(name)}{' '}
        </SM>
      );
    };

    return (
      <Col
        key={`input-${name}`}
        size={field.ui.size}
        sm={field.ui.sm}
        md={field.ui.md}
        lg={field.ui.lg}
        xl={field.ui.xl}
        xxl={field.ui.xxl}
        extraPadding={field.ui.extraPadding}
        style={{
          marginTop: field.ui.marginTop || '0px',
          marginBottom: field.ui.marginBottom || spacingXl,
        }}
      >
        {renderTitle()}
        {isEditing === false ? (
          <>
            <MD bold as="span">
              {get(field, 'ui.label') ||
                get(field, 'ui.title') ||
                startCase(name)}
            </MD>
            <MD as="span"> {get(field, 'value.value')}</MD>
          </>
        ) : (
          <FormCheckbox
            border={!get(field, 'ui.noBorder')}
            // For cases where checkboxes are treated as radio buttons, we don't want to display them as disabled.
            // Just ignore the click if the checkbox is already checked.
            onChange={
              !get(field, 'ui.bypassOnChange')
                ? (e) =>
                    onChangeCheckbox(key, name, {
                      value: e.currentTarget.checked,
                      label: name,
                    })
                : noop
            }
            name={get(field, 'name') || name}
            checked={get(field, 'value.value')}
            disabled={get(field, 'ui.disabled')}
          >
            <MD bold as="span" style={{ whiteSpace }}>
              {get(field, 'ui.label') ||
                get(field, 'ui.title') ||
                startCase(name)}
            </MD>
          </FormCheckbox>
        )}
      </Col>
    );
  };

  const renderRadioOption = ({ field, name, key }) => {
    const renderTitle = () => {
      if (get(field, 'ui.noTitle')) {
        return null;
      }
      if (get(field, 'ui.title') && !get(field, 'ui.condensed')) {
        return (
          <MD
            semibold={!get(field, 'ui.bold')}
            scaled
            paddingBottomMd
            bold={!!get(field, 'ui.bold')}
          >
            <span style={{ color: customRed }}>
              {get(field, 'required') ? '* ' : ''}
            </span>
            {get(field, 'ui.title')}{' '}
          </MD>
        );
      }
      if (get(field, 'ui.title') && get(field, 'ui.condensed')) {
        return (
          <>
            <SM
              bold
              scaled
              paddingBottomSm={!get(field, 'ui.noBottomTitlePadding')}
              style={{ color: customRed }}
              as="span"
            >
              {get(field, 'required') ? '* ' : ''}
            </SM>
            <SM
              bold={get(field, 'ui.bold') !== false}
              slate={get(field, 'ui.slate')}
              scaled
              paddingBottomSm={!get(field, 'ui.noBottomTitlePadding')}
              as="span"
            >
              {get(field, 'ui.title')}{' '}
            </SM>
          </>
        );
      }
      return (
        <SM bold scaled paddingBottomMd>
          <span style={{ color: customRed }}>
            {get(field, 'required') ? '* ' : ''}
          </span>
          {startCase(name)}{' '}
        </SM>
      );
    };

    return (
      <Col
        key={`input-${name}`}
        size={field.ui.size}
        sm={field.ui.sm}
        md={field.ui.md}
        lg={field.ui.lg}
        xl={field.ui.xl}
        extraPadding={field.ui.extraPadding}
        style={{
          marginTop: field.ui.marginTop || '0px',
          marginBottom: field.ui.marginBottom || spacingXl,
        }}
      >
        {renderTitle()}
        <div
          style={{
            paddingLeft: spacingMd,
            display: field?.ui?.display || null,
          }}
        >
          {isEditing === false ? (
            <MD as="span">{get(field, 'value.value') || 'None selected'}</MD>
          ) : (
            <div>
              {map(field.items, (itm) => (
                <FormCheckbox
                  border={field?.ui?.border}
                  type="radio"
                  key={itm.value}
                  onChange={() =>
                    onChangeCheckbox(key, name, {
                      value: itm.value,
                      label: itm.label,
                    })
                  }
                  name={isBoolean(itm?.value) ? itm.label : itm.value}
                  checked={get(field, 'value.value') === itm.value}
                  style={{ paddingRight: field?.ui?.paddingRight || null }}
                >
                  <MD as="span">{itm.label}</MD>
                </FormCheckbox>
              ))}
            </div>
          )}
        </div>
      </Col>
    );
  };

  const renderButtonsOption = ({ field, name, key }) => {
    const renderTitle = () => {
      if (get(field, 'ui.noTitle')) {
        return null;
      }
      if (get(field, 'ui.title') && !get(field, 'ui.condensed')) {
        return (
          <MD
            semibold={!get(field, 'ui.bold')}
            scaled
            paddingBottomLg
            bold={!!get(field, 'ui.bold')}
          >
            <span style={{ color: customRed }}>
              {get(field, 'required') ? '* ' : ''}
            </span>
            {get(field, 'ui.title')}{' '}
          </MD>
        );
      }
      if (get(field, 'ui.title') && get(field, 'ui.condensed')) {
        return (
          <>
            <SM
              bold
              scaled
              paddingBottomSm={!get(field, 'ui.noBottomTitlePadding')}
              style={{ color: customRed }}
              as="span"
            >
              {get(field, 'required') ? '* ' : ''}
            </SM>
            <SM
              bold={get(field, 'ui.bold') !== false}
              slate={get(field, 'ui.slate')}
              scaled
              paddingBottomSm={!get(field, 'ui.noBottomTitlePadding')}
              as="span"
            >
              {get(field, 'ui.title')}{' '}
            </SM>
          </>
        );
      }
      return (
        <SM bold scaled paddingBottomMd>
          <span style={{ color: customRed }}>
            {get(field, 'required') ? '* ' : ''}
          </span>
          {startCase(name)}{' '}
        </SM>
      );
    };

    return (
      <Col
        key={`input-${name}`}
        size={field.ui.size}
        sm={field.ui.sm}
        md={field.ui.md}
        lg={field.ui.lg}
        xl={field.ui.xl}
        extraPadding={field.ui.extraPadding}
        style={{
          marginTop: field.ui.marginTop || '0px',
          marginBottom: field.ui.marginBottom || spacingXl,
        }}
      >
        {renderTitle()}
        {some(field.value, { label: 'Other' }) || field.alwaysShowSearch ? (
          <div
            style={{
              maxWidth: '400px',
              marginBottom: spacingLg,
              marginTop: '-5px',
            }}
          >
            <FilterItemValues
              popperModifiers={popperModifiers}
              error={field.valid === false || invalidFields}
              persistCasing
              itemSelection
              focused
              medium
              showAdd={field.buttons}
              customValues={field.customValues}
              multiple={field.multiple}
              defaultValue={get(field, 'ui.placeholder')}
              setSelectedValue={(value) =>
                onChangeSelectOther(
                  key,
                  name,
                  field.buttons,
                  get(field, 'value'),
                  value
                )
              }
              maxHeight={field.ui.maxHeight}
              title={`${get(field, 'ui.placeholder')}`}
            />
          </div>
        ) : null}

        <Flex wrap="true" alignCenter>
          {map(
            !field.customValues || field.alwaysShowSearch
              ? field.buttons
              : [
                  ...field.buttons,
                  { customValue: true, value: -1, label: 'Other' },
                ],
            (vv, i) => {
              let selectedIndex;
              if (field.multiple) {
                selectedIndex = findIndex(field.value, { value: vv.value });
              } else if (get(field.value, 'value') === vv.value) {
                selectedIndex = 0;
              } else {
                selectedIndex = -1;
              }
              const selected = selectedIndex > -1;
              return (
                <Button
                  size={field.ui.condensed ? 'small' : undefined}
                  style={
                    field.ui.condensed
                      ? { padding: '0 1em', fontSize: '12px' }
                      : {}
                  }
                  key={i}
                  bold
                  ignoreLineHeight={field.ui.condensed}
                  spacingBottom={!field.ui.condensed}
                  spacingRight={!field.ui.condensed}
                  spacingBottomSm={field.ui.condensed}
                  spacingRightSm={field.ui.condensed}
                  hoverColor="#d4dded"
                  hoverTextColor={customNavy}
                  onClick={() => {
                    let newVal;
                    const oldVal = field.value;

                    if (field.multiple) {
                      newVal = selected
                        ? filter(
                            field.value,
                            (v, valueIndex) => valueIndex !== selectedIndex
                          )
                        : [...(field.value || []), vv];
                    } else {
                      newVal = vv;
                    }

                    if (oldVal.value === newVal.value && unselect) {
                      const value = {
                        value: null,
                      };
                      onChangeSelect(key, name, value);
                    } else {
                      onChangeSelect(key, name, newVal);
                    }
                  }}
                  color={!selected ? customDarkGrey : undefined}
                  whiteOutline={!selected}
                  raised="0px 0px 4px rgba(0,0,0,0.1)"
                  borderColor="rgba(0,0,0,0.1)"
                  navy={selected}
                >
                  {vv.label}
                </Button>
              );
            }
          )}
        </Flex>
      </Col>
    );
  };

  const renderPhoneOption = ({ field, name, key }) => {
    const renderTitle = () => {
      if (get(field, 'ui.noTitle')) {
        return null;
      }
      if (get(field, 'ui.title') && !get(field, 'ui.condensed')) {
        return (
          <MD
            semibold={!get(field, 'ui.bold')}
            bold={!!get(field, 'ui.bold')}
            scaled
            paddingBottomSm
          >
            <span style={{ color: customRed }}>
              {get(field, 'required') ? '* ' : ''}
            </span>
            {get(field, 'ui.title')}{' '}
          </MD>
        );
      }
      if (get(field, 'ui.title') && get(field, 'ui.condensed')) {
        return (
          <>
            <SM
              bold
              scaled
              paddingBottomSm={!get(field, 'ui.noBottomTitlePadding')}
              style={{ color: customRed }}
              as="span"
            >
              {get(field, 'required') ? '* ' : ''}
            </SM>
            <SM
              bold={get(field, 'ui.bold') !== false}
              slate={get(field, 'ui.slate')}
              scaled
              paddingBottomSm={!get(field, 'ui.noBottomTitlePadding')}
              as="span"
            >
              {get(field, 'ui.title')}{' '}
            </SM>
          </>
        );
      }
      return (
        <SM bold scaled paddingBottomMd>
          <span style={{ color: customRed }}>
            {get(field, 'required') ? '* ' : ''}
          </span>
          {startCase(name)}
        </SM>
      );
    };
    return (
      <Col
        key={`input-${name}`}
        size={field.ui.size}
        sm={field.ui.sm}
        md={field.ui.md}
        lg={field.ui.lg}
        xl={field.ui.xl}
        extraPadding={field.ui.extraPadding}
        style={{
          marginTop: field.ui.marginTop || '0px',
          marginBottom: field.ui.marginBottom || spacingXxl,
        }}
      >
        {renderTitle()}
        {isEditing === false ? (
          <MD>{get(field, 'value.value') || 'Not entered'}</MD>
        ) : (
          <div>
            {field.multiInput ? (
              <div>
                {map(field.value, (phone, i, array) => (
                  <Flex
                    justifyBetween
                    alignCenter
                    key={i}
                    style={{ marginBottom: '15px' }}
                  >
                    <PhoneInput
                      style={{ flex: 1 }}
                      country="us"
                      placeholder="Enter Phone Number"
                      inputProps={{ autoComplete: 'none' }}
                      value={replace(get(phone, 'value', ''), '+', '')}
                      onChange={(number) => {
                        const newValue = [...array];
                        newValue[i] = {
                          value: number ? `+${number}` : null,
                          label: number,
                        };
                        onChangePhone(key, name, newValue);
                      }}
                    />
                    {array.length > 1 ? (
                      <Icon
                        style={{ borderBottom: '1px solid #cfcfcf' }}
                        fontSize="14px"
                        icon="icon-delete"
                        buttonStyle
                        onClick={() =>
                          onChangePhone(
                            key,
                            name,
                            filter(array, (v, n) => i !== n)
                          )
                        }
                      />
                    ) : null}
                  </Flex>
                ))}
                {some(field.value, ({ value }) => !value) ? null : (
                  <SM
                    link
                    center
                    onClick={() =>
                      onChangePhone(key, name, [
                        ...field.value,
                        { value: '', label: '' },
                      ])
                    }
                  >
                    Add Phone Number
                  </SM>
                )}
              </div>
            ) : (
              <PhoneInput
                country="us"
                placeholder="Enter Phone Number"
                value={replace(get(field, 'value.value', ''), '+', '')}
                onChange={(number) => {
                  onChangePhone(key, name, {
                    value: number ? `+${number}` : null,
                    label: number,
                  });
                }}
              />
            )}
          </div>
        )}
      </Col>
    );
  };

  const renderLocationOption = ({ field, name, key }) => {
    const renderTitle = () => {
      if (get(field, 'ui.noTitle')) {
        return null;
      }
      if (get(field, 'ui.title') && !get(field, 'ui.condensed')) {
        return (
          <MD
            semibold={!get(field, 'ui.bold')}
            bold={!!get(field, 'ui.bold')}
            scaled
            paddingBottomSm
          >
            <span style={{ color: customRed }}>
              {get(field, 'required') ? '* ' : ''}
            </span>
            {get(field, 'ui.title')}{' '}
          </MD>
        );
      }
      if (get(field, 'ui.title') && get(field, 'ui.condensed')) {
        return (
          <>
            <SM
              bold
              scaled
              paddingBottomSm={!get(field, 'ui.noBottomTitlePadding')}
              style={{ color: customRed }}
              as="span"
            >
              {get(field, 'required') ? '* ' : ''}
            </SM>
            <SM
              bold={get(field, 'ui.bold') !== false}
              slate={get(field, 'ui.slate')}
              scaled
              paddingBottomSm={!get(field, 'ui.noBottomTitlePadding')}
              as="span"
            >
              {get(field, 'ui.title')}{' '}
            </SM>
          </>
        );
      }
      return (
        <SM bold scaled paddingBottomMd>
          <span style={{ color: customRed }}>
            {get(field, 'required') ? '* ' : ''}
          </span>
          {startCase(name)}{' '}
        </SM>
      );
    };

    return (
      <Col
        key={`input-${name}`}
        size={field.ui.size}
        sm={field.ui.sm}
        md={field.ui.md}
        lg={field.ui.lg}
        xl={field.ui.xl}
        extraPadding={field.ui.extraPadding}
        style={{
          marginTop: field.ui.marginTop || '0px',
          marginBottom: field.ui.marginBottom || spacingXxl,
        }}
      >
        {renderTitle()}
        {isEditing === false ? (
          <MD>{get(field, 'value.value')}</MD>
        ) : (
          <GoogleLocationLookup
            itemSelection
            fullBorder={get(field, 'ui.fullBorder')}
            medium
            selectedValue={get(field, 'value.value', '')}
            value={get(field, 'value.value', '')}
            clearable={field.clearable}
            defaultValue={
              get(field, 'ui.placeholder') || `Enter Business Location`
            }
            title={get(field, 'ui.placeholder') || 'Business Location'}
            validation={
              field.required &&
              !get(field, 'value.value', '') &&
              get(field, 'dirty', '')
                ? 'error'
                : undefined
            }
            setSelectedValue={(value) => {
              onChangeSelect(key, name, value);
            }}
          />
        )}
      </Col>
    );
  };

  const renderMultiSelectOption = ({ field, name, key }) => {
    const renderTitle = () => {
      if (get(field, 'ui.noTitle')) {
        return null;
      }
      if (get(field, 'ui.title') && !get(field, 'ui.condensed')) {
        return (
          <MD
            semibold={!get(field, 'ui.bold')}
            bold={!!get(field, 'ui.bold')}
            scaled
            paddingBottomSm
          >
            <span style={{ color: customRed }}>
              {get(field, 'required') ? '* ' : ''}
            </span>
            {get(field, 'ui.title')}{' '}
          </MD>
        );
      }
      if (get(field, 'ui.title') && get(field, 'ui.condensed')) {
        return (
          <>
            <SM
              bold
              scaled
              paddingBottomSm={!get(field, 'ui.noBottomTitlePadding')}
              style={{ color: customRed }}
              as="span"
            >
              {get(field, 'required') ? '* ' : ''}
            </SM>
            <SM
              bold={get(field, 'ui.bold') !== false}
              slate={get(field, 'ui.slate')}
              scaled
              paddingBottomSm={!get(field, 'ui.noBottomTitlePadding')}
              as="span"
            >
              {get(field, 'ui.title')}{' '}
            </SM>
          </>
        );
      }
      return (
        <SM bold scaled paddingBottomMd>
          <span style={{ color: customRed }}>
            {get(field, 'required') ? '* ' : ''}
          </span>
          {startCase(name)}{' '}
        </SM>
      );
    };

    return (
      <Col
        column
        justifyEnd
        key={`input-${name}`}
        size={field.ui.size}
        sm={field.ui.sm}
        md={field.ui.md}
        lg={field.ui.lg}
        xl={field.ui.xl}
        extraPadding={field.ui.extraPadding}
        style={{
          marginTop: field.ui.marginTop || '0px',
          marginBottom: field.ui.marginBottom || spacingXxl,
        }}
      >
        {renderTitle()}
        {isEditing === false ? (
          <div>
            {size(get(field, 'value'))
              ? join(
                  map(get(field, 'value'), (item) => item.name || item.label),
                  ', '
                )
              : 'None selected'}
          </div>
        ) : (
          <MultiSelectFormItem
            itemSelection
            // This "multiple" transformer property name seems deceiving - it only determines whether the user can create a new value in the select
            allowCreate={field.multiple}
            popperModifiers={popperModifiers}
            medium
            error={field.valid === false || invalidFields}
            search={
              search[name]
                ? (value) =>
                    searchSuggestion(
                      {
                        setter: search[name].setter,
                        key: search[name].key,
                        name,
                        searchObj: search[name],
                      },
                      value
                    )
                : null
            }
            optionValues={
              search[name] ? search[name].items : get(field, 'items', [])
            }
            selectedItems={get(field, 'value')}
            defaultValue={
              get(field, 'ui.placeholder')
                ? get(field, 'ui.placeholder')
                : `Select ${startCase(name)}`
            }
            setSelectedValue={(value) => onChangeSelect(key, name, value)}
            title={`${startCase(name)}`}
          />
        )}
      </Col>
    );
  };

  const renderItemsOption = ({ field, name, key }) => {
    const renderTitle = () => {
      let title;
      if (get(field, 'ui.noTitle')) {
        title = null;
      } else if (get(field, 'ui.title') && !get(field, 'ui.condensed')) {
        title = (
          <MD
            semibold={!get(field, 'ui.bold')}
            bold={!!get(field, 'ui.bold')}
            scaled
            paddingBottomSm
          >
            <span style={{ color: customRed }}>
              {get(field, 'required') ? '* ' : ''}
            </span>
            {get(field, 'ui.title')}{' '}
          </MD>
        );
      } else if (get(field, 'ui.title') && get(field, 'ui.condensed')) {
        title = (
          <>
            <SM
              bold
              scaled
              paddingBottomSm={!get(field, 'ui.noBottomTitlePadding')}
              style={{ color: customRed }}
              as="span"
            >
              {get(field, 'required') ? '* ' : ''}
            </SM>
            <SM
              bold={get(field, 'ui.bold') !== false}
              slate={get(field, 'ui.slate')}
              scaled
              paddingBottomSm={!get(field, 'ui.noBottomTitlePadding')}
              as="span"
            >
              {get(field, 'ui.title')}{' '}
            </SM>
          </>
        );
      } else {
        title = (
          <SM bold scaled paddingBottomMd>
            <span style={{ color: customRed }}>
              {get(field, 'required') ? '* ' : ''}
            </span>
            {startCase(name)}{' '}
          </SM>
        );
      }
      if (field.ui?.infoTooltip) {
        return (
          <Flex alignCenter>
            {title}
            {renderInfoTooltip(field.ui?.infoTooltip)}
          </Flex>
        );
      }
      return title;
    };

    return (
      <Col
        key={`items-${name}`}
        size={field?.ui?.size}
        sm={field?.ui?.sm}
        md={field?.ui?.md}
        lg={field?.ui?.lg}
        xl={field?.ui?.xl}
        extraPadding={field?.ui?.extraPadding}
        style={{
          marginTop: field?.ui?.marginTop || '0px',
          marginBottom: field?.ui?.marginBottom || spacingXxl,
        }}
      >
        {renderTitle()}
        {isEditing === false ? (
          <MD>{get(field, 'value.label') || 'None selected'}</MD>
        ) : (
          <FilterItemValues
            popperModifiers={popperModifiers}
            size="small"
            error={field.valid === false || invalidFields}
            persistCasing
            itemSelection
            fullBorder={get(field, 'ui.fullBorder')}
            medium
            clearable={field.clearable}
            customValues={field.customValues}
            multiple={field.multiple}
            search={
              search[name]
                ? (value) =>
                    searchSuggestion(
                      {
                        setter: search[name].setter,
                        key: search[name].key,
                        name,
                        searchObj: search[name],
                      },
                      value
                    )
                : null
            }
            maxHeight={field?.ui?.maxHeight}
            ignoreFilter={!!search[name]}
            optionValues={
              search[name] ? search[name]?.items : get(field, 'items', [])
            }
            selectedValue={!field.multiple ? get(field, 'value') : undefined}
            selectedValues={field.multiple ? get(field, 'value') : undefined}
            defaultValue={
              get(field, 'ui.placeholder')
                ? get(field, 'ui.placeholder')
                : `Select ${startCase(name)}`
            }
            setSelectedValue={(value) => onChangeSelect(key, name, value)}
            title={`${startCase(name)}`}
          />
        )}
      </Col>
    );
  };

  const renderFilterItemGroupTwoFiltersOption = ({ field, name, key }) => {
    const renderTitle = () => {
      if (get(field, 'ui.noTitle')) {
        return null;
      }
      if (get(field, 'ui.title') && !get(field, 'ui.condensed')) {
        return (
          <MD
            style={{ ...get(field, 'ui.titleStyle'), paddingBottom: '6px' }}
            semibold={!get(field, 'ui.bold')}
            bold={!!get(field, 'ui.bold')}
            scaled
          >
            <span style={{ color: customRed }}>
              {get(field, 'required') ? '* ' : ''}
            </span>
            {get(field, 'ui.title')}{' '}
          </MD>
        );
      }
      if (get(field, 'ui.title') && get(field, 'ui.condensed')) {
        return (
          <>
            <SM
              bold
              scaled
              style={{ ...get(field, 'ui.titleStyle'), color: customRed }}
              as="span"
            >
              {get(field, 'required') ? '* ' : ''}
            </SM>
            <SM
              bold={get(field, 'ui.bold') !== false}
              slate={get(field, 'ui.slate')}
              style={get(field, 'ui.titleStyle')}
              scaled
              as="span"
            >
              {get(field, 'ui.title')}{' '}
            </SM>
          </>
        );
      }
      return (
        <SM bold scaled style={get(field, 'ui.titleStyle')}>
          <span style={{ color: customRed }}>
            {get(field, 'required') ? '* ' : ''}
          </span>
          {startCase(name)}{' '}
        </SM>
      );
    };
    const fieldValue = get(field, 'value.min')
      ? `${get(field, 'value.min')} - ${get(field, 'value.max')}`
      : get(field, 'value.label') || get(field, 'value.value');

    return (
      <Col
        column
        justifyEnd
        key={`input-${name}`}
        size={field.ui.size}
        sm={field.ui.sm}
        md={field.ui.md}
        lg={field.ui.lg}
        xl={field.ui.xl}
        extraPadding={field.ui.extraPadding}
        style={{
          marginTop: field.ui.marginTop || '0px',
          marginBottom: field.ui.marginBottom || spacingXxl,
        }}
      >
        {isEditing === false ? (
          <>
            {renderTitle()}
            <MD style={get(field, 'ui.titleStyle')}>
              {fieldValue
                ? `${fieldValue} ${get(field, 'uom.label')}`
                : 'Not entered'}
            </MD>
          </>
        ) : (
          <FilterItemGroupTwoFilters
            medium
            ignoreTitle
            itemSelection
            required={get(field, 'required')}
            config={field}
            title={get(field, 'ui.title')}
            type={name}
            titleWeight={
              get(field, 'ui.titleWeight') !== undefined
                ? get(field, 'ui.titleWeight')
                : 'medium'
            }
            onChangeConfig={(type, value) =>
              onChangeFilterItemGroupTwoFilters(key, type, value)
            }
            placeholder={
              get(field, 'ui.placeholder')
                ? get(field, 'ui.placeholder')
                : `Enter ${startCase(name)}`
            }
            // This prop isn't being utilized as intended within the component - renderTitle is a noop. Use condensed prop to render a smaller title
            customTitle={renderTitle()}
            condensed={field.ui.condensed}
            titleStyle={get(field, 'ui.titleStyle')}
            titleColor={get(field, 'ui.titleColor')}
            titleRowMarginBottom={get(field, 'ui.titleRowMarginBottom')}
            prefix={get(field, 'ui.prefix')}
            suffix={get(field, 'ui.suffix')}
          />
        )}
      </Col>
    );
  };

  const renderDateFieldOption = ({ field, name, key }) => {
    const renderTitle = () => {
      if (get(field, 'ui.noTitle')) {
        return null;
      }
      if (get(field, 'ui.title') && !get(field, 'ui.condensed')) {
        return (
          <MD
            semibold={!get(field, 'ui.bold')}
            bold={!!get(field, 'ui.bold')}
            scaled
            paddingBottomSm
          >
            <span style={{ color: customRed }}>
              {get(field, 'required') ? '* ' : ''}
            </span>
            {get(field, 'ui.title')}{' '}
          </MD>
        );
      }
      if (get(field, 'ui.title') && get(field, 'ui.condensed')) {
        return (
          <Flex>
            <SM
              bold
              scaled
              paddingBottomSm={
                !get(field, 'ui.noBottomTitlePadding') ||
                get(field, 'ui.paddingBottom') !== 'medium'
              }
              paddingBottomMd={get(field, 'ui.paddingBottom') === 'medium'}
              style={{ color: customRed }}
              as="span"
            >
              {get(field, 'required') ? '* ' : ''}
            </SM>
            <SM
              bold={get(field, 'ui.bold') !== false}
              slate={get(field, 'ui.slate')}
              scaled
              paddingBottomSm={
                !get(field, 'ui.noBottomTitlePadding') ||
                get(field, 'ui.paddingBottom') !== 'medium'
              }
              paddingBottomMd={get(field, 'ui.paddingBottom') === 'medium'}
              as="span"
            >
              {get(field, 'ui.title')}{' '}
            </SM>
          </Flex>
        );
      }
      return (
        <SM bold scaled paddingBottomMd>
          <span style={{ color: customRed }}>
            {get(field, 'required') ? '* ' : ''}
          </span>
          {startCase(name)}{' '}
        </SM>
      );
    };

    return (
      <Col
        column
        justifyEnd
        key={`input-${name}`}
        size={field.ui.size}
        sm={field.ui.sm}
        md={field.ui.md}
        lg={field.ui.lg}
        xl={field.ui.xl}
        extraPadding={field.ui.extraPadding}
        style={{
          marginTop: field.ui.marginTop || '0px',
          marginBottom: field.ui.marginBottom || spacingXxl,
        }}
      >
        {renderTitle()}
        {isEditing === false ? (
          <MD>{get(field, 'value.value') || 'Not entered'}</MD>
        ) : (
          <DatePicker
            medium
            validation={
              field.valid === false || invalidFields ? 'error' : undefined
            }
            onChange={(value) => onChangeDate(key, name, value)}
            date={
              get(field, 'value.value', '')
                ? new Date(
                    moment(get(field, 'value.value', '')).format('MM/DD/YYYY')
                  )
                : null
            }
          />
        )}
      </Col>
    );
  };

  const renderInputOption = ({ field, name, key, InputType }) => {
    let title;
    const renderTitle = () => {
      if (get(field, 'ui.noTitle')) {
        title = null;
      } else if (get(field, 'ui.title') && !get(field, 'ui.condensed')) {
        title = (
          <MD
            semibold={!get(field, 'ui.bold')}
            bold={!!get(field, 'ui.bold')}
            scaled
            paddingBottomSm
            style={get(field, 'ui.titleStyle')}
          >
            <span style={{ color: customRed }}>
              {get(field, 'required') ? '* ' : ''}
            </span>
            {get(field, 'ui.title')}
          </MD>
        );
      } else if (get(field, 'ui.title') && get(field, 'ui.condensed')) {
        title = (
          <SM
            bold={get(field, 'ui.bold') !== false}
            slate={get(field, 'ui.slate')}
            scaled
            paddingBottomSm={!get(field, 'ui.noBottomTitlePadding')}
            style={get(field, 'ui.titleStyle')}
          >
            <span style={{ color: customRed }}>
              {get(field, 'required') ? '* ' : ''}
            </span>
            {get(field, 'ui.title')}
          </SM>
        );
      } else {
        title = (
          <SM bold scaled paddingBottomMd style={get(field, 'ui.titleStyle')}>
            <span style={{ color: customRed }}>
              {get(field, 'required') ? '* ' : ''}
            </span>
            {startCase(name)}
          </SM>
        );
      }
      if (field.ui?.infoTooltip) {
        return (
          <Flex alignCenter>
            {title}
            {renderInfoTooltip(field.ui?.infoTooltip)}
          </Flex>
        );
      }
      return title;
    };

    const getContainer = () => {
      if (get(field, 'ui.prefix')) {
        return 'prefix';
      }
      if (get(field, 'ui.suffix')) {
        return 'suffix';
      }
      return false;
    };

    function renderValue() {
      const value = get(field, 'value.value');
      const prefix = get(field, 'ui.prefix.value');
      const suffix = get(field, 'ui.suffix.value');

      if (value) {
        let valueString = '';
        if (prefix) {
          valueString = `${prefix} `;
        }
        valueString = `${valueString}${value}`;
        if (suffix) {
          valueString = `${valueString} ${suffix}`;
        }
        return valueString;
      }
      return 'Not entered';
    }

    return (
      <Col
        column
        justifyStart={field.ui?.justifyColumn === 'start'}
        justifyEnd={
          !field.ui?.justifyColumn ? true : field.ui?.justifyColumn === 'end'
        }
        justifyCenter={field.ui?.justifyColumn === 'center'}
        alignCenter={field.ui?.alignColumnCenter}
        key={`input-${name}`}
        size={field.ui?.size}
        sm={field.ui?.sm}
        md={field.ui?.md}
        lg={field.ui?.lg}
        xl={field.ui?.xl}
        extraPadding={field.ui?.extraPadding}
        style={{
          marginTop: field.ui?.marginTop || '0px',
          marginBottom: field.ui?.marginBottom || spacingXxl,
          paddingLeft: field.ui?.paddingLeft,
          paddingRight: field.ui?.paddingRight,
        }}
      >
        {renderTitle()}
        {isEditing === false ? (
          <MD>{renderValue()}</MD>
        ) : (
          <Field
            paddingLeft={get(field, 'ui.prefix.paddingLeft')}
            paddingRight={get(field, 'ui.suffix.paddingRight')}
            container={getContainer()}
          >
            {get(field, 'ui.prefix') ? (
              <SM style={get(field, 'ui.prefix.style', {})} fieldLabelPrefix>
                {field.ui?.prefix.value}
              </SM>
            ) : null}
            <InnerInput
              InputType={InputType}
              field={field}
              invalidFields={invalidFields}
              formName={formName}
              onChangeInput={onChangeInput}
              itemKey={key}
              name={name}
            />
            {get(field, 'ui.suffix') ? (
              <SM style={get(field, 'ui.suffix.style', {})} fieldLabelSuffix>
                {field.ui?.suffix.value}
              </SM>
            ) : null}
          </Field>
        )}
        {get(field, 'ui.subtext') ? (
          <SM slate style={{ marginTop: '5px' }}>
            {field.ui?.subtext}
          </SM>
        ) : null}
      </Col>
    );
  };

  const renderAlloySelectOption = ({ field, name, key }) => {
    const renderTitle = () => {
      if (get(field, 'ui.noTitle')) {
        return null;
      }
      if (get(field, 'ui.title') && !get(field, 'ui.condensed')) {
        return (
          <MD
            semibold={!get(field, 'ui.bold')}
            bold={!!get(field, 'ui.bold')}
            scaled
            paddingBottomSm
          >
            <span style={{ color: customRed }}>
              {get(field, 'required') ? '* ' : ''}
            </span>
            {get(field, 'ui.title')}
          </MD>
        );
      }
      if (get(field, 'ui.title') && get(field, 'ui.condensed')) {
        return (
          <SM
            bold={get(field, 'ui.bold') !== false}
            slate={get(field, 'ui.slate')}
            scaled
            paddingBottomSm={!get(field, 'ui.noBottomTitlePadding')}
          >
            <span style={{ color: customRed }}>
              {get(field, 'required') ? '* ' : ''}
            </span>
            {get(field, 'ui.title')}
          </SM>
        );
      }
      return (
        <SM bold scaled paddingBottomMd>
          <span style={{ color: customRed }}>
            {get(field, 'required') ? '* ' : ''}
          </span>
          {startCase(name)}
        </SM>
      );
    };

    const currentFilters = {
      alloy: get(field, 'filter'),
    };

    return (
      <Col
        column
        justifyEnd
        key={`input-${name}`}
        size={field.ui?.size}
        sm={field.ui?.sm}
        md={field.ui?.md}
        lg={field.ui?.lg}
        xl={field.ui?.xl}
        extraPadding={field.ui?.extraPadding}
        style={{
          marginTop: field.ui?.marginTop || '0px',
          marginBottom: field.ui?.marginBottom || spacingXxl,
        }}
      >
        {renderTitle()}
        {isEditing === false ? (
          <MD>
            {join(map(get(field, 'filter.value'), 'label'), ', ') ||
              'Not entered'}
          </MD>
        ) : (
          <FilterItemSelection
            type="alloy"
            uom={null}
            warning={null}
            error={null}
            selectionFilters={get(field, 'filter.selectionFilters')}
            optionValues={get(field, 'filter.items')}
            selectedFilters={get(field, 'filter.selectedFilters')}
            selectedValue={get(field, 'filter.value')}
            setSelectedValue={(dropdownValue, otherValues) => {
              onChangeAlloy(key, name, dropdownValue, otherValues);
            }}
            currentFilters={currentFilters}
            title="Alloy"
            displaySelection={get(field, 'displaySelection')}
            allowMultiple={get(field, 'multiple')}
          />
        )}
        {get(field, 'ui.subtext') ? (
          <SM slate style={{ marginTop: '5px' }}>
            {field.ui?.subtext}
          </SM>
        ) : null}
      </Col>
    );
  };

  const renderOptions = (argsObject) => {
    const { field } = argsObject;
    if (field.checkbox) return renderCheckboxOption(argsObject);
    if (field.radio) return renderRadioOption(argsObject);
    if (field.buttons) return renderButtonsOption(argsObject);
    if (field.phone) return renderPhoneOption(argsObject);
    if (field.location) return renderLocationOption(argsObject);
    if (field.multiSelect) return renderMultiSelectOption(argsObject);
    if (field.items) return renderItemsOption(argsObject);
    if (field.filterItemGroupTwoFilters)
      return renderFilterItemGroupTwoFiltersOption(argsObject);
    if (field.dateField) return renderDateFieldOption(argsObject);
    if (field.alloy) return renderAlloySelectOption(argsObject);
    return renderInputOption(argsObject);
  };

  return (
    <div>
      {error ? (
        <Alert
          style={{ marginBottom: spacingMd }}
          small
          title={
            error === 'Not found.'
              ? '404 ERROR: The requested URL was not found. For more information contact sales@pipesearch.com'
              : error
          }
          type="error"
        />
      ) : null}

      {map(formData, (bi, key) => {
        if (!bi || !bi.fields || bi.ignoreInForm) {
          return null;
        }
        return (
          <div key={key} style={bi.wrapperStyle || wrapperStyle}>
            {!ignoreHeader && !bi.ignoreHeader ? (
              <GridRow style={{ marginBottom: spacingLg }}>
                <Col alignCenter>
                  <XL bold>
                    {startCase(get(bi, 'title') || get(bi, 'ui.title') || key)}
                  </XL>
                </Col>
              </GridRow>
            ) : null}
            <GridRow extraPaddingTwo={mediaMd} style={{ marginBottom: 0 }}>
              {map(bi.fields, (field, name) => {
                const InputType = field.textarea ? Textarea : Input;
                return field.disabled
                  ? null
                  : renderOptions({ field, name, key, InputType });
              })}
            </GridRow>
          </div>
        );
      })}
    </div>
  );
}

MultiTypeForm.propTypes = {
  // LINT OVERRIDE #6
  // Object has undetermined keys
  // eslint-disable-next-line react/forbid-prop-types
  data: PropTypes.object,
  setData: PropTypes.func,
  formName: PropTypes.string,
  clientId: PropTypes.number,
  contentType: PropTypes.number,
  error: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  wrapperStyle: PropTypes.shape({}),
  ignoreHeader: PropTypes.bool,
  // LINT OVERRIDE #6
  // Object has undetermined keys
  // eslint-disable-next-line react/forbid-prop-types
  popperModifiers: PropTypes.object,
  invalidFields: PropTypes.bool,
  unselect: PropTypes.bool,
  isEditing: PropTypes.bool,
};

function InnerInput({
  InputType,
  field,
  invalidFields,
  formName,
  onChangeInput,
  itemKey,
  name,
}) {
  return (
    <InputType
      medium
      readOnly={field.readOnly}
      bare={field.readOnly}
      autoFocus={field.autoFocus}
      basic={field.textarea && field.basicTextArea}
      textarea={field.textarea}
      style={field.textarea ? { minHeight: '100px' } : {}}
      itemSelection={!field.textarea}
      fullBorder={get(field, 'ui.fullBorder')}
      validation={field.valid === false || invalidFields ? 'error' : undefined}
      placeholder={
        get(field, 'ui.placeholder')
          ? get(field, 'ui.placeholder')
          : `Enter ${startCase(name)}`
      }
      value={get(field, 'value.value', '') || ''}
      onChange={(e) => onChangeInput(itemKey, name, field, e)}
      onBlur={(e) => {
        track('blurMultiTypeForm', {
          itemKey,
          name,
          title: get(field, 'ui.title'),
          value: e.currentTarget.value,
          form: formName,
        });
      }}
    />
  );
}

InnerInput.propTypes = {
  // LINT OVERRIDE #2
  // https://github.com/remix-run/react-router/issues/6177
  // External object
  // eslint-disable-next-line react/forbid-prop-types
  InputType: PropTypes.object,
  field: transformerFieldShape,
  invalidFields: PropTypes.bool,
  formName: PropTypes.string,
  onChangeInput: PropTypes.func,
  itemKey: PropTypes.string,
  name: PropTypes.string,
};

export default MultiTypeForm;
