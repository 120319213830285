export default function generatePagination(data = {}) {
  return {
    page: data.page,
    num_pages: data.num_pages,
    total: data.total,
    limit: data.limit,
  };
}

export function generateSort(data = {}) {
  return { direction: data.direction, sort: data.sort };
}
