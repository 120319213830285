import fetchJson from 'utility/fetchJson';
import { isEmpty } from 'lodash';
import { getValuesForRequest } from 'utility/reverseUrlFilters';
import { BASE } from '../../constants';

export async function getWishlistInterests({
  sorting = {},
  filters = {},
  pagination = {},
}) {
  let originalFilters = filters;
  originalFilters = isEmpty(filters)
    ? getValuesForRequest('wishlistItems')
    : filters;
  const body = { ...originalFilters, ...pagination, ...sorting };
  const response = await fetchJson(`${BASE}/api/on-board/product/list/`, {
    method: 'POST',
    body: JSON.stringify(isEmpty(body) ? { page: 1 } : body),
  });
  return response;
}

export async function getSavedListingInterests(idArr) {
  const response = await fetchJson(`${BASE}/api/p/listing/list/`, {
    method: 'POST',
    body: JSON.stringify({
      listings: idArr,
    }),
  });

  return response;
}

export async function getWishlistItem(id) {
  const response = await fetchJson(`${BASE}/api/on-board/product/${id}`, {
    method: 'GET',
  });
  return response;
}

export async function duplicateListingsTransition(typeObj, route) {
  const response = await fetchJson(`${BASE}/api/${route}/listing-transition/`, {
    method: 'Post',
    body: JSON.stringify(typeObj),
  });

  return response;
}
