import { map } from 'lodash';
import tree from 'state';
import { onboardingReverser, baseValidation } from 'utility/dynamicFormHelpers';
import { variables } from 'theme/variables';

const { media_md: mediaMd } = variables;

export function createConfig(
  {
    company_type: companyType,
    job_function: jobFunction,
    account_type: accountType,
  },
  { condensed, facilitator } = {}
) {
  return {
    aboutYou: {
      title: facilitator ? 'Customer' : undefined,
      fields: {
        job_title: {
          required: true,
          value: { value: '', label: '' },
          validation: baseValidation,
          ui: {
            condensed,
            title: condensed ? 'Job Title' : 'What is your Job Title?',
            sm: 12,
            md: '6',
            xl: condensed ? '6' : '4',
            extraPadding: mediaMd,
          },
        },
        job_function: {
          required: true,
          multiSelect: true,
          value: [],
          items: map(jobFunction, ({ name, id }) => ({
            value: id,
            label: name,
          })),
          validation: baseValidation,
          ui: {
            condensed,
            title: condensed
              ? 'Organization Roles'
              : 'What are your roles within the organization?',
            sm: 12,
            md: '6',
            xl: '6',
            extraPadding: mediaMd,
          },
        },
      },
    },
    aboutYourBusiness: {
      title: facilitator ? 'Customer Business' : undefined,
      fields: {
        company: {
          required: true,
          clearable: true,
          customValues: true,
          value: { value: '', label: '' },
          items: facilitator ? [] : undefined,
          validation: baseValidation,
          ui: {
            condensed,
            title: condensed ? 'Company Name' : 'What is your company name?',
            sm: 12,
            md: '12',
            l: '12',
            xl: condensed ? '12' : '6',
            extraPadding: mediaMd,
            maxHeight: '100%',
          },
        },
        business_location: {
          required: true,
          serverName: 'place_id',
          location: true,
          value: { value: '', label: '' },
          validation: (args) => baseValidation(args, null, 'place_id'),
          ui: {
            condensed,
            title: condensed
              ? 'Business Address'
              : 'What is Your Business Address?',
            sm: 12,
            md: '12',
            l: '12',
            xl: '12',
            extraPadding: mediaMd,
            maxHeight: '100%',
          },
        },
        company_type: {
          required: true,
          customValues: true,
          multiple: true,
          value: [],
          buttons: map(companyType, ({ name, id }) => ({
            value: id,
            label: name,
          })),
          validation: baseValidation,
          ui: {
            placeholder: 'Describe Your Company',
            condensed,
            title: condensed
              ? 'Company Description'
              : 'How would you describe your company?',
            sm: 12,
            md: '12',
            xl: '12',
            extraPadding: mediaMd,
          },
        },
      },
    },
    ourPlatform: {
      title: facilitator ? 'Our Platform' : undefined,
      fields: {
        account_type: {
          required: true,
          value: { value: '', label: '' },
          validation: baseValidation,
          buttons: map({ ...accountType }, ({ name, id }) => ({
            value: id,
            label: name,
          })),
          ui: {
            condensed,
            title: condensed
              ? 'Interests'
              : 'Let us know what you are interested in?',
            sm: 12,
            md: '12',
            xl: '12',
            extraPadding: mediaMd,
          },
        },
      },
    },
  };
}

export function generateOnboardingBusinessInfo({
  metadata,
  serverValues,
} = {}) {
  const newMetadata =
    metadata || tree.select(['onboarding']).get(['meta', 'result']);
  const {
    company_type: companyType,
    job_function: jobFunction,
    account_type: accountType,
  } = newMetadata;

  let config = createConfig({
    company_type: companyType,
    job_function: jobFunction,
    account_type: accountType,
  });

  if (serverValues) {
    config = onboardingReverser(config, serverValues);
    config.valid = true;
  } else {
    config.temp = true;
  }
  return config;
}
