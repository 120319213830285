import React from 'react';
import { variables } from 'theme/variables';
import { Row, Col } from '@zendeskgarden/react-grid';
import { Link } from 'react-router-dom';
import { openModal } from 'state/modals/actions';
import Icon from 'components/Icon/Icon';
import {
  PublicFooterComponent,
  Logo,
  FooterMenu,
  Copyright,
  FooterGrid,
  SocialMediaRow,
} from './PublicFooter.styles';

const { logo_url_white: logoUrlWhite } = variables;

function PublicFooter() {
  return (
    <PublicFooterComponent>
      <FooterGrid>
        <Row sm={12}>
          <Col lg={3} xl={4}>
            <Row style={{ display: 'flex', flexWrap: 'wrap' }}>
              <Col md={12}>
                <Logo className="footer-logo">
                  <Link to="/">
                    <img src={logoUrlWhite} alt="" />
                  </Link>
                </Logo>
              </Col>
              <Col
                md={12}
                style={{ display: 'flex', justifyContent: 'center' }}
              >
                <SocialMediaRow>
                  <Icon
                    icon="icon-facebook"
                    button
                    onClick={(e) => {
                      e.preventDefault();
                      window.open(
                        'https://www.facebook.com/pipesearch/',
                        '_blank'
                      );
                    }}
                    style={{ marginRight: '10px', color: 'white' }}
                  />
                  <Icon
                    fontAwesome
                    icon="linkedin"
                    iconPrefix="fab"
                    button
                    onClick={(e) => {
                      e.preventDefault();
                      window.open(
                        'https://www.linkedin.com/company/pipesearch-inc',
                        '_blank'
                      );
                    }}
                    style={{ marginRight: '10px', color: 'white' }}
                  />
                  <Icon
                    fontAwesome
                    icon="envelope"
                    iconPrefix="fas"
                    button
                    onClick={(e) => {
                      e.preventDefault();
                      openModal({
                        type: 'contactUsModal',
                      });
                    }}
                    style={{ marginRight: '10px', color: 'white' }}
                  />
                  <Icon
                    icon="icon-twitter"
                    button
                    onClick={(e) => {
                      e.preventDefault();
                      window.open('https://twitter.com/pipesearch', '_blank');
                    }}
                    style={{ marginRight: '10px', color: 'white' }}
                  />
                </SocialMediaRow>
              </Col>
            </Row>
          </Col>
          <Col lg={9} xl={8}>
            <Row id="footerRow">
              <Col md={3} className="footerMenuCol">
                <FooterMenu className="footer-menu">
                  <div className="title">About</div>
                  <ul>
                    <li>
                      <Link to="/sellers">Pipe Sellers</Link>
                    </li>
                    <li>
                      <Link to="/buyers">Pipe Buyers</Link>
                    </li>
                    <li>
                      <Link to="/why-pipesearch">Our Company</Link>
                    </li>
                    <li>
                      <Link to="/technical-resources">Technical Resources</Link>
                    </li>
                  </ul>
                </FooterMenu>
              </Col>
              <Col md={3} className="footerMenuCol">
                <FooterMenu className="footer-menu">
                  <div className="title">Legal</div>
                  <ul>
                    <li>
                      <a target="_blank" href="/terms.html">
                        Terms
                      </a>
                    </li>
                    <li>
                      <a target="_blank" href="/privacy-policy.html">
                        Privacy Policy
                      </a>
                    </li>
                    <li>
                      <a target="_blank" href="/disclaimer.html">
                        Disclaimer
                      </a>
                    </li>
                  </ul>
                </FooterMenu>
              </Col>
              <Col md={3} className="footerMenuCol">
                <FooterMenu className="footer-menu">
                  <div className="title">Support</div>
                  <ul>
                    <li>
                      <Link to="/contact">Contact Us</Link>
                    </li>
                    <li>
                      <a href="mailto:sales@pipesearch.com">
                        sales@pipesearch.com
                      </a>
                    </li>
                    <li>
                      <a href="tel:+17138278600">+1 (713) 827-8600</a>
                    </li>
                  </ul>
                </FooterMenu>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row sm={12}>
          <Col md={12}>
            <Copyright>
              © 2020 PipeSearch LLC. <br className="mobile-br" /> All Rights
              Reserved.
            </Copyright>
          </Col>
        </Row>
      </FooterGrid>
    </PublicFooterComponent>
  );
}

export default PublicFooter;
