import React from 'react';
import styled from 'styled-components/macro';
import { MD } from '@zendeskgarden/react-typography';
import Flex from 'styled-flex-component';
import { closeModal } from 'state/modals/actions';
import Icon from 'components/Icon/Icon';
import { variables } from 'theme/variables';
import { PropTypes } from 'prop-types';

const {
  color_white: colorWhite,
  custom_blue: customBlue,
  border_radius: borderRadius,
} = variables;

const StyledModalHeader = styled.div`
  height: auto;
  border-top-right-radius: ${borderRadius};
  border-top-left-radius: ${borderRadius};
`;

function GlobalModalHeader({ title = '' }) {
  return (
    <StyledModalHeader>
      <Flex
        style={{ padding: '0 15px 0', background: customBlue }}
        justifyBetween
        alignCenter
      >
        <MD color={colorWhite} bold>
          {title}
        </MD>
        <Icon
          color={colorWhite}
          onClick={closeModal}
          justifyEnd
          fontSize="9px"
          buttonStyle
          icon="icon-close"
        />
      </Flex>
    </StyledModalHeader>
  );
}

GlobalModalHeader.propTypes = {
  title: PropTypes.string,
};

export default GlobalModalHeader;
