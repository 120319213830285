import tree from 'state';
import asyncTreeRequester from 'utility/asyncTreeRequester';
import * as effects from './effects';

const tagsCursor = tree.select(['tags']);

export async function getTags() {
  const result = await asyncTreeRequester({
    func: effects.getTags.bind(this),
    cursor: tagsCursor,
    path: ['contentTags'],
  });
  return result;
}

export async function addTag(tag) {
  const tagResult = await asyncTreeRequester({
    func: effects.createTag.bind(this, tag),
    cursor: tagsCursor,
    path: ['contentTags'],
    transformer: ({ result, cursor = {} }) => {
      const original = cursor.result || {};
      return {
        ...original,
        results: [...(original.results || []), result],
      };
    },
  });
  return tagResult;
}
