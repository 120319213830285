import React, { useRef, useState, useEffect } from 'react';
import { useBranch } from 'baobab-react/hooks';
import { canUseDOM } from 'exenv';
import { Button } from 'theme/Button';
import { snakeCase, startCase, debounce } from 'lodash';
import { map } from 'lodash';
import { MD, SM } from '@zendeskgarden/react-typography';
import {
  Dropdown,
  Menu,
  Trigger,
  Autocomplete,
  Item,
  Label,
  Field,
} from '@zendeskgarden/react-dropdowns';

import { Field as InputField, Input } from '@zendeskgarden/react-forms';
import { createEntitySchema } from 'state/flexEntities/actions';
import { variables } from 'theme/variables';
const { custom_placeholder } = variables;

export default function AddTableColumn({
  onCommit,
  column,
  index,
  table,
  formatColumn,
  allColumns,
  contentType,
}) {
  const [menuIsOpen, setMenuIsOpen] = useState(false);
  const [submitStatus, setSubmitStatus] = useState({});
  const [columnDetails, setColumnDetails] = useState({
    columnName: '',
    columnType: '',
  });
  const schemaPath = ['flexEntities', 'schema', contentType, 'result'];

  async function onSubmit(columnDetails) {
    setSubmitStatus({ submitting: true });
    const key = snakeCase(columnDetails.columnName);
    let newColumnDetails = {
      accessor: key,
      type: columnDetails.columnType,
      editable: true,
      bulkable: true,
    };

    if (allColumns.find(({ key_id }) => key_id === key)) {
      setSubmitStatus({ error: 'This column already exists' });
      return;
    }

    const columnResult = await createEntitySchema(
      allColumns,
      contentType,
      { [key]: newColumnDetails },
      schemaPath
    );
    setSubmitStatus({});
    if (columnResult && columnResult.error) {
      setSubmitStatus({ error: columnResult.error });
      return;
    }

    const formattedColumns = formatColumn({
      key,
      schema: {
        ...newColumnDetails,
        content_type: columnResult.content_type,
        entity_id: columnResult.id,
      },
    });
    onCommit(formattedColumns);

    setMenuIsOpen(false);
    setColumnDetails({ columnName: '', columnType: '' });
  }

  return (
    <Dropdown
      popperModifiers={{ preventOverflow: { boundariesElement: 'viewport' } }}
      onSelect={(item) => null}
      onStateChange={(changes) => {
        if (
          changes.isOpen !== menuIsOpen &&
          Object.prototype.hasOwnProperty.call(changes, 'isOpen')
        ) {
          setMenuIsOpen(changes.isOpen);
        }
      }}
    >
      <Trigger>
        <div
          className="icon-plus"
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            pointer: 'cursor',
            height: '100%',
            fontSize: '10px',
          }}
          fontSize={'12px'}
        />
      </Trigger>
      {!menuIsOpen ? null : (
        <Menu
          style={{ minWidth: '300px' }}
          popperModifiers={{
            preventOverflow: {
              boundariesElement: canUseDOM
                ? document.querySelector('.table-wrapper')
                : 'viewport',
            },
          }}
        >
          <AddColumnForm
            {...{ setColumnDetails, submitStatus, columnDetails, onSubmit }}
          />
        </Menu>
      )}
    </Dropdown>
  );
}

export function AddColumnFormWrapper({
  columnPath,
  contentType,
  formatColumn,
  onCommit,
  onSuccess,
}) {
  const [submitStatus, setSubmitStatus] = useState({});
  const [columnDetails, setColumnDetails] = useState({
    columnName: '',
    columnType: '',
  });
  const schemaPath = ['flexEntities', 'schema', contentType, 'result'];
  let { allColumns } = useBranch({
    allColumns: schemaPath,
  });
  allColumns = map(allColumns, (d, k) => ({ originalSchema: d, key_id: k }));

  async function onSubmit(columnDetails) {
    setSubmitStatus({ submitting: true });
    const key = snakeCase(columnDetails.columnName);
    let newColumnDetails = {
      accessor: key,
      type: columnDetails.columnType,
      editable: true,
      bulkable: true,
    };

    if (allColumns.find(({ key_id }) => key_id === key)) {
      setSubmitStatus({ error: 'This column already exists' });
      return;
    }

    const columnResult = await createEntitySchema(
      allColumns,
      contentType,
      { [key]: newColumnDetails },
      schemaPath
    );
    setSubmitStatus({});
    if (columnResult && columnResult.error) {
      setSubmitStatus({ error: columnResult.error });
      return;
    }

    const schema = {
      ...newColumnDetails,
      content_type: columnResult.content_type,
      entity_id: columnResult.id,
    };

    const formattedColumns = formatColumn
      ? formatColumn({ key, schema })
      : schema;
    onCommit(formattedColumns);
    setColumnDetails({ columnName: '', columnType: '' });
    if (onSuccess) {
      onSuccess(columnResult);
    }
  }

  return (
    <AddColumnForm
      {...{ setColumnDetails, submitStatus, columnDetails, onSubmit }}
    />
  );
}

export function AddColumnForm({
  setColumnDetails,
  submitStatus,
  columnDetails,
  onSubmit,
}) {
  return (
    <div style={{ padding: '20px' }}>
      <MD noMargin paddingBottomXs bold left tag="h5">
        Field Name
      </MD>
      <InputField style={{ marginBottom: '10px' }}>
        <Input
          autoComplete={'off'}
          style={{ width: '100%' }}
          placeholder="Field Name"
          value={columnDetails.columnName}
          id="inquiry-title"
          name="title"
          onChange={(e) => {
            const value = e.currentTarget.value;
            setColumnDetails((c) => ({ ...c, columnName: value }));
          }}
        />
      </InputField>

      <FormRenderer setColumnDetails={setColumnDetails} />
      <Button
        buttonStatus={true}
        error={submitStatus.error}
        loading={submitStatus.submitting}
        onClick={() =>
          columnDetails.columnName && columnDetails.columnType
            ? onSubmit(columnDetails)
            : null
        }
        full
        spacingTop
        primary
      >
        Submit
      </Button>
      {submitStatus.error ? (
        <SM error style={{ fontSize: '12px', paddingTop: '10px' }}>
          {submitStatus.error}
        </SM>
      ) : null}
    </div>
  );
}

export function FormRenderer({ setColumnDetails }) {
  const options = ['text', 'number'];
  const [selectedItem, setSelectedItem] = useState();
  const [inputValue, setInputValue] = useState('');
  const [matchingOptions, setMatchingOptions] = useState(options);

  /**
   * Debounce filtering
   */
  const filterMatchingOptionsRef = useRef(
    debounce((value) => {
      const matchedOptions = options.filter(
        (option) =>
          option.trim().toLowerCase().indexOf(value.trim().toLowerCase()) !== -1
      );

      setMatchingOptions(matchedOptions);
    }, 300)
  );

  useEffect(() => {
    filterMatchingOptionsRef.current(inputValue);
  }, [inputValue]);

  return (
    <Dropdown
      inputValue={inputValue}
      selectedItem={selectedItem}
      onSelect={(item) => {
        setSelectedItem(item);
        setColumnDetails((c) => ({ ...c, columnType: item }));
      }}
      onInputValueChange={(value) => setInputValue(value)}
      downshiftProps={{ defaultHighlightedIndex: 0 }}
    >
      <Field>
        <Label>Choose Field Type</Label>
        <Autocomplete style={selectedItem ? {} : { color: custom_placeholder }}>
          {startCase(selectedItem) || 'Select Type'}
        </Autocomplete>
      </Field>
      <Menu
        style={{ minWidth: '300px' }}
        popperModifiers={{ preventOverflow: { boundariesElement: 'viewport' } }}
      >
        <Item disabled key={'Choose Type'} value={'Choose Type'}>
          <span>{'Choose Type Type'}</span>
        </Item>
        {matchingOptions.length ? (
          matchingOptions.map((option) => (
            <Item key={option} value={option}>
              <span>{startCase(option)}</span>
            </Item>
          ))
        ) : (
          <Item disabled>No matches found</Item>
        )}
      </Menu>
    </Dropdown>
  );
}
