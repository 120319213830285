import styled from 'styled-components/macro';
import { Col, Grid, Row } from '@zendeskgarden/react-grid';
import { variables } from 'theme/variables';

const {
  app_header_height: appHeaderHeight,
  app_page_padding: appPagePadding,
  media_sm: mediaSm,
  app_mobile_header_height: appMobileHeaderHeight,
  media_md: mediaMd,
  app_page_padding_medium: appPagePaddingMedium,
  custom_blue: customBlue,
  custom_background_color: customBackgroundColor,
} = variables;

export const AppHeaderComponent = styled.header`
  position: fixed;
  z-index: 10;
  top: 0;
  height: ${appMobileHeaderHeight};
  right: 0;
  left: 0;
  background: ${customBlue};
  display: flex;
  padding: 0;
  @media ${mediaSm} {
    align-items: center;
    height: ${appHeaderHeight};
    padding: 0 ${appPagePaddingMedium};
  }
  @media ${mediaMd} {
    box-shadow: 0px 6px 14px 0px rgba(4, 35, 86, 0.04);
    padding: 0 ${appPagePadding};
  }
`;

export const HeaderGrid = styled(Grid)`
  margin-left: 0px;
  margin-right: 0px;
`;

export const HeaderRow = styled(Row)`
  align-items: center;
  width: 100%;
  justify-content: space-between;
`;

export const MenuCol = styled(Col)`
  display: flex;
  justify-content: end;
  margin-right: 25px;
  @media (max-width: 375px) {
    margin-right: 0px;
  }
`;

export const AppHeaderComponentSidebar = styled(AppHeaderComponent)`
  background: ${customBackgroundColor};
  padding: 5px 8px 0;
  @media ${mediaSm} {
    padding: 0;
  }
  @media ${mediaMd} {
    padding: 0;
  }
`;

export const Logo = styled('div')`
  background-image: url('${(props) => props.src}');
  background-size: contain;
  width: 160px;
  height: 35px;
  background-position: center left;
  background-repeat: no-repeat;
  cursor: pointer;
  @media ${mediaMd} {
    height: 40px;
    width: ${(props) => (props.width ? props.width : '100%')};
  }
`;

export const FacilitatorLogo = styled('div')`
  background-image: url('${(props) => props.src}');
  background-size: contain;
  width: 40px;
  height: 30px;
  background-position: center left;
  background-repeat: no-repeat;
  cursor: pointer;
  @media ${mediaMd} {
    height: 40px;
  }
`;

export const LogoColumn = styled(Col)`
  max-width: 210px;
  padding: 0 10px;
  @media (max-width: 388px) {
    max-width: 110px;
  }
`;

export const ActionColumn = styled(Col)`
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  max-width: ${(props) => (props.fLogo ? '305px' : '260px')};
  @media (max-width: 575px) {
    max-width: ${(props) => (props.fLogo ? '140px' : '90px')};
    #accountMenuDropdown {
      display: none;
    }
  }
`;
