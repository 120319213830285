import React, { useState, useCallback } from 'react';
import { map, noop } from 'lodash';
import { Row, Col } from '@zendeskgarden/react-grid';
import { NavLink as Link } from 'react-router-dom';
import {
  Dropdown,
  Menu,
  Trigger,
  Separator,
} from '@zendeskgarden/react-dropdowns';
import { Anchor } from '@zendeskgarden/react-buttons';
import { Tooltip } from '@zendeskgarden/react-tooltips';
import { openModal } from 'state/modals/actions';
import { Logo } from 'components/AppHeader/PublicHeader.styles';
import { Button } from 'theme/Button';
import Icon from 'components/Icon/Icon';
import { getRole, hasSession } from 'utility/hasAccount';
import { LandingPage } from 'routes/Landing/Landing.styles';
import history from 'historyContainer.js';
import { logout } from 'state/authentication/actions';
import NavigationDropdownMenu from 'components/NavigationDropdownMenus/NavigationDropdownMenu';
// import { getSavedListingInterests } from 'state/wishlistInterests/actions';
// import useLocalStorage from 'hooks/useLocalStorage';
import {
  CredentialButton,
  RefactoredDashboardHeader,
  ActionRow,
  PipesearchLogo,
  MobileMenuButton,
  HeaderActionIconCol,
} from './RefactoredDashboard.styles';
import { locationShape } from '../../../propTypesObjects';

function RefactoredDashboard({ location }) {
  const currentPath = location?.pathname;
  const isAuth = hasSession();
  const role = getRole();
  const [dropdownState, setDropdownState] = useState(false);
  const [selectedService, setSelectedService] = useState('');
  // TODO: P2-1570 commented out code is for wishlist capabilities. Leaving code and styling just in case we want to add it back in the future.
  // const [savedItems] = useLocalStorage('wishListSavedItems', []);

  // const { savedListingInterests } = useBranch({
  //   savedListingInterests: ['wishlistInterests', 'savedListingInterests'],
  // });

  // const wishlistItemLength = size(get(savedListingInterests, 'result.docs'));

  // useEffect(() => {
  //   getSavedListingInterests(savedItems);
  // }, [savedItems]);

  const config = [
    {
      title: 'Global Warehouse',
      // Effectively disable link from the menu dropdown "button"
      to: currentPath,
      dropdown: true,
      subLinks: [
        {
          title: 'Global Warehouse',
          link: '/global-warehouse',
        },
        {
          title: 'Product Guide',
          link: '/product-guide',
        },
        {
          title: 'Alloy Guide',
          link: '/c',
        },
      ],
    },
    {
      title: 'Technical Resources',
      to: '/technical-resources',
    },
    {
      title: 'Buyers',
      to: '/buyers',
    },
    {
      title: 'Sellers',
      to: '/sellers',
    },
    {
      title: 'Content',
      to: '/industry-news',
    },
    {
      title: 'Why PipeSearch?',
      to: '/why-pipesearch',
    },
  ];

  const setDropdown = useCallback(() => {
    setDropdownState(!dropdownState);
  }, [dropdownState]);

  const setSubLinkState = useCallback((action, subLink) => {
    if (action === 'click') {
      setSelectedService(subLink);
    }
  }, []);

  const navLinks = map(config, (item) =>
    item?.subLinks ? (
      <NavigationDropdownMenu
        key={item?.title}
        isActive={selectedService}
        link={item?.to || '/'}
        customTitle={item?.title}
        className="dropdown-menu"
        onClick={() => setDropdown()}
        onClose={() => setDropdown()}
        location={history?.pathname}
        style={{ marginTop: '15px', marginRight: '10px' }}
        disableHover
      >
        <div className="categories">
          {map(item?.subLinks, ({ title, link }, i) => (
            <div className="link-wrapper" key={i}>
              <Link
                onClick={setSubLinkState(link)}
                key={i}
                to={link}
                className={`dropdown-link ${
                  link === selectedService ? 'active' : ''
                }`}
              >
                <h5>{title}</h5>
              </Link>
            </div>
          ))}
        </div>
      </NavigationDropdownMenu>
    ) : (
      <Link
        onClick={setSubLinkState(item.to)}
        key={item.title}
        to={item.to}
        style={{ marginTop: '15px', marginRight: '5%' }}
        as="span"
      >
        <span>{item.title}</span>
      </Link>
    )
  );

  function renderPromptButton() {
    if (!isAuth) {
      return (
        <CredentialButton
          className="signInButton"
          onClick={(e) => {
            e.preventDefault();
            history.push('/login');
          }}
          pairButton
          size="small"
          primary
        >
          Sign In
        </CredentialButton>
      );
    }
    if (!role) {
      return (
        <CredentialButton
          className="signInButton"
          onClick={async (e) => {
            e.preventDefault();
            await logout();
            history.push('/login');
          }}
          pairButton
          size="small"
          spacingBottom
          primary
        >
          Log Out
        </CredentialButton>
      );
    }
    return (
      <CredentialButton
        className="signInButton"
        onClick={(e) => {
          e.preventDefault();
          history.push('/dashboard');
        }}
        pairButton
        size="small"
        primary
      >
        My Dashboard
      </CredentialButton>
    );
  }

  const mobileNavLinks = (
    <Menu id="mobileMenuDropdown">
      {map(config, (item) =>
        item?.subLinks ? (
          map(item?.subLinks, ({ title, link }, i) => (
            <Anchor key={`${title}_${i}`} href={link} className="menuLink">
              {title}
            </Anchor>
          ))
        ) : (
          <Anchor key={item.title} href={item.to} className="menuLink">
            {item.title}
          </Anchor>
        )
      )}
      <Anchor
        key="contact"
        onClick={(e) => {
          e.preventDefault();
          openModal({
            type: 'contactUsModal',
          });
        }}
        className="menuLink"
      >
        Contact Us
      </Anchor>
      <Separator />
      <Anchor
        key="wishlist"
        onClick={() =>
          history.push(`/dashboard/m/wishlist/listview`, {
            previousLocation: window.location.href,
          })
        }
        className="menuLink"
      >
        My Wishlist
      </Anchor>
    </Menu>
  );

  function resetDashboard() {
    history.push('/');
  }

  return (
    <LandingPage
      style={{
        position: 'sticky',
        top: '0px',
        zIndex: '20',
      }}
    >
      <ActionRow>
        <HeaderActionIconCol>
          <Icon
            fontAwesome
            icon="envelope"
            iconPrefix="fas"
            button
            onClick={(e) => {
              e.preventDefault();
              openModal({
                type: 'contactUsModal',
              });
            }}
            style={{ marginRight: '10px' }}
          />
          <Icon
            fontAwesome
            icon="linkedin"
            iconPrefix="fab"
            button
            onClick={(e) => {
              e.preventDefault();
              window.open(
                'https://www.linkedin.com/company/pipesearch-inc',
                '_blank'
              );
            }}
            style={{ marginRight: '10px' }}
          />
          <Icon
            icon="icon-twitter"
            button
            onClick={(e) => {
              e.preventDefault();
              window.open('https://twitter.com/pipesearch', '_blank');
            }}
            style={{ marginRight: '10px' }}
          />
          <Tooltip
            type="light"
            className="tooltipStyling"
            placement="end"
            refKey="ref"
            size="medium"
            zIndex={10}
            trigger={<Icon icon="icon-phone" style={{ marginRight: '10px' }} />}
          >
            <div id="tooltipText">
              Need assistance? Have a question? Call us at +1 (713) 827-8600
            </div>
          </Tooltip>
        </HeaderActionIconCol>
        <Col style={{ display: 'flex', justifyContent: 'end' }}>
          {renderPromptButton()}
        </Col>
      </ActionRow>
      <RefactoredDashboardHeader
        mainPage={
          history?.location?.pathname === '/' ||
          history?.location?.pathname === '/landing'
        }
      >
        <Row className="navLinksRow">
          <Col className="pipesearchLogo">
            <Logo className="animate-logo" onClick={() => resetDashboard()}>
              <PipesearchLogo />
            </Logo>
          </Col>
          <Col className="dashboardNavLinks">{navLinks}</Col>
          <Col className="dashboardActionButtons">
            <Button
              className="contactUsButton"
              onClick={(e) => {
                e.preventDefault();
                openModal({
                  type: 'contactUsModal',
                });
              }}
              pairButton
              size="small"
              spacingBottom
              primaryOutline
              last
            >
              <Icon
                button
                fontAwesome
                icon="envelope"
                iconPrefix="far"
                style={{ marginRight: '5px' }}
              />
              Contact Us
            </Button>
            {/* {!isFacilitator() && (
              <WishlistButton
                className="wishlistButton"
                onClick={() =>
                  history.push(`/dashboard/m/wishlist/listview`, {
                    previousLocation: window.location.href,
                  })
                }
                type="submit"
                hasItems={wishlistItemLength >= 1}
                mainPage={
                  history?.location?.pathname === '/' ||
                  history?.location?.pathname === '/landing'
                }
              >
                <Icon
                  button
                  fontAwesome
                  icon="heart"
                  iconPrefix={wishlistItemLength >= 1 ? 'fas' : 'far'}
                  style={{
                    color: customBlue,
                    fontSize: '22px',
                    paddingTop: '2px',
                  }}
                />
                {wishlistItemLength >= 1 && (
                  <WishlistCountBubble>
                    {wishlistItemLength}
                  </WishlistCountBubble>
                )}
              </WishlistButton>
            )} */}
          </Col>
          <Col className="mobileNavLinks">
            <Dropdown style={{ zIndex: '7 !important' }}>
              <Trigger>
                <MobileMenuButton
                  className="mobileMenuButton"
                  onClick={noop}
                  type="submit"
                >
                  <Icon
                    id="menuButton"
                    button
                    icon="bars"
                    fontAwesome
                    iconPrefix="fal"
                  />
                </MobileMenuButton>
              </Trigger>
              {mobileNavLinks}
            </Dropdown>
          </Col>
        </Row>
      </RefactoredDashboardHeader>
    </LandingPage>
  );
}

RefactoredDashboard.propTypes = {
  location: locationShape,
};

export default RefactoredDashboard;
