import { variables } from 'theme/variables';

const { custom_light_grey: customLightGrey, custom_navy: customNavy } =
  variables;

function textInputProps(props) {
  return `
    ${
      props.itemSelection
        ? `
        border-width: 0;
        background-color: ${customLightGrey};
        box-shadow: none !important;
        ::placeholder {
          color: ${customNavy} !important;
          font-weight: 500;
          font-size: 12px;
        }
      `
        : ''
    }
  `;
}

const TextFieldTheme = {
  'forms.text_input': (props) => textInputProps(props),
};

export default TextFieldTheme;
