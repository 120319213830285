import tree from 'state';
import { onboardingReverser } from 'utility/dynamicFormHelpers';
import { createConfig } from 'state/onboarding/transformer_onboardingBusinessInfo';

export function generateCustomerBusinessInfo({ metadata, seedData } = {}) {
  const newMetadata =
    metadata || tree.select(['onboarding']).get(['meta', 'result']) || {};
  const {
    company_type: companyType,
    job_function: jobFunction,
    account_type: accountType,
  } = newMetadata;

  const businessConfig = createConfig(
    {
      company_type: companyType,
      job_function: jobFunction,
      account_type: accountType,
    },
    { condensed: true, facilitator: true }
  );

  let config = {
    ...businessConfig,
  };

  if (seedData) {
    config = onboardingReverser(config, seedData);
    config.valid = true;
  } else {
    config.temp = true;
  }
  return config;
}

export default generateCustomerBusinessInfo;
