import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { MD, XXL } from '@zendeskgarden/react-typography';
import { Formik } from 'formik';
import { cloneDeep, parseInt } from 'lodash';
import { Message, Field } from '@zendeskgarden/react-forms';
import { Row, Col } from '@zendeskgarden/react-grid';
import Flex, { FlexItem } from 'styled-flex-component';
import styled from 'styled-components/macro';
import { Button } from 'theme/Button';
import { FormInput, FormFooter, Form } from 'theme/Form';
import { variables } from 'theme/variables';
import { submitFieldInspectionCreation } from 'state/supplyInterests/actions';
import { FullFormFieldWrapper } from 'theme/AuthForms.styles';
import { PIPE_SEARCH_ASSET_MANAGEMENT_URL } from 'constants.js';

const { spacing_xl: spacingXl } = variables;

const FieldInspectionForm = styled(Form)`
  #formSubmitButton {
    width: 100%;
  }
  #submitButtonText {
    width: 100%;
  }
  @media (max-width: 768px) {
    #formSubmitButton {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
    }
    #submitButtonText {
      text-align: center;
    }
  }
`;

function formValidation(values) {
  const errors = {};
  if (!values.num_joints) {
    errors.num_joints = 'Required';
  } else if (!/^[0-9]+$/.test(values.num_joints)) {
    errors.num_joints = 'Invalid number';
  }
  return errors;
}

function FieldInspectionCreationForm({ onDone, data }) {
  const submitContactForm = useCallback(
    async (values, { setSubmitting, setStatus }) => {
      setSubmitting(true);
      const formattedJoints = parseInt(values.num_joints);
      const cloneValues = cloneDeep(values);
      cloneValues.num_joints = formattedJoints;
      const submitFieldInspectionSuccess = await submitFieldInspectionCreation(
        cloneValues
      );
      const { error } = submitFieldInspectionSuccess;
      if (error) {
        setStatus({ error });
      } else {
        setStatus({
          error: false,
          success: true,
          res: submitFieldInspectionSuccess,
        });
      }
      setSubmitting(false);
    },
    []
  );

  const initialValues = {
    supply_item_id: data?.supplyId,
    num_joints: '',
  };

  return (
    <Formik
      initialValues={initialValues}
      validate={formValidation}
      onSubmit={submitContactForm}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
        status,
      }) =>
        status?.success ? (
          <div>
            <div
              style={{
                margin: '10px',
                background: '#fff',
              }}
            >
              <XXL black center style={{ marginBottom: '10px' }}>
                Thank You.
              </XXL>
              <a
                href={`${PIPE_SEARCH_ASSET_MANAGEMENT_URL}/work-items/edit/${status.res.am_field_inspection_uuid}`}
                target="_blank"
                rel="noreferrer"
                style={{ padding: '10px' }}
              >
                Go to the Field Inspection work item created
              </a>
              {onDone ? (
                <Button onClick={onDone} center spacingTop primary>
                  Done
                </Button>
              ) : null}
            </div>
          </div>
        ) : (
          <FieldInspectionForm size="large" onSubmit={handleSubmit}>
            <div>
              <Row>
                <Col>
                  <FullFormFieldWrapper>
                    <MD bold left tag="h5">
                      Number of Joints
                    </MD>
                    <FormInput
                      autoFocus
                      type="text"
                      id="num_joints"
                      name="num_joints"
                      required
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.num_joints}
                      showMessage
                      error={
                        errors.num_joints &&
                        touched.num_joints &&
                        errors.num_joints
                      }
                      placeholder="Enter Number of Joints for this Field Inspection"
                    />
                  </FullFormFieldWrapper>
                </Col>
              </Row>
              <Row>
                <Col md={12}>
                  {status?.error && (
                    <FormFooter>
                      <Field>
                        <Message validation="error">{status.error}</Message>
                      </Field>
                    </FormFooter>
                  )}
                  <Flex justifyCenter>
                    <FlexItem id="formSubmitButton">
                      <Button
                        loading={isSubmitting || undefined}
                        type="submit"
                        primary
                        disabled={!values.num_joints}
                        style={{
                          marginTop: spacingXl,
                          marginBottom: '5px',
                        }}
                      >
                        Submit
                      </Button>
                    </FlexItem>
                  </Flex>
                </Col>
              </Row>
            </div>
          </FieldInspectionForm>
        )
      }
    </Formik>
  );
}

FieldInspectionCreationForm.propTypes = {
  onDone: PropTypes.func,
  data: PropTypes.shape({
    supplyId: PropTypes.number,
  }),
};

export default FieldInspectionCreationForm;
