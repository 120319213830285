// OVERIDE CSS - TODO IF CHANGED
import { keyframes, css } from 'styled-components/macro';
import { variables } from 'theme/variables';

const {
  custom_grey: customGrey,
  custom_black: customBlack,
  font_size_sm: fontSizeSm,
  font_size_md: fontSizeMd,
  custom_blue: customBlue,
  color_white: colorWhite,
  font_weight_semibold: fontWeightSemibold,
  custom_light_grey: customLightGrey,
  custom_silver: customSilver,
  color_red_400: colorRed400,
  custom_orange: customOrange,
  custom_placeholder: customPlaceholder,
} = variables;

const fadeIn = keyframes`  
  from   {
    opacity: 0; 
    transform: translate3d(0, -10px, 0);
  }
  to  {
    opacity: 1; 
    transform: translate3d(0, 0, 0);
  }
`;

const animation = () =>
  css`
    ${fadeIn} 300ms ease;
  `;

const DropDown = {
  'dropdowns.menu': (props) =>
    `
      ${
        props.overflowLarge
          ? `
        min-width: 600px;
        @media (max-width: 650px) {
          min-width: 550px;
        }
        @media (max-width: 550px) {
          min-width: 450px;
        }
        @media (max-width: 470px) {
          min-width: 400px;
        }
        @media (max-width: 420px) {
          min-width: 380px;
        }
        @media (max-width: 380px) {
          min-width: 320px;
        }
      `
          : ''
      } 
       &[class*="is-open"] {
        animation: ${animation} !important;
      }
    `,

  'dropdowns.select': (props) =>
    `
      background-color: ${props.background || 'transparent'} !important;
      &&&:focus {
        box-shadow: 0 0 0 3px rgba(2, 103, 240, 0.08);
        border-color: rgba(0, 85, 153, 0.3);
      }
      ${
        props.focused
          ? `
        box-shadow: 0 0 0 3px rgba(2, 103, 240, 0.08) !important;
        border-color: rgba(0, 85, 153, 0.3) !important;
        border-bottom: 1px solid rgba(0, 85, 153, 0.3) !important;
      `
          : ''
      }
      ${
        props.itemSelection
          ? `
        border: ${
          props.fullBorder ? `1px solid ${customSilver}` : 'none'
        } !important;
        border-bottom: 1px solid ${customSilver} !important;
        border-radius: ${props.fullBorder ? '5px' : '0'} !important;
        padding-left: ${props.fullBorder ? '10px' : '0'} !important;
        outline: none !important;
        box-shadow: none !important;
        font-size: ${props.fontSize || fontSizeSm};
        color: ${
          props.type === 'button' ? customPlaceholder : customGrey
        } !important;
        cursor: pointer;
        display: flex;
        align-items: center;
        padding-right: 20px !important;
        ${
          props.multiple
            ? `
          padding-right: 30px !important;
          .add {
            color: ${customBlue} !important;
            position: absolute;
            right: 37px;
            font-size: 10px;
            font-weight: 600;
            text-transform: uppercase;
          }
        `
            : ''
        }
        * {
          font-size: ${props.fontSize || fontSizeSm};
          cursor: pointer;
          color: ${customPlaceholder} !important;
          background-position: center center !important;
        }
        .form-option-tag, .form-option-tag * {
          color: ${customBlack} !important; 
        }
        input {
          color: ${customBlack} !important; 
        }
        &:before {
          color: ${customGrey};
          background-position: center center !important;
        }
        ${
          props.size
            ? `
          min-height: ${props.size}px;
          height: ${props.size}px;
          padding: 0;
          &:before {
            height: ${props.size - 1}px !important;
            width: ${props.size - 1}px !important;
          }
        `
            : ''
        }    
        ${
          props.active
            ? `
          color: ${customBlack} !important; 
          * {
            color: ${customBlack} !important; 
          }
        `
            : ''
        }

        ${
          props.focused && !props.disabled
            ? `
          border: ${
            props.fullBorder ? `1px solid ${customBlue}` : 'none'
          } !important
          border-bottom: 1px solid ${customBlue} !important;
          ${
            props.colorOverride
              ? `
            color: ${customGrey} !important;
            * {
              color: ${customGrey} !important;
            }
          `
              : ''
          }
        `
            : ''
        }

      `
          : '' /* END ITEM SELECTION */
      }

      ${
        props.itemSelectionMini
          ? `
        border: none !important;
        padding-top: 0px !important;
        padding-bottom: 0px !important;
        height: auto !important;
        min-height: 20px !important;
        padding-right: 20px !important; 
        padding-left: 5px !important; 
        outline: none !important;
        box-shadow: none !important;
        display: flex; 
        align-items: center;
        border-radius: 0;
        color: ${customGrey};
        font-size: ${fontSizeSm};
        cursor: pointer;
        * {
          cursor: pointer;
        }
        &:before {
          color: ${customGrey};
          height:20px !important;
          width: 20px !important;
          max-height:20px !important;
          max-width: 20px !important;
          background-position: center center !important;
        }

        &:focus:not([disabled]) {
          border-bottom: 1px solid ${customBlue} !important;
        }

        ${
          props.focused && !props.disabled
            ? `
          border-bottom: 1px solid ${customBlue} !important;
          ${
            props.colorOverride
              ? `
            color: ${props.colorOverride} !important;
            * {
              color: ${props.colorOverride} !important;
            }
          `
              : ''
          }
        `
            : ''
        }
      `
          : ''
      }

      ${
        props.range
          ? `
        padding-right: 20px !important;
        &:before {
          width: 20px !important;
          max-width: 20px !important;
        }
      `
          : ''
      }

      ${
        props.medium
          ? `
        background: red;
        min-height: 40px !important;
        font-size: ${fontSizeMd};
        * {
          font-size: ${fontSizeMd};
        }
        &.multi-select input {
          line-height: ${fontSizeMd} !important;
        }
        &:before {
          height:40px !important;
          width: 40px !important;
          max-height:40px !important;
          max-width: 40px !important;
        }
      `
          : ''
      }

      ${
        props.uom
          ? `
        padding-right: 30px !important;
        &:before {
          width: 20px !important;
          max-width: 20px !important;
        }
      `
          : ''
      }

      ${
        props.semibold
          ? `
        font-weight: ${fontWeightSemibold};
      `
          : ''
      }

      ${
        props.colorOverride
          ? `
        color: ${props.colorOverride} !important;
        * {
          color: ${props.colorOverride} !important;
        }
      `
          : ''
      }

      ${
        props.cursorDisabled
          ? `
        caret-color: transparent;
        * {
          caret-color: transparent;
        }
      `
          : ''
      }

      ${
        props.noOutline
          ? `
        box-shadow: none !important;
      `
          : ''
      }

      ${
        props.focused && !props.disabled
          ? `
        border-bottom: 1px solid ${customBlue} !important;
        ${
          props.colorOverride
            ? `
          color: ${props.colorOverride} !important;
          * {
            color: ${props.colorOverride} !important;
          }
        `
            : ''
        }
      `
          : ''
      }

      ${
        props.error && !props.fullBorder
          ? `
        border-bottom: 1px solid ${colorRed400} !important;
        * {
          color: ${colorRed400} !important;
        }
        &:before {
          background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' color='%2368737D'%3E%3Cpath fill='none' stroke='${colorRed400}' stroke-linecap='round' d='M4 6.5l3.6 3.6c.2.2.5.2.7 0L12 6.5'/%3E%3C/svg%3E") !important;
        }
      `
          : ''
      }

      ${
        props.error && props.fullBorder
          ? `
        border: 1px solid ${colorRed400} !important;
        * {
          color: ${colorRed400} !important;
        }
        &:before {
          background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' color='%2368737D'%3E%3Cpath fill='none' stroke='${colorRed400}' stroke-linecap='round' d='M4 6.5l3.6 3.6c.2.2.5.2.7 0L12 6.5'/%3E%3C/svg%3E") !important;
        }
      `
          : ''
      }

      ${
        props.warning
          ? `
        border-bottom: 1px solid ${customOrange} !important;
        * {
          color: ${customOrange} !important;
        }
        &:before {
          background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' color='%2368737D'%3E%3Cpath fill='none' stroke='${customOrange}' stroke-linecap='round' d='M4 6.5l3.6 3.6c.2.2.5.2.7 0L12 6.5'/%3E%3C/svg%3E") !important;
        }
      `
          : ''
      }

      ${
        props.pagination
          ? `
        border: 1px solid ${customLightGrey} !important;
        padding-left: 15px !important;
        font-size: 11px !important;
        line-height: 22px !important;
        color: ${customGrey} !important;
        font-weight: ${fontWeightSemibold} !important;
        padding-right: 2.58571em !important;
        box-shadow: none !important;
        padding-top: 0;
        padding-bottom: 0;
        max-height: 32px;
        min-height: 32px;
        display: flex;
        align-items: center;
        padding-left: 8px !important;
        &:before {
          opacity: 0.5;
          max-height: 32px;
          font-size: 14px !important;
        }
      `
          : ''
      }

       ${
         props.hideArrow
           ? `
        &:before {
          display: none;
        }
      `
           : ''
       }


      ${
        props.filterDropdown
          ? `
          min-height: 30px;
          padding: 5px 10px;
          padding-right: 40px;
          &&:before {
            height: 30px;
          }
          input {
            height: 19px;
            min-height: 19px !important;
          }
          ${
            props.values
              ? `
              border: 1px solid #73a9f2 !important;
              background: ${customBlue} !important;
              color: white !important;
              * {
                color: white !important;
              }
              &&:before {
                color: #fff !important; 
                background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' color='%2368737D'%3E%3Cpath fill='none' stroke='${colorWhite}' stroke-linecap='round' d='M4 6.5l3.6 3.6c.2.2.5.2.7 0L12 6.5'/%3E%3C/svg%3E") !important;
              }
            `
              : ''
          }
      `
          : ''
      }




      ${
        props.tableSort
          ? `
          min-height: 36px;
          padding: 5px 10px;
          padding-right: 40px;
          .select-input {
            line-height: 24px;
            * {
              line-height: 24px;
            }
          }
          &&:before {
            height: 36px;
          }
          input {
            height: 19px;
            min-height: 19px !important;
          }
          ${
            props.values
              ? `
              background: ${customBlue} !important;
              color: white !important;
              * {
                color: white !important;
              }
              &&:before {
                color: #fff !important; 
                background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' color='%2368737D'%3E%3Cpath fill='none' stroke='${colorWhite}' stroke-linecap='round' d='M4 6.5l3.6 3.6c.2.2.5.2.7 0L12 6.5'/%3E%3C/svg%3E") !important;
              }
            `
              : ''
          }
      `
          : ''
      }

    `,

  'dropdowns.item': (props) =>
    `
      ${
        props.itemsSmall
          ? `
        padding-top: 5px !important;
        padding-bottom: 5px !important;
        font-size: 12px !important;
      `
          : ''
      }
      ${
        !props.disabled && !props.clear
          ? `
        &:hover {
          background-color: ${customBlue} !important;
          color: ${colorWhite};
          * {
            color: ${colorWhite};
          }
          &:before {
            background: no-repeat 50%/16px url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' color='%231F73B7'%3E%3Cpath fill='none' stroke='${colorWhite}' stroke-linecap='round' stroke-linejoin='round' d='M1 9l4 4L15 3'/%3E%3C/svg%3E") !important;
          }
        }
      `
          : ''
      }

      ${
        props.hoverColorLight
          ? `
        &:hover {
          background-color: ${customLightGrey} !important;
          color: ${customBlue};
        }
      `
          : ''
      }
      ${
        props.clear
          ? `
        &:hover {
          background-color: ${customLightGrey} !important;
        }
      `
          : ''
      }

    `,
};

export default DropDown;
