import React, { useRef, useState, useEffect } from 'react';
import { SM } from '@zendeskgarden/react-typography';
import Flex from 'styled-flex-component';
import Checkbox from 'components/Checkbox/Checkbox';
import { get, groupBy, map, debounce, filter, startCase, find } from 'lodash';
import { Item, HeaderItem } from '@zendeskgarden/react-dropdowns';

const AlloyFilterItemSelectionTwo = ({ activeState, inputValue }) => {
  const { activeGroups, alloyGroups, alloys } = activeState;

  const options = [...alloys, ...alloyGroups];
  const [matchingOptions, setMatchingOptions] = useState(options);

  const filterMatchingOptionsRef = useRef(
    debounce((value) => {
      const matchingOptions = filter(options, (option) => {
        return (
          (option.label || '')
            .trim()
            .toLowerCase()
            .indexOf((value || '').trim().toLowerCase()) !== -1
        );
      });

      setMatchingOptions(matchingOptions);
    }, 100)
  );

  useEffect(() => {
    filterMatchingOptionsRef.current(inputValue);
  }, [inputValue]);
  return (
    <div style={{ maxHeight: 337 }}>
      {matchingOptions.length ? (
        map(groupBy(matchingOptions, 'group'), (gr, groupId) => {
          const type =
            get(find(alloyGroups, { value: Number(groupId) }), 'label') ||
            'Alloy Group';
          return (
            <div key={`${gr.value}-${type}`}>
              <SM
                bold
                style={{ padding: 10, background: 'rgb(234, 234, 234)' }}
              >
                {type || ''}
              </SM>
              {map(gr, (alloy) => {
                const selected =
                  alloy.selected ||
                  find(activeGroups, {
                    label: alloy.label,
                    value: alloy.value,
                  });
                return (
                  <Item
                    hoverColorLight
                    itemsSmall
                    alignCenter
                    value={{ selected, alloy }}
                    key={`${alloy.value}-${alloy.type}`}
                  >
                    <Flex alignCenter>
                      <Checkbox checked={selected} />
                      <span style={{ paddingLeft: '10px' }}>{alloy.label}</span>
                    </Flex>
                  </Item>
                );
              })}
            </div>
          );
        })
      ) : (
        <div>
          <SM bold style={{ padding: 10, background: 'rgb(243, 243, 243)' }}>
            Custom Alloy
          </SM>
          <Item
            hoverColorLight
            itemsSmall
            alignCenter
            value={{
              customValue: true,
              selected: false,
              alloy: {
                customValue: true,
                value: inputValue,
                label: inputValue,
              },
            }}
          >
            <Flex alignCenter>
              <Checkbox />
              <span style={{ paddingLeft: '10px' }}>{inputValue}</span>
            </Flex>
          </Item>
        </div>
      )}
    </div>
  );
};

export default AlloyFilterItemSelectionTwo;
