import React, { useEffect, useCallback } from 'react';
import Icon from 'components/Icon/Icon';
import useWindowSize from 'hooks/useWindowSize';
import { trackClick } from 'utility/analytics';
import { useBranch } from 'baobab-react/hooks';
import { variables } from 'theme/variables';
import Flex from 'styled-flex-component';
import { Col } from '@zendeskgarden/react-grid';
import { IconButton } from '@zendeskgarden/react-buttons';
import history from 'historyContainer';
import { logout } from 'state/authentication/actions';
import Platform from 'components/Platform/Platform';
import { get } from 'lodash';
import { toggleDrawer } from 'state/sidebars/actions';
import { toggleExpand } from 'state/sidebarPage/actions';
import { isPartner } from 'utility/hasAccount';
import ConversationButton from './components/ConversationButton/ConversationButton';
import AccountMenuDropdown from './components/AccountMenuDropdown/AccountMenuDropdown';
import NotificationDropdown from './components/NotificationDropdown/NotificationDropdown';

import {
  AppHeaderComponentSidebar,
  LogoColumn,
  ActionColumn,
  HeaderGrid,
  HeaderRow,
  Logo,
  FacilitatorLogo,
  MenuCol,
} from './app-header.styles.js';

const { logo_url: logoUrl } = variables;

function AppHeader() {
  const { session } = useBranch({
    session: ['authentication', 'session'],
  });
  const media = get(session, 'media');
  const signedQuerystring = get(session, 'signed_media');
  const facilitatorLogo = get(session, 'roles.facilitator.data.company.logo');

  const appLogout = useCallback(async () => {
    trackClick('logout', 'logout', 'AppHeader');
    const logoutRequest = await logout();
    if (!logoutRequest.error) {
      history.push('/login');
    } else {
      alert('There was an error signing out.');
    }
  }, []);

  const windowSize = useWindowSize();
  const expandSidebar =
    windowSize &&
    (windowSize.innerWidth > 1024 || windowSize.innerWidth <= 768);
  useEffect(() => {
    if (windowSize) {
      toggleExpand(expandSidebar);
    }
  }, [expandSidebar]); // eslint-disable-line react-hooks/exhaustive-deps
  return (
    <AppHeaderComponentSidebar className="app-header-comp">
      <HeaderGrid>
        <HeaderRow gutters={false} alignItems="center">
          <LogoColumn>
            <Flex alignCenter>
              <Logo
                width={facilitatorLogo ? '80%' : ''}
                src={logoUrl}
                onClick={() => {
                  trackClick('logoClick', 'logo', 'AppHeader');
                  history.push('/');
                }}
              />
            </Flex>
          </LogoColumn>
          <MenuCol>
            <ActionColumn fLogo={facilitatorLogo}>
              <Col>
                <ConversationButton />
              </Col>
              {!isPartner() ? (
                <Col size="auto">
                  <NotificationDropdown session={session} />
                </Col>
              ) : null}
              <Col size="auto" id="accountMenuDropdown">
                <AccountMenuDropdown session={session} logout={appLogout} />
              </Col>

              {facilitatorLogo && (
                <Col size="auto">
                  <FacilitatorLogo
                    src={`${media}${facilitatorLogo}${signedQuerystring}`}
                  />
                </Col>
              )}
            </ActionColumn>
            <Platform component={Col} size={1}>
              <IconButton
                onClick={toggleDrawer}
                disableBackground
                alignCenter
                justifyEnd
                size="large"
                aria-label="Menu"
              >
                <Icon icon="icon-align-text" fontSize="18px" />
              </IconButton>
            </Platform>
          </MenuCol>
        </HeaderRow>
      </HeaderGrid>
    </AppHeaderComponentSidebar>
  );
}

export default AppHeader;
