import { map, cloneDeep } from 'lodash';
import tree from 'state';
import { onboardingReverser, baseValidation } from 'utility/dynamicFormHelpers';
import { variables } from 'theme/variables';

const { media_md: mediaMd } = variables;

export function generateCompany({ metadata, seedData } = {}) {
  const rfqMetadata = tree.select(['requestForQuote']).get(['meta', 'result']);
  const metadataCopy = metadata
    ? cloneDeep(metadata)
    : tree.select(['onboarding']).get(['meta', 'result']) || {};
  // company_type
  // types required
  // synonym Alternative names for this company
  // name required
  // rank
  // description
  // slug
  // linkedin_url
  // linkedin_title
  // linkedin_description
  // icon
  // domain
  // parent
  let config = {
    company: {
      ignoreHeader: true,
      fields: {
        name: {
          required: true,
          validation: baseValidation,
          ui: {
            sm: 12,
            md: 6,
            lg: 6,
            extraPadding: mediaMd,
          },
        },
        ticker: {
          required: false,
          validation: baseValidation,
          ui: {
            sm: 12,
            md: 6,
            lg: 6,
            extraPadding: mediaMd,
          },
        },
        synonym: {
          validation: baseValidation,
          multiSelect: true,
          value: [],
          ignoreCustomFieldValidation: true, // this is when we do not pass key_other to the API. the parser needs this so it doesnt remove customField fields
          items: [],
          ui: {
            title: 'Alternate Name',
            condensed: true,
            placeholder: 'Enter Alternate Name',
            sm: 12,
            md: 6,
            lg: 6,
            extraPadding: mediaMd,
          },
        },
        domain: {
          validation: baseValidation,
          ui: {
            sm: 12,
            md: 6,
            lg: 6,
            extraPadding: mediaMd,
          },
        },
        linkedin_url: {
          validation: baseValidation,
          ui: {
            sm: 12,
            md: 6,
            lg: 6,
            extraPadding: mediaMd,
          },
        },
        types: {
          validation: baseValidation,
          multiSelect: true,
          multiple: false,
          items: map(metadataCopy.company_type, ({ name, id }) => ({
            value: id,
            label: name,
          })),
          ui: {
            placeholder: 'Enter Company Type',
            sm: 12,
            md: 6,
            lg: 6,
            extraPadding: mediaMd,
          },
        },
        tag: {
          required: false,
          customValues: false,
          key: 'tag',
          validation: baseValidation,
          ignoreCustomFieldValidation: true,
          multiSelect: true,
          items: [],
          value: [],
          ui: {
            title: 'Tag',
            condensed: true,
            placeholder: 'Enter Tag',
            sm: 12,
            md: 6,
            lg: 6,
            extraPadding: mediaMd,
          },
        },
        status: {
          disabled: false,
          validation: baseValidation,
          items: map(metadataCopy.company_status, ({ name, id }) => ({
            value: id,
            label: name,
          })),
          ui: {
            title: 'Status',
            condensed: true,
            placeholder: 'Enter Status',
            sm: 12,
            md: 6,
            lg: 6,
            extraPadding: mediaMd,
          },
        },
        priority: {
          disabled: false,
          validation: baseValidation,
          items: map(rfqMetadata.priority, ({ name, id }) => ({
            value: id,
            label: name,
          })),
          ui: {
            title: 'Priority',
            condensed: true,
            placeholder: 'Enter Priority',
            sm: 12,
            md: 6,
            lg: 6,
            extraPadding: mediaMd,
          },
        },
        parent: {
          required: false,
          customValues: false,
          serverName: 'parent',
          clearable: true,
          items: [],
          value: { value: '', label: '' },
          key: 'company',
          validation: baseValidation,
          ui: {
            condensed: true,
            title: 'Parent Company',
            placeholder: 'Optional - if this is a subsidiary.',
            sm: 12,
            md: 6,
            lg: 6,
            extraPadding: mediaMd,
          },
        },
        description: {
          textarea: true,
          validation: baseValidation,
          ui: {
            size: '12',
            extraPadding: mediaMd,
          },
        },
      },
    },
  };

  if (seedData) {
    config = onboardingReverser(config, seedData);
    config.valid = true;
  } else {
    config.temp = true;
  }
  return config;
}

export default generateCompany;
