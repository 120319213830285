import styled from 'styled-components/macro';
import { variables } from 'theme/variables';

const {
  font_size_sm: fontSizeSm,
  custom_blue: customBlue,
  custom_grey: customGrey,
  custom_black: customBlack,
  custom_silver: customSilver,
  font_size_md: fontSizeMd,
  color_red_400: colorRed400,
  font_family: fontFamily,
} = variables;

const DatePickerWrapper = styled('div')`
 .react-date-picker {
    width: 100%;
 }

 .react-date-picker__calendar--open {
    top: 100% !important;
    bottom: unset !important;
    z-index:2;
  }


  .react-calendar__tile--now, .react-calendar__tile--now:enabled:hover, .react-calendar__tile--now:enabled:focus {
    background: #cee3ff;
  }

 .react-calendar {
    width: 310px;
    border: 1px solid #e0e0e0;
    box-shadow: 0px 11px 13px rgba(0,0,0,0.15);
    padding: 10px;
    position: relative;
    .react-date-picker__wrapper, .react-date-picker__wrapper * {
      color:  ${(props) =>
        props.validation === 'error' ? colorRed400 : customBlack};
    }
    input::placeholder {
      color: ${(props) =>
        props.validation === 'error' ? colorRed400 : customGrey} !important;
    }
 }

 .react-date-picker__wrapper {
    width: 100%;
    border: none !important;
    border-bottom: 1px solid ${(props) =>
      props.validation === 'error' ? colorRed400 : customSilver} !important;
    border-radius: 0 !important;
    outline: none !important;
    padding: .71429em 0 !important;
    font-size: ${(props) => (props.medium ? fontSizeMd : fontSizeSm)};
    line-height: ${(props) => (props.medium ? fontSizeMd : fontSizeSm)};
    color: ${(props) =>
      props.validation === 'error' ? colorRed400 : customBlack} !important; 
    * {
      color: ${(props) =>
        props.validation === 'error' ? colorRed400 : customBlack}; 
    }
    input::placeholder {
      color: ${(props) =>
        props.validation === 'error' ? colorRed400 : customGrey} !important;
    }
 }

 &.small-table {
  width: 100%;
  .react-date-picker__wrapper {
    padding: 0 !important;
    height: 19px
    svg {
      width: 12px !important;
    }
    button:before {
      font-size: 11px !important;
    }
    button {
      padding: 0 4px !important;
    }
  }
 }

 .react-date-picker__button {
    padding-top: 0;
    padding-bottom: 0;
    outline: none;
    svg {
      width: ${(props) => (props.medium ? '20px' : '15px')};
      * {
        stroke: ${customGrey} !important;
      }
    }
 }

 .react-date-picker__calendar-button {
    &:before {
      font-size: ${(props) => (props.medium ? '18px' : '14px')};
      color: ${customGrey} !important;
    }
    svg {
      display: none;
    }
 }

 .react-date-picker__calendar {
    border-radius: 4px;
    margin-top: 10px;
 }

 .react-date-picker__calendar--closed {
    pointer-events: none;
    opacity: 0;
    display: block !important;
    height: 0;
    transform: translate3d(0, -10px, 0);
 }

   @keyframes SelectSlideIn {
    0% {
      opacity: 0;
      transform: translate3d(0, -10px, 0);
    }
    100% {
      opacity: 1;
      transform: translate3d(0%, 0, 0);
    }
  }

 .react-date-picker__calendar--open {
    pointer-events: all;
    opacity: 1;
    height: auto;
    transition: all 0.2s ease;
    transform: translate3d(0, 0, 0);
    animation: SelectSlideIn 0.2s ease forwards;
 }


 .react-calendar__month-view__weekdays__weekday {
    abbr[title] {
      text-decoration: none;
    }
 }
 .react-calendar__month-view__days__day {
  color: ${customBlack}
  font-size: 12px;
  line-height: 12px;
 }
 .react-calendar__month-view__days__day--weekend {
  color: ${customBlack}
 }
 .react-calendar__month-view__days__day--neighboringMonth {
    color: ${customSilver};
    * {
      color: ${customSilver};
    }
 }
 .react-calendar__tile:disabled {
    background: transparent;
    * {
      color: ${customSilver}
    }
 }
 .react-calendar__tile--active {
  background: transparent;
  position: relative;
  * {
    z-index: 2;
    position: relative;
    color: #fff !important;
  }
  &:before {
    content: "";
    z-index: 1;
    margin: 0 auto;
    left: 0;
    right: 0;
    position: absolute;
    background: ${customBlue};
    min-height: 26px;
    min-width: 26px;
    max-width: 26px;
    max-height: 26px;
    position: absolute;
    border-radius: 100px;
    top: 3px;
  }
 }

 .react-calendar__navigation {
  margin-bottom: 0;
 }

 .react-calendar__navigation__arrow[disabled] {
    background: transparent;
    color: ${customGrey}
  }
  .react-calendar__navigation__next2-button, .react-calendar__navigation__prev2-button {
    display: none
  }

  .react-calendar__navigation__prev-button {
    position: absolute;
    right: 30px;
    height: 40px;
    min-width: 20px;
    max-width: 20px;
    font-size: 20px;
    background: transparent !important;
  }
  .react-calendar__navigation__next-button {
    height: 40px;
    min-width: 20px;
    max-width: 20px;
    font-size: 20px;
    background: transparent !important;
  }
  .react-calendar__navigation__label {
    width: auto;
    padding-left: 9px;
    font-weight: bold;
    text-align: left;
    pointer-events: none;
    font-family: ${fontFamily}
  }
`;

export default DatePickerWrapper;
