import tree from 'state';
import { get } from 'lodash';
import { resetDirty } from 'utility/dynamicFormHelpers';
import { prospectingSearchTypes } from 'utility/staticMap.ts';
import { customersCursor } from '../../actions';
import { generateInitialSearchCriteria as generateInitialUserSearchCriteria } from './transformer_userSearchCriteria';
import { generateInitialSearchCriteria as generateInitialCompanySearchCriteria } from './transformer_companySearchCriteria';

const userSearch = get(prospectingSearchTypes, 'users');

// Called by setActivePriceData
// Fill branch according to MultiTypeForm API
// This allows for 'set' functions to apply dirty and valid states to fields
export async function initSearchCriteriaData(searchType) {
  const path = ['prospecting', 'currentSearch', 'searchCriteriaFormData'];
  // Transform object to MultiTypeForm structure
  let activeSearchCriteriaFormData;
  if (searchType === userSearch) {
    activeSearchCriteriaFormData = generateInitialUserSearchCriteria();
  } else {
    activeSearchCriteriaFormData = generateInitialCompanySearchCriteria();
  }
  customersCursor.set(path, activeSearchCriteriaFormData);

  // This function applies global state changes immediately - important when applying state based on keystrokes in a field
  tree.commit();
}

// Fill field data
export async function setActiveSearchCriteriaData(searchType) {
  // Call actions to fill branch according to MultiTypeForm API
  // This allows for 'set' functions to apply dirty and valid states to fields
  /* const emptySearchCriteria = {
    id: 1,
    keyword: '',
    prospectingIndustry: null,
  } */
  initSearchCriteriaData(searchType);

  // This function applies global state changes immediately - important when applying state based on keystrokes in a field
  tree.commit();
}

// Set branch state with each keystroke in a field
export function setSearchCriteriaFormData(category, type, data) {
  const path = ['prospecting', 'currentSearch', 'searchCriteriaFormData'];

  // Get path to field state
  // REVIEW will location fields be correctly identified when more than one exists?
  const rawItem = customersCursor.get([...path, category, 'fields', type]);
  const currentData = data;
  currentData.dirty = true;
  currentData.valid = rawItem.validation
    ? rawItem.validation(currentData.value, rawItem.required)
    : true;
  customersCursor.set([...path, 'dirty'], true);
  customersCursor.merge([...path, category, 'fields', type], currentData);

  tree.commit();
}

// Save field data on submit
export async function saveSearchCriteriaData({ searchCriteriaFormData }) {
  // Reset component global state after user submit
  const currentSearchCriteriaFormData = searchCriteriaFormData;
  currentSearchCriteriaFormData.dirty = false;
  const resetDirtySearchCriteriaFields = resetDirty(
    currentSearchCriteriaFormData
  );
  customersCursor.set(
    ['prospecting', 'currentSearch', 'searchCriteriaFormData'],
    resetDirtySearchCriteriaFields
  );
}

export function clearSearchCriteriaFormData() {
  const path = ['prospecting', 'currentSearch', 'searchCriteriaFormData'];
  customersCursor.unset(path);
  tree.commit();
}
