import React, { useState, useCallback } from 'react';
import { MD } from '@zendeskgarden/react-typography';
import { useBranch } from 'baobab-react/hooks';
import { addClass, removeClass } from '../../utility/domUtils';
import { trackClick } from '../../utility/analytics';
import history from '../../historyContainer';
import { logout } from '../../state/authentication/actions';
import Icon from '../Icon/Icon';
import { toggleDrawer } from '../../state/sidebars/actions';
import {
  MenuItem,
  Close,
  Logout,
  StyledDrawer,
  DrawerWrapper,
} from './MobileDrawer.styles.js';
import DashboardSidebarMenu, {
  MainSiteLinks,
} from '../SideBarMenus/DashboardSidebarMenu/DashboardSidebarMenu';
import { variables } from '../../theme/variables';

const { custom_navy: customNavy } = variables;

async function appLogout() {
  trackClick('logout', 'logout', 'AppHeader');
  const logoutRequest = await logout();
  toggleDrawer();
  if (!logoutRequest.error) {
    history.push('/login');
  } else {
    alert('There was an error signing out.');
  }
}

function GlobalMobileDrawer() {
  const [opacity, setOpacity] = useState(0);
  const { session, drawerOpen } = useBranch({
    drawerOpen: ['sidebars', 'mobileDrawerOpen'],
    session: ['authentication', 'session'],
  });

  const afterOpen = useCallback(() => {
    addClass(document.getElementsByTagName('html')[0], 'overflow-hidden');
    setTimeout(() => {
      setOpacity(1);
    }, 20);
  }, [setOpacity]);

  const beforeClose = useCallback(() => {
    removeClass(document.getElementsByTagName('html')[0], 'overflow-hidden');
    return new Promise((resolve) => {
      setOpacity(0);
      setTimeout(resolve, 300);
    });
  }, [setOpacity]);

  return (
    <StyledDrawer
      isOpen={drawerOpen}
      onBackgroundClick={toggleDrawer}
      afterOpen={afterOpen}
      beforeClose={beforeClose}
      onEscapeKeydown={toggleDrawer}
      opacity={opacity}
      allowScroll
      backgroundProps={{ opacity }}
    >
      <Close onClick={toggleDrawer}>
        <Icon icon="icon-close" fontSize="15px" color={customNavy} />
      </Close>

      <MD style={{ fontSize: '16px', padding: '3px 10px' }} bold navy>
        Hello {(session && session.first_name) || ''}
      </MD>

      <DrawerWrapper>
        <div>
          <DashboardSidebarMenu disableCollapse />
          <MainSiteLinks onClickLink={toggleDrawer} />
        </div>

        <ul>
          <MenuItem>
            <Logout>
              <MD
                hover
                red
                paddingTopSm
                paddingBottomSm
                center
                onClick={appLogout}
              >
                Logout
              </MD>
            </Logout>
          </MenuItem>
        </ul>
      </DrawerWrapper>
    </StyledDrawer>
  );
}

export default GlobalMobileDrawer;
