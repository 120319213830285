import React from 'react';
import NoDataPlaceholder from 'components/NoDataPlaceholder/NoDataPlaceholder';
import Spinner from 'components/Spinner/Spinner';
import { map } from 'lodash';
import { SM } from '@zendeskgarden/react-typography';
import { variables } from 'theme/variables';
import Icon from 'components/Icon/Icon';

import {
  Table,
  Head,
  HeaderRow,
  HeaderCell,
  Body,
  Cell,
  Row,
} from '@zendeskgarden/react-tables';

const { custom_lighter_grey, custom_blue } = variables;

const AlloyTable = (props) => {
  const data = props.data;
  const loading = props.loading;
  const rows = map(data, (data, i) => {
    return (
      <Row key={i}>
        <Cell width="30%">
          <SM ellipsis>{data.metallurgy_group}</SM>
        </Cell>
        <Cell width="20%">
          <SM ellipsis>{data.name}</SM>
        </Cell>
        <Cell justifyCenter width="25%">
          <Icon icon="icon-eye" color={custom_blue} fontSize={'15px'} />
        </Cell>
        <Cell width="10%">
          <SM ellipsis>{data.pren || '--'}</SM>
        </Cell>
        <Cell justifyCenter width="15%">
          <Icon icon="icon-information" color={custom_blue} fontSize={'12px'} />
        </Cell>
      </Row>
    );
  });
  return loading ? (
    <NoDataPlaceholder>
      <Spinner />
    </NoDataPlaceholder>
  ) : !rows.length ? (
    <NoDataPlaceholder>
      <SM>No Content</SM>
    </NoDataPlaceholder>
  ) : (
    <Table style={props.style || {}}>
      <Head>
        <HeaderRow background={custom_lighter_grey}>
          <HeaderCell width="30%">
            <SM>Group</SM>
          </HeaderCell>
          <HeaderCell width="20%">
            <SM>Name</SM>
          </HeaderCell>
          <HeaderCell justifyCenter width="25%">
            <SM>Data Sheet</SM>
          </HeaderCell>
          <HeaderCell width="10%">
            <SM>Pren</SM>
          </HeaderCell>
          <HeaderCell justifyCenter width="15%">
            <SM>Desc</SM>
          </HeaderCell>
        </HeaderRow>
      </Head>
      <Body>{rows}</Body>
    </Table>
  );
};

export default AlloyTable;
