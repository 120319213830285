import tree from 'state';
import { get } from 'lodash';
import { trackClick } from 'utility/analytics';

export function openModal({ type, data = {}, view = '' }) {
  trackClick('openModal', type, view);
  tree.set(['modals'], {
    modalOpen: true,
    modalType: type,
    modalData: data,
  });
}

export function closeModal() {
  const modal = tree.get(['modals']);
  const { type } = modal;
  trackClick('closeModal', type, 'modal');
  tree.set(['modals'], {
    modalOpen: false,
    modalType: null,
    modalData: null,
  });

  try {
    if (get(modal, 'modalData.onClose')) {
      get(modal, 'modalData.onClose')();
    }
  } catch (err) {
    console.log(err);
  }
}
