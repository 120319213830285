import styled from 'styled-components/macro';
import { MD, SM } from '@zendeskgarden/react-typography';

import { variables } from 'theme/variables';

const { custom_black: customBlack } = variables;

export const SelectedAlloyLabel = styled(SM)`
  color: ${customBlack} !important;
`;

export const SelectedAlloyLabelMedium = styled(MD)`
  color: ${customBlack} !important;
`;
