import { convertToRaw, convertFromRaw } from 'draft-js';
import { stateToHTML } from 'draft-js-export-html';
import linkifyHtml from 'linkify-html';

const OPTIONS = {
  inlineStyles: {
    BOLD: {
      style: { fontWeight: 'bold' },
    },
    ITALIC: {
      style: { fontStyle: 'italic' },
    },
    UNDERLINE: {
      style: { textDecoration: 'underline' },
    },
  },
};

export function getMessageDraftValues(editorState) {
  const draft = convertToRaw(editorState.getCurrentContent());
  let html = stateToHTML(editorState.getCurrentContent(), OPTIONS);
  html = linkifyHtml(html);
  return { draft, html };
}

export function draftjsonToHtml(draft) {
  let html = convertFromRaw(draft.draft, OPTIONS);
  html = stateToHTML(html, OPTIONS);
  html = linkifyHtml(html);
  return html;
}

export function getRawDraft(editorState) {
  const draft = convertToRaw(editorState.getCurrentContent());
  return { draft };
}
