import React from 'react';
import styled from 'styled-components/macro';
import { variables } from 'theme/variables';
const { raisedValue } = variables;

const DashboardCard = styled('div')`
  background-color: #fff;
`;

const StyledNewDashboardCard = styled('div')`
  background-color: #fff;
  border-style: solid;
  border-width: 1px;
  border-color: rgb(226, 229, 236);
  background-color: rgb(255, 255, 255);
  box-shadow: ${raisedValue};
  border-radius: 6px;
  ${(props) =>
    props.pointer
      ? `
    &:hover {
      background: #eceef1;
      cursor: pointer;
      transition: background 0.15s ease;
    }
  `
      : ''}
`;

const DashboardCardComponent = (props) => {
  return props.new ? (
    <NewDashboardCard {...props}>{props.children}</NewDashboardCard>
  ) : (
    <DashboardCard {...props}>{props.children}</DashboardCard>
  );
};

export const NewDashboardCard = (props) => {
  return (
    <StyledNewDashboardCard {...props}>{props.children}</StyledNewDashboardCard>
  );
};

export default DashboardCardComponent;
