import styled from 'styled-components/macro';

export const StyledMenu = styled('div')`
  .dropdown-menu {
    .drop-down {
      display: flex;
      padding: 0;
      min-width: 190px;
      .categories {
        width: 100%;
      }
    }
    .hidden {
      display: none;
    }
  }
`;
