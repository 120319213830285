import tree from 'state';
import * as effects from './effects';
import asyncTreeRequester from 'utility/asyncTreeRequester';

const requestForQuoteCursor = tree.select(['requestForQuote']);

export async function createSimpleRfqInquery(data) {
  const rfq = await asyncTreeRequester({
    func: effects.createSimpleRfqInquery.bind(this, data),
    cursor: requestForQuoteCursor,
    path: ['simpleInquiery'],
  });
  return rfq;
}

export async function getRfqSimpleInquiry(simpleInquiryId) {
  const activeSimpleInquiry = await asyncTreeRequester({
    func: effects.getSimpleRfqInqueryItem.bind(this, simpleInquiryId),
    cursor: requestForQuoteCursor,
    path: ['activeSimpleInquiry'],
  });
  return activeSimpleInquiry;
}
