import moment from 'moment';

import fetchJson from 'utility/fetchJson';
import tree from 'state';
import { BASE } from '../../constants';

export async function getOnboardingMeta({ token, cipher } = {}) {
  const query =
    token && cipher
      ? `?token=${token}&cipher=${encodeURIComponent(cipher)}`
      : '';
  let storedMeta = localStorage.getItem('onboardingMeta');
  storedMeta = storedMeta ? JSON.parse(storedMeta) : null;
  const shouldRefetch = storedMeta
    ? moment(Date.now()).diff(storedMeta.date, 'minutes') > 20
    : true;

  if (shouldRefetch) {
    const response = await fetchJson(`${BASE}/api/on-board/meta/${query}`, {
      method: 'GET',
    });
    if (response && !response.error) {
      localStorage.setItem(
        'onboardingMeta',
        JSON.stringify({ date: new Date(), data: response })
      );
    }
    return response;
  }
  return storedMeta.data;
}

export async function getAreasOfInterest() {
  const userId = tree.get(['authentication', 'session', 'user_id']);
  const response = await fetchJson(`${BASE}/api/on-board/interest/${userId}/`, {
    method: 'GET',
  });
  return response;
}

export async function saveAreasOfInterest(data) {
  const userId = tree.get(['authentication', 'session', 'user_id']);
  const response = await fetchJson(`${BASE}/api/on-board/interest/`, {
    method: 'POST',
    body: JSON.stringify({ ...data, user: userId }),
  });
  return response;
}

export async function updateAreasOfInterest(data) {
  const userId =
    data.user || tree.get(['authentication', 'session', 'user_id']);
  const response = await fetchJson(`${BASE}/api/on-board/interest/${userId}/`, {
    method: 'PATCH',
    body: JSON.stringify(data),
  });
  return response;
}

export async function getBusinessInfoDetails({ userId }) {
  const _userId = userId || tree.get(['authentication', 'session', 'user_id']);
  const response = await fetchJson(
    `${BASE}/api/on-board/business/${_userId}/`,
    {
      method: 'GET',
    }
  );
  return response;
}

export async function saveBusinessInfoDetails(data) {
  const userId =
    data.user || tree.get(['authentication', 'session', 'user_id']);
  const response = await fetchJson(`${BASE}/api/on-board/business/`, {
    method: 'POST',
    body: JSON.stringify({ ...data, user: userId }),
  });
  return response;
}

export async function updateBusinessInfoDetails(data, { userId }) {
  const _userId = userId || tree.get(['authentication', 'session', 'user_id']);
  const response = await fetchJson(
    `${BASE}/api/on-board/business/${_userId}/`,
    {
      method: 'PATCH',
      body: JSON.stringify(data),
    }
  );
  return response;
}

export async function getCategories({ userId }) {
  const _userId = userId || tree.get(['authentication', 'session', 'user_id']);
  const response = await fetchJson(
    `${BASE}/api/on-board/interest/${_userId}/`,
    {
      method: 'GET',
    }
  );
  return response;
}

export async function saveCategories(data) {
  const response = await fetchJson(`${BASE}/api/on-board/interest/`, {
    method: 'POST',
    body: JSON.stringify(data),
  });
  return response;
}

export async function updateCategories(data, { userId }) {
  const _userId = userId || tree.get(['authentication', 'session', 'user_id']);
  const response = await fetchJson(
    `${BASE}/api/on-board/interest/${_userId}/`,
    {
      method: 'PATCH',
      body: JSON.stringify(data),
    }
  );
  return response;
}
