import fetchJson from 'utility/fetchJson';
import { BASE } from '../../constants';

export async function getSellerItem(id) {
  const response = await fetchJson(`${BASE}/api/supply/item/${id}/`, {
    method: 'GET',
  });
  return response;
}

export async function saveSupplyItem(data) {
  const response = await fetchJson(`${BASE}/api/supply/item/`, {
    method: 'POST',
    body: JSON.stringify(data),
  });
  return response;
}

export async function updateSupplyItem(id, data) {
  const response = await fetchJson(`${BASE}/api/supply/item/${id}/`, {
    method: 'PATCH',
    body: JSON.stringify(data),
  });
  return response;
}

export async function deleteSupplyItem(id) {
  const response = await fetchJson(`${BASE}/api/supply/item/${id}/`, {
    method: 'DELETE',
  });
  return response;
}

export async function getSupplyGroup(id) {
  const response = await fetchJson(`${BASE}/api/supply/group/${id}/`, {
    method: 'GET',
  });
  return response;
}

export async function saveSupplyGroup(data) {
  const response = await fetchJson(`${BASE}/api/supply/group/`, {
    method: 'POST',
    body: JSON.stringify(data),
  });
  return response;
}

export async function updateSupplyGroup({ id, data }) {
  const response = await fetchJson(`${BASE}/api/supply/group/${id}/`, {
    method: 'PATCH',
    body: JSON.stringify(data),
  });
  return response;
}

export async function createSupplyGroup({ data }) {
  const response = await fetchJson(`${BASE}/api/supply/group/`, {
    method: 'POST',
    body: JSON.stringify(data),
  });
  return response;
}

export async function deleteSupplyGroup(id) {
  const response = await fetchJson(`${BASE}/api/supply/group/${id}/`, {
    method: 'DELETE',
  });
  return response;
}

export async function transitionSupply(supplyItemId, status, data) {
  const response = await fetchJson(
    `${BASE}/api/supply/item/${supplyItemId}/${status}/`,
    {
      method: 'POST',
      body: JSON.stringify({ json: JSON.stringify(data) }),
    }
  );
  return response;
}

export async function transitionSupplyGroup(supplyGroupId, data) {
  const response = await fetchJson(
    `${BASE}/api/supply/group/${supplyGroupId}/`,
    {
      method: 'PATCH',
      body: JSON.stringify(data),
    }
  );
  return response;
}

export async function createSimplesupplyInquery(data) {
  const response = await fetchJson(`${BASE}/api/p/simple-supply/`, {
    method: 'POST',
    body: JSON.stringify(data),
  });
  return response;
}

export async function getSimpleSupplyInqueryList() {
  const response = await fetchJson(`${BASE}/api/p/simple-supply/`, {
    method: 'GET',
  });
  return response;
}

export async function getSimpleSupplyInqueryItem(id) {
  const response = await fetchJson(`${BASE}/api/p/simple-supply/${id}/`, {
    method: 'GET',
  });
  return response;
}

export async function mergeSupplyGroup({ sourceSupplyId, targetSupplyId }) {
  const data = {
    source_opportunity_id: sourceSupplyId,
    target_opportunity_id: targetSupplyId,
  }
  const response = await fetchJson(
    `${BASE}/api/supply/group/merge/`,
    {
      method: 'POST',
      body: JSON.stringify(data),
    }
  );
  return response;
}

export async function splitSupplyGroup({ supplyGroupId, name, supplyItemIds }) {
  const data = {
    opportunity_id: supplyGroupId,
    name,
    opportunity_item_ids: supplyItemIds,
  };
  const response = await fetchJson(`${BASE}/api/supply/group/split/`, {
    method: 'POST',
    body: JSON.stringify(data),
  });
  return response;
}
