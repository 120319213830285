import { get, omit, reduce, replace, includes } from 'lodash';
import { valueExtractor, generateFilterValues } from './transformer';

export default function submitPreparer(data) {
  const {
    technical_details: technicalDetails,
    filters,
    technicalDetailsConfirmed,
  } = data;

  let newTechnicalDetails = technicalDetails || {};
  newTechnicalDetails = reduce(
    newTechnicalDetails.result,
    (accum, td, key) => {
      let currentKey = key;
      const currentAccum = accum;
      if (!td) {
        return accum;
      }

      if (key === 'length_range') {
        currentKey = 'lengths_min';
      }

      if (get(td.value, 'min')) {
        currentKey = replace(
          replace(replace(replace(key, 'min_', ''), 'max_', ''), '_min', ''),
          '_max',
          ''
        );
        currentAccum[`${currentKey}_min`] = valueExtractor(td.value).min; // returns object with value, min, max if applicabale
        currentAccum[`${currentKey}_max`] = valueExtractor(td.value).max; // returns object with value, min, max if applicabale
      } else {
        currentKey = includes(key, 'min_')
          ? `${replace(key, 'min_', '')}_min`
          : key; // rename keys for API
        currentKey = includes(key, 'max_')
          ? `${replace(key, 'max_', '')}_max`
          : key; // rename keys for API
        currentAccum[currentKey] = valueExtractor(td.value).value; // returns object with value, min, max if applicabale
      }
      return currentAccum;
    },
    {}
  );

  const generatedFilters = generateFilterValues(filters);

  const aa = {
    ...generatedFilters,
    ...omit(newTechnicalDetails, [
      'metallurgy',
      'lengths_min',
      'lengths_max',
      'length_range',
    ]),
    technical_details_confirmed: !!technicalDetailsConfirmed,
    item_number: 0, // TODO WHAT IS THIS
  };

  return aa;
}
