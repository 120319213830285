import { useState, useEffect, useRef } from 'react';
import { canUseDOM } from 'exenv';
import { debounce } from 'lodash';

function getSize() {
  if (canUseDOM) {
    return {
      innerHeight: window.innerHeight,
      innerWidth: window.innerWidth,
      outerHeight: window.outerHeight,
      outerWidth: window.outerWidth,
    };
  } else {
    return {};
  }
}

export default function useWindowSize() {
  const resizeRef = useRef();
  let [windowSize, setWindowSize] = useState(getSize());

  function handleResize() {
    setWindowSize(getSize());
  }

  useEffect(() => {
    resizeRef.current = debounce(handleResize, 50);
    window.addEventListener('resize', resizeRef.current);
    return () => {
      window.removeEventListener('resize', resizeRef.current);
    };
  }, []);

  return windowSize;
}
