import { isNumber, isString } from 'lodash';
import { variables } from 'theme/variables';

const {
  line_height_md: lineHeightMd,
  spacing_sm: spacingSm,
  spacing_md: spacingMd,
  spacing_lg: spacingLg,
  spacing_xl: spacingXl,
  spacing_xxl: spacingXxl,
  font_weight_regular: fontWeightRegular,
  font_weight_semibold: fontWeightSemibold,
  font_weight_medium: fontWeightMedium,
  spacing_xs: spacingXs,
  spacing_xs: spacingXxs,
  font_weight_bold: fontWeightBold,
  custom_grey: customGrey,
  custom_black: customBlack,
  custom_blue: customBlue,
  custom_orange: customOrange,
  color_white: colorWhite,
  color_red_400: colorRed400,
  custom_light_grey: customLightGrey,
  font_size: fontSize,
  custom_navy: customNavy,
  custom_slate: customSlate,
} = variables;

function getLinkColor(color, link) {
  if (color) {
    return color;
  }

  if (link === 'warning') {
    return customOrange;
  }
  if (link === 'error') {
    return colorRed400;
  }
  if (isString(link)) {
    return link;
  }
  return customBlue;
}

export function typographyBaseStyles(props, size) {
  let tt = '';
  if (
    props.overflowTip &&
    props.ellipsis &&
    (isString(props.children) || isNumber(props.children))
  ) {
    tt = props.children || '';
  }

  return `
    color: ${customBlack};
    ${
      props.center
        ? `
      text-align: center;
    `
        : ''
    }

    ${
      props.letterSpacing
        ? `
      letter-spacing: 1.3px;
    `
        : ''
    }

    ${
      props.right
        ? `
      text-align: right;
    `
        : ''
    }

    ${
      props.borderBottom
        ? `
      border-bottom: 1px solid ${customLightGrey};
    `
        : ''
    }

    ${
      props.paddingHorizontalSm
        ? `
      padding-left: ${spacingSm};
      padding-right: ${spacingSm};
    `
        : ''
    }

    ${
      props.paddingHorizontal
        ? `
      padding-left: ${spacingMd};
      padding-right: ${spacingMd};
    `
        : ''
    }

    ${
      props.paddingVertical
        ? `
      padding-top: ${spacingMd};
      padding-bottom: ${spacingMd};
      ${props.borderBottom ? `margin-bottom: ${spacingMd};` : ''}
    `
        : ''
    }

    ${
      props.paddingVerticalLg
        ? `
      padding-top: ${spacingLg};
      padding-bottom: ${spacingLg};
      ${props.borderBottom ? `margin-bottom: ${spacingLg};` : ''}
    `
        : ''
    }

    ${
      props.paddingTopXxs
        ? `
      padding-top: ${spacingXxs};
    `
        : ''
    }

    ${
      props.paddingTopXs
        ? `
      padding-top: ${spacingXs};
    `
        : ''
    }

    ${
      props.paddingTopSm
        ? `
      padding-top: ${spacingSm};
    `
        : ''
    }

     ${
       props.paddingTopMd
         ? `
      padding-top: ${spacingMd};
    `
         : ''
     }

    ${
      props.paddingTopLg
        ? `
      padding-top: ${spacingLg};
    `
        : ''
    }

    ${
      props.paddingTopXl
        ? `
      padding-top: ${spacingXl};
    `
        : ''
    }

    ${
      props.paddingTopXXl
        ? `
      padding-top: ${spacingXxl};
    `
        : ''
    }

    ${
      props.paddingBottomXxs
        ? `
      padding-bottom: ${spacingXxs};
      ${props.borderBottom ? `margin-bottom: ${spacingXs};` : ''}
    `
        : ''
    }

    ${
      props.paddingBottomXs
        ? `
      padding-bottom: ${spacingXs};
      ${props.borderBottom ? `margin-bottom: ${spacingSm};` : ''}
    `
        : ''
    }

    ${
      props.paddingBottomSm
        ? `
      padding-bottom: ${spacingSm};
      ${props.borderBottom ? `margin-bottom: ${spacingMd};` : ''}
    `
        : ''
    }

     ${
       props.paddingBottomMd
         ? `
      padding-bottom: ${spacingMd};
      ${props.borderBottom ? `margin-bottom: ${spacingLg};` : ''}
    `
         : ''
     }

    ${
      props.paddingBottomLg
        ? `
      padding-bottom: ${spacingLg};
      ${props.borderBottom ? `margin-bottom: ${spacingXl};` : ''}
    `
        : ''
    }

    ${
      props.paddingBottomXl
        ? `
      padding-bottom: ${spacingXl};
      ${props.borderBottom ? `margin-bottom: ${spacingXxl};` : ''}
    `
        : ''
    }

    ${
      props.paddingBottomXXl
        ? `
      padding-bottom: ${spacingXxl};
      ${props.borderBottomm ? `marging-bottom: ${spacingXxl};` : ''}
    `
        : ''
    }

    ${
      props.color
        ? `
      color: ${props.color};
    `
        : ''
    }

    ${
      props.grey
        ? `
      color: ${customGrey};
    `
        : ''
    }

    ${
      props.white
        ? `
      color: ${colorWhite};
    `
        : ''
    }

    ${
      props.blue
        ? `
      color: ${customBlue};
    `
        : ''
    }

    ${
      props.navy
        ? `
      color: ${customNavy};
    `
        : ''
    }

    ${
      props.slate
        ? `
      color: ${customSlate};
    `
        : ''
    }

    ${
      props.bold
        ? `
      font-weight: ${fontWeightSemibold};
    `
        : ''
    }

    ${
      props.semibold
        ? `
      font-weight: ${fontWeightMedium};
    `
        : ''
    }

    ${
      props.black
        ? `
      font-weight: ${fontWeightBold};
    `
        : ''
    }

     ${
       props.light
         ? `
      font-weight: ${fontWeightRegular};
    `
         : ''
     }

    ${
      props.uppercase
        ? `
      text-transform: uppercase;
    `
        : ''
    }

    ${
      props.capitalize
        ? `
      text-transform: capitalize;
    `
        : ''
    }

    ${
      props.italic
        ? `
      font-style: italic;
    `
        : ''
    }

    ${
      props.alignCenter
        ? `
      display: flex !important;
      align-items: center !important;
    `
        : ''
    }

    ${
      props.justifyEnd
        ? `
      display: flex !important;
      justify-content: flex-end !important;
    `
        : ''
    }

    ${
      props.justifyStart
        ? `
      display: flex !important;
      justify-content: flex-start !important;
    `
        : ''
    }

    ${
      props.xs
        ? `
      font-size: 12px !important;
      line-height: 20px;
    `
        : ''
    }

    ${
      size === 'SM' && props.paragraph
        ? `
      line-height: ${lineHeightMd} !important;
    `
        : ''
    }

    ${
      size === 'MD' && props.scaled
        ? `
      font-size: ${fontSize} !important;
      line-height: ${'25px'} !important;
    `
        : ''
    }

    ${
      size === 'MD'
        ? `
      line-height: ${'22px'} !important;
    `
        : ''
    }

     ${
       size === 'LG'
         ? `
      line-height: ${'28px'} !important;
    `
         : ''
     }

    ${
      props.lhInherit
        ? `
      line-height: inherit !important;
    `
        : ''
    }

    ${props.allowNewline ? `white-space: pre-wrap;` : ''}

    ${
      props.ellipsis
        ? `
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      min-width: 0;
      ${
        props.overflowTip
          ? `
        &:after {
          content:'';
          opacity: 0;
          transition: all 0.1s 0s ease;
        }
        &:hover:after {
          transition: all 0.1s 800ms ease;
          content:'${tt}';
          opacity: 1;
          overflow: visible;
          text-overflow: inherit;
          background: #fff;
          position: absolute;
          left:auto;
          top:auto;
          width: auto;
          max-width: 20rem;
          border: 1px solid #eaebec;
          border-radius: 2px;
          padding: 15px;
          box-shadow: 0 2px 4px 0 rgba(0,0,0,0.08);
          white-space: normal;
          word-wrap: break-word;
          display:block;
          color:black;
          margin-top:-1.25rem;
          z-index: 22;
        }
      `
          : ''
      }
    `
        : ''
    }

    ${
      props.headline
        ? `
      line-height: 1.5em !important;
    `
        : ''
    }

    ${
      props.subheadline
        ? `
      line-height: 2em !important;
    `
        : ''
    }

    ${
      props.pointer
        ? `
      cursor: pointer !important;
    `
        : ''
    }

    ${
      props.tab
        ? `
      letter-spacing: 0.3px;
      &:hover {
        color: ${customBlue};
      }
      ${
        props.active
          ? `
        color: ${customBlue};
        font-weight: ${fontWeightSemibold};
        letter-spacing: 0;
      `
          : ''
      }

    `
        : ''
    }

      ${
        props.link
          ? `
      color: ${getLinkColor(props.color, props.link)};
        cursor: pointer;
        transition: opacity 0.2s ease;
        opacity: 1;
        &:hover {
          opacity: 0.7;
        }
    `
          : ''
      }

    ${
      props.hover
        ? `
      cursor: pointer;
      transition: opacity 0.2s ease;
      opacity: 1;
      &:hover {
        opacity: 0.7;
      }
    `
        : ''
    }

    ${
      props.error
        ? `
      color: ${colorRed400} !important;
    `
        : ''
    }

    ${
      props.fieldLabel || props.fieldLabelPrefix || props.fieldLabelSuffix
        ? `
      position: absolute;
      right: ${props.fieldLabelPrefix ? 'auto' : '0px'};
      left: ${props.fieldLabelPrefix ? '0px' : 'auto'};
      display: inline;
      top: 50%;
      color: #ccc;
      margin-top: -8px;
    `
        : ''
    }

    span.light {
      color: ${customGrey};
      font-weight: ${fontWeightRegular};
    }
    span.slate {
      color: ${customSlate};
      font-weight: ${fontWeightRegular};
    }
  `;
}

const TypographyTheme = {
  'typography.xxxl': (props) => {
    const styles = typographyBaseStyles(props, 'XXXL');
    return `
      ${styles}
    `;
  },
  'typography.xxl': (props) => {
    const styles = typographyBaseStyles(props, 'XXL');
    return `
      ${styles}
    `;
  },
  'typography.xl': (props) => {
    const styles = typographyBaseStyles(props, 'XL');
    return `
      ${styles}
    `;
  },
  'typography.lg': (props) => {
    const styles = typographyBaseStyles(props, 'LG');
    return `

      ${styles}
    `;
  },
  'typography.md': (props) => {
    const styles = typographyBaseStyles(props, 'MD');
    return `
      ${styles}
    `;
  },
  'typography.sm': (props) => {
    const styles = typographyBaseStyles(props, 'SM');
    return `
      ${styles}
    `;
  },
};

export default TypographyTheme;
