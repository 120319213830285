import React from 'react';
import PropTypes from 'prop-types';
import { closeModal } from 'state/modals/actions';
import Icon from 'components/Icon/Icon';
import { XL } from '@zendeskgarden/react-typography';
import styled from 'styled-components/macro';
import Flex from 'styled-flex-component';
import PageViewWrapper from 'higherOrder/PageViewWrapper';
import FieldInspectionCreationForm from 'components/BasicForms/FieldInspectionCreationForm';
import { getSupplyInterestDetails } from 'state/supplyInterests/actions';

export const Wrapper = styled.section`
  width: 500px;
  max-width: 90%;
  .wrapper {
    width: 100%;
    @media (max-width: 600px) {
      padding: 30px 15px !important;
    }
  }
  form {
    width: 100%;
    button {
      margin-bottom: 0 !important;
      width: 100%;
    }
  }
  .email-link {
    padding: 10px;
    background: #eef0f3;
    text-align: center;
    width: 100%;
    border-bottom-right-radius: 6px;
    border-bottom-left-radius: 6px;
  }
  .icon-close {
    position: absolute;
    top: 5px;
    right: 5px;
  }
`;

function FieldInspectionModal({ styles, supplyId }) {
  function modalClose() {
    closeModal();
    getSupplyInterestDetails(supplyId);
  }

  return (
    <Wrapper
      style={{
        margin: '0 auto',
        maxWidth: '800px',
        ...(styles || { margin: '0 auto' }),
      }}
    >
      <Icon
        onClick={closeModal}
        buttonStyle
        icon="icon-close"
        fontSize="10px"
      />
      <Flex
        className="wrapper"
        column
        alignCenter
        justifyBetween
        style={{ padding: ' 30px' }}
      >
        <XL center bold>
          Create A Field Inspection
        </XL>
        <FieldInspectionCreationForm
          onDone={() => modalClose()}
          data={{ supplyId }}
        />
      </Flex>
    </Wrapper>
  );
}

FieldInspectionModal.propTypes = {
  styles: PropTypes.shape({
    background: PropTypes.string,
    bottom: PropTypes.string,
    boxShadow: PropTypes.string,
    left: PropTypes.string,
    position: PropTypes.string,
    right: PropTypes.string,
    top: PropTypes.string,
  }),
  supplyId: PropTypes.number,
};

export default PageViewWrapper(FieldInspectionModal, {
  pageType: 'modal',
  pageName: 'FieldInspectionCreationModal',
});
