import { variables } from 'theme/variables';
import styled from 'styled-components/macro';
import { isString } from 'lodash';

const {
  spacing_sm: spacingSm,
  line_height_xxxl: lineHeightXXXL,
  height_xxl: heightXXL,
  raisedValue,
  custom_blue: customBlue,
  custom_blue_hover: customBlueHover,
  font_weight_semibold: fontWeightSemibold,
  spacing,
  color_white: colorWhite,
  custom_black: customBlack,
  spacing_lg: spacingLg,
  color_red_400: colorRed400,
  custom_lime: customLime,
  custom_navy: customNavy,
  custom_grey: customGrey,
  custom_light_grey: customLightGrey,
  custom_lighter_grey: customLighterGrey,
  raised_card_value: raisedCardValue,
  font_size: fontSize,
  custom_button_blue: customButtonBlue,
  public_font_size_md: publicFontSizeMd,
  public_font_weight_bold: publicFontWeightBold,
  border_radius: borderRadius,
  custom_button_hover: customButtonHover,
  custom_slate: customSlate,
  custom_red: customRed,
} = variables;

function buttonBaseStyles(props) {
  return `
    *:not(*[class*="icon-"]), *:not(".anchor-child") {
      ${
        !props.mini
          ? `
        line-height: ${
          props.ignoreLineHeight ? 'inherit' : props.lineHeight || '38px'
        } !important;
      `
          : ''
      }
      ${
        props.size === 'large'
          ? `
        line-height: ${props.lineHeight || '46px'} !important;
      `
          : ''
      }
    }
    
    ${
      props.center
        ? `
      display: block;
      margin: 0 auto;
    `
        : ''
    }

    ${
      props.spacingLeft
        ? `
      margin-left: ${spacing}
    `
        : ''
    }

    ${
      props.spacingRight
        ? `
      margin-right: ${spacing}
    `
        : ''
    }

    ${
      props.spacingBottom
        ? `
      margin-bottom: ${spacing}
    `
        : ''
    }

    ${
      props.spacingBottomLg
        ? `
      margin-bottom: ${spacingLg}
    `
        : ''
    }

    ${
      props.spacingLeftSm
        ? `
      margin-left: ${spacingSm}
    `
        : ''
    }

    ${
      props.spacingRightSm
        ? `
      margin-right: ${spacingSm}
    `
        : ''
    }

    ${
      props.spacingBottomSm
        ? `
      margin-bottom: ${spacingSm}
    `
        : ''
    }

    ${
      props.spacingTopSm
        ? `
      margin-top: ${spacingSm}
    `
        : ''
    }

    ${
      props.spacingTop
        ? `
      margin-top: ${spacing}
    `
        : ''
    }

    ${
      props.alignCenter
        ? `
      display: flex !important;
      align-items: center !important;
    `
        : ''
    }

    ${
      props.justifyCenter
        ? `
      display: flex !important;
      justify-content: center !important;
    `
        : ''
    }

    ${
      props.justifyEnd
        ? `
      display: flex !important;
      justify-content: flex-end !important;
    `
        : ''
    }

    ${
      props.justifyStart
        ? `
      display: flex !important;
      justify-content: flex-start !important;
    `
        : ''
    }

    ${
      props.full
        ? `
      width: 100% !important;
    `
        : ''
    }

    ${
      props.black
        ? `
      color: ${customBlack} !important;
    `
        : ''
    }

    ${
      props.color
        ? `
      color: ${props.color} !important;
    `
        : ''
    }

    ${
      props.bold
        ? `
      font-weight: 500 !important;
    `
        : ''
    }

    ${
      props.semiBold
        ? `
      font-weight: ${fontWeightSemibold} !important;
    `
        : ''
    }

    ${
      props.disableBackground
        ? `
      background-color: transparent !important;
      border-color: transparent !important;
    `
        : ''
    }

    ${
      props.uppercase
        ? `
      text-transform: uppercase !important;
    `
        : ''
    }

    ${
      props.square
        ? `
      border-radius: 3px !important;
      min-width: 40px;
      ${
        props.small
          ? `
        min-width: 32px;
        min-height: 32px;
        max-width: 32px;
        max-height: 32px;
      `
          : ''
      }
    `
        : ''
    }

    ${
      props.underline
        ? `
      border-bottom: 2px solid transparent !important;
      text-decoration: none !important;
      padding-bottom: 3px !important;
      ${
        props.active ? `border-bottom: 2px solid ${customBlue} !important;` : ''
      }
    `
        : ''
    }

    ${
      (props.color || props.blue) && props.link
        ? `
      transition: all 0.3s ease;
      color: ${props.color || customBlue} !important;
      * {
        color: ${props.color || customBlue} !important;
      }
      &:hover {
        opacity: 0.6;
        text-decoration: none !important;
      }
      ${
        props.semibold
          ? `
        font-weight: ${fontWeightSemibold} !important;
      `
          : ''
      }

      ${
        props.disabled
          ? `
        opacity: 1 !important;
        background-color: transparent !important;
        color: ${customSlate} !important;
        cursor: not-allowed;
        * {
          cursor: not-allowed;
          background-color: transparent !important;
          color: ${customSlate} !important;
        }
      `
          : ''
      }
    `
        : ''
    }

    ${
      props.whiteButtonLink
        ? `
      position: relative;
      background-color: ${
        !props.inactive
          ? `${colorWhite} !important;`
          : `transparent !important;`
      } 
      border: ${
        !props.inactive && !props.transparentBorder
          ? '1px solid #eeeeee !important;'
          : '1px solid transparent !important;'
      } 
      overflow: visible !important;
      outline: none;
      box-shadow: none !important;
      &:hover {
        background-color: ${
          props.active ? `${colorWhite} !important;` : '#fff !important;'
        } 
      }
    `
        : ''
    }

    

    ${
      props.menu
        ? `
      padding: 0 ${spacingSm} !important;
      min-width: 0 !important;
    `
        : ''
    }

    ${
      props.xl
        ? `
      height: ${lineHeightXXXL} !important;
      ${
        props.square
          ? `
        min-width: ${lineHeightXXXL} !important;
      `
          : ''
      }
    `
        : ''
    }

    ${
      props.xxl
        ? `
      height: ${heightXXL} !important;
      min-width: 200px !important;
    `
        : ''
    }

    ${
      props.mini
        ? `
        min-width: 28px;
        max-height: 28px;
        background: rgb(255,255,255) !important;
        color: ${customBlue};
        line-height: 25px;
        padding: 0 10px;
        font-size: 13px;
        * {
          line-height: 25px !important;
        }
        [class*="icon-"] {
          font-size: 11px !important;
        }
    `
        : ''
    }

    ${
      props.miniTableIcon
        ? `
      min-width: 28px;
      max-height: 22px;
      line-height: 22px;
      padding: 0 8px;
      font-size: 12px;
      * {
        line-height: 22px !important;
      }
    `
        : ''
    }

    ${
      props.raised
        ? `
      box-shadow: ${
        isString(props.raised) ? props.raised : raisedValue
      } !important;
    `
        : ''
    }

    ${
      props.primary
        ? `
        -webkit-font-smoothing: antialiased !important;
        background: ${customBlue} !important;
        [class*="icon-"] {
          color: ${colorWhite} !important;
        }
        &:hover {
          background: ${customBlueHover} !important
        }
        * {
          font-weight: 500;
        }
        a, a:hover {
          color: #fff !important;
        }
        ${
          !props.buttonState
            ? `
            &:disabled {
              background: ${customLightGrey} !important
              color: ${customGrey};
              border-color: ${customLightGrey}     
            }
          `
            : ''
        }
    `
        : ''
    }

    ${
      props.primaryWarning
        ? `
      background: ${colorRed400} !important;
      border-color: ${colorRed400} !important;
      color: #fff !important;
      [class*="icon-"] {
        color: ${colorWhite} !important;
      }
      &:hover {
        background: ${colorRed400} !important
      }
    `
        : ''
    }

    ${
      props.primaryWarningOutline
        ? `
        -webkit-font-smoothing: antialiased !important;
        background: ${colorWhite} !important;
        border: 1px solid ${colorRed400} !important;
        color: ${colorRed400} !important;
        [class*="icon-"] {
          color: ${colorRed400} !important;
        }
        &:hover {
          ${
            props.redHover
              ? `
              background: ${colorWhite} !important;
              color: ${customRed} !important;
              box-shadow: ${
                isString(props.raisedHover) ? props.raisedHover : raisedValue
              } !important;
              [class*="icon-"] {
                color: ${customRed} !important;
              }
            `
              : `
              background: ${customRed} !important;
              color: ${colorWhite} !important;
              [class*="icon-"] {
                color: ${colorWhite} !important;
              }
            `
          }
        }
        &:disabled {
          background: ${customLightGrey} !important
          color: ${customGrey};
          border-color: ${customLightGrey}     
        }
    `
        : ''
    }

    ${
      props.primaryOutline
        ? `
      -webkit-font-smoothing: antialiased !important;
      background: ${colorWhite} !important;
      border: 1px solid ${customBlue} !important;
      color: ${customBlue} !important;
      [class*="icon-"] {
        color: ${customBlue} !important;
      }
      &:hover {
        ${
          props.blueHover
            ? `
            background: ${colorWhite} !important;
            color: ${customBlueHover} !important;
            box-shadow: ${
              isString(props.raisedHover) ? props.raisedHover : raisedValue
            } !important;
            [class*="icon-"] {
              color: ${customBlueHover} !important;
            }
          `
            : `
            background: ${customBlueHover} !important;
            color: ${colorWhite} !important;
            [class*="icon-"] {
              color: ${colorWhite} !important;
            }
          `
        }
      }
      &:disabled {
        background: ${customLightGrey} !important
        color: ${customGrey};
        border-color: ${customLightGrey}     
      }
    `
        : ''
    }

    ${
      props.secondary
        ? `
        background: #d4dded !important;
        border: 1px solid #d4dded;
        color: ${customNavy} !important;
        [class*="icon-"] {
          color: ${customNavy} !important;
        }
        &:hover {
          background: ${customBlueHover} !important;
          color: ${colorWhite} !important;
          [class*="icon-"] {
            color: ${colorWhite} !important;
          }
        }
        &:disabled {
          background: #d4dded !important;
          color: ${customNavy} !important;
        }
    `
        : ''
    }

    ${
      props.whiteOutline
        ? `
      position: relative;
      background-color: transparent !important; 
      border: 1px solid ${props.borderColor || colorWhite} !important;
      color: ${props.color || colorWhite} !important;
      outline: none;
      box-shadow: none !important;
      [class*="icon-"] {
        color: ${props.color || colorWhite} !important;
      }
      ${
        props.disabled
          ? `
        cursor: default;
        opacity: 0.6 !important;
      `
          : ''
      }
      ${
        !props.disabled
          ? `
        &:hover {
          background-color: ${props.hoverColor || customBlue} !important;
          border: 1px solid ${props.hoverColor || customBlue} !important;
          color: ${props.hoverTextColor || colorWhite} !important;
        }
      `
          : ''
      }
      &:active {
        background-color: ${props.hoverColor || customBlue} !important;
        border: 1px solid ${props.hoverColor || customBlue} !important;
        color: ${props.hoverTextColor || colorWhite} !important;
        opacity: 0.8;
      }
      &:focus {
        border: 1px solid ${props.hoverColor || customBlue} !important;
      }
    `
        : ''
    }

    ${
      props.white
        ? `
      background: ${colorWhite} !important;
      color: ${customNavy}
      border: 1px solid ${customLightGrey} !important;
      &:hover {
        border: 1px solid ${customLightGrey} !important;
        color: ${customNavy} !important;
        background: ${customLightGrey} !important
      }
      &:disabled {
        border: 1px solid ${customLightGrey} !important;
        color: ${customGrey} !important;
        background: ${customLightGrey} !important
      }
      &:active, &:focus {
        border: 1px solid ${customLightGrey} !important;
        color: ${customGrey} !important;
        background: ${customLightGrey} !important
      }

    `
        : ''
    }

    ${
      props.navy
        ? `
      background-color: ${customNavy} !important;
      color: ${colorWhite} !important;
      border: 1px solid  ${colorWhite} !important;
      [class*="icon-"] {
        color: ${colorWhite} !important;
      }
      &:hover {
        color: #fff !important;
        background:  #0052c1 !important
      }
      &:disabled {
        border: 1px solid #0052c1 !important;
        color: ${colorWhite} !important;
        background: #0052c1 !important
        opacity: 0.3;
      }
    `
        : ''
    }


    ${
      props.marginTop
        ? `
      margin-top: ${props.marginTop} !important;
    `
        : ''
    }


    ${
      props.rowButton
        ? `
      padding: 20px;
      position: relative;
      background-color: transparent !important; 
      border: 1px solid ${customLighterGrey} !important;
      height: 80px;
      font-size: ${fontSize} !important;
      outline: none;
      box-shadow: none !important;
      min-width: 100%;
      box-shadow: ${raisedCardValue} !important;
      color: ${customBlue} !important;
      [class*="icon-"] {
        color: ${customBlue} !important;
        font-size: 17px !important;
      }
      &:hover {
        background-color: ${customBlue} !important;
        border: 1px solid ${customBlue} !important;
        color: ${colorWhite} !important;
        [class*="icon-"] {
          color: ${colorWhite} !important;
        }
      }
    `
        : ''
    }


     ${
       props.publicButton
         ? `
      padding: 20px 60px;
      border-radius: 8px;
      background-color: ${customButtonBlue};
      box-shadow: ${
        !props.noShadow ? '0 15px 30px -12px rgba(0,0,0,0.5)' : 'none'
      };
      color: ${colorWhite} !important;
      border: 0;
      outline: none;
      font-size: ${publicFontSizeMd};
      font-weight: ${publicFontWeightBold};
      position: relative;
      &&& div, &&& anchor-child {
        line-height: 20px !important;
      }
      div[class^="flex-"], anchor-child {
        transition: transform 0.3s ease-out;
      }
      .icon-arrow-right-short  {
        position: absolute;
        transform: translateX(-50px);
        opacity: 0;
        top: 0;
        bottom: 0;
        margin: auto 0;
        display: block;
        height: 14px;
        font-size: ${publicFontSizeMd};
        transition: transform 0.3s ease-out, opacity 0.1s ease-out;
      }
      ${
        !props.loading
          ? `
        &&&&:hover:not(:disabled) {
          background-color: ${customButtonHover};
          div[class^="flex-"], .anchor-child {
            transform: translateX(20px);
          }
          .icon-arrow-right-short {
            transform: translateX(-20px);
            opacity: 1;
          }
        }`
          : ''
      }
    `
         : ''
     }

    ${
      props.animated === false
        ? `
      opacity: 0;
      transform: translateY(40px);
    `
        : ''
    }

    ${
      props.headerButton
        ? `
      padding: 20px 24px;
      border-radius: ${borderRadius};	
      background-color: ${colorWhite};	
      box-shadow: 0 15px 15px -12px rgba(13,43,83,0.5);
      color: ${customButtonBlue};
      border: 0;
      outline: none;
      font-size: ${publicFontSizeMd};
      font-weight: ${publicFontWeightBold};
      transition: all 0.3s linear;
      &&& div {
        line-height: 18px !important;
      }
      &&&:hover {
        background-color: ${customButtonHover};
        color: ${colorWhite} !important;
      }
      
      margin-left: 16px;
    `
        : ''
    }

    ${
      props.groupButton
        ? `
      border: 1px solid #dee5ed;
      color: ${customBlack} !important;
      background: ${colorWhite} !important;
      box-shadow: none;
      &:hover {
        background: #f0f6ff !important;
        color: ${customBlue} !important;
      }
      ${
        props.selected
          ? `
        box-shadow: 0 0px 4px rgb(222, 231, 245) inset;
        background: ${customBlue} !important;
        color: ${'#FFF'} !important;
        border-color: unset !important;
        [class*="icon-"] {
          color: ${'#FFF'} !important;
        }
        &:hover {
          background: #f0f6ff !important;
          border-color: transparent !important;
          color: ${customBlack} !important;
        }
      `
          : ''
      }
    `
        : ''
    }

    ${
      props.buttonState === 'isSuccess'
        ? `
      background: ${customLime} !important;
      [class*="icon-"] {
        color: ${colorWhite} !important;
      }
      &:hover {
        background: ${customLime} !important
      }
    `
        : ''
    }

    ${
      props.buttonState === 'isError'
        ? `
      background: ${colorRed400} !important;
      [class*="icon-"] {
        color: ${colorWhite} !important;
      }
      &:hover {
        background: ${colorRed400} !important
      }
    `
        : ''
    }


    ${
      props.border
        ? `
      border: ${props.border} !important;
    `
        : ''
    }


    ${
      props.pairButton
        ? `
        @media (max-width: 576px) {
         margin: 0;
         margin-bottom: 20px;
         min-width: 100%;
         ${
           props.last
             ? `
           margin-bottom: 0px;
         `
             : ''
         }
       }
    `
        : ''
    }

    ${
      props.extraLarge
        ? `
      font-size: 16px;
    `
        : ''
    }
   
  `;
}

export const LinkButton = styled.div`
  text-decoration: none !important;
  display: inline-block;
  position: relative;
  a {
    color: inherit !important;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
  ${(props) => `${buttonBaseStyles(props)}`}
`;

const ButtonTheme = {
  'buttons.icon_button': (props) =>
    `
      ${buttonBaseStyles(props, 'icon')}
    `,
  'buttons.button': (props) =>
    `
      ${buttonBaseStyles(props)}
    `,
  'buttons.anchor': (props) =>
    `
      text-decoration: none !important;
      display: inline-block;
      ${buttonBaseStyles(props)}
    `,
};

export default ButtonTheme;
