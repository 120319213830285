import React from 'react';
import PropTypes from 'prop-types';
import { map, sortBy, noop, replace } from 'lodash';
import { variables } from 'theme/variables';
import {
  CheckBoxWrapper,
  CheckboxFoaxInput,
  CheckboxList as CheckBoxListStyled,
  CheckboxListWrapper,
  CheckboxListFooter,
} from './Checkbox.styles';

const { custom_grey: customGrey, color_red_400: colorRed400 } = variables;

function Checkbox({
  checked,
  onChange,
  // Passing the className prop to children allows wrapping Styled Components to extend the styles of this component
  className,
}) {
  return (
    <CheckBoxWrapper
      checked={checked}
      onChange={onChange}
      className={!checked ? className : `${className} icon-checkbox-checked`}
    >
      <input
        onChange={onChange || noop}
        checked={checked || false}
        type="checkbox"
      />
    </CheckBoxWrapper>
  );
}

Checkbox.propTypes = {
  checked: PropTypes.oneOfType([PropTypes.bool, PropTypes.shape({})]),
  onChange: PropTypes.func,
  className: PropTypes.string,
};

export default Checkbox;

export function FormCheckbox({
  style,
  border,
  type,
  loading,
  id,
  onChange,
  name,
  checked,
  children,
  className,
  uncheckedBackground,
  checkedBackground,
  checkmarkColor,
  labelColor,
  disabled,
}) {
  return (
    <CheckboxFoaxInput
      style={style || {}}
      small
      border={border}
      type={type || 'checkbox'}
      loadingData={loading && loading === id}
      // We have to pass className explicitly to received extended styles from parent Styled Components
      className={className}
      uncheckedBackground={uncheckedBackground}
      checkedBackground={checkedBackground}
      checkmarkColor={checkmarkColor}
      labelColor={labelColor}
    >
      <input
        onChange={onChange}
        id={name}
        name={name}
        checked={!!checked}
        type={type || 'checkbox'}
        disabled={disabled}
      />
      <label htmlFor={name}>{children}</label>
    </CheckboxFoaxInput>
  );
}

FormCheckbox.propTypes = {
  // LINT OVERRIDE #6
  // Object has undetermined keys
  // eslint-disable-next-line react/forbid-prop-types
  style: PropTypes.object,
  border: PropTypes.bool,
  type: PropTypes.string,
  loading: PropTypes.number,
  id: PropTypes.number,
  onChange: PropTypes.func,
  name: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  checked: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.shape({}),
    PropTypes.string,
  ]),
  className: PropTypes.string,
  uncheckedBackground: PropTypes.string,
  checkedBackground: PropTypes.string,
  checkmarkColor: PropTypes.string,
  labelColor: PropTypes.string,
  disabled: PropTypes.bool,
};

export function CheckboxList({ onChange, selected, items, height, footer }) {
  function onChangeCheckbox(item, e) {
    onChange(item, e.currentTarget.checked);
  }
  return (
    <CheckboxListWrapper>
      <CheckBoxListStyled className="checkbox-list" height={height}>
        {map(sortBy(items, 'label'), (item, i) => {
          const checked =
            selected && (selected[item.value] || selected[`__${item.value}`]); // __ is for when a number is the key and we prefix it with __ - FILTER SIDE BAR

          const isMissing = item.label === 'AA_Missing';
          const label = isMissing
            ? replace(item.label, 'AA_', '')
            : item.label; /* Hack to sort properly TODO */
          return (
            <FormCheckbox
              style={{ marginBottom: '5px' }}
              onChange={(e) => onChangeCheckbox(item, e)}
              key={`${item.value}-${i}-${item.key}-${item.type}`}
              name={`${item.value}-${i}-${item.key}-${item.type}`}
              checked={checked}
            >
              <span
                style={
                  isMissing ? { color: colorRed400, fontWeight: 'bold' } : {}
                }
              >
                {label}
              </span>
              {item.count ? (
                <span style={{ paddingLeft: '3px', color: customGrey }}>
                  {' '}
                  ({item.count})
                </span>
              ) : null}
            </FormCheckbox>
          );
        })}
      </CheckBoxListStyled>

      {footer ? <CheckboxListFooter>Show more</CheckboxListFooter> : null}
    </CheckboxListWrapper>
  );
}

CheckboxList.propTypes = {
  onChange: PropTypes.func,
  selected: PropTypes.objectOf(PropTypes.bool),
  items: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      label: PropTypes.string,
      key: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      type: PropTypes.string,
      count: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    })
  ),
  height: PropTypes.string,
  footer: PropTypes.bool,
};
