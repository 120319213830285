import { variables } from 'theme/variables';
const { font_weight_semibold, spacing_sm } = variables;

const MenuTheme = {
  'menus.header_item': (props) => {
    return `
      padding: ${'10px'} !important;
      &:after {
        display: none;
      }
    `;
  },

  'menus.item': (props) => {
    return `
      ${
        !props.defaultSpacing
          ? `
        padding: ${spacing_sm} !important;
      `
          : ''
      }
      ${
        props.bold
          ? `
        font-weight: ${font_weight_semibold};
      `
          : ''
      }
      &:after {
        display: none;
      }
    `;
  },

  'menus.menu_view': (props) => {
    return `
      ${
        props.arrowRightOffset
          ? `
        &:before, &:after {
          right: ${props.arrowRightOffset} !important;
        }        
      `
          : ''
      }
    `;
  },
};

export default MenuTheme;
