import {
  pickBy,
  identity,
  has,
  get,
  isString,
  isObject,
  omit,
  isEmpty,
  isArray,
} from 'lodash';
import tree from 'state';
import { calculateWall } from 'utility/math';

/*
  ARGUMENTS

  id (int?): Optional IF passing a custom path
  path (array of strings): Optional. Overrides default path

*/
export function onUpdate({ id, type, branch, currentCursor, path }) {
  // this gets called right after a tree update
  // YOU CANNNOT UPDATE ONLY VALUE - REACT MEMO + NON PERSISTANT SETTING in baobab does not pick up that the value alone changed due to shallow only comparison. NEED TO UPDATE THE WHOLE OBJ
  if (branch === 'filters') {
    currentCursor = currentCursor || tree.select(['requestForQuote']);
    const filters = path
      ? currentCursor.get(path)
      : currentCursor.get(['itemSelection', id, 'filters']);
    if (type === 'od' || type === 'inside_diameter' || type === 'wall') {
      onUpdateOdId({ id, type, branch, filters, currentCursor, path });
    }
  }
}

function formatValue(v) {
  return v && isString(v) ? v.replace(`"`, '').replace(`-`, '') : v;
}

function onUpdateOdId({ filters, id, type, currentCursor, path }) {
  const wallPointer = filters.wall;
  const odPointer = filters.od;
  const idPointer = filters.inside_diameter;
  const od = valueAccessor(odPointer.value);
  const inside_diameter = valueAccessor(idPointer.value);
  const wall = valueAccessor(filters.wall.value);

  const idValue = formatValue(inside_diameter.value);
  const odValue = formatValue(od.value);
  const wallValue = formatValue(wall.value);
  const idMin = formatValue(inside_diameter.min);
  const idMax = formatValue(inside_diameter.max);
  const odMin = formatValue(od.min);
  const odMax = formatValue(od.max);
  const wallMin = formatValue(wall.min);
  const wallMax = formatValue(wall.max);

  const hasId = idValue || (idMin && idMax);
  const hasOd = odValue || (odMin && odMax);
  const hasWall = wallValue || (wallMin && wallMax);
  const hasAll = hasOd && hasId && hasWall;

  let activePointer = '';
  let solveFor = '';
  const solveForId =
    (!hasId && hasOd && hasWall) ||
    (hasAll && (type === 'wall' || type === 'od'));
  const solveForOd =
    (!hasOd && hasId && hasWall) ||
    (hasAll && (type === 'wall' || type === 'id'));

  if (solveForId && type !== 'inside_diameter') {
    solveFor = 'inside_diameter';
    activePointer = idPointer;
  } else if (solveForOd && type !== 'od') {
    solveFor = 'od';
    activePointer = odPointer;
  } else if (hasId && hasOd && type !== 'wall') {
    solveFor = 'wall';
    activePointer = wallPointer;
  } else {
    /////////
    console.error('WALL - NOT SOLVING FOR ANYTHING');
    return;
  }

  let updateObj = {
    operator: { label: 'Equals', value: 'equals' },
  };

  if (solveFor === 'wall') {
    if (idMin && idMax && odMin && odMax) {
      updateObj.operator = { label: 'Range', value: 'range' };
      updateObj.value = {
        min: { value: calculateWall({ id: idMin, od: odMin }) },
        max: { value: calculateWall({ id: idMax, od: odMax }) },
      };
    } else if (idMin && idMax && odValue) {
      updateObj.operator = { label: 'Range', value: 'range' };
      updateObj.value = {
        min: { value: calculateWall({ id: idMin, od: odValue }) },
        max: { value: calculateWall({ id: idMax, od: odValue }) },
      };
    } else if (odMin && odMax && idValue) {
      updateObj.operator = { label: 'Range', value: 'range' };
      updateObj.value = {
        min: { value: calculateWall({ id: idValue, od: odMin }) },
        max: { value: calculateWall({ id: idValue, od: odMax }) },
      };
    } else if (idValue && odValue) {
      let wallCalc = calculateWall({ id: idValue, od: odValue });
      updateObj.value = { value: wallCalc };
    }
  }

  if (solveFor === 'od') {
    if (idMin && idMax && wallMin && wallMax) {
      updateObj.operator = { label: 'Range', value: 'range' };
      updateObj.value = {
        min: { value: calculateWall({ id: idMin, wall: wallMin }) },
        max: { value: calculateWall({ id: idMax, wall: wallMax }) },
      };
    } else if (idMin && idMax && wallValue) {
      updateObj.operator = { label: 'Range', value: 'range' };
      updateObj.value = {
        min: { value: calculateWall({ id: idMin, wall: wallValue }) },
        max: { value: calculateWall({ id: idMax, wall: wallValue }) },
      };
    } else if (wallMin && wallMax && idValue) {
      updateObj.operator = { label: 'Range', value: 'range' };
      updateObj.value = {
        min: { value: calculateWall({ id: idValue, wall: wallMin }) },
        max: { value: calculateWall({ id: idValue, wall: wallMax }) },
      };
    } else if (idValue && wallValue) {
      let wallCalc = calculateWall({ id: idValue, wall: wallValue });
      updateObj.value = {
        value: wallCalc,
        label: wallCalc,
        rangeFilterValue: Number(wallCalc),
      };
    }
  }

  if (solveFor === 'inside_diameter') {
    if (odMin && odMax && wallMin && wallMax) {
      updateObj.operator = { label: 'Range', value: 'range' };
      updateObj.value = {
        min: { value: calculateWall({ od: odMin, wall: wallMin }) },
        max: { value: calculateWall({ od: odMax, wall: wallMax }) },
      };
    } else if (odMin && odMax && wallValue) {
      updateObj.operator = { label: 'Range', value: 'range' };
      updateObj.value = {
        min: { value: calculateWall({ od: odMin, wall: wallValue }) },
        max: { value: calculateWall({ od: odMax, wall: wallValue }) },
      };
    } else if (wallMin && wallMax && odValue) {
      updateObj.operator = { label: 'Range', value: 'range' };
      updateObj.value = {
        min: { value: calculateWall({ od: odValue, wall: wallMin }) },
        max: { value: calculateWall({ od: odValue, wall: wallMax }) },
      };
    } else if (odValue && wallValue) {
      let wallCalc = calculateWall({ od: odValue, wall: wallValue });
      updateObj.value = { value: wallCalc };
    }
  }

  if (updateObj.value && solveFor) {
    path
      ? currentCursor.set([...path, solveFor], {
          ...activePointer,
          ...updateObj,
          dirty: true,
        })
      : currentCursor.set(['itemSelection', id, 'filters', solveFor], {
          ...activePointer,
          ...updateObj,
          dirty: true,
        });
    tree.commit();
  } else {
    // DO WE WANT TO RESET VALUE? TODO
    // tree.set(['requestForQuote', 'itemSelection', id, 'filters', solveFor], {
    //   ...wallPointer,
    //   ...updateObj,
    //   value: {value: undefined}
    // })
    // tree.commit()
  }
}

// HELP FUNCTIONS

function valueAccessor(value) {
  const min = get(value, 'min.value');
  const max = get(value, 'max.value');
  value = max || min ? '' : has(value, 'value') ? value.value : value || '';
  value =
    isObject(value) && !isArray(value) ? omit(value, ['min', 'max']) : value;
  value =
    isObject(value) && !isArray(value) && isEmpty(value) ? undefined : value;
  return pickBy(
    {
      min,
      max,
      value,
    },
    identity
  );
}
