import styled, { css } from 'styled-components/macro';
import { slideUp } from 'theme/Animations.styles.js';

export const FilterItemGroupWrapper = styled('div')`
  position: relative;

  .item-selection-menu {
    @media (max-width: 620px) {
      min-width: 97vw !important;
      max-width: 97vw !important;
      background: #fff;
    }
  }

  .uoms-options {
    div[type='button'] {
      padding-right: 0 !important;
      &:before {
        opacity: 0.4;
      }
    }
  }
`;
