import React, { useState, useEffect, useCallback } from 'react';
import { useBranch } from 'baobab-react/hooks';
import { includes } from 'lodash';

import StyledModalComponent from 'forks/customModal';
import { closeModal } from 'state/modals/actions';
import AllAlloyModal from './modals/AllAlloyModal/AllAlloyModal';
import ConfirmEmailModal from './modals/ConfirmEmailModal/ConfirmEmailModal';
import ContactUsModal from './modals/ContactUsModal/ContactUsModal';
import QuickInqueryModal from './modals/QuickInqueryModal/QuickInqueryModal';
import FieldInspectionModal from './modals/FieldInspectionModal/FieldInspectionModal';
import CreateOrderModal from './modals/CreateOrderModal/CreateOrderModal';
import UserFileUploadModal from './modals/UserFileUploadModal/UserFileUploadModal';

const StyledModal = StyledModalComponent.styled`
  min-width: ${(props) => props.width || '200px'};
  min-height: ${(props) => props.height || '200px'};
  height: auto;
  width: auto;
  display: flex;
  align-items: flex-start;
  margin-top: 10rem;
  border-radius: 0.28571429rem;
  justify-content: center;
  background-color: #fff;
  border-radius: 4px;
  opacity: ${(props) => props.opacity};
  transform: ${(props) =>
    props.opacity ? 'translate3d(0, 0, 0)' : 'translate3d(0, -20px, 0)'};
  transition: all ease 600ms 20ms;
  margin: 5% auto 0;
`;
const innerModalStyles = {
  position: 'relative',
  top: 'auto',
  bottom: 'auto',
  left: 'auto',
  right: 'auto',
  boxShadow: 'none',
  background: 'none',
};

const preventCloseModals = [
  'quickInqueryModalForReal',
  'quickInquirySupply',
  'contactUsModal',
];

function GlobalModals() {
  const [type, setType] = useState(0);
  const [opacity, setOpacity] = useState(0);
  const { modalsCursor } = useBranch({
    modalsCursor: ['modals'],
  });
  const modals = modalsCursor || {};

  const afterOpen = useCallback(() => {
    setTimeout(() => {
      setOpacity(1);
    }, 100);
  }, []);

  const beforeClose = useCallback(
    () =>
      new Promise((resolve) => {
        setOpacity(0);
        setTimeout(resolve, 200);
      }),
    []
  );

  useEffect(() => {
    if (type && !modals.modalType) {
      setTimeout(() => {
        setType(null);
      }, 201);
    } else {
      setType(modals.modalType);
    }
  }, [modals.modalType, type]);

  const activeType = type || modals.modalType;
  const modalData = modals.modalData || {};

  const renderModal = useCallback(() => {
    if (activeType === 'allAlloyModal') {
      // LINT OVERRIDE #3
      // Component wraps another component
      // eslint-disable-next-line react/jsx-props-no-spreading
      return <AllAlloyModal {...modals.modalData} styles={innerModalStyles} />;
    }
    if (activeType === 'confirmEmailModal') {
      return (
        <ConfirmEmailModal
          // LINT OVERRIDE #3
          // Component wraps another component
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...modals.modalData}
          styles={innerModalStyles}
        />
      );
    }
    if (activeType === 'contactUsModal') {
      // LINT OVERRIDE #3
      // Component wraps another component
      // eslint-disable-next-line react/jsx-props-no-spreading
      return <ContactUsModal {...modals.modalData} styles={innerModalStyles} />;
    }
    if (activeType === 'quickInqueryModal') {
      // LINT OVERRIDE #3
      // Component wraps another component
      // eslint-disable-next-line react/jsx-props-no-spreading
      return <ContactUsModal {...modals.modalData} styles={innerModalStyles} />;
    }
    if (activeType === 'quickInqueryModalForReal') {
      return (
        <QuickInqueryModal
          // LINT OVERRIDE #3
          // Component wraps another component
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...modals.modalData}
          styles={innerModalStyles}
        />
      );
    }
    if (activeType === 'quickInquirySupply') {
      return (
        <QuickInqueryModal
          // LINT OVERRIDE #3
          // Component wraps another component
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...modals.modalData}
          styles={innerModalStyles}
        />
      );
    }
    if (activeType === 'fieldInspectionCreationModal') {
      return (
        <FieldInspectionModal
          // LINT OVERRIDE #3
          // Component wraps another component
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...modals.modalData}
          styles={innerModalStyles}
        />
      );
    }
    if (activeType === 'createOrder') {
      return (
        <CreateOrderModal data={modals.modalData} styles={innerModalStyles} />
      );
    }
    if (activeType === 'userFileUploadModal') {
      return (
        <UserFileUploadModal // LINT OVERRIDE #3
          // Component wraps another component
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...modals.modalData}
        />
      );
    }
    return null;
  }, [activeType, modals.modalData]);

  return (
    <StyledModal
      isOpen={modals.modalOpen}
      onBackgroundClick={
        modalData.preventClose || includes(preventCloseModals, activeType)
          ? null
          : closeModal
      }
      afterOpen={afterOpen}
      beforeClose={beforeClose}
      onEscapeKeydown={
        modalData.preventClose || includes(preventCloseModals, activeType)
          ? null
          : closeModal
      }
      opacity={opacity}
      backgroundProps={{ opacity }}
    >
      {renderModal()}
    </StyledModal>
  );
}

export default GlobalModals;
