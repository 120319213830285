import tree from 'state';
import * as effects from './effects';
import { openModal } from 'state/modals/actions';
import { get } from 'lodash';

var authCursor = tree.select(['authentication', 'session']);
authCursor.on('update', function (e) {
  var eventData = e.data;
  if (eventData.currentData && eventData.currentData.needs_confirm) {
    openModal({
      type: 'confirmEmailModal',
    });
  }
});

export async function createAccount(data) {
  try {
    const session = await effects.createAccount(data);
    tree.set(['authentication', 'session'], session);
    return session;
  } catch (err) {
    return {
      error: err.message,
    };
  }
}

export async function signIn({ email, password }) {
  try {
    const session = await effects.signIn({ email, password });
    tree.set(['authentication', 'session'], session);
    return session;
  } catch (err) {
    return {
      error: err.message,
    };
  }
}

export async function refreshSession() {
  let session;
  try {
    const { csrftoken } = await effects.getToken();
    tree.set(['authentication', 'csrftoken'], csrftoken);
    session = await effects.checkSession();
    if (get(session, 'success')) {
      tree.set(['authentication', 'session'], session);
    }
    return session;
  } catch (err) {
    return {
      error: err.message,
    };
  }
}

export async function forgotPassword({ email }) {
  try {
    await effects.forgotPassword({ email });
    return { email };
  } catch (err) {
    return {
      error: err.message,
    };
  }
}

export async function logout() {
  try {
    await effects.logout();
    tree.set(['authentication', 'session'], null);
    return { logout: true };
  } catch (err) {
    return {
      error: err.message,
    };
  }
}

export async function getToken({ state, effects }) {
  try {
    const { csrftoken } = await effects.authentication.getToken();
    tree.set(['authentication', 'csrftoken'], csrftoken);
  } catch (err) {
    console.log(err, 'ERROR GETTING TOKEN');
    return {
      error: err.message,
    };
  }
}

export async function fetchEmailTokenUser({ cipher, token }) {
  try {
    const emailToken = await effects.fetchEmailTokenUser({ cipher, token });
    tree.set(['authentication', 'emailToken'], emailToken);
    return emailToken;
  } catch (err) {
    return {
      error: err.message,
    };
  }
}

export async function resetPassword(key, data) {
  try {
    const reset = await effects.resetPassword(key, data);
    return reset;
  } catch (err) {
    return {
      error: err.message,
    };
  }
}

export async function recoverPassword(data) {
  try {
    const recover = await effects.recoverPassword(data);
    return recover;
  } catch (err) {
    return {
      error: err.message,
    };
  }
}

export async function changeEmail(data) {
  try {
    const recover = await effects.changeEmail(data);
    return recover;
  } catch (err) {
    return {
      error: err.message,
    };
  }
}

export async function changePassword(data) {
  try {
    const recover = await effects.changePassword(data);
    return recover;
  } catch (err) {
    return {
      error: err.message,
    };
  }
}

export async function confirmEmailReq(key) {
  try {
    await effects.confirmEmail(key);
    return { success: true };
  } catch (err) {
    return {
      error: err.message,
    };
  }
}

export async function confirmEmailResend() {
  try {
    await effects.confirmEmailResend();
    return { success: true };
  } catch (err) {
    return {
      error: err.message,
    };
  }
}
