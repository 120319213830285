import fetchJson from 'utility/fetchJson';
import { BASE } from '../../constants';

export async function getTags() {
  const response = await fetchJson(`${BASE}/api/p/tag/`, {
    method: 'GET',
  });
  return response;
}

export async function createTag(data) {
  const response = await fetchJson(`${BASE}/api/p/tag/`, {
    method: 'POST',
    body: JSON.stringify(data),
  });
  return response;
}
