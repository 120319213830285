import styled from 'styled-components/macro';
import PublicPage from 'components/PublicPages/PublicPage';
import { SM } from '@zendeskgarden/react-typography';
import { variables } from 'theme/variables';

const { custom_grey: customGrey } = variables;

export const LandingPage = styled(PublicPage)`
  padding-top: 0px;
`;

export const SectionHeader = styled('div')`
  font-size: 24px;
  margin-left: 10px;
  font-weight: 500 !important;
  line-height: 24px;
  margin-bottom: 8px;
  @media (max-width: 925px) {
    text-align: center;
  }
`;

export const SubSectionHeader = styled(SM)`
  margin-left: 10px;
  color: ${customGrey} !important;
  font-weight: 500 !important;
  margin-bottom: 10px;
  @media (max-width: 925px) {
    text-align: center;
  }
`;
