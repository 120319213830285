import fetchJson from 'utility/fetchJson';
import { isEmpty } from 'lodash';
import { getValuesForRequest } from 'utility/reverseUrlFilters';
import { BASE } from '../../constants';

export async function getProductsForSearch(
  { keyword, criteria = {} },
  { filters, pagination, sorting = {} }
) {
  const currentFilters = filters || getValuesForRequest('categories');
  const body = { ...currentFilters, ...pagination, ...sorting, ...criteria };
  if (keyword) {
    body.keyword = keyword;
  }
  const response = await fetchJson(`${BASE}/api/p/product/list/`, {
    method: 'POST',
    body: JSON.stringify(isEmpty(body) ? { page: 1 } : body),
  });
  return response;
}

export default getProductsForSearch;
