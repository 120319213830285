import React from 'react';
import { useBranch } from 'baobab-react/hooks';
import { closeModal } from 'state/modals/actions';
import Icon from 'components/Icon/Icon';
import { MD } from '@zendeskgarden/react-typography';
import styled from 'styled-components/macro';
import Flex from 'styled-flex-component';
import PageViewWrapper from 'higherOrder/PageViewWrapper';
import QuickInquiryForm from 'components/BasicForms/QuickInquiryForm';
import { PropTypes } from 'prop-types';
import { includes, split } from 'lodash';
import history from 'historyContainer';
import { matchShape, locationShape } from '../../../../propTypesObjects';

export const Wrapper = styled.section`
  width: 450px;
  max-width: 90%;
  .wrapper {
    width: 100%;
  }
  form {
    width: 100%;
    button {
      margin-bottom: 0 !important;
      width: 100%;
    }
  }
  .react-date-picker__wrapper {
    border: 1px solid #d0d0d0 !important;
    width: 100% !important;
    font-size: 14px;
    padding: 0 10px !important;
    outline: none !important;
    box-shadow: none !important;
    border-radius: 4px !important;
    min-height: 40px;
    margin-top: 10px;
    min-height: 40px;
    .react-date-picker__button {
      width: 20px;
    }
  }
  .filter-item-input {
    border: 1px solid #d0d0d0 !important;
    width: 100% !important;
    padding: 0 10px !important;
    outline: none !important;
    box-shadow: none !important;
    border-radius: 4px !important;
    margin-top: 10px !important;
  }
  .email-link {
    padding: 10px;
    background: #eef0f3;
    text-align: center;
    width: 100%;
    border-bottom-right-radius: 6px;
    border-bottom-left-radius: 6px;
    a {
      color: #757575 !important;
    }
  }
  .icon-close {
    position: absolute;
    top: 5px;
    right: 5px;
    z-index: 2;
  }
`;

function QuickInqueryModal(props) {
  const { location, match, isSupply = false, styles } = props;
  const currentPath = location?.pathname || '';
  const basePath = split(currentPath, '/m/')[0];
  const { simplifiedInquiryContentType } = useBranch({
    simplifiedInquiryContentType: [
      'application',
      'publicMeta',
      'file_content_types',
      'SimplifiedInquiry',
    ],
  });

  function onClose() {
    closeModal();
    if (basePath) {
      history.push(basePath);
    }
  }

  return (
    <Wrapper
      style={{
        margin: '0 auto',
        maxWidth: '700px',
        ...(styles || { margin: '0 auto' }),
      }}
    >
      <Icon
        onClick={() => onClose()}
        buttonStyle
        icon="icon-close"
        fontSize="10px"
      />
      <Flex
        className="wrapper"
        column
        alignCenter
        justifyBetween
        style={{ padding: ' 20px 25px 25px' }}
      >
        <QuickInquiryForm
          isSupply={includes(match?.path, 'supply') || isSupply}
          contentType={simplifiedInquiryContentType}
          onClose={() => onClose()}
        />
      </Flex>
      <MD left className="email-link" tag="p">
        <a href="mailto:sales@pipesearch.com">Email Us: sales@pipesearch.com</a>
      </MD>
    </Wrapper>
  );
}

QuickInqueryModal.propTypes = {
  match: matchShape,
  location: locationShape,
  isSupply: PropTypes.bool,
  styles: PropTypes.shape({}),
};

export default PageViewWrapper(QuickInqueryModal, {
  pageType: 'modal',
  pageName: 'QuickInqueryModal',
});
