import styled from 'styled-components/macro';
import { Button } from 'theme/Button';

// LINT OVERRIDE #4
// More exports will be added (this override can be removed at that time)
// eslint-disable-next-line import/prefer-default-export
export const GreyButton = styled(Button)`
  color: white;
  background-color: grey;
  border: 1px solid grey;
  font-weight: 500;
  &:hover {
    background-color: darkgrey !important;
  }
`;
