import styled from 'styled-components/macro';
import { variables } from 'theme/variables';

const {
  custom_navy: customNavy,
  custom_light_grey: customLightGrey,
  spacing,
  spacing_sm: spacingSm,
  custom_sky_blue_30: customSkyBlue30,
} = variables;

export const SidebarFilterHeader = styled.div`
  padding: ${(props) => props.padding || spacing};
  background-color: ${customNavy};
  height: 55px;
  display: flex;
  align-items: center;
`;

export const SidebarFilterBody = styled.div`
  padding: ${(props) => props.padding || spacing};
  flex: 1;
  position: relative;
  height: 100%;
  overflow-y: auto;
  overflow-scrolling: touch;
  padding-bottom: 90px;
`;

export const SidebarFilterTabs = styled.div`
  padding: ${() => `0 ${spacing} 0`};
  background: ${customSkyBlue30};
  .tabs {
    margin-bottom: 0;
    .tab {
      text-transform: uppercase;
      font-size: 13px !important;
      margin-bottom: 0 !important;
      padding-bottom: 8px !important;
      padding-top: 10px !important;
    }
  }
`;

export const SidebarContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: stretch;
`;

export const ResetHeader = styled.div`
  display: flex;
  padding: ${spacingSm} ${spacing};
  justify-content: stretch;
  background-color: ${customLightGrey};
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const SidebarFooterWrapper = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 90px;
  background-color: #fff;
  display: flex;
  align-items: center;
  padding: ${(props) => props.padding || spacing};
  box-shadow: 0px -1px 13px rgba(0, 0, 0, 0.06);
  z-index: 3;
`;
