import fetchJson from 'utility/fetchJson';
import { isEmpty } from 'lodash';
import { getValuesForRequest } from 'utility/reverseUrlFilters';
import { BASE } from '../../constants';

/*
export async function getCompanyEngagements({filters, pagination, sorting = {}}) {
  filters = !filters ? getValuesForRequest('companies') : filters
  const body = {...filters, ...pagination, ...sorting};
  const response = await fetchJson(`${BASE}/api/engagement/list/`, {
    method: 'POST',
    body: JSON.stringify(isEmpty(body) ? {page: 1} : body)
  })
  return response
}
*/
export async function getEngagements({ filters, pagination, sorting = {} }) {
  const currentFilters = !filters ? getValuesForRequest('engagement') : filters;
  const body = { ...currentFilters, ...pagination, ...sorting };
  const response = await fetchJson(`${BASE}/api/crm/engagement/list/`, {
    method: 'POST',
    body: JSON.stringify(isEmpty(body) ? { page: 1 } : body),
  });
  return response;
}

export async function getInteractions({ filters, pagination, sorting = {} }) {
  const currentFilters = !filters ? getValuesForRequest('engagement') : filters;
  const body = { ...currentFilters, ...pagination, ...sorting };
  const response = await fetchJson(`${BASE}/api/crm/interaction/list/`, {
    method: 'POST',
    body: JSON.stringify(isEmpty(body) ? { page: 1 } : body),
  });
  return response;
}

export async function getActionItems({ filters, pagination, sorting = {} }) {
  const currentFilters = !filters
    ? getValuesForRequest('actionitems')
    : filters;
  const body = { ...currentFilters, ...pagination, ...sorting };
  const response = await fetchJson(`${BASE}/api/crm/actionitem/list/`, {
    method: 'POST',
    body: JSON.stringify(isEmpty(body) ? { page: 1 } : body),
  });
  return response;
}

export async function getEngagement(id) {
  const response = await fetchJson(`${BASE}/api/crm/engagement/${id}/`, {
    method: 'GET',
  });
  return response;
}

export async function updateEngagement(engagementId, data) {
  const response = await fetchJson(
    `${BASE}/api/crm/engagement/${engagementId}/`,
    {
      method: 'PATCH',
      body: JSON.stringify(data),
    }
  );
  return response;
}

export async function createEngagement(data) {
  const response = await fetchJson(`${BASE}/api/crm/engagement/`, {
    method: 'POST',
    body: JSON.stringify(data),
  });
  return response;
}

export async function deleteEngagement(engagementId) {
  const response = await fetchJson(
    `${BASE}/api/crm/engagement/${engagementId}/`,
    {
      method: 'DELETE',
    }
  );
  return response;
}

export async function createEngagementCompany(data) {
  const response = await fetchJson(`${BASE}/api/crm/engagementcompany/`, {
    method: 'POST',
    body: JSON.stringify(data),
  });
  return response;
}

export async function deleteEngagementCompany(engagementCompanyId) {
  const response = await fetchJson(
    `${BASE}/api/crm/engagementcompany/${engagementCompanyId}/`,
    {
      method: 'DELETE',
    }
  );
  return response;
}

export async function updateInteraction(interactionId, data) {
  const response = await fetchJson(
    `${BASE}/api/crm/interaction/${interactionId}/`,
    {
      method: 'PATCH',
      body: JSON.stringify(data),
    }
  );
  return response;
}

export async function createInteraction(data) {
  const response = await fetchJson(`${BASE}/api/crm/interaction/`, {
    method: 'POST',
    body: JSON.stringify(data),
  });
  return response;
}

export async function deleteInteraction(interactionId) {
  const response = await fetchJson(
    `${BASE}/api/crm/interaction/${interactionId}/`,
    {
      method: 'DELETE',
    }
  );
  return response;
}

export async function updateActionItem(actionitemId, data) {
  const response = await fetchJson(
    `${BASE}/api/crm/actionitem/${actionitemId}/`,
    {
      method: 'PATCH',
      body: JSON.stringify(data),
    }
  );
  return response;
}

export async function createActionItem(data) {
  const response = await fetchJson(`${BASE}/api/crm/actionitem/`, {
    method: 'POST',
    body: JSON.stringify(data),
  });
  return response;
}

export async function deleteActionItem(actionitemId) {
  const response = await fetchJson(
    `${BASE}/api/crm/actionitem/${actionitemId}/`,
    {
      method: 'DELETE',
    }
  );
  return response;
}

export async function createObjectPersonnel(data) {
  const response = await fetchJson(`${BASE}/api/objectpersonnel/`, {
    method: 'POST',
    body: JSON.stringify(data),
  });
  return response;
}

export async function deleteObjectPersonnel(objectpersonnelId) {
  const response = await fetchJson(
    `${BASE}/api/objectpersonnel/${objectpersonnelId}/`,
    {
      method: 'DELETE',
    }
  );
  return response;
}

export async function getObjectPersonnel({
  content_type: contentType,
  object_id: objectId,
}) {
  const response = await fetchJson(
    `${BASE}/api/objectpersonnel/?content_type=${contentType}&object_id=${objectId}`,
    {
      method: 'GET',
    }
  );
  return response;
}
