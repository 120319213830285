import styled from 'styled-components/macro';
import { variables } from 'theme/variables';
const {
  font_size_sm,
  spacing_lg,
  spacing_md,
  spacing_sm,
  custom_light_grey,
  custom_blue,
  color_white,
  custom_silver,
  font_weight_semibold,
  custom_lighter_grey,
} = variables;

export const EndFinishFilterItemSelectionWrapper = styled('div')`
  position: relative;
  padding: 0 ${spacing_lg};
  padding-bottom: ${spacing_md};
  padding-top: ${spacing_sm};
`;

export const SelectionCol = styled('div')`
  height: 220px;
  border: 1px solid ${custom_light_grey};
  margin-top: ${spacing_sm};
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  overflow-scrolling: touch;
  position: relative;
  ${(props) =>
    props.selected
      ? `
    @media(max-width: 620px) {
      display: none;
    }
  `
      : ''}
`;

export const EmptySelectionCol = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: ${spacing_sm};
  text-align: center;
  height: 100%;
  color: ${custom_silver};
`;

export const Selected = styled('div')`
  width: 10px;
  height: 10px;
  background-color: ${custom_blue};
  position: absolute;
  left: 14px;
  ${(props) =>
    props.disabled
      ? `
    background-color: ${custom_light_grey};
  `
      : ''}
  ${(props) =>
    props.small
      ? `
    left: 9px;
  `
      : ''}
`;

export const Item = styled('div')`
  word-wrap: break-word;
  display: block;
  position: relative;
  z-index: 0;
  cursor: pointer;
  padding: 10px 32px;
  text-decoration: none;
  line-height: ${font_size_sm};
  font-size: ${font_size_sm};
  word-wrap: break-word;
  user-select: none;
  display: flex;
  align-items: center;
  &:hover {
    background-color: ${custom_light_grey};
  }
  ${(props) =>
    props.active
      ? `
    background-color: ${custom_blue} !important;
    color: ${color_white};
  `
      : ''}
  ${(props) =>
    props.selected
      ? `
    background-color: ${custom_blue} !important;
    color: ${color_white};
  `
      : ''}

  ${(props) =>
    props.headerItem
      ? `
    background-color: ${custom_lighter_grey} !important;
    point-events: none;
    font-weight: ${font_weight_semibold}
    padding-left: 13px;
  `
      : ''}
`;
