import styled from 'styled-components/macro';
import { variables } from 'theme/variables';

const {
  custom_dark_blue: customDarkBlue,
  color_white: colorWhite,
  custom_button_blue: customButtonBlue,
  custom_pale_blue: customPaleBlue,
  public_font_size_sm: publicFontSizeSm,
  public_app_max_width: publicAppMaxWidth,
  media_lg: mediaLg,
  public_font_family: publicFontFamily,
  media_md: mediaMd,
} = variables;

export const PublicHeaderComponent = styled.header`
  position: fixed;
  z-index: 20;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 85px;
  background: ${customDarkBlue};
  font-family: ${publicFontFamily};
  transition: all 0.3s ease;
  .header-inner {
    max-width: ${publicAppMaxWidth};
    margin: 0 auto;
    height: 100%;
    position: relative;
    z-index: 3;
    padding: 0px 10px;
    @media ${mediaMd} {
      padding: 0px 30px;
    }
  }
  .header-grid {
    z-index: 2;
    position: relative;
  }

  .header-grid,
  .header-row {
    height: 100%;
  }

  ${(props) =>
    props.whiteMenu || props.alwaysWhiteMenu
      ? `
    background: ${
      props.alwaysWhiteMenu ? colorWhite : 'rgba(255,255,255, 0.8)'
    };
    box-shadow: ${
      !props.whiteMenu ? 'none' : '0 20px 20px -12px rgba(20,29,47,0.08)'
    };
    height: 75px !important;
    opacity: 1;
    .header-grid{
      background: #fff;
    }
    .menu {
      .inner > .nav-link-a, .inner > .icon-nav-link, .inner .item {
        color: ${customPaleBlue} !important;

        &:hover {
          color: ${customButtonBlue} !important;
        }
        &:after {
          background: #EBECEE;
        }
      }

      .inner > .nav-link-a.active-menu-item, .inner > .icon-nav-link .active-menu-item, .wrapper-drop-down .active-menu-item  {
        color: ${customButtonBlue} !important;
        &:after {
          background: #EBECEE;
        }
      }
    }
    .header-button {
      background-color: ${customButtonBlue};
      color: ${colorWhite};
      transform: scale(0.8);
    }
  `
      : ``}

  ${(props) =>
    props.headerHidden
      ? `
    backface-visibility: hidden;
    pointer-events: none;
    opacity: 0;
    .header-inner {
      transition: all 0.3s 0s ease;
      transform: translate3d(0, -20px, 0);
    }
  `
      : ''}

  .header-button {
    min-width: 0;
    transition: transform 0.1s linear;
  }

  .primary-menu-col,
  .secondary-menu-col {
    display: none;
    justify-content: center;
    align-items: center;
    height: 100%;
  }
  .logo-col {
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }

  @media ${mediaMd} {
    height: 84px;
    .secondary-menu-col {
      display: flex;
      padding-right: 60px;
    }
  }

  @media ${mediaLg} {
    .secondary-menu-col {
      padding-right: 10px;
    }
    .primary-menu-col {
      display: flex;
    }
    .logo-col {
      align-items: center;
    }
  }
`;

export const Menu = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  &.menu-1 {
    li a,
    li span {
      &:after {
        content: '';
        display: block;
        position: absolute;
        width: 84%;
        height: 10px;
        background: ${customButtonBlue};
        left: 0;
        right: 0;
        top: 50%;
        z-index: -1;
        margin: 0 auto;
        transform: scaleY(0);
        transition: transform 0.2s linear;
        transform-origin: center bottom;
      }
      &:hover:after {
        transform: scaleY(1);
      }
    }
  }
`;

export const MenuItem = styled.li`
  color: ${colorWhite};
  position: relative;
  flex-shrink: 0;
  a,
  span {
    padding: 16px 12px;
    font-weight: 500;
    font-size: ${publicFontSizeSm};
    color: ${customDarkBlue};
    transition: color 0.2s linear;
    text-decoration: none;
    position: relative;
    cursor: pointer;
    [class^='icon-']:before {
      cursor: pointer;
    }
    &:hover,
    &:focus {
      color: ${customButtonBlue} !important;
      text-decoration: none;
    }
  }
`;

export const HeaderRight = styled.div`
  height: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  min-width: 100%;
  Button {
    background-color: ${colorWhite};
    color: ${customButtonBlue};
  }
`;

export const Logo = styled('div')`
  max-width: 186px;
  opacity: 1;
  max-height: 50px;
  svg {
    width: auto;
    height: 32px;
    max-height: 50px;
    @media ${mediaMd} {
      height: auto;
      width: 100%;
    }
  }
`;

export const MobileMenuTrigger = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
  right: 24px;
  width: 24px;
  height: 20px;
  cursor: pointer;
  display: block;
  z-index: 4;
  &:before {
    content: '';
    position: absolute;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    top: -20px;
    left: -20px;
    bottom: -20px;
    right: -20px;
    margin: auto;
    background: ${customButtonBlue};
    transform: scale(0);
    transition: transform 0.3s cubic-bezier(0.22, 0.265, 0.085, 0.98);
    will-change: auto;
  }
  span {
    background: ${customButtonBlue};
    width: 100%;
    height: 2px;
    position: absolute;
    border-radius: 2px;
    right: 0;
    transition: all 0.2s linear;
    z-index: 2;
    opacity: 1;
    &:nth-child(1) {
      top: 0;
      transform-origin: 0% 50%;
    }
    &:nth-child(2) {
      top: 0;
      bottom: 0;
      margin: auto;
      width: calc(100% - 3px);
      z-index: 1;
    }
    &:nth-child(3) {
      bottom: 0;
      width: calc(100% - 6px);
      transform-origin: 100% 50%;
    }
  }

  ${(props) =>
    props.active
      ? `
    &:before {
      transform: scale(1);
    }
    span {
      &:nth-child(1) {
        width: 14px;
        background: ${colorWhite};
        transform: translateX(-3px) translateY(14px) rotate(-45deg);
      }
      &:nth-child(2) {
        width: 0px;
        
      }
      &:nth-child(3) {
        width: 14px;
        background: ${colorWhite};
        transform: translateX(-7px) translateY(-4px) rotate(45deg);
        
      }
    }
  `
      : ''}

  @media ${mediaLg} {
    display: none;
  }
`;
