import styled from 'styled-components/macro';
import { Form } from 'theme/Form';

const ContactUsFormTwo = styled(Form)`
  #expertDropdownCol {
    ${(props) => (props.fullSizeForm ? 'max-width: 50%' : 'max-width: 100%')};
  }
  #formSubmitButton {
    width: 100%;
  }
  #submitButtonText {
    width: 100%;
  }
  .contactFormName {
    width: 50%;
  }
  @media (max-width: 768px) {
    #expertDropdownCol {
      max-width: 100%;
    }
    #formSubmitButton {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
    }
    #submitButtonText {
      text-align: center;
    }
  }
  @media (max-width: 767px) {
    .contactFormName {
      width: 100%;
      min-width: 320px;
    }
  }
  @media (max-width: 425px) {
    .contactFormName {
      min-width: 200px;
    }
  }
`;

export default ContactUsFormTwo;
