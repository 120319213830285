import {
  pickBy,
  has,
  identity,
  omit,
  isObject,
  zipObject,
  isEmpty,
  values,
  every,
  compact,
  forEach,
  get,
  map,
  reduce,
  isArray,
  includes,
} from 'lodash';
import tree from 'state';

// BASE VALIDATION
function valueExtractor(value) {
  // DUPLICATE FUNCTION IN TRANSFORMER
  const min = get(value, 'min.value');
  const max = get(value, 'max.value');
  let _value;
  if (max || min) {
    _value = '';
  } else if (has(value, 'value')) {
    _value = value.value;
  } else {
    _value = value || '';
  }
  _value =
    isObject(_value) && !isArray(_value)
      ? omit(_value, ['min', 'max'])
      : _value;
  _value =
    isObject(_value) && !isArray(_value) && isEmpty(_value)
      ? undefined
      : _value;
  const totalValues = pickBy(
    {
      min,
      max,
      value: _value,
    },
    identity
  );
  return totalValues;
}

function valueValidation(
  value,
  validateHasField,
  validationOptions,
  validationFunction
) {
  const totalValues = valueExtractor(value);
  if (validateHasField && isEmpty(totalValues)) {
    return false;
  }
  const validationFn = validationFunction.bind(this, validationOptions);
  return every(totalValues, validationFn);
}

export function baseValidation({
  item,
  validateHasField,
  validationOptions = {},
}) {
  if (validationOptions.forceValid) {
    return {
      ...item,
      error: false,
      valid: true,
    };
  }

  const _validateHasField = validateHasField || item.error;
  const pointer = item;
  const tryValidation =
    (_validateHasField && !pointer.disabled) ||
    (pointer.value && pointer.dirty);
  let valid;
  if (tryValidation) {
    valid = valueValidation(
      pointer.value,
      _validateHasField,
      validationOptions,
      (options, v) => {
        const _options = options || {};
        if (_options.isValidFn) {
          return v && _options.isValidFn(v);
        }
        return v && _options.isString ? true : v > 0;
      }
    );
  } else {
    valid = true;
  }

  return {
    ...pointer,
    error: !valid,
  };
}
// END BASE VALIDATION

// VALIDATION
export function validateFilterOptions(id, currentCursor, customPath) {
  // THIS RUNS VALIDATION ON WHEN A FILTER IS CHANGED
  const _currentCursor = currentCursor || tree.select(['requestForQuote']);
  const itemSelectionItem = _currentCursor.get([
    ...customPath,
    'itemSelection',
    id,
  ]);
  if (itemSelectionItem) {
    forEach(itemSelectionItem.filters, (item, key) => {
      if (item.validation) {
        const data = item.validation({
          item,
          key,
          itemSelectionItem,
          itemId: id,
        });
        currentCursor.set(
          [...customPath, 'itemSelection', id, 'filters', key],
          data
        );
      }
    });
  }
}

export function validateTechnicalDetails(id, currentCursor) {
  const _currentCursor = currentCursor || tree.select(['requestForQuote']);
  const itemSelection = _currentCursor.get(['itemSelection']);
  forEach(
    get(itemSelection, `[${id}]technical_details.result`),
    (item, key) => {
      if (item.validation) {
        const data = item.validation({
          itemId: id,
          itemSelectionItem: itemSelection[id],
          item,
          key,
          technicalDetails: get(itemSelection, `[${id}]technical_details`),
        });
        _currentCursor.set(
          ['itemSelection', id, 'technical_details', 'result', key],
          data
        );
      }
    }
  );
}

export function setAndGetRowValidation({
  row,
  rowId,
  itemSelectionItem,
  mergeErrors,
  currentCursor,
  ignoreTechnicalDetails,
  requiredFields,
  customPath = [],
}) {
  const _currentCursor = currentCursor || tree.select(['requestForQuote']);

  // FILTERS VALIDATION
  const currentTemplate = get(row, 'filters.category.value.template');
  let filterErrorMap = map(row?.filters || {}, (item, key) => {
    const validationItem =
      (!item.templates ||
        (currentTemplate && includes(item.templates, currentTemplate))) &&
      item.validation &&
      item.validation({
        itemId: rowId,
        item,
        key,
        itemSelectionItem: itemSelectionItem[rowId],
        validateHasField: get(requiredFields, key) || !ignoreTechnicalDetails,
      });
    if (validationItem && validationItem.error) {
      return { key, value: validationItem, error: validationItem.title };
    }
    return null;
  });
  filterErrorMap = compact(filterErrorMap);

  // TECHNICAL DETAILS VALIDATION
  let technicalDetailsErrorMap = [];
  if (!ignoreTechnicalDetails) {
    technicalDetailsErrorMap = map(
      get(row, 'technical_details.result'),
      (item, key) => {
        const validationItem =
          item &&
          item.validation &&
          item.validation({
            itemId: rowId,
            item,
            key,
            itemSelectionItem: itemSelectionItem[rowId],
            validateHasField: true,
          });
        if (validationItem && validationItem.error) {
          return { key, value: validationItem, error: key };
        }
        return null;
      }
    );
  }
  technicalDetailsErrorMap = compact(technicalDetailsErrorMap);

  const technicalDetailsConfirmedMap = [];

  // BULK ERROR MERGING
  if (mergeErrors) {
    if (technicalDetailsErrorMap.length) {
      const technicalDetailsErrors = zipObject(
        map(technicalDetailsErrorMap, 'key'),
        map(technicalDetailsErrorMap, 'value')
      );
      _currentCursor.merge(
        [...customPath, 'itemSelection', rowId, 'technical_details', 'result'],
        technicalDetailsErrors
      );
    }
    if (filterErrorMap.length) {
      const filterErrors = zipObject(
        map(filterErrorMap, 'key'),
        map(filterErrorMap, 'value')
      );
      _currentCursor.merge(
        [...customPath, 'itemSelection', rowId, 'filters'],
        filterErrors
      );
    }
  }

  // RESULT
  let errors;
  if (ignoreTechnicalDetails) {
    errors = [...filterErrorMap];
  } else {
    errors = [
      ...filterErrorMap,
      ...technicalDetailsErrorMap,
      ...technicalDetailsConfirmedMap,
    ];
  }

  if (mergeErrors || !errors.length) {
    _currentCursor.set(
      [...customPath, 'itemSelection', rowId, 'errors'],
      errors.length ? map(errors, 'error') : null
    );
  }

  _currentCursor.set(
    [...customPath, 'itemSelection', rowId, 'valid'],
    !errors.length
  );

  return errors;
}

export function itemRowsValid({
  checkForDirty,
  mergeErrors = true,
  currentCursor,
  ignoreTechnicalDetails,
} = {}) {
  const _currentCursor = currentCursor || tree.select(['requestForQuote']);
  const itemSelectionItem = _currentCursor.get(['itemSelection']);
  let hasDirty;
  const rows = reduce(
    itemSelectionItem,
    (accum, row, rowId) => {
      const _accum = accum;
      if (!hasDirty && row.dirty) {
        hasDirty = true;
      }
      const errors = setAndGetRowValidation({
        row,
        rowId,
        itemSelectionItem,
        mergeErrors,
        currentCursor: _currentCursor,
        ignoreTechnicalDetails,
      });
      _accum[rowId] = errors;
      return _accum;
    },
    {}
  );
  return every(values(rows), isEmpty) && (checkForDirty ? !hasDirty : true);
}
// END VALIDATION
