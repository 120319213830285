import styled from 'styled-components/macro';

export const NoDataPlaceholderWrapper = styled.div`
  width: 100%;
  min-height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const IconImage = styled.div`
  padding: 0px 0px 20px;
  background-size: cover;
  width: 130px;
  height: 130px;
  background-image: url(/images/single-pipe.png);
  margin: 0 auto 20px;
  opacity: 0.7;
`;
