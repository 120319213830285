import styled from 'styled-components/macro';
import { variables } from 'theme/variables';

const { custom_blue, color_white } = variables;

export const StyledProgessBar = styled('div')`
  position: absolute;
  left: 0;
  bottom: 0;
  z-index: 1;
  width: 100%;
  height: 7px;
  border-top: 1px solid ${color_white}
  background-color: ${color_white};
`;

export const StyledInnerProgessBar = styled('div')`
  position: absolute;
  left: 0;
  top: 0;
  height: 7px;
  background-color: ${custom_blue};
  transition: all 300ms ease-in;
`;
