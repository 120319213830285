import React, { useEffect } from 'react';
import { canUseDOM } from 'exenv';
import { addClass, removeClass } from 'utility/domUtils';
import ReactDOM from 'react-dom';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import { MD } from '@zendeskgarden/react-typography';
import Icon from 'components/Icon/Icon';
import {
  OverlayGroupWrapper,
  OverlayWrapper,
  OverlayBackground,
  OverlayInner,
  OverlayHeader,
  OverlayContent,
} from './InPageOverlay.styles.js';

const InPageOverlay = (props) => {
  const showHeader = props.title;

  useEffect(() => {
    if (props.open && props.disableScroll) {
      addClass(document.getElementsByTagName('html')[0], 'overflow-hidden');
    } else if (props.disableScroll) {
      removeClass(document.getElementsByTagName('html')[0], 'overflow-hidden');
    }
    if (props.unmount) {
      return () => {
        removeClass(
          document.getElementsByTagName('html')[0],
          'overflow-hidden'
        );
      };
    }
  }, [props.open, props.disableScroll]);

  useEffect(() => {
    if (props.open && props.closeOnEsc) {
      document.addEventListener('keyup', escapeHit, true);
    } else if (props.closeOnEsc) {
      document.removeEventListener('keyup', escapeHit, true);
    }
    return () => {
      document.removeEventListener('keyup', escapeHit, true);
    };
  }, [props.open, props.closeOnEsc]);

  function escapeHit(e) {
    if (e.key === 'Escape' || e.key === 'Esc' || e.keyCode === 27) {
      e.preventDefault();
      if (props.onClose) {
        props.onClose();
      }
    }
  }

  const Element = (
    <OverlayGroupWrapper
      open={props.open}
      style={props.wrapperStyle || {}}
      className={props.className || ''}
    >
      <TransitionGroup>
        {props.open ? (
          <CSSTransition
            in={true}
            unmountOnExit={true}
            appear={true}
            timeout={700}
            classNames="overlay"
          >
            <OverlayWrapper justify="flex-start" style={props.style || {}}>
              <OverlayBackground
                background={props.background}
                onClick={
                  !props.disableBackgroundClick && props.onClose
                    ? props.onClose
                    : () => {}
                }
                className="overlay-wrapper-background"
              />
              <OverlayInner
                style={props.innerStyle || {}}
                full={props.full}
                className="overlay-wrapper-inner"
              >
                {showHeader ? (
                  <OverlayHeader>
                    <MD noMargin style={{ paddingRight: '20px' }} bold ellipsis>
                      {props.title}
                    </MD>
                    {props.onClose ? (
                      <Icon
                        buttonStyle
                        style={{ zIndex: 2 }}
                        button
                        onClick={props.onClose}
                        icon="icon-close"
                        fontSize={'12px'}
                      />
                    ) : null}
                  </OverlayHeader>
                ) : props.onClose && props.showClose ? (
                  <Icon
                    style={{
                      zIndex: 2,
                      position: 'absolute',
                      top: 0,
                      right: 0,
                    }}
                    buttonStyle
                    button
                    onClick={props.onClose}
                    icon="icon-close"
                    fontSize={'12px'}
                  />
                ) : null}
                <OverlayContent
                  style={props.contentStyle || {}}
                  scroll={props.disableScroll}
                  padding={props.padding}
                  showHeader={showHeader}
                >
                  {props.children}
                </OverlayContent>
              </OverlayInner>
            </OverlayWrapper>
          </CSSTransition>
        ) : null}
      </TransitionGroup>
    </OverlayGroupWrapper>
  );

  if (props.portal) {
    if (!canUseDOM) {
      return <div />;
    }
    return ReactDOM.createPortal(
      Element,
      document.querySelector('#in-page-modal')
    );
  } else {
    return Element;
  }
};

export default InPageOverlay;
