import React, { Component } from 'react';
import { canUseDOM } from 'exenv';
import menuAim from 'higherOrder/menuAim';
import { NavLink as Link } from 'react-router-dom';
import NavigationDropdownMenu from './NavigationDropdownMenu';
import { get, map, find } from 'lodash';
import { StyledMenu } from './ProductsDropdownMenu.styles';

const LINK_ARRAY = [
  { link: '/pipe-recover', title: 'PipeRecover™' },
  { link: '/pipe-stats', title: 'PipeStats™' },
  { link: '/pipe-facts', title: 'PipeFacts™' },
];

class CompanyDropdownMenu extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    this.props.initMenuAim({
      menuRef: this.menuRef,
      delay: 300,
      tolerance: 75,
    });
    this.setState({
      render: true,
      selectedService: find(LINK_ARRAY, { link: this.props.location.pathname }),
    });
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const inLinkArray = find(LINK_ARRAY, { link: nextProps.location.pathname });
    if (this.state.selectedService && !inLinkArray) {
      this.setState({
        selectedService: false,
        showCategories: false,
        subcategory: null,
      });
    }
  }

  render() {
    const rootSelected =
      this.state.render &&
      canUseDOM &&
      this.props.location.pathname === '/services';
    return (
      <StyledMenu>
        <NavigationDropdownMenu
          isActive={this.state.selectedService || rootSelected}
          link={`/pipe-recover`}
          customTitle="Services"
          className={`dropdown-menu`}
          onClickMenu={this.onClickMenu.bind(this)}
          onClose={this.onClose.bind(this)}
          {...this.props}
        >
          <div
            className="categories"
            onMouseLeave={this.props.handleMouseLeaveMenu}
            ref={(r) => (this.menuRef = r)}
          >
            {map(LINK_ARRAY, ({ title, link }, i) => {
              return (
                <div className="link-wrapper" key={i}>
                  <Link
                    onClick={this.onClickCategory.bind(this, { title, link })}
                    key={i}
                    to={link}
                    className={`dropdown-link ${
                      link === get(this.state, 'selectedService.link')
                        ? 'active'
                        : ''
                    }`}
                  >
                    <h5>{title}</h5>
                  </Link>
                </div>
              );
            })}
          </div>
        </NavigationDropdownMenu>
      </StyledMenu>
    );
  }

  onClickMenu() {
    return !this.state.selectedService;
  }

  onClose() {
    this.setState({});
  }

  onCloseCategories() {
    this.setState({
      selectedService: false,
      showCategories: false,
      subcategory: null,
    });
  }

  onClickCategory(category) {
    this.setState({
      selectedService: category,
    });
  }
}

export default menuAim(CompanyDropdownMenu);
