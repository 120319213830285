import { createBrowserHistory as createHistory } from 'history';
import { canUseDOM } from 'exenv';
import { isEmpty, assign, split, cloneDeep, unset } from 'lodash';
import queryString from 'query-string';

// Replace hash routes from old urls
if (canUseDOM && window.location.hash && window.history.replaceState) {
  const pathname = split(window.location.hash, '#')[1];
  window.history.replaceState(null, null, pathname);
}

if (canUseDOM) {
  const currentLocation = window.location;
  window.originalLocation = {
    origin: currentLocation.origin,
    protocol: currentLocation.protocol,
    host: currentLocation.host,
    hostname: currentLocation.hostname,
    port: currentLocation.port,
    pathname: currentLocation.pathname,
    search: currentLocation.search,
    hash: currentLocation.hash,
    href: currentLocation.href,
  };
}

let state = {};
let prevState = {};
let history = {};

function addLocationQuery() {
  const locationWithoutPrevious = cloneDeep(prevState);
  unset(locationWithoutPrevious, 'previous');
  history.appPrevious = locationWithoutPrevious;
  // The location object is mutable, and state updates are managed by the router
  // Update this object in place, but access it from the 'location' prop
  history.location = assign(history.location, state, {
    query: queryString.parse(history.location.search),
    previous: locationWithoutPrevious,
  });
  history.historyList = [
    { route: history.location.pathname, search: history.location.search },
    ...(history.historyList || []),
  ];
}

if (canUseDOM) {
  history = createHistory();
  history.originalLocation = window.originalLocation;
  const initLocation = cloneDeep(history.location);
  initLocation.previous = {};
  state = initLocation;
  addLocationQuery();
  history.listen((newState) => {
    // Allows us to save location.previous
    prevState = state;
    state = newState;
    addLocationQuery();
  });

  history.pushWithParams = (route) => {
    const currentQuery = !isEmpty(history.location.query)
      ? history.location.query
      : queryString.parse(window.location.search);

    const newQuery = split(route, '?')[1]
      ? queryString.parse(split(route, '?')[1])
      : {};
    const nextQuery = queryString.stringify({
      ...currentQuery,
      ...newQuery,
    });
    history.push(`${split(route, '?')[0]}?${nextQuery}`);
  };

  history.replaceWithParams = (route) => {
    const currentQuery = history.location.query;
    const newQuery = split(route, '?')[1]
      ? queryString.parse(split(route, '?')[1])
      : {};
    const nextQuery = queryString.stringify({
      ...currentQuery,
      ...newQuery,
    });
    // Prevent linter suggesting lodash replace()
    const { replace: replaceHistory } = history;
    replaceHistory(`${split(route, '?')[0]}?${nextQuery}`);
  };
}

export function getState() {
  return state || window.location;
}

if (canUseDOM) {
  window.appHistory = history;
}

export const { replace } = history || {};

const historyConst = history;

export default historyConst;
