import React from 'react';
import GlobalModals from '../GlobalModals/GlobalModals';
import GlobalSideBar from '../GlobalSideBar/GlobalSideBar';
import MobileDrawer from '../MobileDrawer/MobileDrawer';
import { ModalProvider } from '../../forks/customModal';
import {
  FadingBackground,
  FadingBackgroundSidebar,
} from './ModalWrappers.styles.js';

function ModalWrappers() {
  return (
    <span>
      <ModalProvider backgroundComponent={FadingBackground}>
        <GlobalModals />
      </ModalProvider>

      <ModalProvider backgroundComponent={FadingBackgroundSidebar}>
        <GlobalSideBar />
      </ModalProvider>

      <ModalProvider backgroundComponent={FadingBackgroundSidebar}>
        <MobileDrawer />
      </ModalProvider>
    </span>
  );
}

export default ModalWrappers;
