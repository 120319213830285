import styled from 'styled-components/macro';
import { variables } from 'theme/variables';
import { Button } from 'theme/Button';

const {
  custom_dark_blue: customDarkBlue,
  color_white: colorWhite,
  public_font_size_md: publicFontSizeMd,
  font_weight_regular: fontWeightRegular,
  line_height_lg: lineHeightLg,
  custom_blue: customBlue,
  media_lg: mediaLg,
} = variables;

export const MobileMenuComponent = styled.div`
  position: fixed;
  overflow-y: auto;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: ${customDarkBlue};
  padding: 44px 24px 0px;
  pointer-events: none;
  transform: translateX(100%);
  z-index: 3;
  transition: transform 0.6s cubic-bezier(0.22, 0.265, 0.085, 0.98) 0.3s,
    clip-path 0.4s cubic-bezier(0.22, 0.265, 0.085, 0.98) 0.2s;
  will-change: auto;
  @media ${mediaLg} {
    display: none;
  }

  .menu-background {
    position: fixed;
    width: 100%;
    height: 100vh;
    top: 0px;
    left: 0px;
    z-index: -1;
    background: rgb(0, 29, 67);
    pointer-events: none;

    .circle-1 {
      transform: translateX(-300px);
      transition: transform 0.5s cubic-bezier(0.22, 0.265, 0.085, 0.98) 0.5s;
      will-change: auto;
    }
    .circle-2 {
      transform: translateX(300px);
      transition: transform 0.5s cubic-bezier(0.22, 0.265, 0.085, 0.98) 0.7s;
      will-change: auto;
    }
  }

  ${(props) =>
    props.active
      ? `
    transform: translateX(0%);
    pointer-events: all;
    transition-delay: 0.0s;
    .menu-background .circle-1, .menu-background .circle-2 {
      transform: translateX(0px);
    }
  `
      : ''}
`;

export const MobileMenuButton = styled(Button)`
  width: 100%;
  margin-bottom: ${lineHeightLg};
  border-radius: 4px;
  opacity: 0;
  transform: translateY(40px);
  will-change: auto;

  ${(props) =>
    props.white
      ? `
  color: ${customBlue} !important;
  `
      : ''}
`;

export const Menu = styled.div`
  margin-bottom: 46px;
  ul {
    list-style-type: none;
    li {
      opacity: 0;
      will-change: auto;
      a,
      span {
        color: ${colorWhite} !important;
        font-size: ${publicFontSizeMd};
        font-weight: ${fontWeightRegular};
        line-height: 18px;
        padding: 18px 0px;
        display: block;
      }
      &.search {
        a {
          position: relative;
          .icon-search-left {
            color: ${colorWhite};
            right: 0;
            top: 0;
            bottom: 0;
            margin: auto 0;
            position: absolute;
            top: 0;
            line-height: 20px;
            height: 20px;
          }
        }
      }
    }
  }
`;

export const MenuItem = styled.li``;

export const Dropdown = styled.div`
  margin-left: 24px;
  height: 0;
  transition: height 0.4s ease-out;
  overflow: hidden;

  ${(props) =>
    props.active
      ? `
      height: auto;
    `
      : ''}
`;
