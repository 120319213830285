export const GLOBAL_INTERESTS_FILTERS = 'GLOBAL_INTERESTS_FILTERS';
export const CUSTOMER_APPROVAL = 'CUSTOMER_APPROVAL';
export const ADD_PARTNER_CUSTOMER = 'ADD_PARTNER_CUSTOMER';
export const ADD_COMPANY = 'ADD_COMPANY';
export const ADD_ENGAGEMENT = 'ADD_ENGAGEMENT';
export const ADD_INTERACTION = 'ADD_INTERACTION';
export const ADD_ACTIONITEM = 'ADD_ACTIONITEM';
export const ADD_INTERNALRELATIONSHIP = 'ADD_INTERNALRELATIONSHIP';
export const ADD_EMPLOYEE = 'ADD_EMPLOYEE';
export const ADD_MARKETCOMP = 'ADD_MARKETCOMP';
