import styled from 'styled-components/macro';
import { variables } from 'theme/variables';

const {} = variables;

export const LineItemRow = styled.div`
  align-items: center;
  display: flex;
  padding: 20px;
  margin-bottom: 10px;
`;

export const PhoneRowStyles = styled.div`
  input {
    border: none;
    border: 1px solid #d8dcde;
    width: 100%;
    padding: 0 10px;
    outline: none !important;
    box-shadow: none !important;
    border-radius: 4px;
  }

  .react-tel-input {
    border: 1px solid #d8dcde;
    border-radius: 4px;
    height: 41px;
    input,
    .flag-dropdown {
      border: none;
      border-radius: 3px 0 0 3px;
    }
    .selected-flag {
      width: 46px;
      border-radius: 3px 0 0 3px;
      border-bottom: none;
      border-right: 1px solid #d8dcde;
      background: #fff;
      .arrow {
        left: 24px;
      }
    }
    .form-control {
      padding-left: 55px;
      height: 39px;
      width: 100%;
    }
  }
`;
