import * as effects from './effects';
import asyncTreeRequester from 'utility/asyncTreeRequester';
import tree from 'state';

var globalCursor = tree.select(['global']);

export async function fetchGlobalMetallargy() {
  try {
    const metals = await effects.fetchGlobalMetallargy();
    globalCursor.set(['alloyLists'], metals);
    return metals;
  } catch (err) {
    return {
      error: err.message,
    };
  }
}

export async function fetchGlobalMetallargyGroupDetails(slug) {
  await asyncTreeRequester({
    func: effects.fetchGlobalMetallargyGroupDetails.bind(this, slug),
    cursor: globalCursor,
    path: ['alloyGroupDetails', slug],
  });
}

export async function fetchGlobalMetallargyDetails(slug) {
  await asyncTreeRequester({
    func: effects.fetchGlobalMetallargyDetails.bind(this, slug),
    cursor: globalCursor,
    path: ['alloyDetails', slug],
  });
}

export async function postUnAuthCriteria({ criteria }) {
  return await asyncTreeRequester({
    func: effects.postUnAuthCriteria.bind(this, { criteria }),
    cursor: globalCursor,
    handleResult: true,
  });
}

export async function getUnAuthCriteria(id) {
  return await asyncTreeRequester({
    func: effects.getUnAuthCriteria.bind(this, id),
    cursor: globalCursor,
    handleResult: true,
  });
}
