import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { variables } from 'theme/variables';
import { Button } from 'theme/Button';
import { LG, XXXL } from '@zendeskgarden/react-typography';
import Flex, { FlexItem } from 'styled-flex-component';
import { trackClick } from 'utility/analytics';
import * as Sentry from '@sentry/react';
import {
  Logo,
  BackgroundImg,
  BackgroundImgWrapper,
} from 'theme/AuthForms.styles';
import { noop } from 'lodash';

const { spacing, logo_url: logoUrl } = variables;

export default class ErrorBoundry extends Component {
  constructor(props) {
    super(props);
    this.state = { error: null, eventId: null };
  }

  componentDidCatch(error, errorInfo) {
    this.setState({ error });
    Sentry.withScope((scope) => {
      scope.setExtras(errorInfo);
      const eventId = Sentry.captureException(error);
      this.setState({ eventId });
    });
  }

  render() {
    const { error, set, eventId } = this.state;
    if (error) {
      // render fallback UI
      return (
        <div onClick={noop} onKeyDown={noop} role="button" tabIndex={-1}>
          <div style={{ padding: '50px 20px' }}>
            <Flex justifyCenter>
              <FlexItem>
                <Logo src={logoUrl} />
              </FlexItem>
            </Flex>

            <Flex alignCenter justifyCenter>
              <FlexItem>
                {set ? (
                  <XXXL
                    bold
                    center
                    style={{ padding: `0 0 ${spacing} 0` }}
                    tag="h1"
                  >
                    Critical error. Contact support.
                  </XXXL>
                ) : (
                  <XXXL
                    bold
                    center
                    style={{ padding: `0 0 ${spacing} 0` }}
                    tag="h1"
                  >
                    Sorry, an error occurred.
                  </XXXL>
                )}
                <LG grey paddingBottomLg center tag="h3">
                  Error: {error.message}
                </LG>
                <Button
                  style={{ width: '300px' }}
                  center
                  large
                  onClick={() => {
                    trackClick(
                      'reportDialog',
                      'Report feedback',
                      'ErrorBoundry'
                    );
                    Sentry.showReportDialog({ eventId });
                  }}
                  primary
                >
                  Report Feedback
                </Button>

                <a
                  href="mailto:sales@pipesearch.com"
                  style={{
                    margin: '20px auto',
                    display: 'block',
                    textAlign: 'center',
                    width: '300px',
                    color: `#414141`,
                  }}
                >
                  {' '}
                  Email sales@pipesearch.com
                </a>

                <a
                  href="/"
                  style={{
                    fontWeight: 'bold',
                    textAlign: 'center',
                    margin: '50px auto',
                    display: 'block',
                    width: '200px',
                    color: `#414141`,
                  }}
                >
                  {' '}
                  Go To Home Page
                </a>
              </FlexItem>
            </Flex>

            <BackgroundImgWrapper>
              <BackgroundImg src="/images/hero-pipes.png" />
            </BackgroundImgWrapper>
          </div>
        </div>
      );
    }
    // when there's not an error, render children untouched
    const { children } = this.props;
    return children;
  }
}

ErrorBoundry.propTypes = {
  children: PropTypes.shape({}),
};
