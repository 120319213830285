import Checkbox from 'components/Checkbox/Checkbox';
import styled from 'styled-components/macro';
import { variables } from 'theme/variables';

const {
  font_size_sm: fontSizeSm,
  spacing_lg: spacingLg,
  spacing_md: spacingMd,
  spacing_sm: spacingSm,
  custom_light_grey: customLightGrey,
  custom_blue: customBlue,
  color_white: colorWhite,
  custom_silver: customSilver,
  font_weight_semibold: fontWeightSemibold,
  custom_lighter_grey: customLighterGrey,
} = variables;

export const EndFinishFilterItemSelectionWrapper = styled('div')`
  position: relative;
  padding: 0 ${spacingLg};
  padding-bottom: ${spacingMd};
  padding-top: ${spacingSm};
`;

export const SelectionCol = styled('div')`
  height: 220px;
  border: 1px solid ${customLightGrey};
  margin-top: ${spacingSm};
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  overflow-scrolling: touch;
  position: relative;
  ${(props) =>
    props.selected
      ? `
    @media(max-width: 620px) {
      display: none;
    }
  `
      : ''}
`;

export const EmptySelectionCol = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: ${spacingSm};
  text-align: center;
  height: 100%;
  color: ${customSilver};
`;

export const Selected = styled('div')`
  width: 10px;
  height: 10px;
  background-color: ${customBlue};
  position: absolute;
  left: 14px;
  ${(props) =>
    props.disabled
      ? `
    background-color: ${customLightGrey};
  `
      : ''}
  ${(props) =>
    props.small
      ? `
    left: 9px;
  `
      : ''}
`;

export const Item = styled('div')`
  word-wrap: break-word;
  display: block;
  position: relative;
  z-index: 0;
  cursor: pointer;
  padding-left: 32px;
  padding-right: 5px;
  height: 32px;
  text-decoration: none;
  line-height: ${fontSizeSm};
  font-size: ${fontSizeSm};
  word-wrap: break-word;
  user-select: none;
  // Children are always wrapped in a Flex component, so we set its height and width here to properly align and justify its children
  & > div {
    width: 100%;
    height: 100%;
  }
  .set-primary-alloy-button {
    display: none;
  }
  &:hover {
    background-color: ${customLightGrey};
    .set-primary-alloy-button {
      display: block;
    }
  }
  ${(props) =>
    props.active
      ? `
    background-color: ${customBlue} !important;
    color: ${colorWhite};
  `
      : ''}
  ${(props) =>
    props.selected
      ? `
    background-color: ${customBlue} !important;
    color: ${colorWhite};
  `
      : ''}

  ${(props) =>
    props.headerItem
      ? `
    background-color: ${customLighterGrey} !important;
    point-events: none;
    cursor: default;
    font-weight: ${fontWeightSemibold}
    padding-left: 13px;
  `
      : ''}
`;

export const SetPrimaryAlloyButton = styled('button')`
  padding: 3px;
  background-color: ${colorWhite};
  border-radius: 5px;
  border: none;

  &:hover {
    background-color: ${customBlue};
    color: ${colorWhite};
  }
`;

export const PrimaryAlloyNonInteractiveCheckbox = styled(Checkbox)`
  * {
    cursor: default !important;
  }
`;
