import React from 'react';
import AlloyTable from 'components/AlloyTable/AlloyTable';
import GlobalModalHeader from '../../GlobalModalHeader';

const AllAlloyModal = (props) => {
  return (
    <div style={{ maxWidth: '800px', ...(props.styles || {}) }}>
      <GlobalModalHeader title="All Alloys" />
      <AlloyTable data={props.alloys} />
    </div>
  );
};

export default AllAlloyModal;
