import Baobab from 'baobab';
import { get } from 'lodash';

export function state(initialState) {
  return {
    activeId: null,
    summary: {},
    activeRfqInterest: initialActiveRfqInterest(), //details page
    supplyAssessment: initialSupplyAssessment(), //details page

    map: {},
    pagination: {},
    activeMapitem: null,
    list: interestList('rfq'),
    activeInterest: activeInterest('rfq'), // sidebar
    activeInterestItems: activeInterestItems('rfq'), //sidebar
  };
}

export function initialActiveRfqInterest() {
  return {
    initialFeedback: {},
    buildRfq: {
      rfqItemsQuoteItems: {},
      terms: null,
      // these get reset
      quoteItemPricing: null,
      paymentTermsFormData: null,
      deliveryTermsFormData: null,
    },
  };
}

export function initialSupplyAssessment() {
  return {
    rfqItem: undefined,
    activeInventory: {
      inventory: undefined,
      criteria: undefined,
      quoteItems: undefined,
    },
  };
}

function interestList(type) {
  return Baobab.monkey({
    cursors: {
      data: ['rfqInterests', 'map'],
    },
    get({ data }) {
      const list = Object.keys(data).map((id) => data[id]);
      return list;
    },
  });
}

function activeInterestItems(type) {
  return Baobab.monkey({
    cursors: {
      activeId: ['rfqInterests', 'activeId'],
      items: ['rfqInterests', 'items'],
    },
    get({ items = {}, activeId }) {
      const data = get(items, `[${activeId}].docs`);
      const list = data ? Object.keys(data).map((id) => data[id]) : [];
      return list;
    },
  });
}

function activeInterest(type) {
  return Baobab.monkey({
    cursors: {
      activeId: ['rfqInterests', 'activeId'],
      data: ['rfqInterests', 'map'],
    },
    get({ data = {}, activeId }) {
      return data[activeId];
    },
  });
}
