import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { closeModal } from 'state/modals/actions';
import Icon from 'components/Icon/Icon';
import { XL } from '@zendeskgarden/react-typography';
import styled from 'styled-components/macro';
import Flex from 'styled-flex-component';
import PageViewWrapper from 'higherOrder/PageViewWrapper';
import { Divider } from 'theme/Global.styles';
import history from 'historyContainer';
import { variables } from 'theme/variables';
import {
  Item,
  Menu,
  Label,
  Field as DropdownField,
  Dropdown,
  Select,
} from '@zendeskgarden/react-dropdowns';
import { Button } from 'theme/Button';
import { isEmpty, map, replace, toLower } from 'lodash';
import { PIPE_SEARCH_ASSET_MANAGEMENT_URL } from 'constants.js';
import { matchShape } from 'propTypesObjects';
import { supplyOrderOutcomeList } from '../../../../utility/staticMap.ts';

const { custom_border_grey: customBorderGrey } = variables;

export const Wrapper = styled.section`
  width: 500px;
  max-width: 90%;
`;

function CreateOrderModal({ data, styles }) {
  const { data: propData, match } = data || {};
  const [orderType, setOrderType] = useState(null);

  const initiateOrderWorkflow = () => {
    if (propData?.order_status === 'no order') {
      history.push(
        `${match.url}/m/add-new-supply-order/documents/${toLower(
          replace(orderType, ' ', '-')
        )}`
      );
      closeModal({ ignoreCloseWarn: true });
    } else if (propData?.order_status === 'new') {
      history.push(
        `${match.url}/m/add-new-supply-order/${
          propData.order.id
        }/documents/${toLower(replace(orderType, ' ', '-'))}`
      );
      closeModal({ ignoreCloseWarn: true });
    } else if (propData?.order_status === 'processed') {
      // link to Asset Management
      window.open(
        `${PIPE_SEARCH_ASSET_MANAGEMENT_URL}/orders/${propData.order.am_order_uuid}`,
        '_blank'
      );
    }
  };

  return (
    <Wrapper
      style={{
        margin: '0 auto',
        maxWidth: '800px',
        ...(styles || { margin: '0 auto' }),
      }}
    >
      <Icon
        onClick={closeModal}
        buttonStyle
        icon="icon-close"
        fontSize="15px"
      />
      <Flex
        className="wrapper"
        column
        justifyBetween
        style={{ padding: '20px' }}
      >
        <XL style={{ marginBottom: '10px' }}>
          What kind of Acquisition is this?
        </XL>
        <Divider horizontal background={customBorderGrey} />
        <Dropdown
          selectedItem={orderType}
          onSelect={(...args) => setOrderType(...args)}
        >
          <DropdownField style={{ marginTop: '25px' }}>
            <Label>Select Outcome</Label>
            <Select>{orderType || 'Select New Status'}</Select>
          </DropdownField>
          <Menu maxHeight="500px">
            {map(supplyOrderOutcomeList, (outcome) => (
              <Item key={outcome?.key} value={outcome?.title || orderType}>
                {outcome?.title || orderType}
              </Item>
            ))}
          </Menu>
        </Dropdown>
        <Button
          onClick={() => initiateOrderWorkflow()}
          center
          spacingTop
          style={{ marginTop: '35px', marginBottom: '20px' }}
          primary
          disabled={isEmpty(orderType)}
        >
          Save & Initiate the Order
        </Button>
      </Flex>
    </Wrapper>
  );
}

CreateOrderModal.propTypes = {
  styles: PropTypes.shape({
    background: PropTypes.string,
    bottom: PropTypes.string,
    boxShadow: PropTypes.string,
    left: PropTypes.string,
    position: PropTypes.string,
    right: PropTypes.string,
    top: PropTypes.string,
  }),
  data: PropTypes.shape({
    data: PropTypes.shape({
      order_status: PropTypes.string,
      order: PropTypes.shape({
        id: PropTypes.number,
        am_order_uuid: PropTypes.string,
      }),
    }),
    match: matchShape,
  }),
};

export default PageViewWrapper(CreateOrderModal, {
  pageType: 'modal',
  pageName: 'CreateOrderModal',
});
