import React, { useState } from 'react';
import Flex from 'styled-flex-component';
import { groupBy, startCase, find } from 'lodash';
import { SM } from '@zendeskgarden/react-typography';
import {
  Select,
  Dropdown,
  Menu,
  Field,
  Item,
} from '@zendeskgarden/react-dropdowns';
import { variables } from 'theme/variables';
import { Selected } from '../../EndFinishFilterItemSelection.styles.js';
const { custom_blue } = variables;

const EndFinishFilterDropdown = ({
  options,
  onSelectFilter,
  title,
  activeFilters,
}) => {
  const [menuIsOpen, setMenuIsOpen] = useState(false);
  const SelectionItem = ({ name, active }) => (
    <Flex alignCenter>
      {active ? <Selected small /> : null}
      <SM bold style={{ color: 'inherit' }}>
        {name}
      </SM>
    </Flex>
  );

  function onSelectItem(option) {
    const active = find(activeFilters, {
      value: option.value,
      label: option.label,
    });
    onSelectFilter(option, active);
  }

  const filterCount = groupBy(activeFilters, 'type');

  return (
    <Dropdown
      small
      onSelect={onSelectItem}
      downshiftProps={{ itemToString: (item) => item && item.label }}
      onStateChange={(changes) => {
        if (
          changes.isOpen !== menuIsOpen &&
          Object.prototype.hasOwnProperty.call(changes, 'isOpen')
        ) {
          setMenuIsOpen(changes.isOpen);
        }
      }}
    >
      <Field>
        <Select noOutline={true} small>
          <SM bold>
            {startCase(title.replace('end_finish', ''))}{' '}
            <span style={{ color: custom_blue }}>
              {filterCount[title] ? `(${filterCount[title].length})` : ''}
            </span>
          </SM>
        </Select>
      </Field>
      {menuIsOpen ? (
        <Menu small={true} style={{ minWidth: '250px' }} maxHeight={'200px'}>
          {options.map((option) => {
            const active = find(activeFilters, {
              value: option.value,
              label: option.label,
            });
            return (
              <Item active={!!active} key={option.value} value={option}>
                <SelectionItem
                  active={!!active}
                  color={option.value}
                  name={option.label}
                />
              </Item>
            );
          })}
        </Menu>
      ) : null}
    </Dropdown>
  );
};

export default EndFinishFilterDropdown;
