import { onboardingReverser, baseValidation } from 'utility/dynamicFormHelpers';
import { variables } from 'theme/variables';

const { media_md: mediaMd } = variables;

export default function generatePartnerCustomer({ seedData } = {}) {
  let config = {
    customer: {
      fields: {
        first_name: {
          required: true,
          value: { value: '', label: '' },
          validation: baseValidation,
          ui: {
            sm: 12,
            md: '6',
            lg: '6',
            extraPadding: mediaMd,
          },
        },
        last_name: {
          required: true,
          value: { value: '', label: '' },
          validation: baseValidation,
          ui: {
            sm: 12,
            md: '6',
            lg: '6',
            extraPadding: mediaMd,
          },
        },
        email: {
          required: true,
          value: { value: '', label: '' },
          validation: baseValidation,
          ui: {
            sm: 12,
            md: '6',
            extraPadding: mediaMd,
          },
        },
        company_name: {
          required: true,
          value: { value: '', label: '' },
          validation: baseValidation,
          ui: {
            sm: 12,
            md: '6',
            extraPadding: mediaMd,
          },
        },
        mobile_phone: {
          phone: true,
          required: false,
          clearable: true,
          value: { value: '', label: '' },
          validation: baseValidation,
          ui: {
            sm: 12,
            md: '6',
            extraPadding: mediaMd,
          },
        },
        work_phone: {
          phone: true,
          required: false,
          clearable: true,
          value: { value: '', label: '' },
          validation: baseValidation,
          ui: {
            sm: 12,
            md: '6',
            extraPadding: mediaMd,
          },
        },
      },
    },
  };

  if (seedData) {
    config = onboardingReverser(config, seedData);
    config.valid = true;
  } else {
    config.temp = true;
  }
  return config;
}

export function generateBulkUserUpload() {
  const config = {
    bulkUsers: {
      fields: {
        tag: {
          required: false,
          customValues: false,
          key: 'tag',
          validation: baseValidation,
          ignoreCustomFieldValidation: true,
          multiSelect: true,
          items: [],
          value: [],
          ui: {
            title: 'Bulk User Tags',
            condensed: true,
            placeholder: 'Enter Tag',
            sm: 12,
            md: 12,
            lg: 12,
            extraPadding: mediaMd,
          },
        },
      }
    }
  }
  config.temp = true;
  return config;
}
