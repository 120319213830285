export function state(initialState) {
  return {
    listings: {},
    newListing: defaultNewListing(),
    activeListing: defaultActiveListing(),
  };
}

export function defaultNewListing() {
  return {
    rfqItemFilters: {},
    inventory: {},
    customQuoteItem: {},
    listingType: null,
    listingStatus: null,
  };
}

export function defaultActiveListing() {
  return {};
}
