import React, { Component } from 'react';
import Icon from 'components/Icon/Icon';
import menuAim from 'higherOrder/menuAim';
import { NavLink as Link } from 'react-router-dom';
import NavigationDropdownMenu from './NavigationDropdownMenu';
import { get, find, map } from 'lodash';
import { trackClick } from 'utility/analytics';
import { StyledMenu } from './ProductsDropdownMenu.styles';

class ProductsDropdownMenu extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    this.props.initMenuAim({
      submenuDirection: 'right',
      menuRef: this.menuRef,
      delay: 300,
      tolerance: 75,
    });
  }

  render() {
    const subcategory = find(this.props.fullCategories, {
      name: get(this.state, 'selectedCategory.name'),
    });
    const hasSubCategories = get(
      this.state,
      'selectedCategory.subcategories.length'
    );
    return (
      <StyledMenu>
        <NavigationDropdownMenu
          link={`/c/octg`}
          customTitle="OCTG Products"
          className={`${hasSubCategories ? 'with-sub' : ''} dropdown-menu`}
          onClickMenu={this.onClickMenu.bind(this)}
          onClose={this.onClose.bind(this)}
          {...this.props}
        >
          <div
            className="categories"
            onMouseLeave={this.props.handleMouseLeaveMenu}
            ref={(r) => (this.menuRef = r)}
          >
            {map(this.props.fullCategories, (cat, i) => {
              return (
                <div className="link-wrapper" key={i}>
                  <Link
                    key={i}
                    to={`/c/${cat.route}`}
                    className={`dropdown-link ${
                      cat.name === get(this.state, 'selectedCategory.name')
                        ? 'active'
                        : ''
                    }`}
                    onClick={this.onClickCategoryWithSubcategory.bind(
                      this,
                      cat.name
                    )}
                    onMouseEnter={this.props.handleMouseEnterRow.bind(
                      this,
                      cat.name,
                      this.handleSwitchMenuIndex.bind(this)
                    )}
                  >
                    <h5>{cat.name}</h5>
                  </Link>
                  {true ? (
                    <div className="chev">
                      <Icon
                        icon={'icon-chevron-right'}
                        fontSize={'7px'}
                        style={{ padding: 0 }}
                      />
                    </div>
                  ) : null}
                </div>
              );
            })}
          </div>
          <div className={`subcategories ${!hasSubCategories ? 'hidden' : ''}`}>
            {subcategory && subcategory.subcategories.length
              ? this.renderSubcategory({ subcategory, i: 0 })
              : null}
          </div>
        </NavigationDropdownMenu>
      </StyledMenu>
    );
  }

  renderSubcategory({ subcategory, i }) {
    return (
      <div
        key={subcategory.name + i}
        className={`subcat-container ${
          get(this.state, 'selectedCategory.name') !== subcategory.name
            ? 'hidden'
            : ''
        }`}
      >
        <Link
          to={`/c/${subcategory.route}`}
          onClick={this.onClickCategory.bind(this, subcategory.name, true)}
          className="cdropdown-link"
        >
          <h5 className="sub-cat-title">All {subcategory.name}</h5>
        </Link>
        {map(subcategory.subcategories, (sub, i) => {
          return (
            <Link
              key={i}
              to={`/c/${sub.route}`}
              onClick={this.onClickCategory.bind(this, sub.name, true)}
              className="dropdown-link"
            >
              <h5>{sub.name}</h5>
            </Link>
          );
        })}
      </div>
    );
  }

  onClickMenu() {
    return !this.state.selectedCategory;
  }

  onClose() {
    this.setState({
      selectedCategory: false,
      showCategories: false,
      subcategory: null,
    });
  }

  handleSwitchMenuIndex(name) {
    this.onClickCategoryWithSubcategory(name);
  }
  openCategories() {
    trackClick('showCategories', 'categories', 'header');
    this.setState({ showCategories: true });
  }

  onCloseCategories() {
    trackClick('closeCategories', 'categories', 'header');
    this.setState({
      selectedCategory: false,
      showCategories: false,
      subcategory: null,
    });
  }

  setSubcategory(title, categories) {
    this.setState({
      selectedCategory: {
        title,
        categories,
      },
    });
  }

  onClickCategory(name, sub) {
    trackClick('category', name, 'header', { subcategory: !!sub });
    if (sub) {
      this.onCloseCategories();
    }
  }

  onClickCategoryWithSubcategory(name) {
    const category = find(this.props.fullCategories, { name });
    this.setState({
      selectedCategory: category,
    });
  }
}

export default menuAim(ProductsDropdownMenu);
