import React, { useState, useRef, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import Icon from 'components/Icon/Icon';
import {
  debounce,
  map,
  filter,
  includes,
  toLower,
  trim,
  size,
  isEmpty,
} from 'lodash';
import {
  Dropdown,
  Menu,
  Autocomplete,
  Item,
  Field,
} from '@zendeskgarden/react-dropdowns';
import styled from 'styled-components/macro';
import { variables } from 'theme/variables';

const { custom_placeholder: customPlaceholder } = variables;

export const Wrapper = styled.div`
  background: #fff;
  .inactive {
    color: ${customPlaceholder} !important;
  }
  .reset-icon {
    position: absolute;
    top: 0;
    right: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    width: 20px;
    height: 40px;
    line-height: 30px;
  }
`;

function AutoCompleteFormItem({
  reset,
  style,
  defaultValue,
  optionValues = [],
  selectedItem,
  setSelectedValue,
  popperModifiers,
  search,
  awaitOptionValues,
  active,
  maxHeight,
  fullBorder,
  fontSize,
}) {
  const [inputValue, setInputValue] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [searching, setSearching] = useState(false);
  const [isLoadingOptionValues, setLoadingOptionValues] = useState(false);
  const [matchingOptions, setMatchingOptions] = useState(optionValues);
  const searchRef = useRef(search);

  useEffect(() => {
    searchRef.current = search;
  }, [search]);

  async function awaitOptions(value) {
    setLoadingOptionValues(true);
    const optionResult = await awaitOptionValues(value);
    setMatchingOptions(optionResult);
    setLoadingOptionValues(false);
  }

  async function searchOptions(value) {
    setSearching(true);
    const searchResult = await searchRef.current(value);
    setMatchingOptions(searchResult);
    setSearching(false);
  }

  const filterMatchingOptionsRef = useRef(
    debounce((value) => {
      const currentMatchingOptions = filter(optionValues, (option) =>
        includes(
          toLower(trim(option.label || option.name || '')),
          toLower(trim(value || ''))
        )
      );

      if (!currentMatchingOptions.length && searchRef.current && value) {
        searchOptions(value);
      } else if (!currentMatchingOptions.length && awaitOptionValues) {
        awaitOptions(value);
      } else {
        setMatchingOptions(currentMatchingOptions);
      }
      setIsLoading(false);
    }, 300)
  );

  useEffect(() => {
    setIsLoading(true);
    filterMatchingOptionsRef.current(inputValue);
  }, [inputValue]);

  const renderOptions = () => {
    const options = matchingOptions || [];

    if (isLoading || searching || isLoadingOptionValues) {
      return <Item disabled>Loading items...</Item>;
    }

    if (isEmpty(options) && size(inputValue) <= 1) {
      return <Item disabled>Enter more than one character</Item>;
    }

    if (isEmpty(options) && size(inputValue) >= 2) {
      return <Item disabled>No matches found</Item>;
    }

    if (isEmpty(options) && !inputValue) {
      return <Item disabled>Search or Add Value</Item>;
    }

    if (options && options.error) {
      return (
        <Item disabled>
          <span style={{ color: 'red', fontSize: '11px' }}>
            {options.error}
          </span>
        </Item>
      );
    }

    return map(options, (option) => (
      <Item
        key={option.value || option.name}
        value={option}
        disabled={option.disabled}
      >
        <span>{option.name || option.label}</span>
      </Item>
    ));
  };

  const label = selectedItem && selectedItem.label;

  const handleSelectItem = useCallback(
    (item) => {
      if (item?.value && item.value !== selectedItem?.value) {
        setSelectedValue(item);
      } else if (item?.name && item.name !== selectedItem?.name) {
        setSelectedValue(item);
      } else if (!item?.value && !item?.name) {
        setSelectedValue(item);
      }
    },
    [selectedItem?.name, selectedItem?.value, setSelectedValue]
  );

  return (
    <Wrapper style={style}>
      <Dropdown
        inputValue={inputValue || ''}
        selectedItem={selectedItem}
        popperModifiers={popperModifiers}
        downshiftProps={{
          defaultHighlightedIndex: 0,
          itemToString: (item) => item && (item.name || item.label),
        }}
        onSelect={handleSelectItem}
        optionValues={optionValues}
        onStateChange={(changes) => {
          if (
            Object.prototype.hasOwnProperty.call(changes, 'inputValue') &&
            changes.type !== '__autocomplete_blur_input__'
          ) {
            setInputValue(changes.inputValue);
          } else if (
            Object.prototype.hasOwnProperty.call(changes, 'inputValue')
          ) {
            setInputValue('');
          }
        }}
      >
        <Field>
          <Autocomplete
            active={active}
            ellipsis
            itemSelection
            fontSize={fontSize}
            fullBorder={fullBorder !== false}
            style={{
              width: '100%',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              paddingRight: reset && label ? '60px' : undefined,
            }}
          >
            <span>
              <span title={label || defaultValue}>{label || defaultValue}</span>

              {reset && label ? (
                <span
                  className="reset-icon"
                  role="button"
                  tabIndex={0}
                  onClick={(e) => {
                    e.stopPropagation();
                    setSelectedValue(null);
                  }}
                  onKeyDown={(e) => {
                    e.stopPropagation();
                    setSelectedValue(null);
                  }}
                  aria-label="Reset"
                >
                  <Icon pointer icon="icon-close" fontSize="8px" />
                </span>
              ) : null}
            </span>
          </Autocomplete>
        </Field>
        <Menu
          popperModifiers={popperModifiers}
          maxHeight={maxHeight || '200px'}
          isCompact
        >
          {renderOptions()}
        </Menu>
      </Dropdown>
    </Wrapper>
  );
}

AutoCompleteFormItem.propTypes = {
  reset: PropTypes.bool,
  // LINT OVERRIDE #6
  // Object has undetermined keys
  // eslint-disable-next-line react/forbid-prop-types
  style: PropTypes.object,
  defaultValue: PropTypes.string,
  optionValues: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      label: PropTypes.string,
    })
  ),
  selectedItem: PropTypes.oneOfType([
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      label: PropTypes.string,
    }),
    PropTypes.string,
  ]),
  setSelectedValue: PropTypes.func,
  // LINT OVERRIDE #6
  // Object has undetermined keys
  // eslint-disable-next-line react/forbid-prop-types
  popperModifiers: PropTypes.object,
  search: PropTypes.func,
  awaitOptionValues: PropTypes.func,
  active: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  maxHeight: PropTypes.string,
  fullBorder: PropTypes.bool,
  fontSize: PropTypes.string,
};

export default AutoCompleteFormItem;
