export default function state() {
  return {
    companies: {
      currentCompanies: {},
    },
    sidebarCompany: null,
    activeCompany: {
      details: null,
    },
  };
}
