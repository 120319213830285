import tree from 'state';
import { trackClick } from 'utility/analytics';

export function toggleDrawer() {
  const value = !tree.get(['sidebars', 'mobileDrawerOpen']);
  trackClick('toggleMobileDrawer', 'hamburger', 'mobileHeader', {
    open: value,
  });
  tree.merge(['sidebars'], {
    mobileDrawerOpen: value,
  });
}

export function openSidebar({
  type = 'test',
  data = {},
  options = {},
  view = '',
}) {
  trackClick('openSidebar', type, view);
  tree.merge(['sidebars'], {
    sidebarOpen: true,
    sidebarType: type,
    sidebarData: data,
    options,
  });
}

export function closeSidebar() {
  const type = tree.get(['sidebars', 'sidebarType']);
  trackClick('closeSidebar', type, 'sidebar');
  tree.merge(['sidebars'], {
    sidebarOpen: false,
  });
  setTimeout(() => {
    tree.merge(['sidebars'], {
      sidebarType: null,
      sidebarData: null,
      options: null,
    });
  }, 300);
}
