import {
  isNumber,
  intersectionWith,
  map,
  find,
  get,
  size,
  isArray,
  forEach,
} from 'lodash';

function getCategory(c, data, localSeedCategory) {
  const { category_id: categoryId, category } = data || {};
  if (c.multiple) {
    // this is for filtering method
    return {
      value: intersectionWith(
        c.items,
        get(category, 'length') ? category : [category],
        (v, s) => v.value === Number(s)
      ),
    };
  }

  const categoryValue = find(c.items, {
    value:
      categoryId ||
      category ||
      localSeedCategory?.value?.value ||
      Number(categoryId) ||
      Number(category),
  });
  return {
    value: categoryValue || null,
  };
}

function standardValueFinder(
  metadata,
  { value, min, max, minAndMaxNotRequired }
) {
  const newValue = {};
  const hasMinMax = minAndMaxNotRequired ? min || max : min || max;
  if (hasMinMax && min !== max) {
    let minValue;
    if (!min) {
      minValue = undefined;
    } else if (metadata) {
      minValue = find(
        metadata,
        ({ value: _value }) => _value === min || Number(_value) === Number(min)
      ) || { label: min, value: min };
    } else {
      minValue = { label: min, value: min };
    }
    let maxValue;
    if (!max) {
      maxValue = undefined;
    } else if (metadata) {
      maxValue = find(
        metadata,
        ({ value: _value }) => _value === max || Number(_value) === Number(max)
      ) || { label: max, value: max };
    } else {
      maxValue = { label: max, value: max };
    }
    newValue.value = {};
    newValue.value.min = minValue;
    newValue.value.max = maxValue;
    newValue.operator = { value: 'range', label: 'Range' };
  } else if (min || max || value) {
    const anyValue = value || max || min;
    const endValue = metadata
      ? find(
          metadata,
          ({ value: _value }) =>
            _value === max || Number(_value) === Number(anyValue)
        ) || { label: anyValue, value: anyValue }
      : { label: anyValue, value: anyValue };
    newValue.value = endValue;
  }
  return newValue;
}

function getOd(
  standardOd,
  { od, od_max: odMax, od_min: odMin, minAndMaxNotRequired },
  localSeedOd
) {
  return standardValueFinder(standardOd.items, {
    value: od || localSeedOd?.value?.value,
    min: odMin,
    max: odMax,
    minAndMaxNotRequired,
  });
}

function getId(
  standardInsideDiameter,
  {
    inside_diameter: insideDiameter,
    inside_diameter_min: insideDiameterMin,
    inside_diameter_max: insideDiameterMax,
    minAndMaxNotRequired,
  },
  localSeedInsideDiameter
) {
  return standardValueFinder(standardInsideDiameter.items, {
    value: insideDiameter || localSeedInsideDiameter?.value?.value,
    min: insideDiameterMin,
    max: insideDiameterMax,
    minAndMaxNotRequired,
  });
}

function getWall(
  _standardWall,
  { wall, wall_max: wallMax, wall_min: wallMin, minAndMaxNotRequired },
  localSeedWall
) {
  return standardValueFinder(null, {
    value: wall || localSeedWall?.value?.value,
    min: wallMin,
    max: wallMax,
    minAndMaxNotRequired,
  });
}

function getWeight(
  standardWeight,
  {
    weight_per_foot: weightPerFoot,
    weight_per_foot_max: weightPerFootMax,
    weight_per_foot_min: weightPerFootMin,
    minAndMaxNotRequired,
  },
  localSeedWeightPerFoot
) {
  return standardValueFinder(standardWeight.items, {
    value: weightPerFoot || localSeedWeightPerFoot?.value?.value,
    min: weightPerFootMin,
    max: weightPerFootMax,
    minAndMaxNotRequired,
  });
}

function getMinYield(
  _standardMinYield,
  {
    min_yield: minYield,
    min_yield_max: minYieldMax,
    min_yield_min: minYieldMin,
    minAndMaxNotRequired,
  },
  localSeedMinYield
) {
  return standardValueFinder(null, {
    value: minYield || localSeedMinYield?.value?.value,
    min: minYieldMin,
    max: minYieldMax,
    minAndMaxNotRequired,
  });
}

function getRange(
  standardRange,
  {
    range,
    range_id: rangeId,
    lengths_min: lengthsMin,
    lengths_max: lengthsMax,
    minAndMaxNotRequired,
  },
  localSeedRange
) {
  const _rangeId = rangeId || (isNumber(range) ? range : undefined);
  return standardValueFinder(standardRange.items, {
    value: _rangeId || localSeedRange?.value?.value,
    min: lengthsMin,
    max: lengthsMax,
    minAndMaxNotRequired,
  });
}

function getLengthsExact(
  _standardLengthsExact,
  { lengths_exact: lengthsExact, minAndMaxNotRequired },
  localSeedLengthsExact
) {
  return standardValueFinder(null, {
    value: lengthsExact || localSeedLengthsExact?.value?.value,
    minAndMaxNotRequired,
  });
}

function getQuantity(
  standardQuantity,
  standardCategory,
  {
    quantity,
    quantity_uom: quantityUom,
    quantity_uom_id: quantityUomId,
    category_name: categoryName,
    minAndMaxNotRequired,
  },
  localSeedQuantity
) {
  const value = standardValueFinder(null, {
    value: quantity || localSeedQuantity?.value?.value,
    minAndMaxNotRequired,
  });
  /*
    Initially set operators according to category
    This prevents FilterItemGroup immediately setting dirty state when seed category quantity UOM is Unit,
    because the initial list of UOMs is scalar and the list is updated when the field UOM list doesn't match
    the list returned by filterOperators.
  */
  const currentFiltersMock = {
    category: {
      value: {
        label: categoryName,
      },
    },
  };
  const operators = standardQuantity.filterOperators(currentFiltersMock);
  const operator =
    find(operators, { value: quantityUomId }) ||
    find(operators, { value: quantityUom }) ||
    standardQuantity.operator;
  return {
    ...value,
    operator,
    operators,
  };
}

function getEndFinish(
  standardEndFinish,
  {
    end_finish: endFinish,
    end_finish_category: endFinishCategory,
    end_finish_licensee: endFinishLicensee,
    end_finish_thread_type: endFinishThreadType,
    end_finish_other: endFinishOther,
  },
  localSeedEndFinish
) {
  const standardEndFinishItems = standardEndFinish.items;
  let endFinishSeed = endFinish || map(localSeedEndFinish?.value, 'value');
  if (endFinishSeed && !isArray(endFinishSeed)) {
    endFinishSeed = [endFinishSeed];
  }
  const standardEndFinishCategorySelectionFilters =
    standardEndFinish.selectionFilters.end_finish_category;
  const standardEndFinishLicenseeSelectionFilters =
    standardEndFinish.selectionFilters.end_finish_licensee;
  const standardEndFinishThreadTypeSelectionFilters =
    standardEndFinish.selectionFilters.end_finish_thread_type;
  let endFinishFinal = map(endFinishSeed, (f) => {
    const found =
      find(standardEndFinishItems, { value: f }) ||
      find(standardEndFinishItems, { value: Number(f) });
    return found;
  });
  const endFinishCategoryFinal = map(endFinishCategory, (c) => {
    const found =
      find(standardEndFinishCategorySelectionFilters, { value: c }) ||
      find(standardEndFinishCategorySelectionFilters, { value: Number(c) });
    return found;
  });
  const endFinishLicenseeFinal = map(endFinishLicensee, (l) => {
    const found =
      find(standardEndFinishLicenseeSelectionFilters, { value: l }) ||
      find(standardEndFinishLicenseeSelectionFilters, { value: Number(l) });
    return found;
  });
  const endFinishThreadTypeFinal = map(endFinishThreadType, (l) => {
    const found =
      find(standardEndFinishThreadTypeSelectionFilters, { value: l }) ||
      find(standardEndFinishThreadTypeSelectionFilters, { value: Number(l) });
    return found;
  });

  if (size(endFinishOther)) {
    const endFinishCustom = map(endFinishOther, (f) => ({
      customValue: true,
      value: f,
      label: f,
      selected: true,
    }));
    endFinishFinal = [...endFinishFinal, ...endFinishCustom];
  }

  const selectedFilters = [
    ...endFinishCategoryFinal,
    ...endFinishLicenseeFinal,
    ...endFinishThreadTypeFinal,
  ];
  return { selectedFilters, value: endFinishFinal };
}

function getAlloy(
  alloy,
  {
    metallurgy,
    metallurgy_group: metallurgyGroup,
    metallurgy_other: metallurgyOther = [],
    metallurgy_primary_id: metallurgyPrimaryId,
  },
  localSeedAlloy
) {
  let _metallurgy = metallurgy || map(localSeedAlloy?.value, 'value');
  if (_metallurgy && !isArray(_metallurgy)) {
    _metallurgy = [_metallurgy];
  }
  let alloys = alloy.items;
  let alloyGroups = alloy.selectionFilters.groups;

  alloys = map(_metallurgy, (f) => {
    const found =
      find(alloys, { value: f }) || find(alloys, { value: Number(f) });
    return found;
  });
  forEach(alloys, (alloyObj) => {
    const _alloyObj = alloyObj;
    _alloyObj.primary_alloy =
      isNumber(metallurgyPrimaryId) && alloyObj.value === metallurgyPrimaryId;
  });

  alloyGroups = map(metallurgyGroup, (c) => {
    const found =
      find(alloyGroups, { value: c }) ||
      find(alloyGroups, { value: Number(c) });
    return found;
  });

  if (get(metallurgyOther, 'length')) {
    const alloysCustom = map(metallurgyOther, (f) => ({
      customValue: true,
      value: f,
      label: f,
      selected: true,
    }));
    alloys = [...alloys, ...alloysCustom];
  }

  const selectedGroups = [...alloyGroups];
  return { selectedFilters: { groups: selectedGroups }, value: alloys };
}

export default function reverseItemFilters(
  metadata,
  serverValues = {},
  localSeed = {}
) {
  const {
    category,
    od,
    inside_diameter: insideDiameter,
    wall,
    weight_per_foot: weightPerFoot,
    min_yield: minYield,
    range,
    lengths_exact: lengthsExact,
    quantity,
    alloy,
    end_finish: endFinish,
  } = metadata;

  const _metadata = metadata;

  _metadata.category = {
    ...category,
    ...getCategory(category, serverValues, localSeed.category),
  };
  _metadata.od = { ...od, ...getOd(od, serverValues, localSeed.od) };
  _metadata.inside_diameter = {
    ...insideDiameter,
    ...getId(insideDiameter, serverValues, localSeed.inside_diameter),
  };
  _metadata.wall = { ...wall, ...getWall(wall, serverValues, localSeed.wall) };
  _metadata.weight_per_foot = {
    ...weightPerFoot,
    ...getWeight(weightPerFoot, serverValues, localSeed.weight_per_foot),
  };
  _metadata.alloy = {
    ...alloy,
    ...getAlloy(alloy, serverValues, localSeed.alloy),
  };
  _metadata.min_yield = {
    ...minYield,
    ...getMinYield(minYield, serverValues, localSeed.min_yield),
  };
  _metadata.range = {
    ...range,
    ...getRange(range, serverValues, localSeed.range),
  };
  _metadata.lengths_exact = {
    ...lengthsExact,
    ...getLengthsExact(lengthsExact, serverValues, localSeed.lengths_exact),
  };
  _metadata.quantity = {
    ...quantity,
    ...getQuantity(quantity, category, serverValues, localSeed.quantity),
  };
  _metadata.end_finish = {
    ...endFinish,
    ...getEndFinish(endFinish, serverValues, localSeed.end_finish),
  };
  return _metadata;
}
