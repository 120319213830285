export function state(initialState) {
  return {
    waitlistWishlist: defaultWaitlistWishlist(),
  };
}

export function defaultWaitlistWishlist() {
  return {
    itemSelectionArray: [],
    itemSelection: {},
  };
}
