import React from 'react';
import PropTypes from 'prop-types';
import { NavLink as Link } from 'react-router-dom';

import Icon from 'components/Icon/Icon';
import { trackClick } from 'utility/analytics';
import { noop } from 'lodash';
import StyledDropdownMenu from './NavigationDropdownMenu.styles';

class NavigationDropdownMenu extends React.Component {
  constructor(...args) {
    super(...args);
    this.state = {
      showMenu: false,
    };
    this.showMenu = this.showMenu.bind(this);
  }

  componentDidUpdate() {
    this.clearHoverTimeouts();
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.boundHideMenu);
    clearTimeout(this.timeout);
  }

  onMouseEnter() {
    const { disableHover } = this.props;
    if (disableHover) {
      return;
    }
    this.timeoutId = setTimeout(() => {
      this.clearHoverTimeouts();
      this.showMenu();
    }, 150);
  }

  onMouseLeave() {
    this.clearHoverTimeouts();
    this.hideMenu();
  }

  clearHoverTimeouts() {
    clearTimeout(this.timeoutId);
  }

  clickMenu(e) {
    const { onClickMenu } = this.props;
    let preventStop;
    if (onClickMenu) {
      preventStop = onClickMenu();
    }
    if (preventStop) {
      e.nativeEvent.stopImmediatePropagation();
    }
  }

  showMenu(e) {
    const { showMenu } = this.state;
    const { showEventName, label, title } = this.props;
    this.boundHideMenu = this.hideMenu.bind(this);
    if (!showMenu) {
      document.addEventListener('click', this.boundHideMenu);
      this.setState({ showMenu: true, forceHide: false });
      if (e) {
        e.nativeEvent.stopImmediatePropagation();
      }
      trackClick(
        showEventName || 'viewAccountMenu',
        label || title || '',
        'header'
      );
    }
  }

  hideMenu(e) {
    const { onClose } = this.props;
    document.removeEventListener('click', this.boundHideMenu);
    this.setState({ showMenu: false, forceHide: !!e }, () => {
      // force hide to hide menu on click events
      if (e) {
        this.timeout = setTimeout(() => {
          this.setState({ forceHide: false });
        }, 1000);
      }
    });
    if (onClose) {
      onClose();
    }
  }

  render() {
    const { showMenu: isShow, forceHide } = this.state;
    const {
      className,
      disableHover,
      overlayStyles,
      children,
      link,
      isActive,
      customTitle,
    } = this.props;
    return (
      <StyledDropdownMenu>
        <div
          className={`${className || ''} ${forceHide ? 'force-hide' : ''} ${
            isShow ? 'active wrapper-drop-down' : 'wrapper-drop-down'
          }`}
        >
          {isShow ? (
            <div
              className="drop-down-overlay"
              onClick={disableHover ? this.hideMenu.bind(this) : null}
              style={overlayStyles}
              onKeyDown={noop}
              role="button"
              tabIndex={0}
              aria-label="Hide menu"
            />
          ) : null}
          <div
            onMouseEnter={this.onMouseEnter.bind(this)}
            onMouseLeave={this.onMouseLeave.bind(this)}
          >
            {isShow ? (
              <div
                style={{ marginLeft: -5 }}
                className="drop-down"
                onClick={this.clickMenu.bind(this)}
                onKeyDown={noop}
                role="button"
                tabIndex={0}
              >
                <div style={{ width: '125px' }} className="triangle-wrapper">
                  <div className="triangle" />
                </div>
                {children}
              </div>
            ) : (
              <div
                style={{
                  height: 0,
                  overflow: 'hidden',
                  position: 'absolute',
                  left: 0,
                  top: 0,
                }}
              >
                {children}
              </div>
            )}
            <Link
              to={link}
              activeClassName="active-menu-item"
              className="item dropdown-main-link"
              isActive={() => isActive}
              onClick={disableHover ? this.showMenu : null}
            >
              {customTitle}
              <Icon
                style={{
                  display: 'inline',
                  padding: 0,
                  fontSize: '7px',
                  paddingLeft: '6px',
                  color: '#1D457A',
                }}
                icon="icon-chevron-down"
                fontSize="5px"
              />
            </Link>
          </div>
        </div>
      </StyledDropdownMenu>
    );
  }
}

NavigationDropdownMenu.propTypes = {
  disableHover: PropTypes.bool,
  onClickMenu: PropTypes.func,
  showEventName: PropTypes.string,
  label: PropTypes.string,
  title: PropTypes.string,
  onClose: PropTypes.func,
  className: PropTypes.string,
  overlayStyles: PropTypes.shape({}),
  children: PropTypes.shape({}),
  link: PropTypes.string,
  isActive: PropTypes.string,
  customTitle: PropTypes.string,
};

export default NavigationDropdownMenu;
