import styled, { css } from 'styled-components';
import { NavLink } from 'react-router-dom';
import { MD } from '@zendeskgarden/react-typography';
import { variables } from '../../theme/variables';

const { color_grey_200: colorGrey200, ag_grid_hover: agGridHover } = variables;

export const MenuContainer = styled.div`
  width: 210px;
  height: 50px;
  display: flex;
  border-bottom: 1px solid rgba(237, 237, 237, 1);
  align-items: center;
  justify-content: start;
  padding: 5px;
  text-decoration: none;
  ${(props) => {
    if (!props.settings && props.settingsLinks) {
      return `
        border-bottom: none;
        margin-left: 25px;
      `;
    }
    return ``;
  }}
`;

const linkBoxAndCollapseStyle = css`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: start;
  padding-left: 5px;
  user-select: none;

  ${/* Highlight Dashboard menu item when active */ ''}
  &.active {
    background: ${colorGrey200};
    width: 100%;
    height: 40px;
    padding-left: 5px;
    border-radius: 5px;
  }
  &:hover {
    background: ${agGridHover};
    width: 100%;
    height: 40px;
    padding-left: 5px;
    border-radius: 5px;
    cursor: pointer;
  }

  &:link {
    text-decoration: none;
  }
`;

export const MenuCollapse = styled.div`
  ${linkBoxAndCollapseStyle}
`;

export const MenuLinkBox = styled(NavLink)`
  ${linkBoxAndCollapseStyle}
`;

export const MenuButton = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  border-radius: 4px;
  border: none;
  text-decoration: none;
`;

export const MenuText = styled(MD)`
  display: flex;
  justify-content: space-between;
  list-style: none;
  text-decoration: none;
  font-size: 12px;
  margin-left: '5px';
  width: 100%;

  p {
    font-size: 12px;
  }
`;

export const ExternalLink = styled.div`
  width: 200px;
  height: 50px;
  display: flex;
  border-bottom: 1px solid rgba(237, 237, 237, 1);
  align-items: center;
  justify-content: center;
  padding: 5px;
  text-decoration: none;
`;

export const StyledAnchor = styled('a')`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: start;
  padding-left: 5px;
  background: ${(props) => (props.active ? agGridHover : '')};
  &:hover {
    background: ${agGridHover};
    width: 100%;
    height: 40px;
    padding-left: 5px;
    border-radius: 5px;
    cursor: pointer;
  }

  &:link {
    text-decoration: none;
  }
`;
