import Baobab from 'baobab';
import { get, every, pick, reduce, isEmpty, values } from 'lodash';

function rfqItemsValidMonkey() {
  return Baobab.monkey({
    cursors: {
      itemSelection: ['requestForQuote', 'itemSelection'],
      waitlistWishlistItemSelection: [
        'public',
        'waitlistWishlist',
        'itemSelection',
      ],
      sellersApplicationItemSelection: ['sellersApplication', 'itemSelection'],
    },
    get({
      itemSelection = {},
      waitlistWishlistItemSelection = {},
      sellersApplicationItemSelection = {},
    }) {
      let groupedItemSelection;

      if (!isEmpty(itemSelection)) {
        groupedItemSelection = itemSelection;
      } else if (!isEmpty(waitlistWishlistItemSelection)) {
        groupedItemSelection = waitlistWishlistItemSelection;
      } else {
        groupedItemSelection = sellersApplicationItemSelection;
      }

      if (isEmpty(groupedItemSelection)) {
        return {};
      }

      return reduce(
        values(groupedItemSelection),
        (accum, item) => {
          let { allValid, allSaved } = accum;

          if (allValid && !item.valid) {
            allValid = false;
          }

          if (allSaved && !item.saved) {
            allSaved = false;
          }

          return { allValid, allSaved };
        },
        { allValid: true, allSaved: true }
      );
    },
  });
}

function rfqDetailsValidMonkey() {
  return Baobab.monkey({
    cursors: {
      details: ['requestForQuote', 'rfqDetails', 'map'],
    },
    get({ details }) {
      if (isEmpty(details)) {
        return {};
      }
      return reduce(
        values(details),
        (accum, item) => {
          const groupsValid = every(
            pick(item, ['basicInformation', 'wellContent', 'wellInformation']),
            (it) => it.valid !== false
          );
          let { allValid, allSaved } = accum;
          if (allValid && !groupsValid) {
            allValid = false;
          }

          if (allSaved && !item.saved) {
            allSaved = false;
          }
          return { allValid, allSaved };
        },
        { allValid: true, allSaved: true }
      );
    },
  });
}

function state(initialState) {
  return {
    id: null,
    meta: get(initialState, 'rfqMeta'),
    customer: {},
    activeRfq: {
      itemOverrides: {},
      items: {},
      group: {},
      well: {},
      summary: {},
    },
    itemSelectionArray: [],
    itemSelection: {},
    itemSelectionRfqUpdateError: null, // We need to update RFQ when editing and adding items from within item selection screen
    rfqDetails: {
      list: {}, // list of ids in a dictionary. issues with pushing and baobab
      map: {},
    },
    rfqItemInformations: {
      map: {},
      itemOverrideMap: {},
      name: {},
      date: {},
      need_location: {},
      end_use_location: {},
      end_user: {},
      inquiring_company: {},
      reference_number: {},
      leadSource: {},
    },
    // Monkeys
    rfqItemsValid: rfqItemsValidMonkey(),
    rfqDetailsValid: rfqDetailsValidMonkey(),
  };
}

export default state;
