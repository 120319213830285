import tree from 'state';
import asyncTreeRequester from 'utility/asyncTreeRequester';
import { setValues } from 'utility/urlUtils';
import {
  generateItemFilters,
  generateFilterValues,
} from 'state/requestForQuote/transformer';
import { generateCriteriaQueryFilters } from 'state/requestForQuote/transformer_criteria';
import { keys, reduce, get, omitBy, isNil, cloneDeep, size } from 'lodash';
import * as effects from './effects';

const supplyInterests = tree.select(['supplyInterests']);

export function prepareCriteria({ ignoreOmit } = {}) {
  let filterData = generateFilterValues(
    supplyInterests.get(['supplyItemFilter', 'itemFilters'])
  );
  filterData = generateCriteriaQueryFilters(filterData);
  if (!ignoreOmit) {
    filterData = omitBy(filterData, (d) => isNil(d) || get(d, 'length') === 0);
  }
  return filterData;
}

export async function getSupplyInterests({
  pagination,
  filters,
  sorting,
  criteria,
} = {}) {
  return asyncTreeRequester({
    func: effects.getSupplyInterestsItems.bind(this, { criteria }),
    pagination,
    filters,
    sorting,
    cursor: supplyInterests,
    path: ['currentSupply'],
  });
}

export async function getSupplyInterestsForAgGrid({
  pagination,
  filters,
  sorting,
  criteria,
} = {}) {
  return asyncTreeRequester({
    func: effects.getSupplyInterestsItemsForAgGrid.bind(this, { criteria }),
    pagination,
    filters,
    sorting,
    cursor: supplyInterests,
    path: ['currentSupply'],
  });
}

export async function getSupplyInterestsGroups({
  pagination,
  filters,
  sorting,
  criteria,
} = {}) {
  return asyncTreeRequester({
    func: effects.getSupplyInterestsGroups.bind(this, { criteria }),
    pagination,
    filters,
    sorting,
    cursor: supplyInterests,
    path: ['currentSupplyGroups'],
  });
}

export async function getSupplyInterestsGroupsForAgGrid({
  pagination,
  filters,
  sorting,
  criteria,
} = {}) {
  return asyncTreeRequester({
    func: effects.getSupplyInterestsGroupsForAgGrid.bind(this, { criteria }),
    pagination,
    filters,
    sorting,
    cursor: supplyInterests,
    path: ['currentSupplyGroups'],
  });
}

export async function generateOpportunitySearch(branch, type, data, reqType) {
  supplyInterests.set(['supplyItemFilter', 'itemFilters', type], data);
  const criteria = prepareCriteria();
  if (reqType === 'items') {
    getSupplyInterests({ criteria });
  } else {
    getSupplyInterestsGroups({ criteria });
  }

  if (!data.value || !size(data.value)) {
    setValues({ [type]: undefined }, keys(criteria));
  } else {
    setTimeout(() => {
      setValues(criteria, keys(criteria));
    }, 1);
  }
}

export function updateItemFilters(type, data) {
  const { value, operator, selectedFilters } = data;
  const itemFilters = supplyInterests.get(['supplyItemFilter', 'itemFilters']);
  if (selectedFilters) {
    itemFilters[type] = {
      ...itemFilters[type],
      selectedFilters,
    };
  }
  if (value || value === null) {
    itemFilters[type] = {
      ...itemFilters[type],
      value,
    };
  }
  if (operator) {
    itemFilters[type] = {
      ...itemFilters[type],
      operator,
    };
  }
  supplyInterests.set(['supplyItemFilter', 'itemFilters'], itemFilters);
}

export async function createSupplyOpportunity(data) {
  await asyncTreeRequester({
    func: effects.createSupplyOpportunity.bind(this, data),
    cursor: supplyInterests,
    path: ['supplyOpportunity', 'new'],
  });
}

export async function patchSupplyOpportunity(supplyOpportunityId, data) {
  await asyncTreeRequester({
    func: effects.patchSupplyOpportunity.bind(this, supplyOpportunityId, data),
    cursor: supplyInterests,
    path: ['supplyOpportunity', supplyOpportunityId],
  });
}

export async function setActiveSupplyInterest(id) {
  supplyInterests.set(['activeId'], id);
}

export async function getSupplyInterestDetails(supplyInterestId) {
  asyncTreeRequester({
    func: effects.getSupplyInterestDetails.bind(this, supplyInterestId),
    cursor: supplyInterests,
    path: ['summary', supplyInterestId],
  });
}

export function resetSupplyInterestDetails() {
  supplyInterests.set(['activeId'], null);
  supplyInterests.set(['supplyOpportunity'], null);
  supplyInterests.set(['summary'], null);
}

export async function setActiveRfqItem(seed = {}) {
  supplyInterests.set(
    ['supplyItemFilter', 'itemFilters'],
    generateItemFilters(null, seed, {
      range: { multiple: true },
    })
  );
}

export async function generateOpportunityItemSearch(branch, type, data) {
  return generateOpportunitySearch(branch, type, data, 'items');
}

export function clearCriteriaSearch() {
  const keyList = keys(prepareCriteria({ ignoreOmit: true }));
  const filters = reduce(
    keyList,
    (accum, item) => {
      const currentAccum = cloneDeep(accum);
      currentAccum[item] = undefined;
      return currentAccum;
    },
    {}
  );
  setValues(filters, keyList);
  setActiveRfqItem();
}

// Field Inspection
export async function submitFieldInspectionCreation(data) {
  try {
    const fieldInspection = await effects.submitFieldInspection(data);
    return fieldInspection;
  } catch (err) {
    return {
      error: err.message,
    };
  }
}
