import { variables } from 'theme/variables';

const { custom_light_grey: customLightGrey, custom_navy: customNavy } =
  variables;

const DropDown = {
  'dropdowns.select': () =>
    `
    border-width: 0;
    background-color: ${customLightGrey};
    box-shadow: none !important;
    line-height: unset;
    * {
      color: ${customNavy} !important;
      font-weight: 500;
      font-size: 12px !important;
    }
    `,
};

export default DropDown;
