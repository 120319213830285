import { variables } from 'theme/variables';

const {
  spacing_sm: spacingSm,
  line_height_xxxl: lineHeightXXXL,
  font_size_sm: fontSizeSm,
  font_size_md: fontSizeMd,
  custom_placeholder: customPlaceholder,
  font_weight_semibold: fontWeightSemibold,
  custom_black: customBlack,
  custom_silver: customSilver,
  custom_blue: customBlue,
  color_red_400: colorRed400,
  custom_orange: customOrange,
} = variables;

function textareaInputProps() {
  return `
    resize: auto !important;
    width: 100%;
  `;
}

function field(props) {
  return `
    ${
      props.container
        ? `
      position: relative;
      input {
        ${
          props.container === 'prefix'
            ? `
          padding-left: ${props.paddingLeft || 23}px !important;
        `
            : `
          padding-right: ${props.paddingRight || 23}px !important;
        `
        }
      }
    `
        : ''
    }
  `;
}

function textInputProps(props) {
  if (props.textarea) {
    return `
      width: 100%;
      line-height: 22px;
      resize: vertical !important;
      ${
        props.basic
          ? `
        min-height: 0 !important;
        border-top: 0 !important;
        border-left: 0 !important;
        border-right: 0 !important;
        padding: 0 0px 13px 0 !important;
        box-shadow: none !important;
        border-radius: 0 !important;
      `
          : ''
      }
    `;
  }

  return `
        width: 100%;
        resize: vertical !important;
        box-shadow: none !important;
        ${
          props.itemSelection
            ? `
          border-width: ${props.fullBorder ? `1px` : '0'} !important;
          background: transparent;
          border-bottom-width: ${
            props.bare && !props.tolerance ? '0px' : '1px !important'
          };
          border-style: solid;
          border-color: ${customSilver};
          border-radius: ${props.fullBorder ? `5px` : '0'} !important;
          padding: ${props.fullBorder ? `10px` : '.71429em 0'} !important;;
          outline: none !important;
          box-shadow: none !important;
          font-size: ${fontSizeSm};
          color: ${customBlack} !important; 
          ::placeholder {
            color: ${customPlaceholder} !important;
          }
          ${
            props.itemSelection === 'small'
              ? `
            padding: .71429em 0 !important;
          `
              : ''
          }
          ${
            props.size
              ? `
            min-height: ${props.size}px !important;
            height: ${props.size}px !important;
            padding: 0 !important;
            margin-bottom: 0 !important;
          `
              : ''
          }
        `
            : ''
        }
    
        ${
          props.container
            ? `
          position: relative;
          input {
            ${
              props.container === 'prefix'
                ? `
              padding-left: ${props.paddingLeft || 23}px !important;
            `
                : `
              padding-right: ${props.paddingRight || 23}px !important;
            `
            }
          }
        `
            : ''
        }
    
        ${
          props.paddingRight && !props.container
            ? `
          padding-right: ${props.paddingRight || 23}px !important;
        `
            : ''
        }
    
    
        ${
          props.scaled
            ? `
          height: 58px;
        `
            : ''
        }
    
        ${
          props.size === 'xl'
            ? `
          height: ${lineHeightXXXL};
        `
            : ''
        }
    
        ${
          props.medium
            ? `
          font-size: ${fontSizeMd};
        `
            : ''
        }
    
        ${
          props.colorOverride
            ? `
          color: ${props.colorOverride} !important;
          ::placeholder {
            color: ${props.colorOverride} !important;
          }
        `
            : ''
        }
    
        &:focus:not([disabled]) {
          border-bottom: ${
            props.readOnly ? null : `1px solid ${customBlue} !important`
          };
          ${
            props.colorOverride && !props.readOnly
              ? `
            ::placeholder {
              color: ${customPlaceholder} !important;
            }
          `
              : ''
          }
        }
    
        ${
          props.semibold
            ? `
          font-weight: ${fontWeightSemibold};
          ::placeholder {
            font-weight: ${fontWeightSemibold};
          }
        `
            : ''
        }
    
        ${
          props.icon
            ? `
          padding-left: 3em !important;
        `
            : ''
        }
    
        ${
          props['aria-haspopup']
            ? `
          padding-left: 25px !important;
        `
            : ''
        }
    
        ${
          props.hideOutline
            ? `
          box-shadow: none !important;
        `
            : ''
        }
        
        ${
          props.validation === 'error'
            ? `
          color: ${colorRed400} !important;
          ::placeholder {
            color: ${colorRed400} !important;
          }
        `
            : ''
        }
    
        ${
          props.validation === 'warning'
            ? `
          color: ${customOrange} !important;
          ::placeholder {
            color: ${customOrange} !important;
          }
        `
            : ''
        }
        ${
          props.bare && props['data-garden-id'] !== 'forms.media_input'
            ? 'border: none !important;'
            : ''
        }
  `;
}

const TextFieldTheme = {
  'forms.text_message': `
    && {
      margin-top: ${spacingSm}
    }
  `,
  'forms.text_input': (props) => textInputProps(props),
  'forms.text_group': (props) => textInputProps(props),
  'forms.textarea': (props) => textareaInputProps(props),
  'forms.field': (props) => field(props),
};

export default TextFieldTheme;
