// OVERIDE CSS
import { variables } from 'theme/variables';
const { spacing, spacing_md } = variables;

const MenuTheme = {
  'notifications.alert': (props) => {
    return `
      background-image: none !important;
      box-shadow: none !important;
      display: flex;
      align-items: center;
      padding: ${props.small ? spacing_md : spacing} !important; 
      ${
        props.type === 'success'
          ? `
        background: #dbf8ed !important;
        border-color: #3ad79f !important;
        * {
          color: #21694f !important;
        }
      `
          : ''
      }
      ${
        props.type === 'error'
          ? `
        background: #ffd9e0 !important;
        border-color: #ff3057 !important;
        * {
          color: #ff3057 !important;
        }
      `
          : ''
      }

      ${
        props.inline
          ? `
         border: none !important;
         border-radius: 0 !important;
      `
          : ''
      }
    `;
  },

  'notifications.title': (props) => {
    return `
    
    `;
  },
};

export default MenuTheme;
