import fetchJson from 'utility/fetchJson';
import { BASE } from '../../constants';
import { reduce } from 'lodash';

export async function createFlexEntity({ contentType, ids, value }) {
  const response = await fetchJson(`${BASE}/api/util/entity-flex/`, {
    method: 'POST',
    body: JSON.stringify({ content_type: contentType, ids, json: value }),
  });
  return response;
}

export async function createFlexEntityJson({ contentType, id, value }) {
  const response = await fetchJson(`${BASE}/api/util/entity-flex/${id}/`, {
    method: 'PUT',
    body: JSON.stringify({ content_type: contentType, id, json: value }),
  });
  return response;
}

export async function fetchEntitySchema(id) {
  const response = await fetchJson(`${BASE}/api/util/entity-schema/${id}/`, {
    method: 'GET',
  });
  return response;
}

export async function updateEntitySchema(id, schema) {
  const response = await fetchJson(`${BASE}/api/util/entity-schema/${id}/`, {
    method: 'PUT',
    body: JSON.stringify({ content_type: id, schema }),
  });
  return response;
}

export async function addEntitySchema(id, schema) {
  const response = await fetchJson(`${BASE}/api/util/entity-schema/${id}/`, {
    method: 'PATCH',
    body: JSON.stringify({ content_type: id, schema }),
  });
  return response;
}

export async function deleteEntitySchema(id, schema) {
  const response = await fetchJson(`${BASE}/api/util/entity-schema/${id}/`, {
    method: 'DELETE',
    body: JSON.stringify({ content_type: id, schema }),
  });
  return response;
}

export async function getCustomersSchema(contentTypeId) {
  let customerSchema = {};
  try {
    const req = await fetchEntitySchema(contentTypeId);
    customerSchema = reduce(
      req.schema,
      (accum, s, k) => {
        accum[k] = {
          ...s,
          content_type: req.content_type,
          entity_id: req.id,
        };
        return accum;
      },
      {}
    );
  } catch (err) {
    console.log(err, 'error getCustomersSchema');
  }

  return new Promise((resolve) => {
    resolve({
      ...customerSchema,
      full_name: {
        group: 'user',
        editable: false,
        order: 0,
        formatter: 'linkFormatter',
        sticky: 'left',
        link: {
          value: 'id',
          root: '/dashboard/users/__VALUE__/profile',
          url: '',
        },
      },
      registration_status: {
        editable: false,
        group: 'user',
        accessor: 'status_name',
        order: 1,
        formatter: 'statusFormatter',
      },
      status: {
        bulkable: false,
        group: 'user',
        order: 2,
        accessor: 'contact_status_name',
        formatter: 'statusFormatter',
        metadataPath: 'onboardingMeta.contact_status',
        originKey: 'contact_status',
      },

      email: {
        order: 6,
        width: 234,
        group: 'user',
      },
      date_added: {
        order: 7,
        group: 'user',
        accessor: 'create_timestamp',
        formatter: 'dateTagFormatter',
      },
      priority: {
        bulkable: false,
        order: 2,
        width: 100,
        accessor: 'priority_name',
        formatter: 'priorityFormatter',
        metadataPath: 'rfqMetadata.priority',
        originKey: 'priority',
      },
      company_name: {
        group: 'user',
        bulkable: false,
        order: 3,
        formatter: 'linkFormatter',
        link: {
          value: 'company',
          root: '/dashboard/companies/__VALUE__/details',
          url: '',
        },
      },
      job_title: {
        group: 'user',
        bulkable: false,
        order: 4,
      },
      company_type: {
        group: 'company',
        bulkable: false,
        order: 3,
        width: 180,
        accessor: 'company_type_name',
        metadataPath: 'onboardingMeta.company_type',
        originKey: 'company_type',
        formatter: 'arrayFormatter',
      },
      mobile_phone: {
        group: 'user',
        order: 5,
        formatter: 'phoneFormatter',
        width: 134,
      },
      formatted_address: {
        group: 'company',
        width: 260,
      },
    });
  }, 300);
}
