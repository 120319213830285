import styled from 'styled-components/macro';
import { SM, MD } from '@zendeskgarden/react-typography';
import { variables } from 'theme/variables';
const { spacing, custom_light_grey, spacing_xxs } = variables;

export const StyledNotificationDropdownList = styled('div')`
  padding: ${(props) => props.padding || `0px`};
  a,
  a * {
    text-decoration: none !important;
  }
`;

export const StyledNotificationItem = styled('div')`
  padding: ${(props) => props.padding || `${spacing} 0`};
  border-bottom: 1px solid ${custom_light_grey};
  cursor: pointer;
  &:first-child {
    padding-top: 0;
  }
`;
export const StyledNotificationText = styled(SM)`
  padding-top: ${spacing_xxs};
`;

export const StyledNotificationSubject = styled(MD)``;

export const StyledNotificationSender = styled(SM)`
  padding-top: ${spacing_xxs};
`;
