import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Flex from 'styled-flex-component';
import { IconButton } from '@zendeskgarden/react-buttons';
import { MD } from '@zendeskgarden/react-typography';

import Icon from 'components/Icon/Icon';
import { closeSidebar } from 'state/sidebars/actions';
import InternalRelationshipForm from 'components/BasicForms/InternalRelationshipForm';
import { getClientId } from 'utility/hasAccount';
import { variables } from 'theme/variables';
import { internalRelationshipShape } from 'propTypesObjects.js';
import {
  SidebarFilterHeader,
  SidebarFilterBody,
  SidebarContentWrapper,
} from '../../GlobalSideBar.styles';

const { spacing } = variables;

function InternalRelationshipSidebar({ options }) {
  const { onSuccess } = options;
  useEffect(() => {
    getClientId();
  }, []);

  const handleSuccess = (result) => {
    onSuccess(result);
    closeSidebar();
  };

  return (
    <SidebarContentWrapper>
      <SidebarFilterHeader padding={`${spacing}`}>
        <Flex full justifyBetween alignCenter>
          <Flex alignCenter>
            <MD scaled paddingHorizontal bold color="#fff">
              Internal Relationships
            </MD>
          </Flex>
          <IconButton
            onClick={closeSidebar}
            disableBackground
            alignCenter
            justifyEnd
            size="large"
            aria-label="closeSidebar"
          >
            <Icon button icon="icon-close" fontSize="13px" color="#fff" />
          </IconButton>
        </Flex>
      </SidebarFilterHeader>
      <SidebarFilterBody>
        <InternalRelationshipForm
          customerId={options.customerId}
          relationship={options.relationship}
          onSuccess={handleSuccess}
        />
      </SidebarFilterBody>
    </SidebarContentWrapper>
  );
}

InternalRelationshipSidebar.propTypes = {
  options: PropTypes.shape({
    customerId: PropTypes.string,
    relationship: internalRelationshipShape,
    onSuccess: PropTypes.func,
  }),
};

export default InternalRelationshipSidebar;
