import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { hasSession } from 'utility/hasAccount';
import { track } from 'utility/analytics';
import { Link } from 'react-router-dom';
import styled from 'styled-components/macro';
import DropZoneFileManager from 'components/DropZone/DropZoneFileManager';
import Icon from 'components/Icon/Icon';
import PhoneInput from 'react-phone-input-2';
import { MD, XL, XXL, LG } from '@zendeskgarden/react-typography';
import { Formik, FieldArray } from 'formik';
import {
  omit,
  cloneDeep,
  map,
  some,
  get,
  keys,
  isEmpty,
  size,
  replace,
} from 'lodash';
import { Button } from 'theme/Button';
import { FormInput, Form, FormFooter } from 'theme/Form';
import { Message, Field } from '@zendeskgarden/react-forms';
import { Row, Col } from '@zendeskgarden/react-grid';
import Flex, { FlexItem } from 'styled-flex-component';
import { variables } from 'theme/variables';
import { createSimpleRfqInquery } from 'state/requestForQuote/actions_simpleInquiry';
import { createSimpleSupplyInquery } from 'state/sellersApplication/actions_simpleinquiry';
import { FullFormFieldWrapper } from 'theme/AuthForms.styles';

const {
  spacing_xl: spacingXl,
  custom_red: customRed,
  custom_blue: customBlue,
} = variables;

export const HeaderItem = styled.section`
  display: flex;
  justify-content: space-between;
  position: absolute;
  left: 0;
  right: 0;
  background: #edf0f3;
  padding: 20px 40px;
  top: 0;
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
`;

export const DropWrapper = styled.section`
  width: 100%;
  margin-top: 25px;
  .dropzone-drop {
    padding: 0px;
    height: auto !important;
    min-height: 0 !important;
    .file-wrapper {
      width: 100% !important;
    }
    .file-container {
      padding: 10px 0 !important;
    }
    svg {
      border: 2px dashed rgb(218 218 218);
      border-radius: 11px;
      * {
        opacity: 0;
      }
    }

    .file-container {
      background: #f3f4f5;
      border-radius: 5px;
      padding-left: 10px !important;
    }
    .el-divider {
      display: none;
    }
    .file-row-item ~ .file-row-item {
      margin-top: 10px;
    }
  }

  .dropzone-drop.empty {
    padding: 20px 0;
    .icon-cloud-upload {
      display: none;
    }
  }
`;

function formValidation(values) {
  const errors = {};
  if (!values.email) {
    errors.email = 'Required';
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
    errors.email = 'Invalid email address';
  }
  return errors;
}

const itemShape = PropTypes.shape({
  first_name: PropTypes.string,
  last_name: PropTypes.string,
  company: PropTypes.string,
  phone: PropTypes.string,
  email: PropTypes.string,
  message: PropTypes.string,
});

function QuickInquiryForm({ isSupply, contentType, onClose }) {
  const isLoggedIn = !!hasSession();
  const [formStatus, setFormStatus] = useState({ items: !!isLoggedIn });
  const [filesAdded, setFiles] = useState({});

  useEffect(() => {
    const flagEl = document.querySelector('.flag-dropdown .selected-flag');
    if (flagEl) {
      document.querySelector('.flag-dropdown .selected-flag');
    }
  }, []);

  const submitInquiryForm = useCallback(
    async (values, { setSubmitting, setStatus }) => {
      setSubmitting(true);
      const cloneValues = cloneDeep(values);
      const submissionData = {
        ...omit(cloneValues, ['items', 'message']),
        json: {
          message: cloneValues.message,
          items: cloneValues.items[0].name ? cloneValues.items : [],
        },
      };

      const submitFunction = isSupply
        ? createSimpleSupplyInquery
        : createSimpleRfqInquery;
      const inquirySubmission = await submitFunction(submissionData);
      const { error } = inquirySubmission;
      if (error) {
        track('simpleInquiryError', submissionData);
        setStatus({ error });
        if (!isLoggedIn) {
          setFormStatus({});
        }
      } else {
        setStatus({
          error: false,
          inquiryResult: inquirySubmission,
          success: isEmpty(filesAdded.files),
          initialSuccess: true,
        });
        if (isEmpty(filesAdded)) {
          track('simpleInquirySuccess');
        } else {
          track('simpleInquiryIniitalSuccess');
        }
      }
      setSubmitting(false);
      return inquirySubmission;
    },
    [filesAdded, isLoggedIn, isSupply]
  );

  const initialValues = isLoggedIn
    ? { items: [{ id: '1', name: '', quantity: '', uom: 'FT.' }], message: '' }
    : {
        first_name: '',
        last_name: '',
        email: '',
        phone: '',
        company: '',
        items: [{ id: '1', name: '', quantity: '', uom: 'FT.' }],
        message: '',
      };

  return (
    <Formik
      initialValues={initialValues}
      validate={isLoggedIn ? undefined : formValidation}
      onSubmit={submitInquiryForm}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
        status,
        setStatus,
        setFieldValue,
      }) => {
        const forwardProps = {
          isSupply,
          isLoggedIn,
          contentType,
          values,
          errors,
          touched,
          handleBlur,
          handleChange,
          setFieldValue,
          status,
          isSubmitting,
          setFormStatus,
          handleSubmit,
          filesAdded,
          setFiles,
        };

        function renderTitle() {
          if (formStatus.items && !isLoggedIn) {
            return (
              <XL
                paddingBottomSm
                style={{
                  paddingTop: '10px',
                  fontSize: '25px',
                  lineHeight: '35px',
                }}
                center
                black
                navy
              >
                Add Your Items Or Upload File
              </XL>
            );
          }
          if (isSupply) {
            return (
              <XL
                paddingBottomSm
                style={{
                  paddingTop: '10px',
                  fontSize: '25px',
                  lineHeight: '35px',
                }}
                center
                black
                navy
              >
                A Faster Way To Move Your Supply
              </XL>
            );
          }
          return (
            <XL
              paddingBottomSm
              style={{
                paddingTop: '10px',
                fontSize: '25px',
                lineHeight: '35px',
              }}
              center
              black
              navy
            >
              A Faster Way To Submit An RFQ
            </XL>
          );
        }

        return status && status.success ? (
          <Row>
            <Col sm={12}>
              <Flex
                alignCenter
                justifyCenter
                column
                style={{ marginTop: 20, padding: '20px 0px 0' }}
              >
                <XXL bold blue>
                  Thank you for your inquiry.
                </XXL>
                {isLoggedIn ? (
                  <LG paddingTopMd center>
                    You will receive a <b>confirmation email</b> shortly. View
                    your inquiry on your{' '}
                    <Link
                      onClick={(e) => {
                        e.stopPropagation();
                        onClose();
                      }}
                      to="/dashboard"
                    >
                      dashboard
                    </Link>
                    .
                  </LG>
                ) : (
                  <LG paddingTopMd center>
                    You will receive a <b>confirmation email</b> shortly. Once
                    you confirm your email, you will see your inquiry in your
                    dashboard.{' '}
                  </LG>
                )}

                <Button
                  type="button"
                  onClick={(e) => {
                    e.stopPropagation();
                    onClose();
                  }}
                  primary
                  style={{ marginTop: '30px' }}
                >
                  Done
                </Button>
              </Flex>
            </Col>
          </Row>
        ) : (
          <Form size="large" onSubmit={handleSubmit}>
            {formStatus.items && !isLoggedIn ? (
              <FlexItem style={{ width: '100%' }}>
                <Button
                  type="button"
                  blue
                  iconColor={customBlue}
                  link
                  iconBefore="icon-arrow-left-short"
                  style={{
                    fontSize: '11px',
                    width: '60px',
                    marginTop: '-3px',
                  }}
                  onClick={() => {
                    setFormStatus({});
                  }}
                  paddingTopMd
                  bold
                  left
                >
                  Back
                </Button>
              </FlexItem>
            ) : null}
            {renderTitle()}

            <div style={!formStatus.items ? { display: 'none' } : {}}>
              <Items
                // LINT OVERRIDE #12
                // This component is passing a specific group of props to multiple children
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...forwardProps}
                setStatus={setStatus}
              />
            </div>
            {!isLoggedIn ? (
              <div style={formStatus.items ? { display: 'none' } : {}}>
                <Contact
                  // LINT OVERRIDE #12
                  // This component is passing a specific group of props to multiple children
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  {...forwardProps}
                />
              </div>
            ) : null}
          </Form>
        );
      }}
    </Formik>
  );
}

QuickInquiryForm.propTypes = {
  isSupply: PropTypes.bool,
  contentType: PropTypes.number,
  onClose: PropTypes.func,
};

function Contact({
  values,
  errors,
  touched,
  handleBlur,
  handleChange,
  setFieldValue,
  status,
  isSubmitting,
  setFormStatus,
}) {
  return (
    <div>
      <Row>
        <Col size={6} md={6}>
          <FullFormFieldWrapper>
            <MD bold navy left tag="h5">
              First Name
              <MD as="span" color={customRed}>
                {' '}
                *
              </MD>
            </MD>
            <FormInput
              autoFocus
              type="text"
              id="first_name"
              name="first_name"
              onChange={handleChange}
              value={values.first_name}
              showMessage
              error={
                !!size(errors.first_name) &&
                touched.first_name &&
                errors.first_name[0]
              }
              placeholder="Enter Your Name"
            />
          </FullFormFieldWrapper>
        </Col>
        <Col size={6} md={6}>
          <FullFormFieldWrapper>
            <MD bold navy left tag="h5">
              Last Name
              <MD as="span" color={customRed}>
                {' '}
                *
              </MD>
            </MD>
            <FormInput
              type="text"
              id="last_name"
              name="last_name"
              onChange={handleChange}
              value={values.last_name}
              showMessage
              error={
                !!size(errors.last_name) &&
                touched.last_name &&
                errors.last_name[0]
              }
              placeholder="Enter Your Name"
            />
          </FullFormFieldWrapper>
        </Col>
        <Col size={6} md={6}>
          <FullFormFieldWrapper>
            <MD bold navy left tag="h5">
              Email
              <MD as="span" color={customRed}>
                {' '}
                *
              </MD>
            </MD>
            <FormInput
              type="email"
              id="email"
              name="email"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.email}
              showMessage
              error={!!size(errors.email) && touched.email && errors.email[0]}
              placeholder="Enter Email"
            />
          </FullFormFieldWrapper>
        </Col>
        <Col size={6} md={6}>
          <FullFormFieldWrapper>
            <MD bold navy left tag="h5">
              Company
              <MD as="span" color={customRed}>
                {' '}
                *
              </MD>
            </MD>
            <FormInput
              type="company"
              id="company"
              name="company"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.company}
              showMessage
              error={
                !!size(errors.company) && touched.company && errors.company[0]
              }
              placeholder="Enter Company"
            />
          </FullFormFieldWrapper>
        </Col>
        <Col size={12} md={12}>
          <FullFormFieldWrapper>
            <MD bold navy left tag="h5">
              Phone Number
              <MD as="span" color={customRed}>
                {' '}
                *
              </MD>
            </MD>
            <PhoneInput
              type="phone"
              id="phone"
              name="phone"
              country="us"
              placeholder="Enter Phone Number"
              inputProps={{ autoComplete: 'none' }}
              onBlur={handleBlur}
              value={replace(values.phone || '', ('++', '+'))}
              onChange={(phone) => {
                setFieldValue('phone', `+${phone}`);
              }}
            />
          </FullFormFieldWrapper>
        </Col>
      </Row>

      <Row>
        <Col md={12}>
          <FullFormFieldWrapper>
            <MD bold navy left tag="h5">
              Project Information (optional)
            </MD>
            <FormInput
              textarea
              type="message"
              id="message"
              name="message"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.message}
              showMessage
              error={
                !!size(errors.message) && touched.message && errors.message[0]
              }
              placeholder="Enter project information, need date, locations, etc"
            />
          </FullFormFieldWrapper>
        </Col>
      </Row>

      <Row>
        <Col md={12}>
          {status && status.error ? (
            <FormFooter>
              <Field>
                <Message validation="error">{status.error}</Message>
              </Field>
            </FormFooter>
          ) : null}
          <Flex justifyCenter>
            <FlexItem style={{ width: '100%' }}>
              <Button
                disabled={
                  !!keys(errors).length ||
                  !(
                    values.first_name &&
                    values.last_name &&
                    values.email &&
                    values.company &&
                    values.phone
                  )
                }
                loading={isSubmitting || undefined}
                type="button"
                onClick={(e) => {
                  e.stopPropagation();
                  setFormStatus({ items: true });
                  track('simpleInquiryItemsStep', { ...values });
                }}
                primary
                style={{ marginTop: '30px', marginBottom: spacingXl }}
              >
                Continue To Add Items
              </Button>
            </FlexItem>
          </Flex>
        </Col>
      </Row>
    </div>
  );
}

Contact.propTypes = {
  values: itemShape,
  errors: PropTypes.objectOf(PropTypes.arrayOf(PropTypes.string)),
  touched: PropTypes.objectOf(
    PropTypes.arrayOf(PropTypes.objectOf(PropTypes.bool))
  ),
  status: PropTypes.shape({
    error: PropTypes.bool,
  }),
  handleBlur: PropTypes.func,
  handleChange: PropTypes.func,
  setFieldValue: PropTypes.func,
  isSubmitting: PropTypes.bool,
  setFormStatus: PropTypes.func,
};

function Items({
  isLoggedIn,
  setStatus,
  contentType,
  values,
  errors,
  touched,
  handleBlur,
  handleChange,
  status,
  isSubmitting,
  filesAdded,
  setFiles,
  isSupply,
}) {
  const inquiryResult = status && status.inquiryResult;
  const [resubmitKey, setResubmitKey] = useState();

  // filesAddedError:
  // this is to make the UI work with the upload component we already have
  // if there is an error uploading a file we dont want to resubmit the form, rather retry just submitting the file
  // we also dont want to show the items again as we are not resubmitting the form.
  const filesAddedError = filesAdded.error;

  const handleSetExternalState = useCallback(
    (f) => {
      setFiles(f);
      if (f.error) {
        track('simpleInquiryFilesError', { ...values });
      }
    },
    [setFiles, values]
  );

  return (
    <div>
      <Row>
        <RenderItems {...{ isSupply, values, errors, touched, handleChange }} />
      </Row>

      {isLoggedIn ? (
        <Row>
          <Col md={12}>
            <FullFormFieldWrapper>
              <MD bold navy left tag="h5">
                Project Information (optional)
              </MD>
              <FormInput
                textarea
                type="message"
                id="message"
                name="message"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.message}
                showMessage
                error={
                  !!size(errors.message) && touched.message && errors.message[0]
                }
                placeholder="Enter project information, need date, locations, etc"
              />
            </FullFormFieldWrapper>
          </Col>
        </Row>
      ) : null}

      <Row>
        <Col md={12}>
          <DropWrapper>
            <MD paddingBottomXs bold navy left tag="h5">
              Add A File (optional)
            </MD>
            <DropZoneFileManager
              objectId={get(inquiryResult, 'id')}
              contentType={contentType}
              type="upload"
              externalSubmit
              // Specific flag to not fetch previously uploaded files
              hidePreviousUploads
              errorResubmitKey={resubmitKey}
              onSaved={() => {
                setStatus({ success: true });
                track('simpleInquirySuccess');
              }}
              setExternalState={handleSetExternalState}
              style={{ marginBottom: '20px' }}
            />
          </DropWrapper>
        </Col>
      </Row>

      <Row>
        <Col md={12}>
          {status && status.error ? (
            <FormFooter>
              <Field>
                <Message validation="error">{status.error}</Message>
              </Field>
            </FormFooter>
          ) : null}

          <FlexItem style={{ width: '100%' }}>
            <Button
              disabled={
                isEmpty(filesAdded) &&
                (!values.items.length ||
                  (values.items.length === 1 && !get(values.items, '[0].name')))
              }
              loading={isSubmitting || undefined}
              type={filesAddedError ? 'button' : 'submit'} // dont submit the form again. try resubmitting files
              onClick={
                !filesAddedError
                  ? null
                  : () => {
                      setResubmitKey(Math.random());
                    }
              }
              primary
              style={{ marginTop: '30px', marginBottom: spacingXl }}
            >
              Submit
            </Button>
          </FlexItem>
        </Col>
      </Row>
    </div>
  );
}

Items.propTypes = {
  isLoggedIn: PropTypes.bool,
  setStatus: PropTypes.func,
  contentType: PropTypes.number,
  values: PropTypes.shape({
    message: PropTypes.string,
    items: PropTypes.arrayOf(itemShape),
  }),
  errors: PropTypes.objectOf(PropTypes.arrayOf(PropTypes.string)),
  touched: PropTypes.shape({
    items: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.bool)),
    message: PropTypes.bool,
  }),
  handleBlur: PropTypes.func,
  handleChange: PropTypes.func,
  status: PropTypes.shape({
    error: PropTypes.bool,
    inquiryResult: PropTypes.shape({}),
  }),
  isSubmitting: PropTypes.bool,
  filesAdded: PropTypes.shape({
    files: PropTypes.shape({}),
    error: PropTypes.bool,
  }),
  setFiles: PropTypes.func,
  isSupply: PropTypes.bool,
};

function Item({ arrayHelpers, items, errors, touched, handleChange }) {
  return (
    <div>
      {map(items, (item, i, arr) => (
        <Flex alignCenter center key={`${item.id}-${i}`}>
          <FullFormFieldWrapper
            style={{
              flex: 1,
              marginTop: 0,
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <FormInput
              autoFocus={i === arr.length - 1}
              type="text"
              id={`items[${i}].name`}
              name={`items[${i}].name`}
              autoComplete="item"
              onChange={handleChange}
              value={item.name}
              showMessage
              error={!!size(errors.items) && touched.items && errors.items[0]}
              wrapperStyle={{
                width: '100%',
                flex: 1,
                maxWidth: '100%',
              }}
              placeholder="Enter Item Name"
            />

            <FormInput
              wrapperStyle={{ maxWidth: '80px', marginLeft: '10px' }}
              type="number"
              id={`items[${i}].quantity`}
              name={`items[${i}].quantity`}
              autoComplete="item-quantity"
              onChange={handleChange}
              value={item.quantity}
              showMessage
              error={!!size(errors.items) && touched.items && errors.items[0]}
              placeholder="Quantity"
            />

            <FormInput
              wrapperStyle={{ maxWidth: '50px', marginLeft: '10px' }}
              type="text"
              id={`items[${i}].uom`}
              name={`items[${i}].uom`}
              autoComplete="item-uom"
              onChange={handleChange}
              value={item.uom}
              showMessage
              error={!!size(errors.items) && touched.items && errors.items[0]}
              placeholder="Uom"
            />
          </FullFormFieldWrapper>
          {i === 0 && arr.length === 1 ? null : (
            <MD
              link
              style={{
                color: customRed,
                fontSize: '22px',
                padding: '24px 0px 13px 15px',
                marginTop: '7px',
              }}
              type="button"
              onClick={() => arrayHelpers.remove(i)}
            >
              <Icon
                pointer
                fontSize="9px"
                color={customRed}
                icon="icon-close"
                style={{
                  top: 'auto',
                  right: 'auto',
                  position: 'relative',
                }}
              />
            </MD>
          )}
        </Flex>
      ))}
      <Button
        disabled={some(items, ({ name }) => !name)}
        blue
        iconColor={customBlue}
        link
        iconBefore="icon-circle-plus"
        style={{
          color: 'red',
          width: '115px',
          margin: '18px auto 0',
          cursor: 'pointer',
          display: 'flex',
        }}
        onClick={() => {
          arrayHelpers.push({
            id: Math.random(),
            name: '',
            quantity: '',
            uom: 'FT.',
          });
        }}
        type="button"
        paddingTopMd
        bold
        left
        tag="h5"
      >
        Add An Item
      </Button>
    </div>
  );
}

Item.propTypes = {
  arrayHelpers: PropTypes.shape({
    remove: PropTypes.func,
    push: PropTypes.func,
  }),
  items: PropTypes.arrayOf(itemShape),
  errors: PropTypes.objectOf(PropTypes.arrayOf(PropTypes.string)),
  touched: PropTypes.shape({
    items: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.bool)),
    message: PropTypes.bool,
  }),
  handleChange: PropTypes.func,
};

function RenderItems({ values, errors, touched, handleChange, isSupply }) {
  return (
    <Col size={12} md={12}>
      {isSupply ? (
        <MD paddingTopMd bold navy left tag="h5">
          What Items Do You Want To Move?
        </MD>
      ) : (
        <MD paddingTopMd bold navy left tag="h5">
          What Items Do You Need?
        </MD>
      )}
      <FieldArray
        name="items"
        render={(arrayHelpers) => (
          <Item
            arrayHelpers={arrayHelpers}
            items={values.items}
            handleChange={handleChange}
            errors={errors}
            touched={touched}
          />
        )}
      />
    </Col>
  );
}

RenderItems.propTypes = {
  values: PropTypes.shape({
    items: PropTypes.arrayOf(itemShape),
  }),
  errors: PropTypes.objectOf(PropTypes.arrayOf(PropTypes.string)),
  touched: PropTypes.shape({
    items: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.bool)),
    message: PropTypes.bool,
  }),
  handleChange: PropTypes.func,
  isSupply: PropTypes.bool,
};

export default QuickInquiryForm;
