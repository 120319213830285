import Baobab from 'baobab';
import { reduce, isEmpty } from 'lodash';

export function state(initialState) {
  return {
    meta: {},
    businessInfo: {},
    account: {},
    alerts: {
      email: null,
    },
    areasOfInterests: {},
    interests: {
      activeRfq: {
        items: {},
      },
      meta: {},
      itemSelectionArray: [],
      itemSelection: {},
      itemSelectionRfqUpdateError: null,
    },
    rfqItemsValid: interestItemsValidMonkey(),
  };
}

function interestItemsValidMonkey() {
  return Baobab.monkey({
    cursors: {
      itemSelection: ['onboarding', 'interests', 'itemSelection'],
    },
    get({ itemSelection }) {
      if (isEmpty(itemSelection)) {
        return {};
      }
      return reduce(
        Object.values(itemSelection),
        (accum, item) => {
          if (accum.allValid && !item.valid) {
            accum.allValid = false;
          }

          if (accum.allSaved && !item.saved) {
            accum.allSaved = false;
          }
          return accum;
        },
        { allValid: true, allSaved: true }
      );
    },
  });
}
