import React, { useEffect } from 'react';
import { Grid } from '@zendeskgarden/react-grid';
import { trackClick } from 'utility/analytics';
import history from 'historyContainer';
import { Button } from 'theme/Button';
import { getRfqMeta } from 'state/requestForQuote/actions';
import { useBranch } from 'baobab-react/hooks';
import {
  prepareCriteria,
  onChangeGlobalSearchCriteria,
  setActiveRfqItem,
} from 'state/search/actions';
import { PublicItemSelectionFilters } from 'components/ItemSelectionFilters/ItemSelectionFilters';
import { omit, isEmpty, some, get } from 'lodash';
import {
  PublicItemSelectionFiltersWrapper,
  CrtieriaSearchWrapper,
} from './InlineSearchOverlay.styles.js';

const CriteriaSearchOverlay = (props) => {
  const { rfqItemFilters, rfqMetadata } = useBranch({
    rfqItemFilters: ['search', 'searchItemFilter', 'rfqItemFilters'],
    rfqMetadata: ['requestForQuote', 'meta', 'result'],
  });

  const hasRfqMetadata = !!rfqMetadata;
  const activeFilters = omit(rfqItemFilters, ['category', 'quantity']);
  const activeCrtieria = prepareCriteria();
  const shoudlShowSearch = some(activeCrtieria, (a, key) => {
    return get(a, 'length');
  });

  useEffect(() => {
    if (isEmpty(rfqMetadata)) {
      getRfqMeta();
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (hasRfqMetadata && !rfqItemFilters) {
      setActiveRfqItem();
    }
  }, [hasRfqMetadata]); // eslint-disable-line react-hooks/exhaustive-deps

  function goToSearch() {
    props.toggleSearch();
    trackClick('search', 'search', 'landing');
    history.pushWithParams(`/s/`, { criteria: prepareCriteria() });
  }

  return (
    <div>
      <PublicItemSelectionFiltersWrapper>
        <CrtieriaSearchWrapper>
          <Grid>
            <PublicItemSelectionFilters
              rowIndex={0}
              onChangeFilters={onChangeGlobalSearchCriteria}
              filters={activeFilters}
            />
          </Grid>
          <Button
            iconBefore="icon-search-left"
            iconColor="#fff"
            className={`${shoudlShowSearch ? 'show' : ''}`}
            onClick={goToSearch}
            small
            primary
          >
            Search
          </Button>
        </CrtieriaSearchWrapper>
      </PublicItemSelectionFiltersWrapper>
    </div>
  );
};

export default CriteriaSearchOverlay;
