import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import ButtonTheme from 'theme/ThemeProviderOverrides/Button';
import TextFieldTheme from 'theme/ThemeProviderOverrides/TextField';
import GridTheme from 'theme/ThemeProviderOverrides/Grid';
import TableTheme from 'theme/ThemeProviderOverrides/Table';
import TagTheme from 'theme/ThemeProviderOverrides/Tag';
import TabTheme from 'theme/ThemeProviderOverrides/Tab';
import DropdownTheme from 'theme/ThemeProviderOverrides/Dropdown';
import { ThemeProvider } from '@zendeskgarden/react-theming';
import { Page } from 'theme/Page';
import { variables } from 'theme/variables';
import TypographyTheme from './Typography';

const PublicTheme = {
  ...TagTheme,
  ...TabTheme,
  ...TableTheme,
  ...TypographyTheme,
  ...ButtonTheme,
  ...GridTheme,
  ...DropdownTheme,
  ...TextFieldTheme,
};

const { color_white: colorWhite } = variables;

export const PublicPageWrapper = styled(Page)`
  @import url('/fonts/roboto.css');
  padding: 0 !important;
  background: ${colorWhite};
  height: 100%;
  a {
    &:link,
    &:focus,
    &:hover,
    &:active,
    &:visited {
      text-decoration: none;
    }
  }
  .parallax-outer {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  .fade-in {
    opacity: 0;
    &.animated {
      opacity: 1;
    }
  }
  .fade-in-up {
    transform: translateY(50px);
    opacity: 0;
    &.animated {
      transform: translate(0px, 0px);
      opacity: 1;
    }
  }
  .fade-in-left {
    transform: translateX(-50px);
    opacity: 0;
    &.animated {
      transform: translate(0px, 0px);
      opacity: 1;
    }
  }
  .fade-in-right {
    transform: translateX(50px);
    opacity: 0;
    &.animated {
      transform: translate(0px, 0px);
      opacity: 1;
    }
  }
`;

const styleDefault = {};

function PublicPage({ style = styleDefault, children }) {
  return (
    <ThemeProvider theme={PublicTheme}>
      <PublicPageWrapper style={style}>{children}</PublicPageWrapper>
    </ThemeProvider>
  );
}

PublicPage.propTypes = {
  style: PropTypes.shape({}),
  children: PropTypes.node,
};

export default PublicPage;
