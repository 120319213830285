import React, { useState } from 'react';
import styled from 'styled-components/macro';
import Icon from 'components/Icon/Icon';
import { SM } from '@zendeskgarden/react-typography';
import { variables } from 'theme/variables';
const { custom_light_grey, custom_grey, custom_silver, spacing_sm } = variables;

const StyledAccordian = styled('div')`
  padding: ${spacing_sm} 0;
`;

const StyledBody = styled('div')`
  padding: ${spacing_sm} 0;
`;

const StyledHeader = styled('div')`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid ${custom_light_grey};
  padding: ${spacing_sm} 15px;
  padding-left: 10px;
  cursor: pointer;
  user-select: none;
`;

const Accordion = ({
  title,
  children,
  onToggle,
  style = {},
  headerStyle = {},
  bodyStyle = {},
  disabled,
}) => {
  const [visible, setVisibility] = useState(true);
  const visibility = visible && !disabled;
  return (
    <StyledAccordian style={style}>
      <StyledHeader
        style={headerStyle}
        onClick={() => {
          setVisibility(!visibility);
          if (onToggle) onToggle(!visibility);
        }}
      >
        <SM
          xs
          className="accordian-header"
          color={disabled ? custom_silver : undefined}
          bold
          uppercase
        >
          {title}
        </SM>
        {visibility ? (
          <Icon
            color={disabled ? custom_silver : custom_grey}
            icon="icon-chevron-up"
            fontSize="12px"
          />
        ) : (
          <Icon
            color={disabled ? custom_silver : custom_grey}
            icon="icon-chevron-down"
            fontSize="12px"
          />
        )}
      </StyledHeader>
      {visibility ? (
        <StyledBody style={bodyStyle}>{children}</StyledBody>
      ) : null}
    </StyledAccordian>
  );
};

export default Accordion;
