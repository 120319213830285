export default function state() {
  return {
    marketComparables: {
      currentData: {
        result: {},
        filters: {},
      },
    },
  };
}
