export function state(initialState) {
  return {
    products: {},
    activeProducts: defaultActiveProduct(),
  };
}

export function defaultActiveProduct() {
  return {};
}
