import React, { useState } from 'react';
import Icon from 'components/Icon/Icon';
import { IconButton } from '@zendeskgarden/react-buttons';
import { variables } from 'theme/variables';
import CustomizedNavLink from './ConversationButton.styles';

const { custom_blue: customBlue } = variables;

function ConversationButton() {
  const [active, setActive] = useState(false);
  return (
    <span style={{ maxWidth: '40px' }}>
      <IconButton
        style={{ maxWidth: '35px', maxHeight: '35px', minWidth: '35px' }}
        square
        whiteButtonLink
        black
        bold
        alignCenter
        justifyCenter
      >
        <CustomizedNavLink
          to="/dashboard/conversations"
          onClick={() => setActive(true)}
        >
          <Icon
            pointer
            color={active ? customBlue : ''}
            fontAwesome
            icon="comments"
            fontSize="15px"
          />
        </CustomizedNavLink>
      </IconButton>
    </span>
  );
}

export default ConversationButton;
