import { useRef, useEffect, useMemo } from 'react';
import { debounce } from 'lodash';

// https://www.developerway.com/posts/debouncing-in-react
export default function useDebounce(callback: () => void, delay: number) {
  const callbackRef = useRef(callback);

  useEffect(() => {
    callbackRef.current = callback;
  }, [callback]);

  const debouncedCallback = useMemo(() => {
    const callbackWrapper = (...args: unknown[]) => {
      callbackRef.current?.(...(args as []));
    };

    return debounce(callbackWrapper, delay);
  }, [delay]);

  return debouncedCallback;
}
