/* eslint-disable import/no-import-module-exports */
import 'react-app-polyfill/ie11';
import 'core-js/features/array/find';
import 'core-js/features/array/includes';
import 'core-js/features/number/is-nan';
import 'core-js/features/string/starts-with';
import 'core-js/features/string/ends-with';
import 'core-js/features/instance/repeat';
import 'core-js/features/string/repeat';
import 'core-js/features/instance/map';
import 'core-js/features/object/values';
import 'core-js/features/object/entries';
import 'core-js/features/string/match';
import 'core-js/features/array/fill';
import 'core-js/features/array/find-index';
import 'core-js/features/set';

import 'fast-text-encoding';

import React from 'react';
import { hydrateRoot, createRoot } from 'react-dom/client';
import Loadable from 'react-loadable';
import { Router } from 'react-router-dom';
import { canUseDOM } from 'exenv';
import { get } from 'lodash';
import * as Sentry from '@sentry/react';
import { ThemeProvider } from '@zendeskgarden/react-theming';
import ErrorBoundry from 'components/ErrorBoundry/ErrorBoundry';
import ThemeProviderOverrides from 'theme/ThemeProviderOverrides/index';
import { HelmetProvider } from 'react-helmet-async';
import UtilityProvider from 'providers/UtilityProvider';

import {
  getToken,
  checkSession,
  identifyUser,
} from 'state/authentication/effects';
import ScrollToTop from 'components/Navigation/ScrollToTop';
import tree from './state';
import history from './historyContainer';
import App from './App';
import { ENVIRONMENT, SENTRY_URL } from './constants';

import 'theme/variables';
import './index.css';
import './theme/global.scss';

import Root from './Root';

try {
  Sentry.init({
    dsn: SENTRY_URL,
    environment: ENVIRONMENT,
    integrations: [
      Sentry.replayIntegration({
        maskAllText: false,
        blockAllMedia: false,
      }),
    ],
    replaysOnErrorSampleRate: 1.0,
  });
} catch (err) {
  console.log(err, 'err');
}

const Provider = Root;
const AppBundle = (
  <Router history={history}>
    <ThemeProvider theme={ThemeProviderOverrides}>
      <UtilityProvider>
        <Provider>
          <ErrorBoundry>
            <HelmetProvider>
              <ScrollToTop />
              <App />
            </HelmetProvider>
          </ErrorBoundry>
        </Provider>
      </UtilityProvider>
    </ThemeProvider>
  </Router>
);

function wasServerRendered() {
  return (
    !canUseDOM ||
    (canUseDOM &&
      window.__INITIAL_STATE__ &&
      get(window.__INITIAL_STATE__, 'authentication.csrftoken'))
  );
}

async function refreshSession() {
  let session;
  if (wasServerRendered()) {
    session = tree.get(['authentication', 'session']);
    return session;
  }
  try {
    const { csrftoken } = await getToken();
    tree.set(['authentication', 'csrftoken'], csrftoken);
    session = await checkSession();
    if (get(session, 'success')) {
      tree.set(['authentication', 'session'], session);
    }
    return session;
  } catch (err) {
    console.log('error refreshing session', err);
    return null;
  }
}

function setUser(session = {}) {
  identifyUser(session);
}

// https://github.com/recharts/recharts/issues/3615#issuecomment-1636923358
// This can be removed at a later date, once packages catch up to this error notice:
// "Support for defaultProps will be removed from function components in a future major release. Use JavaScript default parameters instead."
const { error } = console;
console.error = (...args) => {
  if (/defaultProps/.test(args[0])) return;
  error(...args);
};

function render() {
  let session = refreshSession();
  const container = document.getElementById('root');
  const root = createRoot(container);
  const renderMethod = module.hot
    ? () => root.render(AppBundle)
    : () => hydrateRoot(container, AppBundle);
  window.onload = async () => {
    session = await session;
    setUser(session);
    Loadable.preloadReady().then(() => {
      renderMethod();
    });
  };
}

render();
