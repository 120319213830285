import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';
import { canUseDOM } from 'exenv';
import { addClass, removeClass } from 'utility/domUtils';
import { fadeIn } from 'theme/Animations.styles';
import styled, { css } from 'styled-components/macro';
import { fileUploadShape } from 'propTypesObjects';
import DropZone from './DropZone.tsx';

const animation = () =>
  css`
    ${fadeIn} 300ms 10ms ease forwards;
  `;

const StyledDropZoneModalWrapper = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  height: 100vh;
  width: 100%;
  background: rgba(0, 0, 0, 0.7);
  z-index: 40;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  animation: ${animation};
  .dropzone-wrapper-component {
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .drop-zone-inner {
    position: relative;
    max-width: 500px !important;
    width: 100%;
    background-color: #fff !important;
    box-shadow: 0 0 30px rgba(0, 0, 0, 0.2);
    .drop-zone-border {
      display: none !important;
    }
  }

  .DraftEditor-root {
    max-width: 90%;
    margin: 0 auto;
    margin-top: 12px;
  }

  .DraftEditor-root {
    min-height: 80px;
    border: 1px solid #dcdcdc !important;
    box-shadow: none;
    .public-DraftEditorPlaceholder-root {
      opacity: 0.6 !important;
    }
  }

  .editor-input-container-wrapper ~ .dropzone-drop {
    min-height: 239px !important;
  }
`;

function DropZoneModalComponent({
  portal,
  isImage,
  uploadOnDrop,
  multiple,
  onRemoveFile,
  onSubmitFiles,
  uploaded,
  setUploaded,
  descriptionContainer,
  renderContinueContainer,
  onClose,
  height,
  onAddFile,
  modalHeader,
  submitting,
  fileType,
}) {
  // dropzone-modal
  return (
    <DropzonePortal portal={portal}>
      <StyledDropZoneModalWrapper className="dropzone-modal">
        <DropZone
          portal={portal}
          isImage={isImage}
          uploadOnDrop={uploadOnDrop}
          multiple={multiple}
          onRemoveFile={onRemoveFile}
          onSubmitFiles={onSubmitFiles}
          uploaded={uploaded}
          setUploaded={setUploaded}
          descriptionContainer={descriptionContainer}
          renderContinueContainer={renderContinueContainer}
          onClose={onClose}
          height={height}
          onAddFile={onAddFile}
          modalHeader={modalHeader}
          submitting={submitting}
          fileType={fileType}
        />
      </StyledDropZoneModalWrapper>
    </DropzonePortal>
  );
}

const DropzonePortal = (props) => {
  const el = canUseDOM && document.querySelector('#dropzone-modal');
  const portal = el && props.portal;

  useEffect(() => {
    if (portal) {
      addClass(document.getElementsByTagName('html')[0], 'overflow-hidden');
    } else {
      removeClass(document.getElementsByTagName('html')[0], 'overflow-hidden');
    }
  }, [portal]);

  useEffect(
    () => () => {
      removeClass(document.getElementsByTagName('html')[0], 'overflow-hidden');
    },
    []
  );

  if (el && props.portal) {
    return ReactDOM.createPortal(props.children, el);
  }
  return props.children;
};

DropZoneModalComponent.propTypes = {
  portal: PropTypes.bool,
  isImage: PropTypes.bool,
  uploadOnDrop: PropTypes.bool,
  multiple: PropTypes.bool,
  onRemoveFile: PropTypes.func,
  onSubmitFiles: PropTypes.func,
  uploaded: PropTypes.objectOf(fileUploadShape),
  setUploaded: PropTypes.func,
  descriptionContainer: PropTypes.node,
  renderContinueContainer: PropTypes.func,
  onClose: PropTypes.func,
  height: PropTypes.string,
  onAddFile: PropTypes.func,
  modalHeader: PropTypes.node,
  submitting: PropTypes.bool,
  fileType: PropTypes.string,
};

export default DropZoneModalComponent;
