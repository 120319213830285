import { isEmpty } from 'lodash';

import fetchJson from 'utility/fetchJson';
import { getValuesForRequest } from 'utility/reverseUrlFilters';
import { BASE } from '../../../constants';

export async function getPartnerCustomers({
  sorting = {},
  filters = {},
  pagination = {},
}) {
  const currentFilters = isEmpty(filters)
    ? getValuesForRequest('customers')
    : filters;
  const body = { ...currentFilters, ...pagination, ...sorting };
  const response = await fetchJson(`${BASE}/api/user/partner-user-list/`, {
    method: 'POST',
    body: JSON.stringify(isEmpty(body) ? { page: 1 } : body),
  });
  return response;
}

export async function getPartnerCustomerDetails(userId) {
  const response = await fetchJson(
    `${BASE}/api/user/partner-user/?id=${userId}`,
    {
      method: 'GET',
    }
  );
  return response;
}

export async function submitPartnerCustomer(data) {
  const response = await fetchJson(`${BASE}/api/user/partner-user/`, {
    method: 'POST',
    body: JSON.stringify(data),
  });
  return response;
}

export async function updatePartnerCustomer(userId, data) {
  const response = await fetchJson(`${BASE}/api/user/partner-user/`, {
    method: 'PATCH',
    body: JSON.stringify({ id: userId, ...data }),
  });
  return response;
}
