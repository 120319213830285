import React from 'react';
import ReactDOM from 'react-dom';
/**
 * react-menu-aim is a React Mixin heavily inspired by jQuery-menu-aim. All rights
 * reserved by the original author.
 *
 * https://github.com/jasonslyvia/react-menu-aim
 * https://github.com/kamens/jQuery-menu-aim
 */
const MOUSE_LOCS_TRACKED = 3; // number of past mouse locations to trackv
const DELAY = 300; // ms delay when user appears to be entering submenu
const TOLERANCE = 75; // bigger = more forgivey when entering submenu

/**
 *
 * DOM helpers
 *
 */
function on(el, eventName, callback) {
  el.addEventListener(eventName, callback, false);
}

function off(el, eventName, callback) {
  el.removeEventListener(eventName, callback);
}

function offset(el) {
  if (!el) {
    return {
      left: 0,
      top: 0,
    };
  }
  const rect = el.getBoundingClientRect();
  return {
    top: rect.top + document.body.scrollTop,
    left: rect.left + document.body.scrollLeft,
  };
}

function outerWidth(el) {
  let _width = el.offsetWidth;
  const style = el.currentStyle || getComputedStyle(el);

  _width += parseInt(style.marginLeft, 10) || 0;
  return _width;
}

function outerHeight(el) {
  let _height = el.offsetHeight;
  const style = el.currentStyle || getComputedStyle(el);

  _height += parseInt(style.marginLeft, 10) || 0;
  return _height;
}

/**
 *
 * Util helpers
 *
 */

// Consider multiple instance using ReactMenuAim, we just listen mousemove once
let mousemoveListener = 0;
let mouseLocs = [];

// Mousemove handler on document
function handleMouseMoveDocument(e) {
  mouseLocs.push({
    x: e.pageX,
    y: e.pageY,
  });

  if (mouseLocs.length > MOUSE_LOCS_TRACKED) {
    mouseLocs.shift();
  }
}

function getActivateDelay(config) {
  config = config || {};
  const menu = config.menuRef;
  // If can't find any DOM node
  if (!menu || !menu.querySelector) {
    return 0;
  }
  const menuOffset = offset(menu);

  const upperLeft = {
    x: menuOffset.left,
    y: menuOffset.top - (config.tolerance || TOLERANCE),
  };
  const upperRight = {
    x: menuOffset.left + outerWidth(menu),
    y: upperLeft.y,
  };
  const lowerLeft = {
    x: menuOffset.left,
    y: menuOffset.top + outerHeight(menu) + (config.tolerance || TOLERANCE),
  };
  const lowerRight = {
    x: menuOffset.left + outerWidth(menu),
    y: lowerLeft.y,
  };

  const loc = mouseLocs[mouseLocs.length - 1];
  let prevLoc = mouseLocs[0];

  if (!loc) {
    return 0;
  }

  if (!prevLoc) {
    prevLoc = loc;
  }

  // If the previous mouse location was outside of the entire
  // menu's bounds, immediately activate.
  if (
    prevLoc.x < menuOffset.left ||
    prevLoc.x > lowerRight.x ||
    prevLoc.y < menuOffset.top ||
    prevLoc.y > lowerRight.y
  ) {
    return 0;
  }

  // If the mouse hasn't moved since the last time we checked
  // for activation status, immediately activate.
  if (
    this._lastDelayDoc &&
    loc.x === this._lastDelayDoc.x &&
    loc.y === this._lastDelayDoc.y
  ) {
    return 0;
  }

  // Detect if the user is moving towards the currently activated
  // submenu.
  //
  // If the mouse is heading relatively clearly towards
  // the submenu's content, we should wait and give the user more
  // time before activating a new row. If the mouse is heading
  // elsewhere, we can immediately activate a new row.
  //
  // We detect this by calculating the slope formed between the
  // current mouse location and the upper/lower right points of
  // the menu. We do the same for the previous mouse location.
  // If the current mouse location's slopes are
  // increasing/decreasing appropriately compared to the
  // previous's, we know the user is moving toward the submenu.
  //
  // Note that since the y-axis increases as the cursor moves
  // down the screen, we are looking for the slope between the
  // cursor and the upper right corner to decrease over time, not
  // increase (somewhat counterintuitively).
  function slope(a, b) {
    return (b.y - a.y) / (b.x - a.x);
  }

  let decreasingCorner = upperRight;
  let increasingCorner = lowerRight;

  // Our expectations for decreasing or increasing slope values
  // depends on which direction the submenu opens relative to the
  // main menu. By default, if the menu opens on the right, we
  // expect the slope between the cursor and the upper right
  // corner to decrease over time, as explained above. If the
  // submenu opens in a different direction, we change our slope
  // expectations.
  if (config.submenuDirection === 'left') {
    decreasingCorner = lowerLeft;
    increasingCorner = upperLeft;
  } else if (config.submenuDirection === 'below') {
    decreasingCorner = lowerRight;
    increasingCorner = lowerLeft;
  } else if (config.submenuDirection === 'above') {
    decreasingCorner = upperLeft;
  }

  const decreasingSlope = slope(loc, decreasingCorner);
  const increasingSlope = slope(loc, increasingCorner);
  const prevDecreasingSlope = slope(prevLoc, decreasingCorner);
  const prevIncreasingSlope = slope(prevLoc, increasingCorner);

  // Mouse is moving from previous location towards the
  // currently activated submenu. Delay before activating a
  // new menu row, because user may be moving into submenu.
  if (
    decreasingSlope < prevDecreasingSlope &&
    increasingSlope > prevIncreasingSlope
  ) {
    this._lastDelayLoc = loc;
    return config.delay || DELAY;
  }

  this._lastDelayLoc = null;
  return 0;
}

// For the concern of further changes might happen when activeRowIndex changes,
// this mixin doesn't setState directly, instead it calls the callback provided
// by user.
function activate(rowIdentifier, handler) {
  handler.call(this, rowIdentifier);
}

function possiblyActivate(rowIdentifier, handler, config) {
  const delay = getActivateDelay.call(this, config);

  if (delay) {
    this.__reactMenuAimTimer = setTimeout(() => {
      possiblyActivate.call(this, rowIdentifier, handler, config);
    }, delay);
  } else {
    activate.call(this, rowIdentifier, handler);
  }
}

export default function menuAim(Component) {
  class ComposedMenuAim extends React.Component {
    componentDidMount() {
      if (mousemoveListener === 0) {
        on(document, 'mousemove', this.__getMouseMoveDocumentHandler());
      }
      mousemoveListener += 1;
    }

    componentWillUnmount() {
      mousemoveListener -= 1;

      if (mousemoveListener === 0) {
        off(document, 'mousemove', this.__getMouseMoveDocumentHandler());
        mouseLocs = [];
      }

      clearTimeout(this.__reactMenuAimTimer);
      this.__reactMenuAimTimer = null;
      this.__mouseMoveDocumentHandler = null;
    }

    initMenuAim(options) {
      this.__reactMenuAimConfig = options;
    }

    __getMouseMoveDocumentHandler() {
      if (!this.__mouseMoveDocumentHandler) {
        this.__mouseMoveDocumentHandler = handleMouseMoveDocument.bind(this);
      }
      return this.__mouseMoveDocumentHandler;
    }

    handleMouseLeaveMenu(handler, e) {
      if (this.__reactMenuAimTimer) {
        clearTimeout(this.__reactMenuAimTimer);
      }

      if (typeof handler === 'function') {
        handler.call(this, e);
      }
    }

    handleMouseEnterRow(rowIdentifier, handler) {
      if (this.__reactMenuAimTimer) {
        clearTimeout(this.__reactMenuAimTimer);
      }

      possiblyActivate.call(
        this,
        rowIdentifier,
        handler,
        this.__reactMenuAimConfig
      );
    }

    render() {
      return (
        <Component
          {...this.props}
          initMenuAim={this.initMenuAim.bind(this)}
          handleMouseLeaveMenu={this.handleMouseLeaveMenu.bind(this)}
          handleMouseEnterRow={this.handleMouseEnterRow.bind(this)}
        />
      );
    }
  }
  return ComposedMenuAim;
}
