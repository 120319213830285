import React, { useState, memo } from 'react';
import PropTypes from 'prop-types';
import {
  Select,
  Dropdown,
  Menu,
  Item,
  Field,
} from '@zendeskgarden/react-dropdowns';
import { map } from 'lodash';

function FilterItemOperators({
  operators,
  selectedOperator,
  onChangeOperator,
}) {
  const [menuIsOpen, setMenuIsOpen] = useState(false);
  return (
    <Dropdown
      selectedItem={selectedOperator}
      onSelect={(selectedOp) => onChangeOperator(selectedOp)}
      downshiftProps={{ itemToString: (item) => item && item.label }}
      onStateChange={(changes) => {
        if (
          changes.isOpen !== menuIsOpen &&
          Object.prototype.hasOwnProperty.call(changes, 'isOpen')
        ) {
          setMenuIsOpen(changes.isOpen);
        }
      }}
    >
      <Field>
        <Select className="filter-item-input operator" small itemSelectionMini>
          {selectedOperator ? selectedOperator.label : ''}
        </Select>
      </Field>
      {menuIsOpen ? (
        <Menu
          small
          popperModifiers={{
            flip: { enabled: false },
          }}
        >
          {map(operators, (operator) => (
            <Item key={operator.value || operator.id} value={operator}>
              {operator.label}
            </Item>
          ))}
        </Menu>
      ) : null}
    </Dropdown>
  );
}

FilterItemOperators.propTypes = {
  operators: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      label: PropTypes.string,
      id: PropTypes.number,
    })
  ),
  selectedOperator: PropTypes.shape({
    label: PropTypes.string,
  }),
  onChangeOperator: PropTypes.func,
};

export default memo(FilterItemOperators);
