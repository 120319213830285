export function defaultActiveEmployee() {
  return {
    firstName: {},
    lastName: {},
    email: {},
    pipesearchUser: {},
    instructions: {},
    role: {},
    managerId: {},
  };
}

export function state() {
  return {
    employees: {},
    activeEmployees: defaultActiveEmployee(),
  };
}
