import {
  omit,
  omitBy,
  get,
  uniqBy,
  isArray,
  includes,
  reject,
  isNil,
  find,
} from 'lodash';
import { generateFilterValues } from 'state/requestForQuote/transformer';
import { CRITERIA_QUERY_VALUES } from 'state/requestForQuote/transformer_criteria';

function prepareCriteria({ filters } = {}) {
  let filterData = generateFilterValues(filters);
  filterData = omitBy(filterData, (d) => isNil(d) || get(d, 'length') === 0);

  const metallurgyGroup = get(filterData, 'metallurgy_group');
  if (metallurgyGroup && !isArray(metallurgyGroup)) {
    filterData.metallurgy_group = [metallurgyGroup];
  }
  return filterData;
}

export default function filterFacetsWithCriteria({
  type,
  data,
  options = {},
  currentFilters,
  facetFilters,
}) {
  const { external } = options;
  const attribute =
    type === 'metallurgy' || type === 'metallurgy_group' ? 'alloy' : type;

  let filters = {}; // not all filters are part of RFQ item. ie status
  let itemFilters;
  if (external && includes(CRITERIA_QUERY_VALUES, type)) {
    // type to account for metallurgy vs alloy
    itemFilters = data;
    const checked = get(options, 'changeValue.checked');
    const currentFilter = currentFilters[attribute];
    if (
      type !== 'metallurgy_group' &&
      currentFilter &&
      isArray(currentFilter.value)
    ) {
      const array = checked
        ? [...currentFilter.value, { ...data, selected: checked }]
        : reject(currentFilter.value, ['value', data.value]);
      itemFilters = { ...currentFilter, value: uniqBy(array, 'value') };
    } else if (type === 'metallurgy_group') {
      const group = find(currentFilter.selectionFilters.groups, {
        value: data.value,
      });
      const array = checked
        ? [...currentFilter.selectedFilters.groups, group]
        : reject(currentFilter.selectedFilters.groups, { value: data.value });
      itemFilters = {
        ...currentFilter,
        selectedFilters: {
          ...currentFilter.selectedFilters,
          groups: array,
        },
      };
    }
  } else if (
    external &&
    !includes(CRITERIA_QUERY_VALUES, attribute) &&
    facetFilters
  ) {
    filters = facetFilters;
  } else if (!external) {
    itemFilters = data; // when the item component makes the change
    filters = facetFilters;
  }
  filters = omit(filters, CRITERIA_QUERY_VALUES);

  let newFilters;
  if (type === 'metallurgy_group') {
    newFilters = {
      ...currentFilters,
      alloy: {
        ...currentFilters.alloy,
        selectedFilters: {
          ...currentFilters.alloy.selectedFilters,
          groups: itemFilters.selectedFilters.groups,
        },
      },
    };
  } else {
    newFilters = {
      ...currentFilters,
      [attribute]: { ...(itemFilters || {}), disabled: false },
    };
  }
  const reqData = options.reset
    ? {}
    : prepareCriteria({ filters: newFilters }) || {};
  return { filters, criteria: reqData, itemFilters, attribute };
}
