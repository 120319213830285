import styled from 'styled-components/macro';
import { variables } from 'theme/variables';

const {
  spacing,
  border_radius: borderRadius,
  custom_sky_blue_30: customSkyBlue30,
  custom_sky_blue: customSkyBlue,
  spacing_sm: spacingSm,
} = variables;

export const OverlayWrapper = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: ${(props) => (props.justify ? props.justify : 'center')};
  align-items: center;
  z-index: 25;
  padding: 40px ${spacingSm};
`;

export const OverlayBackground = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background: ${(props) =>
    props.background ? props.background : 'rgba(0,0,0,0.7);'};
  z-index: 1;
`;

export const OverlayInner = styled.div`
  min-height: 200px;
  min-width: 410px;
  max-width: 100%;
  background: #fff;
  z-index: 2;
  position: relative;
  display: flex;
  flex-direction: column;
  border-radius: ${borderRadius};
  @media (max-width: 410px) {
    min-width: unset;
    max-width: 90%;
    width: 100%;
  }
  @media (max-width: 340px) {
    min-width: unset;
    max-width: 95%;
    width: 100%;
  }
  ${(props) =>
    props.full
      ? `
    position: fixed;
    padding: 0;
    top: 60px;
    left: 50px;
    right: 50px;
    bottom: 50px;
    width: auto;
    @media (max-width:540px) {
      left: 15px;
      right: 15px;
      bottom: 15px;
      width: auto;
      max-width: 100%;
    } 
    `
      : ''};
`;

export const OverlayHeader = styled('div')`
  padding: ${spacingSm} ${spacing};
  height: 61px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: ${customSkyBlue30};
  border-bottom: 1px solid ${customSkyBlue};
  border-top-left-radius: ${borderRadius};
  border-top-right-radius: ${borderRadius};
  min-width: 0;
`;

export const OverlayContent = styled.div`
  min-height: ${(props) => (props.showHeader ? 'calc(100% - 61px)' : '100%')};
  flex: 1;
  display: flex;
  justify-content: center;
  form {
    overflow: hidden;
    ${/* due to dropdowns */ ''}
  }
  ${(props) =>
    props.padding
      ? `
    padding: ${spacing} ${spacing};
   `
      : ''}

  ${(props) =>
    props.scroll
      ? `
    max-height: calc(100% - 61px);
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    scrollbar-width: none;
    ::-webkit-scrollbar{
      width: 0;
    }
  `
      : ''}
`;

export const OverlayGroupWrapper = styled.div`
  pointer-events: ${(props) => (props.open ? 'all' : 'none')};
  .overlay-exit .overlay-wrapper-inner {
    opacity: 1;
    transform: translateY('0%');
  }

  .overlay-exit.overlay-exit-active .overlay-wrapper-inner {
    opacity: 0.01;
    transition: all 300ms 0ms ease;
  }

  .overlay-enter .overlay-wrapper-inner {
    transform: translateY(-6px);
    opacity: 0.01;
  }

  .overlay-enter.overlay-enter-active .overlay-wrapper-inner {
    transform: translateY(0%);
    opacity: 1;
    transition: all 500ms 100ms ease;
  }

  .overlay-appear .overlay-wrapper-inner {
    opacity: 0.01;
    transform: translateY(-6px);
  }

  .overlay-appear.overlay-appear-active .overlay-wrapper-inner {
    opacity: 1;
    transform: translateY(0);
    transition: all 700ms 0ms ease;
  }

  .overlay-enter .overlay-wrapper-background {
    transition: all 400ms 0ms ease;
    opacity: 0;
  }

  .overlay-enter.overlay-enter-active .overlay-wrapper-background {
    opacity: 1;
    transition: all 400ms 0ms ease;
  }

  .overlay-appear .overlay-wrapper-background {
    transition: all 400ms 0ms ease;
    opacity: 0.01;
  }

  .overlay-appear.overlay-appear-active .overlay-wrapper-background {
    transition: all 400ms 0ms ease;
    opacity: 1;
  }
  .overlay-exit .overlay-wrapper-background {
    opacity: 1;
  }

  .overlay-exit.overlay-exit-active .overlay-wrapper-background {
    opacity: 0.01;
    transition: all 300ms 0ms ease;
  }
`;
