// OVERIDE CSS
import { variables } from 'theme/variables';

const {
  custom_blue: customBlue,
  font_weight_regular: fontWeightRegular,
  font_size_sm: fontSizeSm,
  custom_grey: customGrey,
} = variables;

const MenuTheme = {
  'checkboxes.label': (props) => `
      &:before {
        outline: none;
        box-shadow: none;
      }
      ${
        props.inverted
          ? `
        padding-right: 24px;
        padding-left: 0;
        &:before {
          left: auto !important;
          right: 0 !important;
        }
      `
          : ''
      }
      ${
        props.outline
          ? `
        &:before {
          top: 11px;
          background-color: transparent;
          border: 1px solid ${
            props.disabled ? customGrey : customBlue
          } !important;
        }
      `
          : ''
      }

      ${
        props.blue
          ? `
        color: ${customBlue} !important;
      `
          : ''
      }

      ${
        props.light
          ? `
        font-weight: ${fontWeightRegular} !important;
      `
          : ''
      }

      ${
        props.small
          ? `
        font-size: ${fontSizeSm} !important;
        &:before {
          top: 9px;
        }
      `
          : ''
      }

      ${
        props.disabled
          ? `
        color: ${customGrey} !important;
      `
          : ''
      }
    `,
  'checkboxes.checkbox_view': () => `
      .checkbox__c-chk__input___triCm:checked:enabled~.checkbox__c-chk__label___R6mUZ:not(.checkbox__is-disabled___10BbS):before, .checkbox__c-chk__input___triCm:enabled~.checkbox__c-chk__label___R6mUZ.checkbox__is-checked___2xZoq:not(.checkbox__is-disabled___10BbS):before, .checkbox__c-chk__input___triCm:enabled~.checkbox__c-chk__label___R6mUZ:not(.checkbox__c-chk__label--radio___1W4Mr):not(.checkbox__c-chk__label--toggle___1oiDF).checkbox__is-indeterminate___2uS-h:not(.checkbox__is-disabled___10BbS):before, .checkbox__c-chk__input___triCm:indeterminate:enabled~.checkbox__c-chk__label___R6mUZ:not(.checkbox__c-chk__label--radio___1W4Mr):not(.checkbox__c-chk__label--toggle___1oiDF):not(.checkbox__is-disabled___10BbS):before {
        border-color: ${customBlue};
        background-color: ${customBlue};
      }
    `,
};

export default MenuTheme;
