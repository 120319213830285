import tree from 'state';
import { baseValidation } from 'utility/dynamicFormHelpers';
import {
  map,
  round,
  keys,
  isArray,
  sortBy,
  isEmpty,
  filter,
  forEach,
  get,
  find,
} from 'lodash';
import moment from 'moment';
import { variables } from 'theme/variables';
import { getTags } from 'state/tags/actions';
import numeral from 'numeral';

const { media_md: mediaMd } = variables;

function transformUom(uom) {
  return map(uom, ({ id, name }) => ({ value: id, label: name }));
}

function transformUomValue(uom, index, defaultName) {
  if (defaultName) {
    const defaultUom = find(uom, { name: defaultName });
    return {
      label: defaultUom.name,
      value: defaultUom.id,
    };
  }
  return {
    label: get(uom, `[${index || 0}].name`),
    value: get(uom, `[${index || 0}].id`),
  };
}

function formatValue(data) {
  if (data?.group) {
    return {
      value: data?.v || '',
      label: data?.l || '',
      group: data?.group || '',
    };
  }
  if (data?.t) {
    return {
      value: data?.v || '',
      label: data?.l || '',
      template: data?.t || '',
    };
  }
  return { value: data?.v || '', label: data?.l || '' };
}

function formatMultiValue(field, data) {
  const {
    metallurgy,
    creation_metallurgy: creationMetallurgy,
    metallurgy_name: metallurgyName,
    metallurgy_group: metallurgyGroup,
    creation_metallurgy_group: creationMetallurgyGroup,
    end_finish: endFinish,
    end_finish_name: endFinishName,
    end_finish_category: endFinishCategory,
    end_finish_licensee: endFinishLicensee,
    end_finish_thread_type: endFinishThreadType,
  } = data || {};

  const finalMetallurgy = creationMetallurgy || metallurgy;
  const finalMetallurgyGroup = creationMetallurgyGroup || metallurgyGroup;

  if (field === 'alloy' && finalMetallurgy) {
    const metallurgyArray = isArray(finalMetallurgy)
      ? finalMetallurgy
      : [finalMetallurgy];
    return map(metallurgyArray, (metal, idx) => ({
      value: metal || '',
      label: metallurgyName[idx] || '',
      group: finalMetallurgyGroup[idx] || '',
    }));
  }
  if (field === 'end_finish' && endFinish) {
    return isArray(endFinish)
      ? map(endFinish, (finish, idx) => ({
          selected: true,
          value: finish,
          label: endFinishName[idx] || '',
          end_finish_category: endFinishCategory[idx] || '',
          end_finish_licensee: endFinishLicensee[idx] || '',
          end_finish_thread_type: endFinishThreadType[idx] || '',
        }))
      : [
          {
            selected: true,
            value: endFinish || '',
            label: endFinishName || '',
            end_finish_category: endFinishCategory || '',
            end_finish_licensee: endFinishLicensee || '',
            end_finish_thread_type: endFinishThreadType || '',
          },
        ];
  }
  return null;
}

function getMetadata() {
  return (
    tree.select(['requestForQuote']).get(['meta', 'result']) ||
    tree.select(['onboarding']).get(['meta', 'result']) ||
    tree.select(['metadata']).get(['rfqMetadata', 'result']) ||
    tree.select(['metadata']).get(['onboardingMetadata', 'result'])
  );
}

export async function formatTags(tagsArr) {
  const currentTags = await getTags();
  const mappedTagValues = map(tagsArr, (tag) => {
    const filteredArr = filter(currentTags.results, { name: tag });
    if (!isEmpty(filteredArr)) {
      return { value: tag, label: tag, customValue: false };
    }
    return { value: tag, label: tag, customValue: true };
  });
  return mappedTagValues;
}

export async function transformFromBEtoForm(tab, type) {
  let rawData = {};
  const metadata = await getMetadata();
  if (type === 'Edit') {
    rawData = tree
      .select(['marketComparables'])
      .get(['currentMarketComparable', 'result']);
  }

  const deliveryTimeDataIsRange =
    rawData?.delivery_time_min || rawData?.delivery_time_max;
  const deliveryTimeDataAvailable =
    deliveryTimeDataIsRange || rawData?.delivery_time;
  const overviewConfig = {
    type: {
      title: 'Purchase Type',
      required: true,
      defaultValue: 'Select a Sales Type',
      value: formatValue({ v: rawData?.type, l: rawData?.type_display }),
      validation: baseValidation,
    },
    employee: {
      title: 'Employee',
      defaultValue: 'Search for an Employee',
      value: formatValue({
        v: rawData?.employee_id,
        l: rawData?.employee_name,
      }),
      validation: baseValidation,
    },
    received_date: {
      type: 'date',
      value: formatValue({
        v: rawData?.received_date ? new Date(rawData?.received_date) : '',
        l: moment(rawData?.received_date).format('DD-MMM-YYYY'),
      }),
      title: 'Received Date',
      validation: baseValidation,
    },
    transaction_date: {
      type: 'date',
      value: formatValue({
        v: rawData?.transaction_date ? new Date(rawData?.transaction_date) : '',
        l: moment(rawData?.transaction_date).format('DD-MMM-YYYY'),
      }),
      title: 'Transaction Date',
      validation: baseValidation,
    },
    delivery_time: {
      fields: {
        delivery_time: {
          filterItemGroupTwoFilters: true,
          range: true,
          value: deliveryTimeDataIsRange
            ? {
                min: {
                  value: rawData?.delivery_time_min,
                  label: rawData?.delivery_time_min,
                },
                max: {
                  value: rawData?.delivery_time_max,
                  label: rawData?.delivery_time_max,
                },
              }
            : {
                value: rawData?.delivery_time,
                label: rawData?.delivery_time,
              },
          required: false,
          inputType: 'number',
          validation: baseValidation,
          uomKey: 'time_uom',
          uomOptions: transformUom(metadata.timing_uom),
          uom: transformUomValue(
            metadata.timing_uom,
            null,
            (deliveryTimeDataAvailable && rawData?.delivery_time_uom_display) ||
              'Week'
          ),
          uomOptionsSize: 3,
          operators: [
            { label: 'Equals', value: 'equals' },
            { label: 'Range', value: 'range' },
          ],
          operator: deliveryTimeDataIsRange
            ? { label: 'Range', value: 'range' }
            : { label: 'Equals', value: 'equals' },
          ui: {
            title: 'Delivery Time',
            condensed: true,
            sm: 12,
            md: 6,
            lg: 6,
            extraPadding: mediaMd,
            marginBottom: '0px',
            titleWeight: 'bold',
          },
        },
      },
    },
    location_address: {
      title: 'Address/Location',
      addressPath: 'location_address',
      serverName: 'google_address_place_id',
      defaultValue: 'Enter Address/Location',
      location: true,
      type: 'address',
      value: formatValue({
        v: rawData?.formatted_address,
        l: rawData?.formatted_address,
      }),
      validation: baseValidation,
    },
    tag: {
      fields: {
        tag: {
          title: 'Tags',
          required: false,
          customValues: false,
          key: 'tag',
          value: await formatTags(rawData?.tags),
          validation: baseValidation,
          ignoreCustomFieldValidation: true,
          multiSelect: true,
          items: [],
          ui: {
            title: 'Tags',
            condensed: true,
            placeholder: 'Enter Tag',
            sm: 12,
            md: 6,
            lg: 6,
            extraPadding: mediaMd,
          },
        },
      },
    },
  };

  const descriptionConfig = {
    category: {
      title: 'Category',
      defaultValue: 'Select a Category',
      validation: baseValidation,
      value: rawData?.category
        ? formatValue({
            v: rawData?.category,
            l: rawData?.category_name,
            t: rawData?.category_name,
          })
        : null,
      items:
        map(metadata?.category, ({ name, id }) => ({
          value: id,
          label: name,
        })) || [],
    },
    od: {
      required: true,
      title: 'OD',
      validation: baseValidation,
      defaultValue: 'Select an OD',
      value: formatValue({
        v: rawData?.od,
        l: rawData?.od ? `${round(rawData?.od, 3)}"` : '',
      }),
      items:
        map(metadata?.standard_od, ({ name, od, id }) => ({
          value: od,
          label: name,
          id,
        })) || [],
    },
    weight_per_foot: {
      required: true,
      title: '#/FT',
      defaultValue: 'Select Weight per Foot',
      value: formatValue({
        v: `${rawData?.weight_per_foot}`,
        l: rawData?.weight_per_foot ? `${rawData?.weight_per_foot}#` : '',
      }),
      validation: baseValidation,
      items: sortBy(
        map(
          metadata?.standard_weight,
          ({
            name,
            weight_per_foot: weightPerFoot,
            standard_od: standardOd,
          }) => ({
            rangeFilterValue: Number(weightPerFoot),
            value: weightPerFoot,
            label: name,
            standard_od: standardOd,
          })
        ),
        'rangeFilterValue'
      ),
    },
    alloy: {
      required: true,
      inputType: 'FilterItemSelection',
      title: 'Alloy',
      multiple: false,
      ignoreTitle: true,
      templates: ['OCTG', 'Mechanical', 'Coupling', 'Bar', 'Shell'],
      customValues: true,
      validation: baseValidation,
      value: formatMultiValue('alloy', rawData),
      selectionFilters: {
        groups: map(metadata?.creation_metallurgy_group, ({ name, id }) => ({
          value: id,
          label: name,
        })),
      },
      items: map(
        metadata?.creation_metallurgy,
        ({ name, id, metallurgy_group: metallurgyGroup }) => ({
          value: id,
          label: name,
          group: metallurgyGroup,
        })
      ),
    },
    min_yield: {
      required: true,
      title: 'Yield',
      defaultValue: 'Enter Min Yield',
      uom: 'KSI',
      templates: ['OCTG', 'Mechanical', 'Coupling', 'Bar'],
      type: 'input',
      inputType: 'number',
      validation: baseValidation,
      value: formatValue({
        v: rawData?.min_yield,
        l: `${rawData?.min_yield || ''}`,
      }),
    },
    end_finish: {
      inputType: 'FilterItemSelection',
      defaultValue: 'Select End Finish',
      title: 'End Finish',
      ignoreTitle: true,
      multiple: false,
      customValues: true,
      templates: ['OCTG', 'Coupling'],
      validation: baseValidation,
      value: formatMultiValue('end_finish', rawData),
      selectionFilters: {
        end_finish_category: map(
          metadata?.end_finish_category,
          ({ name, id }) => ({
            type: 'end_finish_category',
            value: id,
            label: name,
          })
        ),
        end_finish_licensee: map(
          metadata?.end_finish_licensee,
          ({ name, id }) => ({
            type: 'end_finish_licensee',
            value: id,
            label: name,
          })
        ),
        end_finish_thread_type: map(
          metadata?.end_finish_thread_type,
          ({ name, id }) => ({
            type: 'end_finish_thread_type',
            value: id,
            label: name,
          })
        ),
      },
      items: map(
        metadata?.end_finish,
        ({ category: cat, id, licensee, name, thread_type: threadType }) => ({
          value: id,
          label: name,
          end_finish_category: cat,
          end_finish_thread_type: threadType,
          end_finish_licensee: licensee,
        })
      ),
    },
    condition: {
      title: 'Condition',
      type: 'dropdown',
      value: formatValue({ v: rawData?.condition, l: rawData?.condition_name }),
      validation: baseValidation,
      items: map(metadata?.condition, ({ id, name }) => ({
        value: id,
        label: name,
      })),
    },
    manufacturer: {
      title: 'Manufacturer',
      defaultValue: 'Select a Manufacturer',
      value: formatValue({
        v: rawData?.manufacturer,
        l: rawData?.manufacturer_name,
      }),
      validation: baseValidation,
      items: map(metadata?.manufacturer, ({ id, name }) => ({
        value: id,
        label: name,
      })),
    },
    mill_year: {
      required: rawData?.type_display === 'Mill',
      title: 'Mill Year',
      type: 'input',
      inputType: 'number',
      defaultValue: 'Enter Mill Year',
      validation: baseValidation,
      value: formatValue({
        v: `${rawData?.mill_year || ''}`,
        l: `${rawData?.mill_year || ''}`,
      }),
    },
    mtr: {
      title: 'This Market Comp has MTRs',
      type: 'radio',
      validation: baseValidation,
      value: formatValue({ v: rawData?.mtr, l: rawData?.mtr }),
    },
  };

  const quantityConfig = {
    footage: {
      title: 'Footage',
      uom: 'FT',
      defaultValue: 'Enter Footage',
      validation: baseValidation,
      value: formatValue({
        v: `${rawData?.footage || ''}`,
        l: `${rawData?.footage || ''}`,
      }),
    },
    meters: {
      title: 'Meters',
      uom: 'M',
      defaultValue: 'Enter Meters',
      validation: baseValidation,
      value: formatValue({
        v: `${rawData?.meters || ''}`,
        l: `${rawData?.meters || ''}`,
      }),
    },
    inches: {
      title: 'Inches',
      uom: 'IN',
      defaultValue: 'Enter Inches',
      validation: baseValidation,
      value: formatValue({
        v: `${rawData?.inches || ''}`,
        l: `${rawData?.inches || ''}`,
      }),
    },
    joints: {
      title: 'Joints',
      uom: 'joints',
      defaultValue: 'Enter Joints',
      validation: baseValidation,
      value: formatValue({
        v: `${rawData?.joints || ''}`,
        l: `${rawData?.joints || ''}`,
      }),
    },
    lbs: {
      title: 'Lbs',
      uom: 'Lbs',
      defaultValue: 'Enter Pounds',
      validation: baseValidation,
      value: formatValue({
        v: `${rawData?.lbs || ''}`,
        l: `${rawData?.lbs || ''}`,
      }),
    },
    kgs: {
      title: 'Kgs',
      uom: 'KG',
      defaultValue: 'Enter Kilograms',
      validation: baseValidation,
      value: formatValue({
        v: `${rawData?.kgs || ''}`,
        l: `${rawData?.kgs || ''}`,
      }),
    },
  };

  const pricingConfig = {
    price_per_foot: {
      title: 'Price per Foot',
      uom: '/ft',
      defaultValue: 'Enter Price per Foot',
      validation: baseValidation,
      value: formatValue({
        v: rawData?.price_per_foot,
        l: rawData?.price_per_foot,
      }),
    },
    price_per_meter: {
      title: 'Price per Meter',
      uom: '/m',
      defaultValue: 'Enter Price per Meter',
      validation: baseValidation,
      value: formatValue({
        v: rawData?.price_per_meter,
        l: rawData?.price_per_meter,
      }),
    },
    price_per_inch: {
      title: 'Price per Inch',
      uom: '/in',
      defaultValue: 'Enter Price per Inch',
      validation: baseValidation,
      value: formatValue({
        v: rawData?.price_per_inch,
        l: rawData?.price_per_inch,
      }),
    },
    price_per_joint: {
      title: 'Price per Joint',
      uom: '/joint',
      defaultValue: 'Enter Price per Joint',
      validation: baseValidation,
      value: formatValue({
        v: rawData?.price_per_joint,
        l: rawData?.price_per_joint,
      }),
    },
    price_per_lb: {
      title: 'Price per Lb',
      uom: '/lb',
      defaultValue: 'Enter Price per Pound',
      validation: baseValidation,
      value: formatValue({
        v: rawData?.price_per_lb,
        l: rawData?.price_per_lb,
      }),
    },
    price_per_kg: {
      title: 'Price per Kg',
      uom: '/kg',
      defaultValue: 'Enter Price per Kilogram',
      validation: baseValidation,
      value: formatValue({
        v: rawData?.price_per_kg,
        l: rawData?.price_per_kg,
      }),
    },
  };

  const personnelConfig = {
    supplier: {
      title: 'Supplying Company',
      defaultValue: 'Enter Supplying Company',
      value: formatValue({ v: rawData?.supplier_id, l: rawData?.supplier }),
      validation: baseValidation,
    },
    contact: {
      defaultValue: 'Enter Supplier Contact',
      value: formatValue({ v: rawData?.contact_id, l: rawData?.contact_name }),
      validation: baseValidation,
      title: 'Supplier Personnel',
    },
    buyer: {
      title: 'Buying Company',
      defaultValue: 'Enter Buying Company',
      value: formatValue({ v: rawData?.buyer_id, l: rawData?.buyer }),
      validation: baseValidation,
    },
    end_user: {
      title: 'End User',
      defaultValue: 'Enter End User',
      value: formatValue({
        v: rawData?.end_user_id,
        l: rawData?.end_user,
      }),
      validation: baseValidation,
    },
  };

  const documentsConfig = {
    files: {
      title: 'Documents',
    },
    images: {
      title: 'Images',
    },
    comments: {
      title: 'Comments',
      type: 'input_container',
      dependency: rawData?.id,
      value: rawData?.comments || null,
      validation: baseValidation,
    },
  };

  switch (tab) {
    case 'overview':
      return overviewConfig;
    case 'description':
      return descriptionConfig;
    case 'quantity':
      return quantityConfig;
    case 'pricing':
      return pricingConfig;
    case 'personnel':
      return personnelConfig;
    case 'documents':
      return documentsConfig;
    default:
      return {};
  }
}

const numberAsStringToNumber = (value) => {
  if (value === '') {
    return null;
  }
  return parseFloat(value);
};

export async function transformFromFormtoBE(data) {
  const returningBody = {};
  const fieldKeys = keys(data);
  forEach(fieldKeys, (key) => {
    if (key === 'delivery_time') {
      const value = data[key];
      const isRange = data.delivery_time_operator.value === 'range';

      returningBody.delivery_time = isRange
        ? null
        : numberAsStringToNumber(value?.value);
      returningBody.delivery_time_min = isRange
        ? numberAsStringToNumber(value?.min.value)
        : null;
      returningBody.delivery_time_max = isRange
        ? numberAsStringToNumber(value?.max.value)
        : null;
      return;
    }
    if (key === 'delivery_time_operator') {
      return;
    }
    if (key === 'received_date' || key === 'transaction_date') {
      returningBody[key] =
        data[key] === null ? null : new Date(data[key]).toISOString();
      return;
    }
    if (key === 'location_address') {
      returningBody.google_address_place_id = data[key].place_id;
      return;
    }
    if (key === 'weight_per_foot' || key === 'od') {
      if (data[key].rangeFilterValue) {
        returningBody[key] = data[key].rangeFilterValue;
      } else {
        returningBody[key] = numeral(data[key].value).format('0.000');
      }
      return;
    }
    // alloy and end_finish will only have 1 entry each
    if (key === 'alloy') {
      returningBody.metallurgy = data[key][0]?.value || null;
      return;
    }
    if (key === 'end_finish') {
      returningBody[key] = data[key][0]?.value || null;
      return;
    }
    if (
      key === 'type' ||
      key === 'category' ||
      key === 'condition' ||
      key === 'manufacturer' ||
      key === 'mill_year' ||
      key === 'mtr' ||
      key === 'supplier' ||
      key === 'contact' ||
      key === 'buyer' ||
      key === 'end_user' ||
      key === 'delivery_time_uom' ||
      key === 'employee'
    ) {
      returningBody[key] = data[key] === null ? null : data[key]?.value;
      return;
    }
    // This format is handled in the submit function
    if (key === 'tag') {
      returningBody[key] = data[key];
      return;
    }
    if (key === 'comments') {
      returningBody[key] = data[key];
      return;
    }
    returningBody[key] = numeral(data[key].value).format('0.00');
  });
  return returningBody;
}
