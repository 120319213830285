import tree from 'state';
import asyncTreeRequester from 'utility/asyncTreeRequester';
import { find, get } from 'lodash';
import * as effects from './effects';
import { defaultActiveEmployee } from './state';
import { generateEmployee } from './transformer_employees';

export const employeesCursor = tree.select(['employees']);

export function resetEmployeeCursors() {
  employeesCursor.set(['activeEmployee'], defaultActiveEmployee());
}

export async function setActiveEmployee(employee, { reset } = {}) {
  if (reset !== false) {
    resetEmployeeCursors();
  }
  const employeeId = employee ? employee.id : null;
  const currentEmployee = employee;
  const employeeFormData = generateEmployee({
    seedData: currentEmployee,
  });
  employeeFormData.id = employeeId;
  employeesCursor.set(['activeEmployee'], employeeFormData);
  tree.commit();
}

export async function setEmployeeInfo(basePath, category, type, data) {
  const currentBasePath = basePath || [employeesCursor, 'activeEmployee'];
  const path = [...currentBasePath, category, 'fields', type];
  const item = employeesCursor.get(path);
  const currentData = data;
  currentData.dirty = true;
  currentData.valid = item?.validation
    ? item.validation(currentData.value, item.required, type)
    : true;

  // Specifically to conditionally render the manager field when creating a partner
  const managerPath = [...currentBasePath, category, 'fields', 'manager_id'];
  if (type === 'role' && data?.value?.label === 'Partner') {
    employeesCursor.merge(managerPath, { required: true, disabled: false });
  } else if (type === 'role' && data?.value?.label !== 'Partner') {
    employeesCursor.merge(managerPath, { required: false, disabled: true });
  }
  //

  employeesCursor.merge(path, currentData);
  employeesCursor.set([category, 'dirty'], true);
  tree.commit();
}

export async function getEmployees({ pagination, filters, sorting }) {
  employeesCursor.set(['currentEmployees'], {});
  tree.commit();
  await asyncTreeRequester({
    func: effects.getEmployees.bind(this),
    pagination,
    filters,
    sorting,
    cursor: employeesCursor,
    path: ['currentEmployees'],
  });
}

export async function updateEmployeeRole(employeeInfo, role) {
  try {
    const response = await effects.updateEmployeeRole(employeeInfo, role);
    const currentEmployeesList = employeesCursor.get([
      'currentEmployees',
      'result',
      'docs',
    ]);
    if (!get(response, 'error')) {
      find(currentEmployeesList, { employee_id: employeeInfo.id }).roles = [
        role,
      ];
      employeesCursor.set(
        ['currentEmployees', 'result', 'docs'],
        currentEmployeesList
      );
    }
    return response;
  } catch (err) {
    return { error: err.message };
  }
}

export async function addEmployeeRole(employeeInfo) {
  try {
    const response = await effects.addEmployeeRole(employeeInfo);
    return response;
  } catch (err) {
    return { error: err.message };
  }
}
