import styled from 'styled-components/macro';
import { variables } from 'theme/variables';
import { webRefactorSwitch } from 'utility/environment';

const {
  app_header_height: appHeaderHeight,
  app_footer_height: appFooterHeight,
  app_mobile_header_height: appMobileHeaderHeight,
  app_mobile_footer_height: appMobileFooterHeight,
  media_sm: mediaSm,
  media_md: mediaMd,
  custom_background_color: customBackgroundColor,
  color_white: colorWhite,
} = variables;

export const AppContainer = styled.section`
  padding-top: ${(props) => {
    if (props.hiddenHeader) {
      return '0px';
    }
    if (props.publicHeader) {
      if (webRefactorSwitch) {
        return props.transparentHeader ? '0px' : '10px';
      }
      return props.transparentHeader ? '0px' : '75px';
    }
    return appMobileHeaderHeight;
  }};
  min-height: ${(props) =>
    props.publicHeader
      ? `calc(100vh - 573px)`
      : `calc(100vh - ${appMobileFooterHeight})`};
  background-color: ${(props) =>
    props.publicHeader ? colorWhite : customBackgroundColor};
  @media ${mediaMd} {
    min-height: ${(props) =>
      props.publicHeader
        ? `calc(100vh - 573px)`
        : `calc(100vh - ${props.hiddenFooter ? '0px' : appFooterHeight})`};
  }
  @media ${mediaSm} {
    padding-top: ${(props) => {
      if (props.hiddenHeader) {
        return '0px';
      }
      if (props.publicHeader) {
        if (webRefactorSwitch) {
          return props.transparentHeader ? '0px' : '10px';
        }
        return props.transparentHeader ? '0px' : '75px';
      }
      return appHeaderHeight;
    }};
  }
`;

export const FullModalWrapper = styled.section`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: #fff;
  z-index: 20;
`;

export const ModalScrollContainer = styled.section`
  overflow-y: auto;
  overflow-scrolling: touch;
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
`;
