import queryString from 'query-string';
import {
  omitBy,
  omit,
  mapValues,
  isBoolean,
  isArray,
  reduce,
  cloneDeep,
  includes,
  split,
} from 'lodash';
import historyContainer from 'historyContainer';
import { canUseDOM } from 'exenv';

const STRING_VALUES = {
  od: true,
  od_min: true,
  od_max: true,
  weight_per_foot: true,
  weight_per_foot_min: true,
  weight_per_foot_max: true,
  min_yield: true,
  following: true,
};

export function setValues(params = {}, available = []) {
  if (canUseDOM) {
    let parsed = queryString.parse(window.location.search);
    parsed = {
      ...omit(parsed, available),
      ...params,
    };

    parsed = reduce(
      omitBy(parsed, (v) => v === '' || v === undefined || v === null),
      (accum, v, k) => {
        let accumCopy = accum ? cloneDeep(accum) : accum;
        /*
          K = "metallurgy" as an example
          if k is not in params, this has been removed from criteria.
        */
        if (!accumCopy) accumCopy = {};

        if (!(k in params) || !accumCopy) return accumCopy;

        accumCopy[k] = isArray(v) ? v.join(',') : v;

        return accumCopy;
      },
      {}
    );

    const stringified = queryString.stringify(parsed);
    const currentQuerystring = historyContainer.location.search || '';
    const newQuerystring = stringified ? `?${stringified}` : '';

    // this helps with going back with buttons. --- see history container
    if (newQuerystring !== currentQuerystring) {
      historyContainer.push(
        `${historyContainer.location.pathname}${newQuerystring}`
      );
    }

    window.history.replaceState(
      null,
      null,
      `${window.location.pathname}${newQuerystring}`
    );
    return stringified;
  }
  return {};
}

/** It's possible to call this function before window is defined. The function call should be wrapped in a canUseDOM check when used to retrieve initial values.
 */
export function getValues({ arrayFunction, querystring } = {}) {
  if (canUseDOM) {
    const parsed = querystring
      ? queryString.parse(querystring)
      : queryString.parse(window.location.search);
    return mapValues(parsed, (v, k) => {
      if (v === 'true' || v === 'false' || isBoolean(v)) {
        return JSON.parse(v);
      }
      const isStringValue =
        k === 'keyword' ||
        k === 'limit' ||
        k === 'page' ||
        k === 'sort' ||
        k === 'direction' ||
        STRING_VALUES[k];

      let returnValue;

      if (v) {
        if (includes(v, ',')) {
          returnValue = split(v, ',');
        } else if (isStringValue) {
          returnValue = v;
        } else if (arrayFunction) {
          returnValue = arrayFunction({ v, k });
        } else {
          returnValue = [v];
        }
      } else {
        returnValue = '';
      }

      return returnValue;
    });
  }
  return {};
}
