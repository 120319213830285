import { groupBy, omit, startCase, map, get, isBoolean } from 'lodash';
import { OMMITED, MAPPING } from 'utility/reverseUrlFilters';
import { facetLabelOverrides } from './staticMap.ts';

export default function categorizeFacets(facets, mappingType) {
  const mapType = MAPPING[mappingType];
  if (!mapType) {
    return [];
  }
  const facetMap = map(omit(facets, OMMITED), (facet, key) => {
    const type = mapType[key];
    const name = key;

    let buckets = map(facet.buckets, (bucket) => {
      let currentName;
      if (bucket.name) {
        currentName = bucket.name;
      } else if (!bucket.name && bucket.val === true) {
        currentName = 'Yes';
      } else if (!bucket.name && bucket.val === false) {
        currentName = 'No';
      } else {
        currentName = bucket.val;
      }
      return {
        type: key,
        count: bucket.count,
        label: currentName,
        value: bucket.val,
        key: bucket.val,
        isBoolean: isBoolean(bucket.val),
      };
    });

    if (get(facet, 'missing.count')) {
      buckets = [
        {
          type: key,
          count: facet.missing.count,
          label: 'AA_Missing',
          value: 'missing',
          key: 'missing',
        },
        ...buckets,
      ];
    }
    if (key === 'quantity_uom') {
      return {
        ...facet,
        title: 'Quantity Range',
        range: true,
        config: {
          customTitle: 'Please select UOM first',
          inputType: 'number',
          operator: { label: 'Range', value: 'range' },
          operators: [{ label: 'Range', value: 'range' }],
          uomOptions: buckets,
          value: {
            min: { value: '', label: '' },
          },
          uomKey: 'quantity_uom',
        },
        currentConfig: {
          saved: false,
          valid: false,
          fields: {
            quantity_uom: {
              inputType: 'number',
              operators: [{ label: 'Range', avalue: 'range' }],
              uomKey: 'quantity_uom',
              uomOptions: buckets,
              value: { value: '', label: '' },
            },
          },
        },
        type,
        key,
      };
    }
    return {
      ...facet,
      key,
      name: facetLabelOverrides[name]
        ? facetLabelOverrides[name]
        : startCase(name),
      type,
      buckets,
    };
  });

  const grouped = groupBy(facetMap, 'type');
  const checkboxValues = grouped.checkbox || [];
  // specific to range filters
  const rangeValues = grouped.range || [];

  return [...checkboxValues, ...rangeValues];
}
