import React, { useState, useCallback } from 'react';
import Icon from 'components/Icon/Icon';
import { NewDashboardCard } from 'components/DashboardCard/DashboardCard';
import { SM, LG, MD } from '@zendeskgarden/react-typography';
import { Formik } from 'formik';
import { Row, Col } from '@zendeskgarden/react-grid';
import { FullFormFieldWrapper } from 'theme/AuthForms.styles';
import { useBranch } from 'baobab-react/hooks';
import { Form } from 'theme/Form';
import { Button } from 'theme/Button';
import { map, reject } from 'lodash';
import Flex from 'styled-flex-component';
import CompanyLookup from 'components/CompanyLookup/CompanyLookupV2';
import {
  createEngagementCompany,
  deleteEngagementCompany,
} from 'state/crm/actions';
import { variables } from 'theme/variables';
import PropTypes from 'prop-types';
import { getRole } from 'utility/hasAccount';

const { custom_silver: customSilver, color_red_400: colorRed400 } = variables;

const dataDefault = {};

function CompanyForm({ data = dataDefault, disabled, onSuccess }) {
  const { value: role } = getRole() || {};
  const [companies, setCompanies] = useState(data.companies);
  const [companiesStatus, setCompaniesStatus] = useState({});
  const { clientId } = useBranch({
    clientId: [
      'authentication',
      'session',
      'roles',
      role,
      'data',
      'company',
      'id',
    ],
  });

  const submitCompany = useCallback(
    async (values, { setSubmitting, setStatus, resetForm }) => {
      setSubmitting(true);
      const outData = { engagement: data.id, company: values.company.value };

      const saveEngagementCompanyResult = await createEngagementCompany(
        outData
      );
      const { error } = saveEngagementCompanyResult;
      if (error) {
        setStatus({ error });
      } else {
        setCompanies((os) => [
          { ...values.company, ...saveEngagementCompanyResult },
          ...os,
        ]);

        resetForm();
        if (onSuccess) {
          onSuccess({
            ...data,
            companies: [
              ...data.companies,
              {
                company: values.company.value,
                name: values.company.name,
              },
            ],
          });
        }
      }
      setSubmitting(false);
    },
    [onSuccess, data]
  );

  const onDeleteCompany = useCallback(
    async (id) => {
      setCompaniesStatus({ [`message_${id}`]: { loading: true } });
      const result = await deleteEngagementCompany(id);
      const currentCompaniesList = reject(data.companies, { id });
      if (result.error) {
        setCompaniesStatus({ [`message_${id}`]: { error: result.error } });
      } else {
        setCompanies(currentCompaniesList);
        setCompaniesStatus({ [`message_${id}`]: { loading: false } });
        onSuccess({
          ...data,
          companies: currentCompaniesList,
        });
      }
    },
    [onSuccess, data]
  );

  return (
    <Formik
      initialValues={{
        company: '',
      }}
      onSubmit={submitCompany}
    >
      {(props) => (
        <FormRendered
          // LINT OVERRIDE #3
          // Component wraps another component
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...props}
          companiesStatus={companiesStatus}
          companies={companies}
          onDelete={onDeleteCompany}
          disabled={disabled}
          {...{ clientId }}
        />
      )}
    </Formik>
  );
}

CompanyForm.propTypes = {
  disabled: PropTypes.bool,
  data: PropTypes.shape({
    companies: PropTypes.arrayOf(PropTypes.shape({})),
    id: PropTypes.number,
  }),
  onSuccess: PropTypes.func,
};

function FormRendered({
  disabled,
  values,
  handleSubmit,
  isSubmitting,
  status,
  setFieldValue,
  companies,
  clientId,
  onDelete,
  companiesStatus,
}) {
  return (
    <Form size="large" onSubmit={handleSubmit}>
      <Row style={{ marginBottom: 40 }}>
        <Col size="9">
          <SM bold navy scaled tag="span">
            Company
          </SM>
          <FullFormFieldWrapper spacer spacing="0px">
            <CompanyLookup
              searchType="company"
              placeholder="Search Company"
              clientId={clientId}
              style={{ marginTop: 10 }}
              selectedItem={values.company}
              setSelectedValue={(value) => {
                setFieldValue('company', value);
              }}
            />
          </FullFormFieldWrapper>
        </Col>
        <Col size="3">
          <Button
            loading={isSubmitting || undefined}
            type="submit"
            disabled={disabled}
            buttonStatus
            error={status && status.error}
            primary
            style={{ marginTop: '30px', width: '100%', marginBottom: 0 }}
          >
            Add Company
          </Button>
        </Col>
        <Col md={12}>
          {status && status.error ? (
            <div>
              <SM error validation="error">
                {status.error}
              </SM>
            </div>
          ) : null}
        </Col>
      </Row>
      {companies && companies.length ? (
        map(companies, (company, i) => (
          <NewDashboardCard key={i} style={{ padding: 20, marginBottom: 20 }}>
            <Flex justifyBetween full alignCenter>
              <div>
                <LG bold>{company.name}</LG>
              </div>
              <Icon
                buttonStyle
                color={customSilver}
                hoverColor={colorRed400}
                onClick={(...otherDeleteItems) =>
                  onDelete(company.id, ...otherDeleteItems)
                }
                icon="icon-delete"
              />
            </Flex>
            {companiesStatus[`message_${company.id}`]?.error ? (
              <SM paddingTopSm error>
                {companiesStatus[`message_${company.id}`]?.error}
              </SM>
            ) : null}
          </NewDashboardCard>
        ))
      ) : (
        <NewDashboardCard style={{ padding: 20 }}>
          <MD center>No Companies Yet.</MD>
        </NewDashboardCard>
      )}
    </Form>
  );
}

FormRendered.propTypes = {
  disabled: PropTypes.bool,
  values: PropTypes.shape({
    company: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]),
  }),
  handleSubmit: PropTypes.func,
  isSubmitting: PropTypes.bool,
  status: PropTypes.shape({
    error: PropTypes.string,
  }),
  setFieldValue: PropTypes.func,
  companies: PropTypes.arrayOf(PropTypes.shape({})),
  clientId: PropTypes.number,
  onDelete: PropTypes.func,
  companiesStatus: PropTypes.shape({}),
};

export default CompanyForm;
