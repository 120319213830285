import styled from 'styled-components/macro';
import { variables } from 'theme/variables';
import { Page } from 'theme/Page';

const {
  color_white: colorWhite,
  media_sm: mediaSm,
  spacing_xl: spacingXl,
  spacing,
  public_font_size_sm: publicFontSizeSm,
  custom_placeholder: customPlaceholder,
  color_red_400: colorRed400,
} = variables;

export const AuthPage = styled(Page)`
  padding: 40px 0;
  background: #fff;
  height: 100vh;
  @media ${mediaSm} {
    background: transparent;
    height: auto;
  }
  form {
    max-width: ${(props) => props.formMaxWidth || '400px'};
    width: 100%;
  }
`;

export const Section = styled.section`
  position: relative;
  .already {
    color: #a2a2a2;
    font-size: ${publicFontSizeSm};
  }
  p {
    color: #a2a2a2;
    font-size: ${publicFontSizeSm};
  }
`;

export const Logo = styled.img`
  height: 55px;
  width: auto;
  margin-bottom: 0px;
  @media ${mediaSm} {
    margin-bottom: 30px;
  }
`;

export const AuthForm = styled.div`
  padding: ${spacing};
  position: relative;
  z-index: 2;
  width: 340px;
  border-radius: 4px;
  background-color: ${colorWhite};
  width: 100%;
  @media ${mediaSm} {
    padding: ${spacingXl};
    box-shadow: 0 30px 50px 0 rgba(68, 89, 109, 0.1);
  }
`;

export const FormFieldWrapper = styled.div`
  margin-top: ${(props) => props.spacing || spacingXl};
  input {
    border: none;
    border-bottom: 1px solid #d0d0d0;
    width: 100%;
    padding-bottom: 10px;
    outline: none !important;
    box-shadow: none !important;
    border-radius: 0;
    padding: 0;
  }
  textarea {
    outline: none !important;
    box-shadow: none !important;
  }
`;

export const FullFormFieldWrapper = styled.div`
  margin-top: ${(props) => props.spacing || '21px'};
  ${(props) =>
    props.spacer
      ? `
    margin: 10px 0
  `
      : ``};
  input {
    border: 1px solid #cacdd3;
    width: 100%;
    padding: 0 10px;
    outline: none !important;
    box-shadow: none !important;
    border-radius: 4px;
    min-height: 42px;
  }

  .autocomplete,
  .filter-item-input {
    min-height: 42px !important;
    border: 1px solid #cacdd3;
    color: ${customPlaceholder};
    &.active {
      color: #000;
    }
    &:focus {
      box-shadow: 0 0 0 3px rgba(2, 103, 240, 0.08);
      border-color: rgba(0, 85, 153, 0.3);
    }
  }

  .form-item-wrapper {
    position: relative;
    &.with-prefix {
      .form-input-wrapper {
        flex: 1;
      }
      .prefix {
        position: absolute;
        z-index: 1;
        display: flex;
        align-items: center;
        width: 30px;
        text-align: center;
        justify-content: center;
        background: #ececec;
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px;
        color: #7b7b7b;
        font-size: 16px;
        top: 1px;
        bottom: 1px;
        left: 1px;
      }
      input {
        padding-left: 40px;
      }
    }
  }

  .with-suffix {
    display: flex;
    .form-input-wrapper input {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }
    .form-input-wrapper ~ .autocomplete-form-wrapper .autocomplete {
      border-left: none;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      padding-right: 26px;
      padding-left: 9px;
      &:before {
        background-position: center;
        width: 25px;
      }
    }
  }

  div.react-date-picker__wrapper {
    width: 100%;
    border: none !important;
    border: 1px solid
      ${(props) => (props.validation === 'error' ? colorRed400 : '#d8dcde')} !important;
    outline: none !important;
    padding: 0.71429em 0 !important;
    font-size: 12px;
    border-radius: 4px !important;
    line-height: 12px;
    min-height: 42px;
    color: #000;
    * {
      color: #000;
    }
    input {
      border: none;
      min-height: 0;
      &::placeholder {
        color: ${customPlaceholder};
      }
    }
    .react-date-picker__inputGroup__divider {
      color: #8c949b;
    }
    .react-date-picker__calendar-button:before {
      font-size: 13px;
      color: #8c949b;
    }
    .react-date-picker__button svg {
      width: 15px;
      fill: #8c949b;
    }
  }

  .react-tel-input {
    margin-top: 10px;
    border: 1px solid #d8dcde;
    border-radius: 4px;
    height: 41px;
    input,
    .flag-dropdown {
      border: none;
      border-radius: 3px 0 0 3px;
    }
    .selected-flag {
      width: 46px;
      border-radius: 3px 0 0 3px;
      border-bottom: none;
      border-right: 1px solid #d8dcde;
      background: #fff;
      .arrow {
        left: 24px;
      }
    }
    .form-control {
      padding-left: 55px;
      height: 39px;
      width: 100%;
    }
  }

  textarea {
    outline: none !important;
    box-shadow: none !important;
  }
`;

export const FormWrapper = styled.div`
  margin-top: ${(props) => props.spacing || spacingXl};
  input {
    border: none;
    border-bottom: 1px solid #d0d0d0;
    width: 100%;
    padding-bottom: 10px;
    outline: none !important;
    box-shadow: none !important;
    border-radius: 0;
    padding: 0;
  }
  textarea {
    outline: none !important;
    box-shadow: none !important;
  }
`;

export const BackgroundImgWrapper = styled.div`
  overflow: hidden;
  position: fixed;
  height: 600px;
  width: 500px;
  left: 0;
  right: -74%;
  bottom: 0;
  margin: 0 auto;
  pointer-events: none;
`;

export const BackgroundImg = styled.img`
  height: auto;
  width: 500px;
  opacity: 0.01;
  pointer-events: none;
  @media ${mediaSm} {
    opacity: 0.03;
  }
`;

export const FormTextBottom = styled.div`
  p {
    color: #a2a2a2;
    font-size: ${publicFontSizeSm};
  }
`;

export const Line = styled.div`
  box-sizing: border-box;
  height: 2px;
  width: 34px;
  border: 1px solid #eaeaea;
  margin: 15px 0;
`;
