import styled from 'styled-components/macro';
import { variables } from 'theme/variables';

const { spacing_sm: spacingSm, custom_border_grey: customBorderGrey } =
  variables;

export const DocumentWrapper = styled('div')`
  padding: ${spacingSm};
  border: 1px solid ${customBorderGrey};
  cursor: pointer;
  border-radius: 3px;
  * {
    word-break: break-word;
  }
`;

export const ThumbnailWrapper = styled('div')`
  padding: ${spacingSm};
  border: 1px solid ${customBorderGrey};
  cursor: pointer;
  border-radius: 3px;
  margin-right: ${spacingSm};
  * {
    word-break: break-word;
  }
`;
