import styled from 'styled-components/macro';
import { variables } from 'theme/variables';

const {
  spacing,
  color_grey_900: colorGrey900,
  custom_navy: customNavy,
  color_white: colorWhite,
  color_blue_800: colorBlue800,
  custom_blue_hover: customBlueHover,
} = variables;

const ItemSelectionFilterWrapper = styled('div')`
  border-radius: ${(props) => (props.inline ? '10px' : undefined)};
  box-shadow: ${(props) =>
    props.inline ? `2px 2px 10px ${colorGrey900}` : undefined};
  padding: ${(props) => (props.inline ? '10px 40px' : spacing)};
  margin-bottom: ${(props) => (props.inline ? '20px' : undefined)};
  padding-bottom: 0;
  background: #fff;
  .filter-wrapper.not_small {
    max-width: 223px;
    min-width: 130px;
    &.range {
      min-width: 180px;
    }
  }
  .filter-wrapper.can_be_small {
    max-width: 130px;
    min-width: 100px;
  }
  .filter-wrapper.min_yield,
  .filter-wrapper.range,
  .filter-wrapper.od,
  .filter-wrapper.weight_per_foot {
    min-width: 100px;
    max-width: 120px;
    &.range {
      max-width: 220px;
      min-width: 166px;
    }
  }
  .filter-wrapper.quantity {
    min-width: 140px;
    max-width: 150px;
  }
  .filter-wrapper.category {
    max-width: 180px;
  }
  .filter-wrapper.end_finish {
    max-width: 170px;
    min-width: 130px;
  }
  .filter-wrapper.alloy {
    max-width: 120px;
    min-width: 100px;
  }
  .iconButton {
    display: flex;
    flex-wrap: noWrap;
    justify-content: center;
    height: 40px;
    min-width: 200px;
    background-color: ${(props) =>
      props.page === 'home' ? colorBlue800 : customNavy};
    border-radius: 4px;
    margin-bottom: 10px;
    padding-right: 10px;
    padding-left: 10px;
    ${(props) => {
      if (props.page === 'home') {
        return `&:hover {
          background-color: ${customBlueHover}
        }`;
      }
      return '';
    }}
  }
  .resourcesIcon {
    height: 40px;
    width: 30px;
    color: ${colorWhite};
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: -5px;
  }
  .resourcesName {
    font-size: 12px;
    color: ${colorWhite};
    text-align: center;
    line-height: 14px;
  }
  @media (max-width: 1050px) {
    .filter-wrapper {
      max-width: 25% !important;
      min-width: 25% !important;
    }
  }
  @media (max-width: 760px) {
    .filter-wrapper {
      max-width: 33% !important;
      min-width: 33% !important;
    }
  }
  @media (max-width: 570px) {
    .filter-wrapper {
      max-width: 50% !important;
      min-width: 50% !important;
    }
  }
  @media (max-width: 400px) {
    .filter-wrapper {
      max-width: 100% !important;
      min-width: 50% !important;
    }
  }
  @media (min-width: 1050px) {
    .filter-wrapper {
      padding-right: ${(props) => (props.inline ? '5px' : '15px')};
      padding-left: ${(props) => (props.inline ? '5px' : '15px')};
    }
    .filter-wrapper.can_be_small {
      max-width: 170px;
      min-width: 100px;
    }
    .filter-wrapper.not_small {
      max-width: 223px;
      min-width: 130px;
    }
  }
  @media (min-width: 1600px) {
    .filter-wrapper.can_be_small {
      max-width: none;
      min-width: 100px;
    }
    .filter-wrapper.not_small {
      max-width: none;
      min-width: 130px;
    }
  }
`;

export default ItemSelectionFilterWrapper;
