import React, { useEffect, useState } from 'react';
import { Ellipsis } from 'theme/Global.styles';
import { Col, Row } from '@zendeskgarden/react-grid';
import { SM, MD } from '@zendeskgarden/react-typography';
import Flex from 'styled-flex-component';
import {
  snakeCase,
  get,
  isObject,
  values,
  filter as _filter,
  isString,
  isEmpty,
} from 'lodash';
import { canUseDOM } from 'exenv';
import { variables } from 'theme/variables';
import PropTypes from 'prop-types';
import { FilterItemGroupWrapper } from './FilterItemGroup.styles';
import FilterItemValues from './components/FilterItemValues/FilterItemValues';
import FilterItemInputs from './components/FilterItemInputs/FilterItemInputs';
import FilterItemOperators from './components/FilterItemOperators/FilterItemOperators';

const { spacing_xxs: spacingXxs } = variables;

function FilterItemGroupTwoFilters({
  customTitle,
  config,
  type,
  onChangeConfig,
  currentConfigs,
  titleWeight = 'bold',
  medium,
  semibold,
  placeholder,
  title,
  minWidth,
  condensed,
  titleStyle,
  required,
  titleColor,
  titleRowMarginBottom,
  prefix,
  suffix,
}) {
  const {
    items = [],
    operators = [],
    inputType,
    uomOptions,
    uomOptionsSize,
    title: configTitle,
    error,
    warning,
    value,
    valid,
    filter,
    operator,
    customValues,
    minDropdownWidth,
  } = config;

  const [, setRender] = useState();

  useEffect(() => {
    setTimeout(() => {
      setRender(true);
    }, 10);
  }, []);

  if (!config) {
    return <div />;
  }
  const currentTitle = configTitle || title;
  const range = get(config, 'operator.value') === 'range';
  let isDropdown = inputType === 'FilterItemValues' || items.length;
  const validationError = error || valid === false;

  function changeValue(filterType, dropdownValue, otherValues) {
    const updateValue = {};
    if (filterType === 'uom') {
      // TODO P10-810 config object has stale destructured values
      updateValue.value = config.value;
      updateValue.uom = dropdownValue;
      // DUNNO YET
    }
    if (
      filterType === 'operator' &&
      isObject(value) &&
      get(dropdownValue, 'value') !== 'range'
    ) {
      // We want to reset value when going from range to normal value
      updateValue.value = isDropdown ? value?.min : values(value)[0] || '';
    } else if (
      filterType === 'operator' &&
      value &&
      !value?.min &&
      get(dropdownValue, 'value') === 'range'
    ) {
      // We want to change MIN to value when going to range from normal value
      updateValue.value = { min: value };
    } else if (filterType === 'value' && value !== dropdownValue) {
      updateValue.value = {
        ...dropdownValue,
      };
    }
    onChangeConfig(type, {
      ...config,
      [filterType]: dropdownValue,
      ...otherValues,
      ...updateValue,
    });
  }

  const filteredItems = filter ? filter(items, currentConfigs) : items;
  if (filter && filteredItems === null) {
    isDropdown = false;
  }
  let minFilterItems;
  let maxFilterItems;
  const minRangeFilterValue = value?.min?.rangeFilterValue;
  const maxRangeFilterValue = value?.max?.rangeFilterValue;
  if (minRangeFilterValue && range) {
    maxFilterItems = _filter(filteredItems, (itm) =>
      itm.rangeFilterValue ? itm.rangeFilterValue > minRangeFilterValue : true
    );
  }
  if (maxRangeFilterValue && range) {
    minFilterItems = _filter(filteredItems, (itm) =>
      itm.rangeFilterValue ? itm.rangeFilterValue < maxRangeFilterValue : true
    );
  }

  const titleSnake = snakeCase(type);
  let width = 120;
  if (canUseDOM) {
    const inputEl = document.querySelector(
      `.uoms-options.${titleSnake} .input-ellipsis`
    );
    if (inputEl) {
      width = inputEl.offsetWidth + 35;
    }
  }

  const TitleTypography = condensed ? SM : MD;

  return (
    <FilterItemGroupWrapper key={currentTitle}>
      <Flex
        style={{ marginBottom: titleRowMarginBottom || spacingXxs }}
        justifyBetween
      >
        {(!config.uom && customTitle) || (
          <TitleTypography
            error={validationError}
            style={{ ...titleStyle, minWidth: '0px', paddingRight: '10px' }}
            semibold={titleWeight === 'medium'}
            bold={titleWeight === 'bold'}
            black={titleColor === 'black'}
            slate={titleColor === 'slate'}
          >
            <Ellipsis>
              <span style={{ color: 'red' }}>{required ? '* ' : ''}</span>
              {currentTitle}
            </Ellipsis>
          </TitleTypography>
        )}
        {/* If there is only 1 operator, this dropdown will not show */}
        {operators && operators.length > 1 ? (
          <FilterItemOperators
            operators={operators}
            selectedOperator={operator || operators[0]}
            onChangeOperator={(...otherVals) =>
              changeValue('operator', ...otherVals)
            }
          />
        ) : (
          <span />
        )}
      </Flex>

      {isDropdown && range ? (
        <Row style={{ flexWrap: 'nowrap' }}>
          <Col>
            <FilterItemValues
              warning={warning}
              error={validationError}
              customValues={customValues}
              persistCasing
              range
              rangeKey="min"
              minDropdownWidth={minDropdownWidth}
              optionValues={minFilterItems || filteredItems}
              selectedValue={value}
              defaultValue="min"
              setSelectedValue={(...otherVals) =>
                changeValue('value', ...otherVals)
              }
              title={currentTitle}
              medium={medium}
              semibold={semibold}
            />
          </Col>
          <Col>
            <FilterItemValues
              warning={warning}
              error={validationError}
              customValues={customValues}
              persistCasing
              range
              rangeKey="max"
              minDropdownWidth={minDropdownWidth}
              optionValues={maxFilterItems || filteredItems}
              selectedValue={value}
              defaultValue="max"
              setSelectedValue={(...otherVals) =>
                changeValue('value', ...otherVals)
              }
              title={currentTitle}
              medium={medium}
              semibold={semibold}
            />
          </Col>

          {uomOptions ? (
            <Col
              className={`uoms-options ${titleSnake}`}
              size={uomOptionsSize || 2}
              style={
                range
                  ? { minWidth: `${width}px` }
                  : {
                      paddingLeft: 0,
                      marginLeft: '-10px',
                      minWidth: `${width}px`,
                    }
              }
            >
              <FilterItemValues
                persistCasing
                rangeKey="uom"
                minDropdownWidth="100px"
                optionValues={uomOptions}
                selectedValue={config.uom}
                defaultValue="uom"
                setSelectedValue={(...otherUom) =>
                  changeValue('uom', ...otherUom)
                }
                title={currentTitle}
                medium={medium}
                semibold={semibold}
              />
            </Col>
          ) : null}
        </Row>
      ) : (
        <Row>
          <Col>
            {isDropdown ? (
              <FilterItemValues
                warning={warning}
                error={validationError}
                customValues={customValues}
                persistCasing
                range={range}
                minDropdownWidth={minDropdownWidth}
                optionValues={filteredItems}
                selectedValue={value}
                defaultValue={
                  isString(currentTitle)
                    ? `Select ${currentTitle}`
                    : currentTitle
                }
                setSelectedValue={(...otherVals) =>
                  changeValue('value', ...otherVals)
                }
                title={currentTitle}
                medium={medium}
                semibold={semibold}
              />
            ) : (
              <FilterItemInputs
                type={type}
                warning={warning}
                error={validationError}
                inputType={config.inputType}
                value={value || ''}
                range={range}
                tolerance={config.tolerance}
                title={currentTitle}
                placeholder={placeholder}
                medium={medium}
                semibold={semibold}
                disabled={isEmpty(config.uom)}
                onChangeInput={(...otherVals) =>
                  changeValue('value', ...otherVals)
                }
                prefix={prefix}
                suffix={suffix}
              />
            )}
          </Col>
          {uomOptions ? (
            <Col
              className={`uoms-options ${titleSnake}`}
              size={uomOptionsSize || 2}
              style={
                range
                  ? { minWidth: `${minWidth || width}px` }
                  : {
                      paddingLeft: 0,
                      marginLeft: '-10px',
                      minWidth: `${width}px`,
                    }
              }
            >
              <FilterItemValues
                warning={warning}
                persistCasing
                rangeKey="uom"
                uom
                minDropdownWidth={minDropdownWidth}
                optionValues={uomOptions}
                selectedValue={config.uom}
                defaultValue={
                  config.uomKey === 'quantity_uom' ? 'UOM' : 'Select uom'
                }
                setSelectedValue={(...otherUom) =>
                  changeValue('uom', ...otherUom)
                }
                title={currentTitle}
                medium={medium}
                semibold={semibold}
              />
            </Col>
          ) : null}
        </Row>
      )}
    </FilterItemGroupWrapper>
  );
}

FilterItemGroupTwoFilters.propTypes = {
  customTitle: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  config: PropTypes.shape({
    items: PropTypes.arrayOf(PropTypes.shape({})),
    operators: PropTypes.arrayOf(PropTypes.shape({})),
    inputType: PropTypes.string,
    uomOptions: PropTypes.arrayOf(PropTypes.shape({})),
    uomOptionsSize: PropTypes.number,
    title: PropTypes.string,
    error: PropTypes.bool,
    warning: PropTypes.bool,
    value: PropTypes.oneOfType([
      PropTypes.shape({
        min: PropTypes.shape({
          rangeFilterValue: PropTypes.string,
        }),
        max: PropTypes.shape({
          rangeFilterValue: PropTypes.string,
        }),
      }),
      PropTypes.string,
    ]),
    valid: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
    filter: PropTypes.func,
    operator: PropTypes.shape({}),
    customValues: PropTypes.shape({}),
    minDropdownWidth: PropTypes.string,
    uom: PropTypes.shape({}),
    uomKey: PropTypes.string,
    tolerance: PropTypes.bool,
  }),
  type: PropTypes.string,
  onChangeConfig: PropTypes.func,
  currentConfigs: PropTypes.shape({}),
  titleWeight: PropTypes.string,
  medium: PropTypes.bool,
  semibold: PropTypes.bool,
  placeholder: PropTypes.string,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  minWidth: PropTypes.number,
  condensed: PropTypes.bool,
  titleStyle: PropTypes.shape({}),
  required: PropTypes.bool,
  titleColor: PropTypes.string,
  titleRowMarginBottom: PropTypes.string,
  prefix: PropTypes.shape({}),
  suffix: PropTypes.shape({}),
};

export default FilterItemGroupTwoFilters;
