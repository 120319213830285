import Baobab from 'baobab';
import { merge } from 'lodash';
import { canUseDOM } from 'exenv';
import getInitialState from './getInitialState';

const options = {
  immutable: true,
  pure: false,
  persistent: false,
};

let tree;

if (canUseDOM) {
  const { cursors, facets } = getInitialState(window.__INITIAL_STATE__);
  tree = new Baobab(merge(cursors, facets), options);
} else {
  tree = new Baobab({}, options);
}

const finalTree = tree;

export default finalTree;
