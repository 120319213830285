import React, { useRef, useState, useEffect } from 'react';
import { SM } from '@zendeskgarden/react-typography';
import Flex from 'styled-flex-component';
import Checkbox from 'components/Checkbox/Checkbox';
import { groupBy, map, debounce, filter, startCase, find } from 'lodash';
import { Item, HeaderItem } from '@zendeskgarden/react-dropdowns';
const EndFinishFilterFilterResults = ({ activeState, inputValue }) => {
  const { activeFilters, selectionFilters, finishes } = activeState;

  const { end_finish_category, end_finish_licensee, end_finish_thread_type } =
    selectionFilters;
  const options = [
    ...end_finish_category,
    ...end_finish_licensee,
    ...end_finish_thread_type,
    ...finishes,
  ];

  const [matchingOptions, setMatchingOptions] = useState(options);

  const filterMatchingOptionsRef = useRef(
    debounce((value) => {
      const matchingOptions = filter(options, (option) => {
        return (
          (option.label || '')
            .trim()
            .toLowerCase()
            .indexOf((value || '').trim().toLowerCase()) !== -1
        );
      });

      setMatchingOptions(matchingOptions);
    }, 100)
  );

  useEffect(() => {
    filterMatchingOptionsRef.current(inputValue);
  }, [inputValue]);

  return (
    <div style={{ maxHeight: 337 }}>
      {matchingOptions.length ? (
        map(groupBy(matchingOptions, 'type'), (gr, type) => {
          return (
            <div key={`${type || 'finish'}`}>
              <HeaderItem>
                {type === 'undefined'
                  ? 'Finishes'
                  : startCase(type.replace(/_/g, ' ').replace('_type', ''))}
              </HeaderItem>
              {map(gr, (finish) => {
                const selected =
                  finish.selected ||
                  find(activeFilters, {
                    label: finish.label,
                    value: finish.value,
                  });
                return (
                  <Item
                    hoverColorLight
                    itemsSmall
                    alignCenter
                    value={{ selected, finish }}
                    key={`${finish.value}-${finish.type}`}
                  >
                    <Flex alignCenter>
                      <Checkbox checked={selected} />
                      <span style={{ paddingLeft: '10px' }}>
                        {finish.label}
                      </span>
                    </Flex>
                  </Item>
                );
              })}
            </div>
          );
        })
      ) : (
        <div>
          <HeaderItem>Custom Value</HeaderItem>
          <Item
            hoverColorLight
            itemsSmall
            alignCenter
            value={{
              customValue: true,
              selected: false,
              finish: {
                customValue: true,
                value: inputValue,
                label: inputValue,
              },
            }}
          >
            <Flex alignCenter>
              <Checkbox />
              <span style={{ paddingLeft: '10px' }}>{inputValue}</span>
            </Flex>
          </Item>
        </div>
      )}
    </div>
  );
};

export default EndFinishFilterFilterResults;
