import styled from 'styled-components/macro';
import { SM } from '@zendeskgarden/react-typography';
import { Col } from '@zendeskgarden/react-grid';
import { variables } from 'theme/variables';
const {
  custom_light_grey,
  app_footer_height,
  media_md,
  app_page_padding_medium,
  app_page_padding,
  app_mobile_footer_height,

  custom_grey,
  custom_border_grey,
  custom_dark_grey,
  custom_silver,
  custom_lighter_grey,
} = variables;

export const AppFooterComponent = styled.footer`
  z-index: 2;
  position: relative;
  background-color: ${custom_lighter_grey};
  height: ${app_mobile_footer_height};
  border-top: 1px solid ${custom_border_grey};
  display: flex;
  align-items: center;
  padding: 0;
  @media ${media_md} {
    height: ${app_footer_height};
    padding: 0 ${app_page_padding_medium};
  }
  @media ${media_md} {
    padding: 0 ${app_page_padding};
  }
`;

export const FooterCol = styled(Col)`
  display: flex;
  align-items: center;
  justifycontent: center;
  flex-direction: column;
  @media ${media_md} {
    display: block;
  }
`;

export const Logo = styled('div')`
  background-image: url('${(props) => props.src}');
  background-size: contain;
  width: 180px;
  height: 40px;
  background-repeat: no-repeat;
`;

export const FooterDesc = styled(SM)`
  margin-bottom: 20px;
  margin-top: 20px;
  text-align: center;
  @media ${media_md} {
    text-align: left;
    margin-bottom: 0px;
    margin-top: 0px;
  }
`;
