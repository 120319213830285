import tree from 'state';
import { mapValues } from 'lodash';
import * as effects from './effects';
import asyncTreeRequester from 'utility/asyncTreeRequester';
import {
  generateFlexCustomer,
  createBaseFlexCustomer,
} from '../customers/transformer_customers.js';

const flexCursor = tree.select(['flexEntities']);

export function addNewFieldToFlexDataMultitypeForm(field, { path } = {}) {
  path = path || [
    'customers',
    'activeCustomer',
    'customerFlexFormData',
    'customFields',
    'fields',
  ];
  const newv = createBaseFlexCustomer(null, field.accessor, field.type);
  const current = tree.select(path).get() || {};
  tree.set(path, { ...current, ...newv });
}

export async function generateFormFlexData({ seedData }) {
  const contentType = tree
    .select(['requestForQuote', 'meta'])
    .get(['result', 'entity_schema_content_types', 'Contact']);
  const customerSchema =
    flexCursor.get(['schema', contentType, 'result']) ||
    (await getCustomersSchema());
  const customerFlexFormData = generateFlexCustomer({
    seedData,
    schema: customerSchema,
  });
  return customerFlexFormData;
}

export async function getCustomersSchema(contentType) {
  contentType =
    contentType ||
    tree
      .select(['requestForQuote', 'meta'])
      .get(['result', 'entity_schema_content_types', 'Contact']);
  const schema = await asyncTreeRequester({
    func: effects.getCustomersSchema.bind(this, contentType),
    cursor: flexCursor,
    path: ['schema', contentType],
  });
  return schema;
}

export async function createEntitySchema(
  currentSchema,
  contentType,
  schema,
  schemaPath
) {
  const result = await asyncTreeRequester({
    func: effects.addEntitySchema.bind(this, contentType, schema),
    cursor: flexCursor,
    handleResult: true,
  });

  const newSchema = mapValues(schema, (s) => {
    return { ...s, content_type: contentType, entity_id: result.id };
  });
  if (tree.get(schemaPath)) {
    tree.merge(schemaPath, newSchema);
  } else {
    tree.set(schemaPath, newSchema);
  }
  return result;
}

export async function deleteEntitySchema(currentSchema, columnToDelete) {
  const result = await asyncTreeRequester({
    func: effects.deleteEntitySchema.bind(this, columnToDelete.content_type, {
      [columnToDelete.key_id]: columnToDelete.key_id,
    }),
    cursor: flexCursor,
    handleResult: true,
  });
  return result;
}

export async function createFlexEntity({ value, ids, contentType }) {
  const result = await asyncTreeRequester({
    func: effects.createFlexEntity.bind(this, { value, ids, contentType }),
    cursor: flexCursor,
    handleResult: true,
  });
  return result;
}

export async function createFlexEntityJson({ value, id, contentType }) {
  const result = await asyncTreeRequester({
    func: effects.createFlexEntityJson.bind(this, { value, id, contentType }),
    cursor: flexCursor,
    handleResult: true,
  });
  return result;
}
