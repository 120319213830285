import React, { useState } from 'react';
import { Message, Field, Input } from '@zendeskgarden/react-forms';

import { SM } from '@zendeskgarden/react-typography';
import { Formik } from 'formik';
import { map, isEmpty, isEqual } from 'lodash';
import { Button } from 'theme/Button';
import { Form, FormFooter } from 'theme/Form';

import { Row, Col } from '@zendeskgarden/react-grid';
import { variables } from 'theme/variables';
import styled from 'styled-components/macro';

const FormWrapper = styled.div`
  margin-bottom: 10px;
`;

const { spacing_lg, spacing } = variables;

function formValidation(values) {
  let errors = {};
  return errors;
}

const AddressForm = ({ index, seed, onSave, onDelete, onInputChange }) => {
  const [initialValues, setInitialValues] = useState({
    name: seed.name || '',
    address1: seed.address1 || '',
    address2: seed.address2 || '',
    zip_code: seed.zip_code || '',
    city: seed.city || '',
    country: seed.country || '',
    state: seed.state || '',
  });
  async function submitAddress(values, { setSubmitting, setStatus }) {
    setSubmitting(true);
    const submission = await onSave(values);
    if (submission.error) {
      setStatus({ error: submission.error });
    } else {
      setStatus({ error: null });
      setTimeout(() => {
        setInitialValues(values);
      }, 1000);
    }
    setSubmitting(false);
  }

  return (
    <Formik
      initialValues={initialValues}
      validate={(values) => formValidation(values)}
      onSubmit={submitAddress}
    >
      {({
        values,
        errors,
        touched,
        handleBlur,
        handleSubmit,
        handleChange,
        isSubmitting,
        setFieldValue,
        status,
      }) => {
        function onHandleChange(e) {
          if (onInputChange) {
            onInputChange(true);
          }
          handleChange(e);
        }

        return (
          <div style={{ width: '400px', maxWidth: '100%', minWidth: '100%' }}>
            <Form size="large" onSubmit={handleSubmit}>
              <Row>
                <Col md={12}>
                  <FormWrapper>
                    <SM xs uppercase slate paddingBottomXs bold left tag="h5">
                      Name
                    </SM>
                    <Field>
                      <Input
                        validation={
                          touched.name && errors.name ? 'error' : undefined
                        }
                        style={{ width: '100%' }}
                        placeholder="Name"
                        value={values.name || ''}
                        id={`inquiry-name-${index}`}
                        name="name"
                        onChange={onHandleChange}
                      />
                    </Field>
                  </FormWrapper>
                </Col>

                <Col md={6}>
                  <FormWrapper>
                    <SM xs uppercase slate paddingBottomXs bold left tag="h5">
                      Address One
                    </SM>
                    <Field>
                      <Input
                        validation={
                          touched.address1 && errors.address1
                            ? 'error'
                            : undefined
                        }
                        style={{ width: '100%' }}
                        placeholder="Address One"
                        value={values.address1 || ''}
                        id={`inquiry-address1-${index}`}
                        name="address1"
                        onChange={onHandleChange}
                      />
                    </Field>
                  </FormWrapper>
                </Col>

                <Col md={6}>
                  <FormWrapper>
                    <SM xs uppercase slate paddingBottomXs bold left tag="h5">
                      Address Two
                    </SM>
                    <Field>
                      <Input
                        validation={
                          touched.address2 && errors.address2
                            ? 'error'
                            : undefined
                        }
                        style={{ width: '100%' }}
                        placeholder="Address Two"
                        value={values.address2 || ''}
                        id={`inquiry-address2-${index}`}
                        name="address2"
                        onChange={onHandleChange}
                      />
                    </Field>
                  </FormWrapper>
                </Col>

                <Col md={6}>
                  <FormWrapper>
                    <SM xs uppercase slate paddingBottomXs bold left tag="h5">
                      Postal/Zip Code
                    </SM>
                    <Field>
                      <Input
                        validation={
                          touched.zip_code && errors.zip_code
                            ? 'error'
                            : undefined
                        }
                        style={{ width: '100%' }}
                        placeholder="(Optional)"
                        value={values.zip_code || ''}
                        id={`inquiry-zip_code-${index}`}
                        name="zip_code"
                        onChange={onHandleChange}
                      />
                    </Field>
                  </FormWrapper>
                </Col>

                <Col md={6}>
                  <FormWrapper>
                    <SM xs uppercase slate paddingBottomXs bold left tag="h5">
                      City
                    </SM>
                    <Field>
                      <Input
                        validation={
                          touched.city && errors.city ? 'error' : undefined
                        }
                        style={{ width: '100%' }}
                        placeholder="City"
                        value={values.city || ''}
                        id={`inquiry-city-${index}`}
                        name="city"
                        onChange={onHandleChange}
                      />
                    </Field>
                  </FormWrapper>
                </Col>

                <Col md={6}>
                  <FormWrapper>
                    <SM xs uppercase slate paddingBottomXs bold left tag="h5">
                      State/Province/Region
                    </SM>
                    <Field>
                      <Input
                        validation={
                          touched.state && errors.state ? 'error' : undefined
                        }
                        style={{ width: '100%' }}
                        placeholder="(Optional)"
                        value={values.state || ''}
                        id={`inquiry-state-${index}`}
                        name="state"
                        onChange={onHandleChange}
                      />
                    </Field>
                  </FormWrapper>
                </Col>

                <Col md={6}>
                  <FormWrapper>
                    <SM xs uppercase slate paddingBottomXs bold left tag="h5">
                      Country
                    </SM>
                    <Field>
                      <Input
                        validation={
                          touched.country && errors.country
                            ? 'error'
                            : undefined
                        }
                        style={{ width: '100%' }}
                        placeholder="Country"
                        value={values.country || ''}
                        id={`inquiry-country-${index}`}
                        name="country"
                        onChange={onHandleChange}
                      />
                    </Field>
                  </FormWrapper>
                </Col>
              </Row>

              <Row>
                <Col md={12}>
                  {!isEmpty(errors) ? (
                    <FormFooter>
                      <Field>
                        <Message validation={'error'}>
                          {map(errors, (v) => v).join(', ')}
                        </Message>
                      </Field>
                    </FormFooter>
                  ) : status && status.error ? (
                    <FormFooter>
                      <Field>
                        <Message validation={'error'}>{status.error}</Message>
                      </Field>
                    </FormFooter>
                  ) : null}
                  <Button
                    buttonStatus={true}
                    error={!!(status && status.error)}
                    disabled={isEqual(values, initialValues)}
                    loading={isSubmitting || undefined}
                    type="submit"
                    primary
                    size="large"
                    style={{
                      marginTop: spacing_lg,
                      display: 'block',
                      width: '100%',
                    }}
                  >
                    Submit
                  </Button>

                  <Button
                    onClick={onDelete}
                    link
                    color="red"
                    style={{
                      fontSize: '11px',
                      marginTop: spacing,
                      display: 'block',
                      width: '100%',
                    }}
                  >
                    Delete Address
                  </Button>
                </Col>
              </Row>
            </Form>
          </div>
        );
      }}
    </Formik>
  );
};

export default AddressForm;
