import styled from 'styled-components/macro';

// LINT OVERRIDE #4
// More exports will be added (this override can be removed at that time)
// eslint-disable-next-line import/prefer-default-export
export const ButtonGroupWrapper = styled('div')`
  display: flex;
  align-items: center;
  button {
    border-radius: 0;
    margin: 0 -1px;
  }
  button:first-child {
    border-radius: 4px 0 0 4px;
  }
  button:last-child {
    border-radius: 0 4px 4px 0;
  }
`;
