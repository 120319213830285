import React, { useState, useCallback } from 'react';
import { trackClick } from 'utility/analytics';
import { Button } from 'theme/Button';
import Icon from 'components/Icon/Icon';
import { useBranch } from 'baobab-react/hooks';
import Flex from 'styled-flex-component';
import { IconButton } from '@zendeskgarden/react-buttons';
import { MD, XL } from '@zendeskgarden/react-typography';
import { get, pick, includes } from 'lodash';
import { closeSidebar } from 'state/sidebars/actions';
import { variables } from 'theme/variables';
import MultiTypeForm from 'components/MultiTypeForm/MultiTypeForm';
import { setCustomerInfo } from 'state/customers/actions';
import { PropTypes } from 'prop-types';
import {
  setActivePartnerCustomer,
  getPartnerCustomers,
  submitPartnerCustomer,
} from 'state/customers/partner/actions';
import { getValues } from 'utility/urlUtils';
import {
  SidebarFilterHeader,
  SidebarFilterBody,
  SidebarContentWrapper,
  SidebarFooterWrapper,
} from '../../GlobalSideBar.styles';

const { spacing, custom_lime: customLime } = variables;

function PartnerCustomerSidebar({ options, onSetCloseWarn, onClickClose }) {
  const { refetchUser } = options;
  const { rfqMetadata, activeCustomer, customerDetails } = useBranch({
    activeCustomer: ['customers', 'activeCustomer', 'customerFormData'],
    customerDetails: ['customers', 'activeCustomer', 'customerDetails'],
    rfqMetadata: ['requestForQuote', 'meta', 'result'],
  });

  const [finalSuccess, setFinalSuccess] = useState(false);

  const registerFields = activeCustomer ? pick(activeCustomer, 'customer') : {};

  const basePath = ['activeCustomer', 'customerFormData'];

  const isSaving = get(customerDetails, 'isSaving');
  const error = get(customerDetails, 'error');

  const onSetWarnSetting = useCallback(
    (warn) => {
      if (onSetCloseWarn) {
        onSetCloseWarn(warn);
      }
    },
    [onSetCloseWarn]
  );

  async function onSubmitCustomer(e) {
    trackClick('onSubmitCustomer', 'Submit', 'PartnerCustomerSidebar');
    e.preventDefault();
    const customer = await submitPartnerCustomer({ refetchUser });
    if (!customer.error) {
      if (!includes(window.location.href, '/profile')) {
        setFinalSuccess(true);
        const querystring = getValues();
        getPartnerCustomers(querystring);
        closeSidebar();
      } else {
        onSetWarnSetting(false);
        setActivePartnerCustomer(customer);
      }
    }
  }

  const onSetChange = useCallback(
    (baseP, category, type, data) => {
      onSetWarnSetting(true);
      setCustomerInfo(baseP, category, type, data);
    },
    [onSetWarnSetting]
  );

  return (
    <SidebarContentWrapper>
      <SidebarFilterHeader padding={`${spacing}`}>
        <Flex full justifyBetween alignCenter>
          <Flex alignCenter>
            <Icon icon="icon-profile" fontSize="18px" color="#fff" />
            <MD scaled paddingHorizontal bold color="#fff">
              {includes(window.location.href, '/profile')
                ? `Edit User Information`
                : `User Information`}
            </MD>
          </Flex>
          <IconButton
            onClick={onClickClose}
            disableBackground
            alignCenter
            justifyEnd
            size="large"
            aria-label="closeSidebar"
          >
            <Icon button icon="icon-close" fontSize="13px" color="#fff" />
          </IconButton>
        </Flex>
      </SidebarFilterHeader>
      {finalSuccess ? (
        <SidebarFilterBody>
          <Flex column alignCenter justifyCenter style={{ marginTop: spacing }}>
            <Icon
              color={customLime}
              icon="icon-check-outline"
              fontSize="57px"
            />
            <XL paddingVerticalLg bold>
              Successfully Added User.
            </XL>
            <Button primary onClick={closeSidebar}>
              Continue
            </Button>
          </Flex>
        </SidebarFilterBody>
      ) : (
        <>
          <SidebarFilterBody>
            <form onSubmit={(e) => onSubmitCustomer(e)}>
              <MultiTypeForm
                contentType={rfqMetadata?.tag_content_types?.AuthUser}
                formName="partnerCustomerApproval"
                error={error}
                // TODO: usual changes have not fixed this will come back to fix
                // eslint-disable-next-line react/jsx-no-bind
                setData={onSetChange.bind(this, basePath)}
                data={registerFields}
              />
            </form>
          </SidebarFilterBody>

          <SidebarFooterWrapper>
            <Button
              error={error}
              buttonStatus
              loading={isSaving}
              style={{ width: '100%' }}
              primary
              onClick={(e) => onSubmitCustomer(e)}
            >
              Save User
            </Button>
          </SidebarFooterWrapper>
        </>
      )}
    </SidebarContentWrapper>
  );
}

PartnerCustomerSidebar.propTypes = {
  options: PropTypes.shape({
    showTabs: PropTypes.bool,
    refetchUser: PropTypes.bool,
    companyTab: PropTypes.string,
    onSubmit: PropTypes.func,
  }),
  onSetCloseWarn: PropTypes.func,
  onClickClose: PropTypes.func,
};

export default PartnerCustomerSidebar;
