import { variables } from 'theme/variables';
const {
  custom_blue,
  spacing_sm,
  color_red_400,
  custom_orange,
  custom_navy,
  custom_slate,
} = variables;

const MenuTheme = {
  'tags.tag_view': (props) => {
    return `
      overflow: hidden;
      white-space: nowrap;
      min-width: 0 !important;
      text-overflow: ellipsis;
      ${
        props.absolute
          ? `
        position: absolute;
        top: 0;
        right: 0;     
      `
          : ''
      }

      ${
        props.button
          ? `
        top: -10px;
        right: -10px;
      `
          : ''
      }

      ${
        props.circle
          ? `
        border-radius: 100px !important;
      `
          : ''
      }

      ${
        props.circle && props.size === 'small'
          ? `
        min-width: 24px;
        min-height: 24px;
      `
          : ''
      }

      ${
        props.circle && props.xs
          ? `
        min-width: 19px !important;
        max-width: 19px !important;
        min-height: 19px !important;
        max-height: 19px !important;
        font-size: 8px !important;
      `
          : ''
      }

      ${
        props.xs && !props.circle
          ? `
        font-size: 8px !important;
        height: 16px !important;
      `
          : ''
      }

      ${
        props.list
          ? `
        margin-right: ${spacing_sm};
        margin-bottom: ${spacing_sm};
      `
          : ''
      }

      ${
        props.blue
          ? `
        background-color: ${custom_blue} !important;
        color: #fff !important;
        * {
          color: #fff !important;
        }
      `
          : ''
      }

      ${
        props.navy
          ? `
        background-color: ${custom_navy} !important;
        color: #fff !important;
        * {
          color: #fff !important;
        }
      `
          : ''
      }

      ${
        props.slate
          ? `
        background-color: #d4dded !important;
        color: ${custom_blue} !important;
        * {
          color: ${custom_blue} !important;
        }
      `
          : ''
      }

      ${
        props.red
          ? `
        background-color: ${color_red_400} !important;
        color: #fff !important;
        * {
          color: #fff !important;
        }
      `
          : ''
      }

      ${
        props.orange
          ? `
        background-color: ${custom_orange} !important;
        color: #fff !important;
        * {
          color: #fff !important;
        }
      `
          : ''
      }

      ${
        props.color
          ? `
        background-color: ${props.color} !important;
        color: #fff !important;
        * {
          color: #fff !important;
        }
      `
          : ''
      }


      ${
        props.shadow
          ? `
        box-shadow: 0px 4px 5px rgba(66, 100, 251, 0.31);
      `
          : ''
      }
      
      ${
        props.hover
          ? `
        &:hover {
          cursor: pointer;
          opacity: 0.8;
        }
       `
          : ''
      }      
        
      ${
        props.center
          ? `
        text-align: center;
        justify-content: center;
      `
          : ''
      }

      ${
        props.uppercase
          ? `
        text-transform: uppercase;
      `
          : ''
      }

      ${
        props.size === 'small'
          ? `
        padding: 0 6px !important;
        font-size: 9px;
        height: 17px;
        font-size: 9px !important;
      `
          : ''
      }

      ${
        props.size === 'medium' || props.medium
          ? `
        font-size: 11px !important;
        font-weight: normal;
      `
          : ''
      }


    `;
  },
};

export default MenuTheme;
