import { canUseDOM } from 'exenv';

let url;
let sentryUrl = 'https://6380a9102a28449ca1a25ce819bedeb7@sentry.io/1441528';
let environment;
let segmentKey = 'tq0FQ03TzXPRufI3yxQ0HqUpGIH6pjV6';
let pipeSearchAnalyticsUrl = 'http://dev-analytics.pipesearch.com:8000';
let pipeSearchAssetManagementUrl = 'https://assets.dev.pipesearch.com';
let intercomAppId = '';
let mapboxKey =
  'pk.eyJ1IjoidnByb2ZldGExMSIsImEiOiJjanZwbWY1bXAyYXpqNDhxbGtuMGJocmNzIn0.S9hlAReThThiaURSmaJOeA';
let lightGallery = 'BEC9479A-43424F0C-BE670406-B9F92D7F';
let streamApiKey;
let streamAppId;

const agGridKey =
  'Using_this_AG_Grid_Enterprise_key_( AG-040786 )_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_( legal@ag-grid.com )___For_help_with_changing_this_key_please_contact_( info@ag-grid.com )___( PipeSearch LLC )_is_granted_a_( Single Application )_Developer_License_for_the_application_( PipeSearch )_only_for_( 1 )_Front-End_JavaScript_developer___All_Front-End_JavaScript_developers_working_on_( PipeSearch )_need_to_be_licensed___( PipeSearch )_has_not_been_granted_a_Deployment_License_Add-on___This_key_works_with_AG_Grid_Enterprise_versions_released_before_( 27 May 2024 )____[v2]_MTcxNjc2NDQwMDAwMA==ac7c37330a19ac9f1a9861e57835ffc8';
const pipefactSummaryPdf = '/documents/PipeFactsforBuyersSample.pdf';

if (canUseDOM && process.env.REACT_APP_LOCAL_BASE) {
  url = process.env.REACT_APP_LOCAL_BASE;
  intercomAppId = process.env.REACT_APP_INTERCOM_APP_ID;
  streamApiKey = process.env.REACT_APP_STREAM_API_KEY;
  streamAppId = process.env.REACT_APP_STREAM_APP_ID;
  segmentKey = process.env.REACT_APP_SEGMENT_KEY;
} else if (canUseDOM) {
  url = window.__BASE_API__;
}

if (canUseDOM && window.__ENVIRONMENT__) {
  environment = window.__ENVIRONMENT__;
} else if (canUseDOM) {
  environment = 'dev';
}

if (canUseDOM && window.__PIPE_SEARCH_ANALYTICS_URL__) {
  pipeSearchAnalyticsUrl = window.__PIPE_SEARCH_ANALYTICS_URL__;
}

if (canUseDOM && window.__PIPE_SEARCH_ASSET_MANAGEMENT_URL__) {
  pipeSearchAssetManagementUrl = window.__PIPE_SEARCH_ASSET_MANAGEMENT_URL__;
} else if (
  canUseDOM &&
  process.env.REACT_APP_PIPE_SEARCH_ASSET_MANAGEMENT_URL
) {
  // Allow the env variable to be passed via command line when running the development server (non-build)
  pipeSearchAssetManagementUrl =
    process.env.REACT_APP_PIPE_SEARCH_ASSET_MANAGEMENT_URL;
}

if (canUseDOM && window.__SENTRY_URL__) {
  sentryUrl = window.__SENTRY_URL__;
}

if (canUseDOM && window.__LG_KEY__) {
  lightGallery = window.__LG_KEY__;
}

if (canUseDOM && window.__SEGMENT_KEY__) {
  segmentKey = window.__SEGMENT_KEY__;
}

if (canUseDOM && window.__MAPBOX_KEY__) {
  mapboxKey = window.__MAPBOX_KEY__;
}

if (canUseDOM && window.__STREAM_API_KEY__) {
  streamApiKey = window.__STREAM_API_KEY__;
  streamAppId = window.__STREAM_APP_ID__;
}

export const BASE = url;
export const SENTRY_URL = sentryUrl;
export const ENVIRONMENT = environment;
export const PIPE_SEARCH_ANALYTICS_URL = pipeSearchAnalyticsUrl;
export const PIPE_SEARCH_ASSET_MANAGEMENT_URL = pipeSearchAssetManagementUrl;
export const INTERCOM_APP_ID = intercomAppId;
export const SEGMENT_KEY = segmentKey;
export const MAPBOX_KEY = mapboxKey;
export const LIGHT_GALLERY_KEY = lightGallery;
export const PIPEFACTS_EXEC_SUMMARY_PDF = pipefactSummaryPdf;
export const STREAM_API_KEY = streamApiKey;
export const STREAM_APP_ID = streamAppId;
export const AG_GRID_KEY = agGridKey;
