import Dropdown from './Dropdown';
import TextField from './TextField';
import Typography from './Typography';
import MainTheme from '../index';

const theme = {
  ...MainTheme,
  ...Dropdown,
  ...TextField,
  ...Typography,
};

export default theme;
