import React from 'react';
import styled from 'styled-components/macro';
import { variables } from 'theme/variables';
import { PropTypes } from 'prop-types';

const { media_md: mediaMd } = variables;

function generateStyle(Type = 'div') {
  return styled(Type)`
    margin-right: 10px;
    min-width: 40px;
    @media ${mediaMd} {
      display: none;
    }
  `;
}

function Platform(props) {
  const { component, children } = props;
  const Type = generateStyle(component); // === 'Col' ? PlatformCol : PlatformComponent;
  return (
    <Type
      // LINT OVERRIDE #3
      // Component wraps another component
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
    >
      {children}
    </Type>
  );
}

Platform.propTypes = {
  component: PropTypes.shape({}),
  children: PropTypes.node,
};

export default Platform;
