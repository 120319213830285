import React from 'react';
import styled from 'styled-components/macro';
import { variables } from 'theme/variables';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const {
  custom_black,
  spacing_sm,
  spacing_xxs,
  custom_border_grey,
  custom_blue,
} = variables;

const IconWrapper = styled.span`
  box-shadow: none;
  display: block;
  outline: none;
  backface-visibility: visible;
  transform: translate3d(0, 0, 0);
  font-size: ${(props) => (props.fontSize ? props.fontSize : '18px')};
  color: ${(props) => (props.color ? props.color : custom_black)};
  cursor: ${(props) => (props.pointer || props.button ? 'pointer' : 'default')};
  transition: all 0.3s ease;
  transform: ${(props) => (props.flipped ? 'rotate(180deg)' : 'rotate(0deg)')};
  &:hover {
    color: ${(props) =>
      props.hoverColor
        ? props.hoverColor
        : props.color
        ? props.color
        : custom_black};
  }
  &:before {
    backface-visibility: visible;
    transform: translate3d(0, 0, 0);
    cursor: ${(props) =>
      props.pointer || props.button ? 'pointer' : 'default'};
  }

  ${(props) =>
    props.buttonStyle
      ? `
    width: 40px;
    height: 40px;
    ${
      props.small
        ? `
      width: 30px;
      height: 30px;
    `
        : ''
    }
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    opacity: 1;
    ${
      props.round
        ? `
      transition: all 0.3s ease;
      width: 35px;
      height: 35px;
      transition: all 0.3s ease;
      background-color: #fff;
      border: 1px solid ${custom_border_grey};
      color: ${custom_blue};
      cursor: pointer;
      border-radius: 100%;
      &:hover {
        background-color: ${props.color || custom_blue};
        color: #fff;
        * {
          color: #fff;
        }
      }
    `
        : ''
    }
    &:before {
      cursor: pointer;
    }
  `
      : ''}

  ${(props) =>
    props.fadeOnHover || props.buttonStyle
      ? `
    opacity: 1;
    transition: opacity 0.3s ease, color 0.3s ease;
    cursor: pointer;
    * {
      cursor: pointer;
    }
    &:before {
      cursor: pointer;
      transition: transform 0.3s ease, color 0.3s ease;
      transform: scale(1);
    }
    &:hover {
      opacity: 0.8;
    }
    &:active {
      &:before {
        transform: scale(0.95);
      }
    }
  `
      : ''}

  ${(props) =>
    props.fadeOnHover
      ? `
    transition: opacity 0.3s ease;
    opacity: 1;
    &:hover {
      opacity: 0.5;
    }
  `
      : ''}

  ${(props) =>
    props.alignCenter
      ? `
    display: flex;
    align-items: center;
  `
      : ''}

  ${(props) =>
    props.spacingRight
      ? `
    margin-right: ${spacing_sm};
  `
      : ''}

  ${(props) =>
    props.spacingLeft
      ? `
    margin-left: ${spacing_sm};
  `
      : ''}

  ${(props) =>
    props.spacingRightSm
      ? `
    margin-right: ${spacing_xxs};
  `
      : ''}

  ${(props) =>
    props.spacingLeftSm
      ? `
    margin-left: ${spacing_xxs};
  `
      : ''}

  ${(props) =>
    props.justifyEnd
      ? `
    justify-content: flex-end;
  `
      : ''}

  ${(props) =>
    props.center
      ? `
    display: flex;
    justify-content: center;
  `
      : ''}

  ${(props) =>
    props.inline
      ? `
    display: inline;
    vertical-align: middle;
    padding-right: 7px;
    line-height: inherit;
  `
      : ''}
`;

/*
    USING FONTAWESOME ICONS
    Include the props: 
      fontAwesome={true}
      icon="icon-name"
      iconPrefix="far" (Defaults to "fad". Use the style prefix listed on the fontawesome site: "far" for the regular icon style and "fas" for the solid style)
    Refer to the icon by the name found in the code snippet at the fontawesome site, minus any 'fa' prefix ('fa-clipboard' becomes icon="clipboard")
    Import the icon in FontAwesomeIconInit.js by using its full name in lowerCamelCasing ('faClipboard') and add to library.add()
    You can debug any unexpected behavior by browsing the available icon definitions directly in node_modules/@fortawesome/pro-duotone-svg-icons

    Using this component without fontAwesome={true} will utilize the IcoMoon font libarary, which is deprecated
*/

const Icon = (props) => {
  return props.fontAwesome ? (
    <IconWrapper {...props} className={`${props.className || ''} icon-wrapper`}>
      <FontAwesomeIcon
        style={!props.fontSize ? {} : { fontSize: props.fontSize }}
        icon={[props.iconPrefix || 'fad', props.icon]}
        color={props.color}
      />
    </IconWrapper>
  ) : (
    <IconWrapper className={`${props.icon} ${props.className}`} {...props} />
  );
};

export default Icon;
