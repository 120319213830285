import { includes } from 'lodash';
import { ENVIRONMENT } from '../constants';

// Returns true if it is not in stage or local, otherwise it returns false
// Helps to add a feature to stage and local development without being accidentally pushed to production.
export function isNotProd() {
  return ENVIRONMENT ? !includes(ENVIRONMENT, 'live') : false;
}

export const webRefactorSwitch = true;

// Returns true if running locally, otherise it returns false
// Helps to add a feature only for local development use without it showing in stage or prod.
export function isOnlyDev() {
  return includes(ENVIRONMENT, 'dev');
}
