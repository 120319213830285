import fetchJson from 'utility/fetchJson';
import { prospectingSearchTypes } from 'utility/staticMap.ts';
import { get, isEmpty } from 'lodash';
import { getValuesForRequest } from 'utility/reverseUrlFilters';
import { BASE } from '../../../constants';

import { customersCursor } from '../actions';

const userSearch = get(prospectingSearchTypes, 'users');

export async function getProspectingHistory({
  sorting = {},
  filters = {},
  pagination = {},
}) {
  const currentFilters = isEmpty(filters)
    ? getValuesForRequest('customers')
    : filters;
  const body = {
    ...currentFilters,
    ...pagination,
    ...sorting,
  };
  const response = await fetchJson(`${BASE}/api/prospect/list/`, {
    method: 'POST',
    body: JSON.stringify(isEmpty(body) ? { page: 1 } : body),
  });
  return response;
}

export async function fetchProspectingSuggestion(key, value) {
  const data = { [key]: value };
  const response = await fetchJson(`${BASE}/api/user/prospect-autocomplete/`, {
    method: 'POST',
    body: JSON.stringify(data),
  });
  return response;
}

export async function getProspectPreview(terms, searchType) {
  let response;
  if (searchType === userSearch) {
    response = await fetchJson(`${BASE}/api/user/prospect-preview/`, {
      method: 'POST',
      body: JSON.stringify(terms),
    });
  } else {
    response = await fetchJson(`${BASE}/api/company/company-preview/`, {
      method: 'POST',
      body: JSON.stringify(terms),
    });
  }
  // If submitting a scroll token, we should add to the existing list
  if (get(terms, 'scroll_token')) {
    response = {
      ...response,
      data: [
        ...customersCursor.get([
          'prospecting',
          'currentSearch',
          'prospects',
          'result',
          'data',
        ]),
        ...get(response, 'data'),
      ],
    };
  }
  return response;
}

export async function ingestProspects(psLogId, enrichmentIds, attributeValues) {
  const data = {
    ps_log_id: psLogId,
    enrichment_ids: enrichmentIds,
    attribute_values: attributeValues,
  };
  const response = await fetchJson(`${BASE}/api/user/prospect-ingest/`, {
    method: 'POST',
    body: JSON.stringify(data),
  });
  return response;
}

export async function ingestCompanies(
  psLogId,
  companyLinkedinUrls,
  attributeValues
) {
  const data = {
    ps_log_id: psLogId,
    company_linkedin_urls: companyLinkedinUrls,
    attribute_values: attributeValues,
  };
  const response = await fetchJson(`${BASE}/api/company/company-ingest/`, {
    method: 'POST',
    body: JSON.stringify(data),
  });
  return response;
}
