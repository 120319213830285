import * as listingEffects from 'state/listings/effects';
import * as contentEffects from 'state/content/effects';
import {
  generateItemFilters,
  generateFilterValues,
} from 'state/requestForQuote/transformer';
import asyncTreeRequester from 'utility/asyncTreeRequester';
import tree from 'state';
import { debounce, keys, get, uniqBy, omitBy, isNil, size } from 'lodash';
import { setValues } from 'utility/urlUtils';
import { generateFiltersObject } from 'utility/reverseUrlFilters';
import { CRITERIA_QUERY_VALUES } from 'state/requestForQuote/transformer_criteria';

import filterFacetsWithCriteria from 'utility/filterFacetsWithCriteria';
import * as effects from './effects';

const searchCursor = tree.select(['search']);

export async function fetchProducts({
  pagination,
  filters,
  sorting,
  keyword,
  criteria,
} = {}) {
  await asyncTreeRequester({
    func: effects.getProductsForSearch.bind(this, { keyword, criteria }),
    pagination,
    filters,
    sorting,
    cursor: searchCursor,
    path: ['c'],
  });
}

export async function fetchListings({
  pagination = { limit: 50 },
  filters,
  sorting,
  keyword,
  criteria,
} = {}) {
  await asyncTreeRequester({
    func: listingEffects.getListings.bind(this, { keyword, criteria }),
    pagination,
    filters,
    sorting,
    cursor: searchCursor,
    path: ['listings'],
  });
}

const DEBOUNCE_TIME = 400;
const debounceProductSearch = debounce(fetchProducts, DEBOUNCE_TIME);
const debounceListingSearch = debounce(fetchListings, DEBOUNCE_TIME);

export async function setActiveRfqItem(seed = {}) {
  searchCursor.set(
    ['searchItemFilter', 'rfqItemFilters'],
    generateItemFilters(null, seed, {
      range: { multiple: true },
      category: { multiple: true },
      hidden: { quantity: true },
    })
  );
}

export function onSearchChange(_, type, data, options = {}) {
  const currentFilters = searchCursor.get([
    'searchItemFilter',
    'rfqItemFilters',
  ]);
  let facetFilters = searchCursor.get(['searchFilters', 'filters']);
  facetFilters = { ...(facetFilters || {}), ...(options.filters || {}) };

  const { filters, criteria, itemFilters, attribute } =
    filterFacetsWithCriteria({
      type,
      data,
      options,
      currentFilters,
      facetFilters,
    });
  searchCursor.set(['searchFilters', 'filters'], { ...criteria, ...filters });
  if (itemFilters) {
    searchCursor.set(
      ['searchItemFilter', 'rfqItemFilters', attribute],
      itemFilters
    );
  }
  if (options.reset) {
    setActiveRfqItem();
  }
  if (criteria) {
    debounceListingSearch({ criteria, filters });
    debounceProductSearch({ criteria, filters });
    const urlQueryParams = generateFiltersObject({ ...criteria, ...filters });
    setValues(urlQueryParams, [
      ...CRITERIA_QUERY_VALUES,
      ...keys(facetFilters),
    ]);
  }
}

export function prepareCriteria({ filters } = {}) {
  let filterData = generateFilterValues(
    filters || searchCursor.get(['searchItemFilter', 'rfqItemFilters'])
  );
  filterData = omitBy(filterData, (d) => isNil(d) || size(d) === 0);
  return filterData;
}

export function onChangeGlobalSearchCriteria(branch, type, data) {
  const currentFilters = searchCursor.get([
    'searchItemFilter',
    'rfqItemFilters',
  ]);
  const newFilters = { ...currentFilters, [type]: data };
  searchCursor.set(['searchItemFilter', 'rfqItemFilters', type], data);
  const reqData = prepareCriteria({ filters: newFilters }) || {};
  const urlQueryParams = generateFiltersObject(reqData);
  setValues(urlQueryParams, CRITERIA_QUERY_VALUES);
}

export function setSearchFilters({ filters }) {
  searchCursor.set(['searchFilters', 'filters'], filters);
}

export async function onChangeCriteria(branch, type, data) {
  searchCursor.set(['searchItemFilter', 'rfqItemFilters', type], data);
  fetchProducts({ criteria: prepareCriteria() });
}

export async function onChangeCriteriaListings(branch, type, data) {
  searchCursor.set(['searchItemFilter', 'rfqItemFilters', type], data);
  fetchListings({ criteria: prepareCriteria() });
}

export async function getContentForSearch({
  pagination,
  filters,
  sorting,
} = {}) {
  await asyncTreeRequester({
    func: contentEffects.getContentList.bind(this),
    pagination,
    filters,
    sorting,
    cursor: searchCursor,
    path: ['content'],
    transformer({ result, cursor }) {
      const updatedResult = result.docs;
      updatedResult.docs = uniqBy(
        [...get(cursor, 'result.docs', []), ...result.docs],
        'id'
      );
      return updatedResult;
    },
  });
}
