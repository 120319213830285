import React from 'react';
import { map } from 'lodash';
import Flex from 'styled-flex-component';
import DocumentDownload from 'components/DocumentDownload/DocumentDownload';
import ThumbnailDownload from 'components/DocumentDownload/ThumbnailDownload';

const DropZoneFileRenderer = ({
  onClickThumbnail,
  files,
  onRemoveFile,
  disableDelete,
  isImage,
}) => {
  return (
    <div className="dropzone-fileloader">
      <Flex wrap="wrap" className="file-list">
        {map(files, (f, i) => {
          const title = f.saved.original_file_name;
          const type = title ? title.split('.').pop() : '';
          const fileId = f.saved.id;
          return isImage ? (
            <div
              className="file-list-item"
              onClick={
                onClickThumbnail
                  ? onClickThumbnail.bind(this, { data: f, key: i })
                  : null
              }
              key={i}
            >
              <ThumbnailDownload
                modal={!onClickThumbnail}
                disableDelete={disableDelete}
                onRemoveFile={(e) => {
                  e.stopPropagation();
                  onRemoveFile({ data: f, key: i });
                }}
                documentItem={{ title, type, fileId }}
                key={i}
              />
            </div>
          ) : (
            <div
              className="file-list-item"
              style={{
                maxWidth: '350px',
                minWidth: '250px',
                padding: '5px 12px',
                marginLeft: '-12px',
              }}
              key={i}
            >
              <DocumentDownload
                disableDelete={disableDelete}
                onRemoveFile={onRemoveFile.bind(this, { data: f, key: i })}
                documentItem={{ title, type, fileId }}
              />
            </div>
          );
        })}
      </Flex>
    </div>
  );
};

export default DropZoneFileRenderer;
