import React, { useState, useEffect } from 'react';
import Promise from 'bluebird';
import { Button } from 'theme/Button';
import uuid from 'uuid';
import { NewDashboardCard } from 'components/DashboardCard/DashboardCard';
import Flex from 'styled-flex-component';
import { SM, MD } from '@zendeskgarden/react-typography';
import AddressForm from '../AddressForm/AddressForm';
import Icon from 'components/Icon/Icon';
import PhoneInput from 'react-phone-input-2';
import { isEqual, map, get, filter } from 'lodash';
import styled from 'styled-components/macro';
import { LineItemRow, PhoneRowStyles } from './LineItemRows.styles.js';
import { IconOutlineButton } from 'theme/Global.styles.js';
import { Field, Input } from '@zendeskgarden/react-forms';

export const Wrapper = styled.div``;
export function PhoneNumbersList({
  phoneNumbers,
  onSave,
  onDelete,
  onSetCloseWarn,
}) {
  const [items, setItems] = useState(phoneNumbers || []);
  useEffect(() => {
    setItems(phoneNumbers || []);
  }, [get(phoneNumbers, 'length')]);

  return (
    <Wrapper>
      {items.length ? (
        <div>
          {map(items, (item, i) => {
            return (
              <PhoneRow
                onSetCloseWarn={onSetCloseWarn}
                onSave={onSave}
                onDelete={() => {
                  if (item.uuid) {
                    setItems(
                      filter(items, (number) => number.uuid !== item.uuid)
                    );
                  } else {
                    onDelete(item);
                  }
                }}
                key={`${i}-${item.id || 'n'}-${item.uuid || 'u'}`}
                number={item}
              />
            );
          })}
          <AddNewButton
            title={'Add New Phone Number'}
            onClick={() => setItems([...items, { uuid: uuid.v4() }])}
          />
        </div>
      ) : (
        <EmptyState
          emptyTitle="There Are No Phone Numbers"
          title="Add First Phone Number"
          onClick={() => setItems([{ uuid: uuid.v4() }, ...items])}
        />
      )}
    </Wrapper>
  );
}

export function PhoneRow({
  onSave,
  onDelete,
  number = {},
  onSetCloseWarn = () => {},
}) {
  const [item, setItem] = useState(number);
  const [status, setStatus] = useState({});

  async function onSaveLocal(number, data) {
    setStatus({ saving: true });
    const d = await onSave(number, data);
    await Promise.delay(500);
    if (d.error) {
      setStatus({ error: d.error });
    } else {
      setStatus({});
    }
  }
  return (
    <NewDashboardCard style={{ marginBottom: '10px' }}>
      <LineItemRow>
        <div style={{ width: '100%' }}>
          <PhoneRowStyles
            style={{ flex: 1, display: 'flex', alignItems: 'flex-end' }}
          >
            <div style={{ flex: 1, marginRight: '20px' }}>
              <SM xs uppercase slate paddingBottomXs bold left tag="h5">
                Name
              </SM>
              <Field>
                <Input
                  style={{ width: '100%' }}
                  placeholder="Phone Name"
                  value={item.name || ''}
                  id={`inquiry-name-phone-${item.id}`}
                  name="name"
                  onChange={(e) => {
                    onSetCloseWarn(true);
                    setItem({ ...item, name: e.currentTarget.value });
                  }}
                />
              </Field>
            </div>
            <div style={{ flex: 1 }}>
              <SM xs uppercase slate paddingBottomXs bold left tag="h5">
                Phone Number
              </SM>
              <PhoneInput
                country={'us'}
                placeholder="Enter Phone Number"
                value={item.phone_number}
                onChange={(phone) => {
                  onSetCloseWarn(true);
                  setItem({ ...item, phone_number: phone });
                }}
              />
            </div>
          </PhoneRowStyles>

          <div>
            <Button
              // disabled={isEqual(item, number)}
              buttonStatus={true}
              error={status.error}
              loading={status.saving}
              onClick={onSaveLocal.bind(this, number, {
                name: item.name,
                phone_number: `+${item.phone_number}`.replace('++', '+'),
              })}
              primary
              style={{ marginTop: '30px', display: 'block', width: '100%' }}
            >
              Submit
            </Button>
            {status.error ? (
              <SM xs error>
                {status.error}
              </SM>
            ) : null}

            <Button
              onClick={onDelete.bind(this, number, item)}
              link
              color="red"
              style={{
                fontSize: '11px',
                marginTop: '20px',
                display: 'block',
                width: '100%',
              }}
            >
              Delete Phone Number
            </Button>
          </div>
        </div>
      </LineItemRow>
    </NewDashboardCard>
  );
}

// ADDRESS ------------------------------------------------

export function AddressList({ addresses, onSave, onDelete, onSetCloseWarn }) {
  const [items, setItems] = useState(addresses || []);
  useEffect(() => {
    setItems(addresses || []);
  }, [get(addresses, 'length')]);

  return (
    <Wrapper>
      {get(items, 'length') ? (
        <div>
          {map(items, (item, i) => {
            return (
              <AddressRow
                onSetCloseWarn={onSetCloseWarn}
                key={`${i}-${item.id || item.uuid}`}
                index={i}
                onSave={onSave}
                onDelete={() => {
                  if (item.uuid) {
                    setItems(
                      filter(items, (address) => address.uuid !== item.uuid)
                    );
                  } else {
                    onDelete(item);
                  }
                }}
                address={item}
              />
            );
          })}
          <AddNewButton
            title={'Add New Address'}
            onClick={() => setItems([...items, { uuid: uuid.v4() }])}
          />
        </div>
      ) : (
        <EmptyState
          emptyTitle="There Are No Addresses"
          title="Add First Address"
          onClick={() => setItems([...items, { uuid: uuid.v4() }])}
        />
      )}
    </Wrapper>
  );
}

export function AddressRow({
  index,
  placeholder,
  onSave,
  onDelete,
  address = {},
  onSetCloseWarn,
}) {
  return (
    <NewDashboardCard style={{ marginBottom: '10px' }}>
      <LineItemRow>
        <div style={{ flex: 1 }}>
          <AddressForm
            key={index}
            index={index}
            onInputChange={() => onSetCloseWarn(true)}
            onSave={onSave.bind(this, address)}
            onDelete={onDelete.bind(this, address)}
            seed={address}
          />
        </div>
      </LineItemRow>
    </NewDashboardCard>
  );
}

export function EmptyState({ onClick, title, emptyTitle }) {
  return (
    <NewDashboardCard>
      <Flex
        style={{ padding: '30px', cursor: 'pointer' }}
        onClick={onClick}
        column
        alignCenter
        justifyCenter
      >
        <MD bold paddingBottomSm>
          {emptyTitle}{' '}
        </MD>
        <Flex alignCenter>
          <IconOutlineButton alignCenter justifyCenter>
            <Icon
              icon="icon-plus"
              color={'#fff'}
              fontSize="9px"
              onClick={(e) => {}}
            />
          </IconOutlineButton>
          <SM link navy uppercase xs>
            {title}
          </SM>
        </Flex>
      </Flex>
    </NewDashboardCard>
  );
}

export function AddNewButton({ style, onClick, title }) {
  return (
    <Flex
      style={
        style
          ? { ...style, cursor: 'pointer' }
          : { marginTop: '30px', cursor: 'pointer' }
      }
      onClick={onClick}
      column
      alignCenter
      justifyCenter
    >
      <Flex alignCenter>
        <IconOutlineButton alignCenter justifyCenter>
          <Icon
            icon="icon-plus"
            color={'#fff'}
            fontSize="9px"
            onClick={(e) => {}}
          />
        </IconOutlineButton>
        <SM link navy uppercase xs>
          {title}
        </SM>
      </Flex>
    </Flex>
  );
}
