import algebra from 'algebra.js';
import { pickBy, isNull, keys, isUndefined } from 'lodash';

export function calculateWall({ id, od, wall, solveFor }) {
  od = od ? parseFloat(od) : null;
  id = id ? parseFloat(id) : null;
  wall = wall ? parseFloat(wall) : null;

  const isFalsy = keys(
    pickBy({ id, od, wall }, (v) => isUndefined(v) || isNull(v))
  );
  solveFor = solveFor || isFalsy[0];

  od = od || 'od';
  id = id || 'id';
  wall = wall || 'wall';

  var eq = algebra.parse(`${od} = ${id} + (${wall} * 2)`);
  var ans = eq.solveFor(solveFor);
  try {
    // eslint-disable-next-line no-eval
    return parseFloat(eval(ans.toString()).toFixed(4)) || '0';
  } catch (err) {
    console.log(err.message, 'WALL CALC');
    return '';
  }
}
