import React from 'react';
import { get } from 'lodash';
import { canUseDOM } from 'exenv';
import { page } from 'utility/analytics';

export default function pageWrapper(
  Component,
  { ignoreScrollTop, pageName, pageTitle, pageType, enableAll }
) {
  class PageViewWrapper extends React.Component {
    componentDidMount() {
      this.trackCurrentPage();
      //&& get(this.props, 'history.action') !== 'POP'
      if (!ignoreScrollTop && canUseDOM) {
        setTimeout(() => {
          window.scrollTo(0, 0);
        }, 150);
      }
    }

    render() {
      return (
        <Component
          {...this.props}
          trackCurrentPage={this.trackCurrentPage.bind(this)}
        />
      );
    }

    trackCurrentPage() {
      const properties = {
        pageType: pageType || 'route',
      };
      const integrations = enableAll
        ? {
            integrations: {
              'Google Analytics': true,
              'Google AdWords': true,
              'Facebook Audiences': true,
              'Twitter Ads': true,
              'Facebook Conversions': true,
            },
          }
        : null;
      page(pageName, properties, integrations);
    }
  }

  return PageViewWrapper;
}
