import tree from 'state';
import * as effects from './effects';
import asyncTreeRequester from 'utility/asyncTreeRequester';

const sellersApplicationCursor = tree.select(['sellersApplication']);

export async function createSimpleSupplyInquery(data) {
  const supply = await asyncTreeRequester({
    func: effects.createSimplesupplyInquery.bind(this, data),
    cursor: sellersApplicationCursor,
    path: ['simpleInquiery'],
  });
  return supply;
}

export async function getSupplySimpleInquiry(simpleInquiryId) {
  const activeSimpleInquiry = await asyncTreeRequester({
    func: effects.getSimpleSupplyInqueryItem.bind(this, simpleInquiryId),
    cursor: sellersApplicationCursor,
    path: ['activeSimpleInquiry'],
  });
  return activeSimpleInquiry;
}
