import React, { useEffect } from 'react';
import { fetchGlobalMetallargy } from 'state/global/actions';
import { fetchPublicMeta } from 'state/application/actions';
const GlobalAppApiRequests = (props) => {
  useEffect(() => {
    fetchGlobalMetallargy();
  }, []);

  useEffect(() => {
    fetchPublicMeta();
  }, []);
  return null;
};

export default GlobalAppApiRequests;
